import styled from 'styled-components';
// import { shade } from 'polished';

export const Container = styled.div`
  margin: 0;
  padding: 0 30px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.backgroundColor};
  z-index: 2;

  .responsive{
    display: none;

    @media screen and (max-width: 1080px) {
      display: block;
    }
  }

  .full-size{
    @media screen and (max-width: 1080px) {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    padding: 0 10px;
  }
`;

export const Content = styled.div`
  /* max-width: 1120px; */
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    /* margin-left: auto; */
    background: transparent;
    border: 0;
    display: block;

    svg {
      color: #999591;
      width: 20px;
      height: 20px;
    }
  }
`;

export const WrapperMenuIcon = styled.div`
  width: 40px;

  svg{
    font-size: 25px;
    cursor: pointer;
  }

  @media screen and (max-width: 500px) {
    margin-left: 10px;
  }
`;

export const WrapperLogo = styled.div`
  width: 260px;

  > a img {
    height: 60px;
  }

  @media screen and (max-width: 1080px) {
    flex: 1;
  }
`;

export const WrapperTitle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;

  > svg{
    font-size: 20px;
    margin-top: 0px;
    margin-right: 10px;
  }

  > h1{
    font-size: 18px;
    font-weight: 300;
  }

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

export const WrapperActions = styled.div`
  margin-left: 10px;
`;

export const WrapperSelect = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: end;
`;

export const WrapperSelectItem = styled.div`
  flex: 1;
  margin: 0 5px;
  max-width: 200px;
`;