import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import FormInput from '../../components/FormInput';
// import FormInputMask from '../../components/FormInputMask';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem,
} from './styles';

interface CursoFormData {
  id: string;
  titulo: string;
  codigo: string;
}

interface OptionsData {
  value: string;
  label: string;
}

const CursosEdicao: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location:any = useLocation();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<CursoFormData>({} as CursoFormData);

  useEffect(() => {
    if(location && location.state){
      setSelectedItem(location.state.selectedItem);
    }
  }, [location]);

  useEffect(() => {
    if(selectedItem){
      //console.log("selectedItem", selectedItem);
      formRef.current?.setFieldValue("titulo", selectedItem.titulo);
      formRef.current?.setFieldValue("codigo", selectedItem.codigo);
    }
  }, [selectedItem]);

  const handleSubmit = useCallback(
    async (data: CursoFormData) => {
      try {
        // console.log("handleSubmit", data);

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          titulo: Yup.string().required('Título obrigatório'),
          // codigo: Yup.string().required('Código obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let params = {
          titulo: data.titulo,
          // codigo: data.codigo,
        }

        // console.log(params);

        await api.put(`/curso/${selectedItem.id}`, params);

        addToast({
          type: 'success',
          title: 'Curso atualizado com sucesso',
          description: '',
        });

        goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }
        
        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar curso',
            description: err.response?.data[error] ?
              err.response.data[error] :
              'Erro ao atualizar curso, tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, goBack, selectedItem],
  );

  function goBack() {
    history.push('/cursos');
  }

  if(loading){
    return (
      <Loading />
    )
  }

  return (
    <Container>
      <Content>
        <Card>
          <div className="form">
            <div className="header">
              <h2>Editar Curso</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            <Form ref={formRef} onSubmit={handleSubmit}>
                {/* <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="codigo"
                      type="text"
                      placeholder="Código"
                    />
                  </WrapRowItem>
                </WrapRow> */}

                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="titulo"
                      type="text"
                      placeholder="Título"
                    />
                  </WrapRowItem>
                </WrapRow>

              <Button type="submit" loading={loading}>
                Atualizar
              </Button>

            </Form>
          </div>
        </Card>
      </Content>
    </Container>
  );
};

export default CursosEdicao;
