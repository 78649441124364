import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { FiTrash, FiPlus, FiEdit } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { useAuth } from '../../../../hooks/auth';
import { useToast } from '../../../../hooks/toast';

import Card from '../../../../components/Card';
import Loading from '../../../../components/Loading';

import api from '../../../../services/api';

import {
  Container,
  Content,
  TextField,
  ClearButton
} from './styles';

interface UsersInterface {
  id: string;
  name: string;
}

const Users: React.FC = () => {
  const history = useHistory();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal)

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<UsersInterface[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filteredItems, setFilteredItems] = useState<UsersInterface[]>([]);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (clearSelectedRows) {
      setClearSelectedRows(false)
    }
  }, [clearSelectedRows]);

  const getUsers = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/usuario`);
      //console.log("response", response.data);

      setUsers(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  // function goStoreForm() {
  //   history.push("/account/users/cadastro");
  // }

  function goUpdateForm() {
    history.push("/account/users/edicao", {
      selectedItem: selectedRows[0]
    });
  }

  const handleChange = (state: any) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    //console.log('Selected Rows: ', state.selectedRows);

    setSelectedRows(state.selectedRows);
  };

  const actions = (
    <></>
    // <FiPlus className="icon" onClick={() => {
    //   goStoreForm();
    // }} />
  );

  const contextActions = (
    <>
      {selectedRows.length === 1 &&
        <FiEdit className="icon" style={{ marginRight: "15px" }} onClick={() => {
          goUpdateForm();
        }} />
      }
    </>
  );

  const filtered = useMemo(() => {
    if (!filterText)
      return users

    let search = filterText.toLowerCase();

    search = search.split("-").join("");
    search = search.split(".").join("");

    search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

    return users.filter((user:any) => {
      return user.dados_usuario?.nome.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        user.dados_usuario?.cpf.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        user.email.toLowerCase().normalize('NFD').includes(search)
    })
  }, [filterText, users])

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <>
        <TextField id="search" type="text" placeholder="Pesquisar" aria-label="Search Input" value={filterText} onChange={(e: any) => setFilterText(e.target.value)} />
        <ClearButton type="button" onClick={handleClear}>x</ClearButton>
      </>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: 'Nome',
      selector: 'nome',
      sortable: false,
      // right: true,
      cell: (row: any) => {
        return (
          <>
            {row.dados_usuario?.nome}
          </>
        )
      }
    },
    {
      name: 'E-mail',
      selector: 'email',
      sortable: false,
      // right: true,
    },
    {
      name: 'CPF',
      selector: 'cpf',
      sortable: false,
      // right: true,
      cell: (row: any) => {
        return (
          <>
            {row.dados_usuario?.cpf}
          </>
        )
      }
    },
    {
      name: 'Tipo de Usuário',
      selector: 'tipo',
      sortable: false,
      // right: true,
      cell: (row: any) => {
        return (
          <>
            {row.roles.map((item:any) => {
              return (
                <span key={`${item.name}`} style={{marginRight:"10px"}}>
                  {item.name} 
                </span>
              )
            })}
          </>
        )
      }
    },
    {
      name: 'Permissões Especiais',
      selector: 'tipo',
      sortable: false,
      // right: true,
      cell: (row: any) => {
        return (
          <>
            {row.permissions.map((item:any) => {
              return (
                <span key={`${item.name}`} style={{marginRight:"10px"}}>
                  {item.name} 
                </span>
              )
            })}
          </>
        )
      }
    },
  ];

  return (
    <Container>
      <Content>
        <Card>
          <DataTable
            title="Usuários"
            columns={columns}
            data={filtered}
            selectableRows
            highlightOnHover
            onSelectedRowsChange={handleChange}
            defaultSortField="name"
            pagination
            actions={actions}
            contextActions={contextActions}
            subHeader
            subHeaderComponent={subHeaderComponent}
            noDataComponent="Nenhum registro encontrado."
            paginationComponentOptions={{
              rowsPerPageText: 'Itens por página',
              rangeSeparatorText: 'de'
            }}
            clearSelectedRows={clearSelectedRows}
            contextMessage={{
              singular: 'item selecionado',
              plural: 'itens selecionados',
              message: ''
            }}
            progressComponent={<Loading />}
            progressPending={loading}
          />
        </Card>
      </Content>
    </Container>
  );
};

export default Users;
