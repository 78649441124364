import { FormHandles } from '@unform/core';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiX } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import convertUTCDateToLocalDate from '../../utils/convertUTCDateToLocalDate';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
// import Loading from '../../components/Loading';
// import FormInputMask from '../../components/FormInputMask';
import Timeline from '../../components/Timeline';
// import UploadInput from '../../components/UploadInput';

import api from '../../services/api';

import {
  Container,
  Content,
  ContratoData,
  WrapRow,
  WrapRowItem,
} from './styles';

interface ContratosResumoVisualizarInterface {
  id: string;
  data_contrato: string;
  prazo_entrega: string;
  tema: any;
  observacoes: string;
  produtos: any;
  especialista: any;
  remetente: any;
  valor_total: any;
  hash: string;
  tipo_contratacao: string;
  contatos: any;
  data_pagamento_comandado: string;
  data_pagamento_permitido: string;
  data_pagamento_previsao: string;
  status: any;
  dados_pagamento: any;
  arquivos: any;
  timeline: any;
  arquivo_contrato: any;
  arquivo_direito_imagem: any;
  status_cancelamento: any;
  blacklist: any;
  matricula: string;
  tipo_contrato: string;
  data_prestacao_servico: string;
  arquivo_certificado: any;
  disciplina_producao: any;
  curso_producao: any;
  id_tema: string;
  cliente: any;
}

interface OptionsData {
  value: any;
  label: string;
}

const ContratosResumoVisualizar: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();
  const location: any = useLocation();

  const { user, token, roles, permissions, routes } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal);

  const [loading, setLoading] = useState(false);
  const [selectedContrato, setSelectedContrato] = useState<
    ContratosResumoVisualizarInterface
  >({} as ContratosResumoVisualizarInterface);
  const [pj, setPJ] = useState<any>({} as any);
  const [arquivos, setArquivos] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<
    ContratosResumoVisualizarInterface
  >({} as ContratosResumoVisualizarInterface);
  const [forceGoBack, setForceGoBack] = useState(false);
  const [blocked, setBlocked] = useState(false);

  useEffect(() => {
    if (location && location.state) {
      //console.log("location", location);
      setSelectedItem(location.state.selectedItem);
    } else {
      if (location && location.search) {
        //console.log("location", location.search);
        const query = new URLSearchParams(location.search);
        const id_contrato = query.get('id_contrato');
        //console.log("id", id_contrato);

        if (id_contrato && user.id) {
          getContrato(id_contrato);
          setForceGoBack(true);
        }
      }
    }
  }, [location, user]);

  useEffect(() => {
    if (selectedItem && selectedItem.id) {
      if (user.id) {
        getContrato(selectedItem.id);
      }
    }
  }, [selectedItem, user]);

  useEffect(() => {
    if (selectedContrato && selectedContrato.id) {
      // console.log("contrato", selectedContrato)

      if (selectedContrato.especialista?.dados_usuario?.pj_cnpj) {
        searchCNPJ(selectedContrato.especialista?.dados_usuario?.pj_cnpj);
      }

      if (!selectedContrato.arquivo_certificado) {
        if (
          selectedContrato.status?.slug === 'pagamento_permitido' ||
          selectedContrato.status?.slug === 'pagamento_comandado'
        ) {
          gerarCertificado();
        }
      }
    }
  }, [selectedContrato]);

  const getContrato = useCallback(
    async id => {
      setLoading(true);

      try {
        const response = await api.get(`/contrato/${id}`);
        //console.log("contrato", response.data);

        if (response.data.id_especialista == user.id) {
          setSelectedContrato(response.data);
        } else {
          setBlocked(true);
        }

        if (response.data.arquivos) {
          getTiposArquivo(response.data.arquivos);
        }
      } catch (error) {
        //console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [user],
  );

  const gerarCertificado = useCallback(
    async (user_action = false) => {
      setLoading(true);

      const params: any = {
        hash: selectedContrato.hash,
      };

      try {
        const response = await api.post(`/certificado`, params);
        //console.log("gerarCertificado", response.data);

        if (user_action) {
          addToast({
            type: 'success',
            title: 'Certificado gerado com sucesso',
            description: '',
          });
        }

        getContrato(selectedContrato.id);
      } catch (error) {
        //console.log(error);

        if (user_action) {
          addToast({
            type: 'error',
            title: 'Houve um problema ao gerar o certificado',
            description: '',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [selectedContrato],
  );

  const getTiposArquivo = useCallback(async arquivosContrato => {
    setLoading(true);

    try {
      const response = await api.get(`/tipo-arquivo`);
      //console.log("response", response.data);

      let temp: any = [];

      arquivosContrato.forEach((item: any) => {
        let current = response.data.find(
          (n: any) => n.id === item.id_tipo_arquivo,
        );

        const params = {
          ...item,
          titulo: current ? current.titulo : '',
        };

        temp.push(params);
      });

      //console.log("arquivos", temp);
      setArquivos(temp);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const dataContrato = useMemo(() => {
    if (selectedContrato.data_contrato) {
      let dt = convertUTCDateToLocalDate(
        new Date(selectedContrato.data_contrato),
      );
      return `${('0' + dt.getDate()).slice(-2)}/${(
        '0' +
        (dt.getMonth() + 1)
      ).slice(-2)}/${dt.getFullYear()}`;
    } else {
      return '';
    }
  }, [selectedContrato]);

  const dataPagamentoComandado = useMemo(() => {
    if (selectedContrato.data_pagamento_comandado) {
      let dt = convertUTCDateToLocalDate(
        new Date(selectedContrato.data_pagamento_comandado),
      );
      return `${('0' + dt.getDate()).slice(-2)}/${(
        '0' +
        (dt.getMonth() + 1)
      ).slice(-2)}/${dt.getFullYear()}`;
    } else {
      return '';
    }
  }, [selectedContrato]);

  const dataPagamentoPrevisao = useMemo(() => {
    if (selectedContrato.data_pagamento_previsao) {
      let dt = convertUTCDateToLocalDate(
        new Date(selectedContrato.data_pagamento_previsao),
      );
      return `${('0' + dt.getDate()).slice(-2)}/${(
        '0' +
        (dt.getMonth() + 1)
      ).slice(-2)}/${dt.getFullYear()}`;
    } else {
      return '';
    }
  }, [selectedContrato]);

  const dataPagamentoPermitido = useMemo(() => {
    if (selectedContrato.data_pagamento_permitido) {
      let dt = convertUTCDateToLocalDate(
        new Date(selectedContrato.data_pagamento_permitido),
      );
      return `${('0' + dt.getDate()).slice(-2)}/${(
        '0' +
        (dt.getMonth() + 1)
      ).slice(-2)}/${dt.getFullYear()}`;
    } else {
      return '';
    }
  }, [selectedContrato]);

  const dataPrazoEntrega = useMemo(() => {
    if (selectedContrato.prazo_entrega) {
      let dt = convertUTCDateToLocalDate(
        new Date(selectedContrato.prazo_entrega),
      );
      return `${('0' + dt.getDate()).slice(-2)}/${(
        '0' +
        (dt.getMonth() + 1)
      ).slice(-2)}/${dt.getFullYear()}`;
    } else {
      return '';
    }
  }, [selectedContrato]);

  const dataPrestacaoServico = useMemo(() => {
    if (selectedContrato.data_prestacao_servico) {
      let dt = convertUTCDateToLocalDate(
        new Date(selectedContrato.data_prestacao_servico),
      );

      const hora_split = selectedContrato.data_prestacao_servico
        .split('T')[1]
        .split(':');
      const hora = `${hora_split[0]}:${hora_split[1]}`;

      // console.log("hora", hora )

      if (hora !== '00:00') {
        return `${('0' + dt.getDate()).slice(-2)}/${(
          '0' +
          (dt.getMonth() + 1)
        ).slice(-2)}/${dt.getFullYear()} às ${hora}`;
      } else {
        return `${('0' + dt.getDate()).slice(-2)}/${(
          '0' +
          (dt.getMonth() + 1)
        ).slice(-2)}/${dt.getFullYear()}}`;
      }
    }
  }, [selectedContrato]);

  const searchCNPJ = useCallback(async cnpj => {
    if (cnpj.length > 0) {
      const cnpj_final = cnpj
        .split('_')
        .join('')
        .replace('-', '')
        .split('.')
        .join('')
        .replace('/', '');

      if (cnpj_final.length === 14) {
        const response = await api.get(
          `https://brasilapi.com.br/api/cnpj/v1/${cnpj_final}`,
        );
        //console.log("cnpj:", response.data);

        if (response.data) {
          setPJ(response.data);
        }
      }
    }
  }, []);

  function goBack() {
    if (forceGoBack) {
      history.push('/contratos');
    } else {
      history.goBack();
    }
  }

  if (blocked) {
    return (
      <Container>
        <Content>
          <h3>Sem permissão de acesso</h3>
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Content>
        <div className="form">
          <Card>
            <div className="header" style={{ height: '0px' }}>
              <h2>Contrato - {selectedContrato.hash}</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            <ContratoData>
              <div className="wrapper-data">
                <div className="wrapper-data-label">Tipo de Contrato:</div>
                <div className="wrapper-data-info">
                  {selectedContrato.tipo_contrato === 'producao_atual'
                    ? 'Produção'
                    : selectedContrato.tipo_contrato === 'manutencao_reativa'
                    ? 'Manutenção Reativa'
                    : selectedContrato.tipo_contrato === 'manutencao_proativa'
                    ? 'Manutenção Proativa'
                    : selectedContrato.tipo_contrato === 'manutencao_reativa'
                    ? 'Manutenção Reativa'
                    : selectedContrato.tipo_contrato === 'manutencao_proativa'
                    ? 'Manutenção Proativa'
                    : selectedContrato.tipo_contrato ===
                      'manutencao_proativa_direcionada'
                    ? 'Manutenção Proativa Direcionada'
                    : selectedContrato.tipo_contrato === 'terceirizado'
                    ? 'Terceirizado'
                    : selectedContrato.tipo_contrato === 'prestacao_servico'
                    ? 'Prestação de Serviço'
                    : selectedContrato.tipo_contrato
                    ? selectedContrato.tipo_contrato
                        .replace('_', ' ')[0]
                        .toUpperCase() +
                      selectedContrato.tipo_contrato
                        .replace('_', ' ')
                        .substring(1)
                    : ''}
                </div>
              </div>

              {selectedContrato && selectedContrato.id_tema ? (
                <div className="wrapper-data">
                  <div className="wrapper-data-label">Tema:</div>
                  <div className="wrapper-data-info">
                    {selectedContrato.tema?.codigo_tema
                      ? `${selectedContrato.tema?.codigo_tema} - `
                      : ''}
                    {selectedContrato.tema?.titulo}
                  </div>
                </div>
              ) : (
                <></>
              )}

              {selectedContrato && selectedContrato.disciplina_producao ? (
                <div className="wrapper-data">
                  <div className="wrapper-data-label">Disciplina:</div>
                  <div className="wrapper-data-info">
                    {selectedContrato.disciplina_producao?.codigo_producao
                      ? `${selectedContrato.disciplina_producao?.codigo_producao} - `
                      : ''}
                    {selectedContrato.disciplina_producao?.disciplina?.titulo}
                  </div>
                </div>
              ) : (
                <></>
              )}

              {selectedContrato && selectedContrato.curso_producao ? (
                <div className="wrapper-data">
                  <div className="wrapper-data-label">Curso:</div>
                  <div className="wrapper-data-info">
                    {selectedContrato.curso_producao?.codigo_producao
                      ? `${selectedContrato.curso_producao?.codigo_producao} - `
                      : ''}
                    {selectedContrato.curso_producao?.curso?.titulo}
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="wrapper-data">
                <WrapRow>
                  <WrapRowItem>
                    <div className="wrapper-data-label">Status:</div>
                  </WrapRowItem>
                </WrapRow>

                <div className="wrapper-timeline">
                  <Timeline
                    items={
                      selectedContrato.timeline ? selectedContrato.timeline : []
                    }
                  />
                </div>
              </div>

              <div className="wrapper-data">
                <div className="wrapper-data-label">Especialista:</div>
                <div className="wrapper-data-info">
                  Nome: {selectedContrato.especialista?.dados_usuario?.nome}
                </div>
                <div className="wrapper-data-info">
                  Email: {selectedContrato.especialista?.email}
                </div>
                <div className="wrapper-data-info">
                  CPF: {selectedContrato.especialista?.dados_usuario?.cpf}
                </div>
                <div className="wrapper-data-info">
                  Matricula:{' '}
                  {selectedContrato.especialista?.dados_usuario?.matricula}
                </div>
              </div>

              <div className="wrapper-data">
                <div className="wrapper-data-label">Remetente:</div>
                <div className="wrapper-data-info">
                  Nome: {selectedContrato.remetente?.dados_usuario?.nome}
                </div>
                <div className="wrapper-data-info">
                  Email: {selectedContrato.remetente?.email}
                </div>
              </div>

              <div className="wrapper-data">
                <div className="wrapper-data-label">Dados do Contrato:</div>
                <div className="wrapper-data-info">
                  Tipo de Contratação:
                  {selectedContrato.tipo_contratacao === 'pessoa_juridica'
                    ? ' Pessoa Juridica'
                    : ''}
                  {selectedContrato.tipo_contratacao === 'pessoa_fisica'
                    ? ' Pessoa Física'
                    : ''}
                  {selectedContrato.tipo_contratacao === 'interno'
                    ? ' Interno'
                    : ''}
                </div>
                {selectedContrato.matricula &&
                  selectedContrato.tipo_contratacao === 'interno' && (
                    <div className="wrapper-data-info">
                      Matricula Especialista: {selectedContrato.matricula}
                    </div>
                  )}
                <div className="wrapper-data-info">
                  Data Contrato: {dataContrato}
                </div>
                {selectedContrato.data_prestacao_servico ? (
                  <>
                    <div className="wrapper-data-info">
                      Prazo de Entrega: {dataPrestacaoServico}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="wrapper-data-info">
                      Prazo de Entrega: {dataPrazoEntrega}
                    </div>
                  </>
                )}
                <div className="wrapper-data-info">
                  Data Pagamento Permitido: {dataPagamentoPermitido}
                </div>
                <div className="wrapper-data-info">
                  Data Pagamento Comandado: {dataPagamentoComandado}
                </div>
                <div className="wrapper-data-info">
                  Previsão de Pagamento: {dataPagamentoPrevisao}
                </div>
                {/* {(selectedContrato && selectedContrato.status_cancelamento) &&
                  <div className="wrapper-data-info">
                    Motivo Cancelamento: {selectedContrato.status_cancelamento?.descricao}
                  </div>
                } */}
                {selectedContrato.cliente?.nome === 'Ensino' ||
                selectedContrato.cliente?.nome === 'Premium' ? (
                  <div className="wrapper-data-info">
                    {selectedContrato.cliente?.nome === 'Ensino'
                      ? 'Informações importantes para o docente:'
                      : 'Observações:'}
                    <div
                      style={{ marginTop: '10px', marginLeft: '10px' }}
                      dangerouslySetInnerHTML={{
                        __html: selectedContrato.observacoes?.replace(
                          /\n/g,
                          '<br>',
                        ),
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>

              {selectedContrato.tipo_contratacao !== 'interno' && (
                <div className="wrapper-data">
                  <div className="wrapper-data-label">
                    Dados para Pagamento:
                  </div>
                  <div className="wrapper-data-info">
                    Tipo de Conta Bancária:
                    {selectedContrato.dados_pagamento?.modelo_pagamento === 'pf'
                      ? ' Pessoa Física'
                      : ''}
                    {selectedContrato.dados_pagamento?.modelo_pagamento === 'pj'
                      ? ' Pessoa Jurídica'
                      : ''}
                    {selectedContrato.dados_pagamento?.modelo_pagamento ===
                    'interno'
                      ? ' Interno'
                      : ''}
                  </div>
                  <div className="wrapper-data-info">
                    Banco: {selectedContrato.dados_pagamento?.banco}
                  </div>
                  <div className="wrapper-data-info">
                    Agência: {selectedContrato.dados_pagamento?.agencia}
                  </div>
                  <div className="wrapper-data-info">
                    Conta: {selectedContrato.dados_pagamento?.conta}
                  </div>
                  <div className="wrapper-data-info">
                    Digito Verificador:{' '}
                    {selectedContrato.dados_pagamento?.digito_verificador}
                  </div>
                </div>
              )}

              {selectedContrato.tipo_contratacao === 'pessoa_juridica' && (
                <div className="wrapper-data">
                  <div className="wrapper-data-label">
                    Dados Pessoa Jurídica:
                  </div>
                  <div className="wrapper-data-info">
                    CNPJ:{' '}
                    {selectedContrato.especialista?.dados_usuario?.pj_cnpj}
                  </div>
                  <div className="wrapper-data-info">
                    Razão social:{' '}
                    {pj.razao_social ||
                      selectedContrato.especialista?.dados_usuario
                        ?.pj_razao_social}
                  </div>
                </div>
              )}

              <div className="wrapper-data">
                <WrapRow>
                  <WrapRowItem>
                    <div className="wrapper-data-label">Documentos:</div>
                  </WrapRowItem>

                  {/* <div className="change-status" onClick={() => history.push("/contratos/documentos/upload", { selectedItem: selectedContrato })}>
                    Enviar documentos
                    </div> */}
                </WrapRow>

                {arquivos.map((arq: any) => (
                  <div key={arq.arquivo.id}>
                    <div className="wrapper-data-info">
                      <a
                        className="link_upload"
                        href={`${arq.arquivo.url}?token=${token}`}
                        target="_blank"
                      >
                        {arq.titulo}
                      </a>
                    </div>
                  </div>
                ))}
              </div>

              <div className="wrapper-data">
                <div className="wrapper-data-label">Contratos:</div>
                {selectedContrato.arquivo_contrato ? (
                  <div className="wrapper-data-info">
                    <a
                      className="link_upload"
                      href={`${selectedContrato.arquivo_contrato.url}?token=${token}`}
                      target="_blank"
                    >
                      Contrato de Prestação de Serviços
                    </a>
                  </div>
                ) : (
                  <div className="wrapper-data-info">
                    <span className="link_upload">
                      Contrato de Prestação de Serviços: Não gerado
                    </span>
                  </div>
                )}
                {selectedContrato.arquivo_direito_imagem && (
                  <div className="wrapper-data-info">
                    <a
                      className="link_upload"
                      href={`${selectedContrato.arquivo_direito_imagem.url}?token=${token}`}
                      target="_blank"
                    >
                      Termo de Autorização de Direitos de Imagem, Nome e Voz
                    </a>
                  </div>
                )}
              </div>

              {selectedContrato.status?.slug === 'pagamento_permitido' ||
              selectedContrato.status?.slug === 'pagamento_comandado' ? (
                <div className="wrapper-data">
                  <div className="wrapper-data-label">Certificados:</div>
                  {selectedContrato.arquivo_certificado ? (
                    <div className="wrapper-data-info">
                      <a
                        className="link_upload"
                        href={`${selectedContrato.arquivo_certificado.url}?token=${token}`}
                        target="_blank"
                      >
                        Certificado de Produção
                      </a>
                    </div>
                  ) : (
                    <div className="wrapper-data-info">
                      <a
                        className="link_upload"
                        onClick={() => {
                          gerarCertificado(true);
                        }}
                      >
                        Gerar Certificado
                      </a>
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}

              <div className="wrapper-data">
                <div className="wrapper-data-label">Produtos:</div>

                {selectedContrato.produtos?.map((item: any) => (
                  <div key={item.id} className="wrapper-produto">
                    <div className="wrapper-data-label">{item.titulo}:</div>
                    <div className="wrapper-data-info">
                      Quantidade: {item.pivot.quantidade}
                    </div>
                    {item.pivot.justificativa ? (
                      <>
                        {/* <div className="wrapper-data-info">
                          Valor: R$ {parseFloat(item.valor).toFixed(2)}
                        </div> */}
                        <div className="wrapper-data-info">
                          Valor: R$ {parseFloat(item.pivot.valor).toFixed(2)}
                        </div>
                        <div className="wrapper-data-info">
                          Valor total: R${' '}
                          {parseFloat(item.pivot.valor_total).toFixed(2)}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="wrapper-data-info">
                          Valor: R$ {parseFloat(item.valor).toFixed(2)}
                        </div>
                        <div className="wrapper-data-info">
                          Valor total: R${' '}
                          {parseFloat(item.pivot.valor_total).toFixed(2)}
                        </div>
                      </>
                    )}
                  </div>
                ))}
                {(selectedContrato.valor_total ||
                  selectedContrato.valor_total === 0) && (
                  <div className="wrapper-money">
                    <div
                      className="wrapper-data-label"
                      style={{
                        paddingTop: '25px',
                        fontSize: '30px',
                        color: '#999',
                      }}
                    >
                      R$ {parseFloat(selectedContrato.valor_total).toFixed(2)}
                    </div>
                  </div>
                )}
              </div>
            </ContratoData>
          </Card>
        </div>
      </Content>
    </Container>
  );
};

export default ContratosResumoVisualizar;
