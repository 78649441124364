import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';

import { useAuth } from '../../../../hooks/auth';
import { useToast } from '../../../../hooks/toast';

import getValidationErrors from '../../../../utils/getValidationErrors';

import FormInput from '../../../../components/FormInput';
// import FormInputMask from '../../../../components/FormInputMask';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import Loading from '../../../../components/Loading';

import api from '../../../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem,
} from './styles';

interface UsersFormData {
  id: number;
  email: string;
  roles: any;
  permissions: any;
  password: string;
}

interface RolesFormData {
  id: string;
  name: string;
}

interface PermissionsFormData {
  id: string;
  name: string;
}

interface OptionsData {
  value: any;
  label: string;
}

const UsersEdicao: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location:any = useLocation();

  const { permissions: userPermissions } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<UsersFormData>({} as UsersFormData);
  const [roles, setRoles] = useState<RolesFormData[]>([]);
  const [optionsRoles, setOptionsRoles] = useState<OptionsData[]>([]);
  const [selectedRole, setSelectedRole] = useState<OptionsData[]>([]);
  const [permissions, setPermissions] = useState<PermissionsFormData[]>([]);
  const [optionsPermissions, setOptionsPermissions] = useState<OptionsData[]>([]);
  const [selectedPermission, setSelectedPermission] = useState<OptionsData[]>([]);

  const canSeeOptionsEmailPassword = userPermissions.some((permission:string) => permission === 'alterar-email-senha')

  useEffect(() => {
    if(location && location.state){
      setSelectedItem(location.state.selectedItem);
      // console.log("userPermissions", userPermissions)
    }
  }, [location]);

  useEffect(() => {
    if(selectedItem && selectedItem.id && optionsRoles && optionsRoles.length > 0){
      //console.log("selectedItem", selectedItem);

      formRef.current?.setFieldValue("email", selectedItem.email);
      // formRef.current?.setFieldValue("description", selectedItem.description);

      if(selectedItem.roles){
        let temp:OptionsData[] = [];
        roles.forEach((role:RolesFormData) => {
          selectedItem.roles.forEach((role_:RolesFormData) => {
            if(role.id === role_.id){
              temp.push({
                value: role_.id, 
                label: role_.name
              })
            }
          });
        });

        if(temp){
          setSelectedRole(temp);
        }
      }

      if(selectedItem.permissions){
        let temp:OptionsData[] = [];
        permissions.forEach((permission:PermissionsFormData) => {
          selectedItem.permissions.forEach((permission_:PermissionsFormData) => {
            if(permission.id === permission_.id){
              temp.push({
                value: permission_.id, 
                label: permission_.name
              })
            }
          });
        });

        if(temp){
          setSelectedPermission(temp);
        }
      }
    }
  }, [selectedItem, optionsRoles, optionsPermissions]);

  useEffect(() => {
    getRoles();
    getPermissions();
  }, []);

  useEffect(() => {
    if (roles.length === 0) return;

    var options: OptionsData[] = [];

    roles.map(item => {
      if(item.name !== "Administrador"){
        options.push({ value: item.id, label: item.name });
      }
    });

    setOptionsRoles(options);
    //console.log("options", options);
  }, [roles]);

  useEffect(() => {
    if (permissions.length === 0) return;

    var options: OptionsData[] = [];

    permissions.map(item => {
      options.push({ value: item.id, label: item.name });
    });

    setOptionsPermissions(options);
    //console.log("options", options);
  }, [permissions]);

  const getRoles = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/roles`);
      //console.log("response", response.data);

      setRoles(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getPermissions = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/permissions`);
      //console.log("response", response.data);

      setPermissions(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: UsersFormData) => {
      try {
        //console.log("handleSubmit", data);

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string().required('E-mail obrigatório'),
          // description: Yup.string().required('Descrição obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const params = {
          roles: selectedRole.map((item:any) => item.value),
          permissions: selectedPermission.map((item:any) => item.value),
        }
        
        //console.log("params", params);

        await api.put(`/usuario/${selectedItem.id}`, params);

        if(data.email !== selectedItem.email || !!data.password){
          const params_:any = {
            id_usuario: selectedItem.id
          }

          if(data.email !== selectedItem.email){
            params_.email = data.email;
          }

          if(!!data.password){
            params_.password = data.password;
          }

          // console.log("params_", params_)

          await api.put(`/atualizar-email-senha`, params_);
        }

        addToast({
          type: 'success',
          title: 'Usuário atualizado com sucesso',
          description: '',
        });

        goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }
        
        //console.log(err.response?.data);

        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar usuário',
            description: err.response?.data[error] ?
              err.response.data[error] :
              'Erro ao atualizar usuário, tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, selectedItem, goBack, selectedRole, selectedPermission],
  );

  function handleChangeSelectedRole(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedRole([]);
      return;
    };

    if (newValue.length === 0) {
      setSelectedRole([]);
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue", newValue.value);

    setSelectedRole(newValue);
  };

  function handleChangeSelectedPermission(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedPermission([]);
      return;
    };

    if (newValue.length === 0) {
      setSelectedPermission([]);
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue", newValue.value);

    setSelectedPermission(newValue);
  };

  function goBack() {
    history.push('/account/users');
  }

  if(loading){
    return (
      <Loading />
    )
  }

  return (
    <Container>
      <Content>
        <Card>
          <div className="form">
            <div className="header">
              <h2>Editar Usuário</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            <Form ref={formRef} onSubmit={handleSubmit}>
                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="email"
                      type="text"
                      placeholder="E-mail"
                      disabled={!(canSeeOptionsEmailPassword && selectedItem.id !== 1)}
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow style={{ marginTop: "10px", marginBottom: "30px" }}>
                  <WrapRowItem>
                    <label>Tipo de usuário:</label>
                    <Select
                      isMulti
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedRole}
                      options={optionsRoles}
                      value={selectedRole}
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow style={{ marginTop: "10px", marginBottom: "30px" }}>
                  <WrapRowItem>
                    <label>Permissões:</label>
                    <Select
                      isMulti
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedPermission}
                      options={optionsPermissions}
                      value={selectedPermission}
                    />
                  </WrapRowItem>
                </WrapRow>

                { (canSeeOptionsEmailPassword && selectedItem.id !== 1) && 
                  <WrapRow style={{ marginTop: "10px", marginBottom: "30px" }}>
                    <WrapRowItem>
                      <FormInput
                        name="password"
                        type="password"
                        placeholder="Nova senha"
                        autoComplete="new-password"
                      />
                    </WrapRowItem>
                  </WrapRow>
                }

                <Button type="submit" loading={loading}>
                  Salvar
                </Button>

              </Form>
          </div>
        </Card>
      </Content>
    </Container>
  );
};

export default UsersEdicao;
