import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import Switch from "react-switch";

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import FormInput from '../../components/FormInput';
// import FormInputMask from '../../components/FormInputMask';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem,
} from './styles';

interface ProjetosFormData {
  id: string;
  titulo: string;
  tipo_contrato: string;
}

interface OptionsData {
  value: any;
  label: string;
}

const ProjetosEdicao: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location: any = useLocation();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ProjetosFormData>({} as any);
  const [projeto, setProjeto] = useState<ProjetosFormData>({} as any);
  const [modalidades, setModalidades] = useState<any[]>([]);
  const [optionsModalidades, _] = useState<OptionsData[]>([
    { value: "tema", label: "Tema" },
    { value: "tema avulso", label: "Tema Avulso" },
    { value: "disciplina", label: "Disciplina" },
    { value: "curso", label: "Curso" }
  ]);
  const [selectedModalidade, setSelectedModalidade] = useState<OptionsData>({ value: "", label: "" });
  const [optionsClientes, setOptionsClientes] = useState<OptionsData[]>([]);
  const [selectedCliente, setSelectedCliente] = useState<OptionsData>({} as OptionsData);
  const [utilizaVerba, setUtilizaVerba] = useState<boolean>(false);
  const [utilizaDataPrestacaoServico, setUtilizaDataPrestacaoServico] = useState<boolean>(false);

  useEffect(() => {
    getClientes();
  }, []);

  useEffect(() => {
    if (location && location.state) {
      setSelectedItem(location.state.selectedItem);
    }
  }, [location]);

  useEffect(() => {
    if (selectedItem && selectedItem.id) {
      // console.log("selectedItem", selectedItem);

      getProjeto(selectedItem.id);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (projeto && projeto.id) {
      // console.log("projeto", projeto);
      formRef.current?.setFieldValue("titulo", projeto.titulo);
    }
  }, [projeto]);

  const getProjeto = useCallback(async (id) => {
    const response = await api.get(`/projeto/${id}`);
    //console.log("projeto", response.data);

    if (response.data) {
      const modalidade_split = response.data.tipo_modalidade.split(" ");

      for (var i = 0; i < modalidade_split.length; i++) {
        modalidade_split[i] = modalidade_split[i].charAt(0).toUpperCase() + modalidade_split[i].slice(1);
      }
      const label = modalidade_split.join(" ");

      setSelectedModalidade({
        value: response.data.tipo_modalidade,
        label
      })

      setSelectedCliente({
        value: response.data.id_cliente,
        label: response.data.cliente.nome,
      })

      setUtilizaVerba(response.data.utiliza_verba);
      setUtilizaDataPrestacaoServico(response.data.tem_data_prestacao_servico);

      setProjeto(response.data);
    }

  }, []);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        //console.log("handleSubmit", data);

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          titulo: Yup.string().required('Título obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        //console.log("data", data);

        const params = {
          titulo: data.titulo,
          tipo_contrato: data.tipo_contrato === "" ? null : data.tipo_contrato,
          id_cliente: selectedCliente.value,
          tipo_modalidade: selectedModalidade.value,
          tem_data_prestacao_servico: utilizaDataPrestacaoServico,
          utiliza_verba: utilizaVerba
        }

        await api.put(`/projeto/${projeto.id}`, params);

        addToast({
          type: 'success',
          title: 'Projeto atualizado com sucesso',
          description: '',
        });

        goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar projeto',
            description: err.response?.data[error] ?
              err.response.data[error] :
              'Erro ao atualizar projeto, tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, projeto, goBack, selectedCliente, selectedModalidade, utilizaDataPrestacaoServico, utilizaVerba],
  );

  function goBack() {
    history.push('/projetos');
  }


  function handleChangeSelectedModalidade(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedModalidade({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedModalidade({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue tema", newValue.value);

    setSelectedModalidade(newValue);
  };

  function handleChangeSelectedCliente(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedCliente({} as OptionsData);
      return;
    };

    if (newValue.length === 0) {
      setSelectedCliente({} as OptionsData);
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue tema", newValue.value);

    setSelectedCliente(newValue);
  };

  const getClientes = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/cliente`);
      //console.log("response", response.data);

      setOptionsClientes(response.data.map((item: any) => {
        return {
          value: item.id,
          label: item.nome
        }
      }));
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  if (loading && !(projeto && projeto.id)) {
    return (
      <Loading />
    )
  }

  return (
    <Container>
      <Content>
        <Card>
          <div className="form">
            <div className="header">
              <h2>Editar Projeto</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            <Form ref={formRef} onSubmit={handleSubmit}>
              <WrapRow>
                <WrapRowItem>
                  <FormInput
                    name="titulo"
                    type="text"
                    placeholder="Título"
                    defaultValue={projeto.titulo}
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem style={{ marginBottom: "20px" }}>
                  <div style={{ color: '#666', marginBottom: '5px', }}>Selecione o Cliente:</div>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedCliente}
                    options={optionsClientes}
                    value={selectedCliente}
                    isDisabled
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem>
                  <FormInput
                    name="tipo_contrato"
                    type="text"
                    placeholder="Tipos de Contrato"
                    // disabled={!(selectedCliente && selectedCliente.value && selectedCliente.value !== 1)}
                    defaultValue={projeto.tipo_contrato}

                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem style={{ marginTop: "10px" }}>
                  <div style={{ color: '#666', marginBottom: '5px', }}>Selecione a Modalidade:</div>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedModalidade}
                    options={optionsModalidades}
                    value={selectedModalidade}
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow style={{ marginTop: "30px" }}>
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  offColor={'#e6e6e6'}
                  onColor={'#2FB4C2'}
                  onChange={() => { setUtilizaVerba(!utilizaVerba) }}
                  checked={utilizaVerba}
                />
                <span className="text-switch">Utiliza Verba</span>
              </WrapRow>

              <WrapRow style={{ marginTop: "30px" }}>
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  offColor={'#e6e6e6'}
                  onColor={'#2FB4C2'}
                  onChange={() => { setUtilizaDataPrestacaoServico(!utilizaDataPrestacaoServico) }}
                  checked={utilizaDataPrestacaoServico}
                />
                <span className="text-switch">Utiliza Data Prestação Serviço</span>
              </WrapRow>

              <Button type="submit" loading={loading}>
                Salvar
              </Button>

            </Form>
          </div>
        </Card>
      </Content>
    </Container>
  );
};

export default ProjetosEdicao;
