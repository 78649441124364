import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import FormInput from '../../components/FormInput';
// import FormInputMask from '../../components/FormInputMask';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem,
} from './styles';

interface ProfissionalFormData {
  id: string;
  titulo: string;
}

const ProfissionalEdicao: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location:any = useLocation();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ProfissionalFormData>({} as ProfissionalFormData);

  useEffect(() => {
    if(location && location.state){
      setSelectedItem(location.state.selectedItem);
    }
  }, [location]);

  useEffect(() => {
    if(selectedItem){
      formRef.current?.setFieldValue("titulo", selectedItem.titulo);
    }
  }, [selectedItem]);

  const handleSubmit = useCallback(
    async (data: ProfissionalFormData) => {
      try {
        //console.log("handleSubmit", data);

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          titulo: Yup.string().required('Título obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        //console.log("data", data);

        await api.put(`/profissional/${selectedItem.id}`, data);

        addToast({
          type: 'success',
          title: 'Tipo de Profissional atualizado com sucesso',
          description: '',
        });

        goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        for (let error in err.response?.data) {
          //console.log("???", error);
          if(typeof error === 'string'){
            addToast({
              type: 'error',
              title: 'Erro ao atualizar formação',
              description: 'Erro ao atualizar formação, tente novamente mais tarde.',
            });
          }else{
            addToast({
              type: 'error',
              title: 'Erro ao atualizar tipo de profissional',
              description: err.response?.data[error] ?
                err.response.data[error] :
                'Erro ao atualizar tipo de profissional, tente novamente mais tarde.',
            });
          }
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, goBack],
  );

  function goBack() {
    history.push('/profissional');
  }

  if(loading){
    return (
      <Loading />
    )
  }

  return (
    <Container>
      <Content>
        <Card>
          <div className="form">
            <div className="header">
              <h2>Editar Tipo de Profissional</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            <Form ref={formRef} onSubmit={handleSubmit}>
              <WrapRow>
                <WrapRowItem>
                  <FormInput
                    name="titulo"
                    type="text"
                    placeholder="Título"
                  />
                </WrapRowItem>
              </WrapRow>

              <Button type="submit" loading={loading}>
                Salvar
              </Button>
            </Form>
          </div>
        </Card>
      </Content>
    </Container>
  );
};

export default ProfissionalEdicao;
