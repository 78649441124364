import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiChevronLeft, FiX } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import * as Yup from 'yup';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import convertUTCDateToLocalDate from '../../utils/convertUTCDateToLocalDate';
import getValidationErrors from '../../utils/getValidationErrors';

import Button from '../../components/Button';
import Card from '../../components/Card';
import FormInput from '../../components/FormInput';
import FormInputMask from '../../components/FormInputMask';
import FormInputMoneyMask from '../../components/FormInputMoneyMask';
import Loading from '../../components/Loading';

import api from '../../services/api';

import FormTextarea from '../../components/FormTextarea';
import {
  Container,
  Content,
  ContratoData,
  ProductItem,
  TagProductPrice,
  TemaData,
  WrapRow,
  WrapRowItem,
  WrapperProdButton,
  WrapperProduct,
  WrapperProducts,
} from './styles';

interface ContratoFormData {
  id: string;
  // data_contrato: string;
  prazo_entrega: string;
  tema: any;
  observacoes: string;
  produtos: any;
  data_prestacao_servico: any;
  horario_prestacao_servico: any;
}

interface TemaFormData {
  id: string;
  titulo: string;
  modelo: any;
  tags: any;
  tema: string;
  produtos: any;
}

interface DisciplinaFormData {
  id: string;
  titulo: string;
  modelo: any;
  tags: any;
  disciplina: string;
  produtos: any;
}

interface ProfissionalFormData {
  id: string;
  titulo: string;
  slug: string;
}

interface ProdutoFormData {
  id: string;
  produto: string;
  unidade_aplicavel: number;
  unidade_descricao: string;
  unidade_limite: number;
  valor: any;
  quantidade: number;
  contratos_existentes: any;
  decimal: boolean;
}

interface ProdutoPrecosFormData {
  id: string;
  total: any;
  valor: any;
  quantidade: number;
  preco_diferenciado: boolean;
  preco_diferenciado_valor: number;
}

interface CuradoriaFormData {
  id: string;
  nome: string;
}

interface ProjetoFormData {
  id: string;
  titulo: string;
  slug: string;
  tipo_contrato: any;
  tipo_modalidade: string;
  tem_curadoria: boolean;
  tipo_contratacao: boolean;
  tem_data_prestacao_servico: boolean;
  id_cliente: string;
}

interface CursoFormData {
  id: string;
  codigo: string;
  titulo: string;
  produtos: any;
}

interface EspecialistaFormData {
  id: string;
  nome: string;
  email: string;
  matricula: string;
  conta_pessoa_fisica: any;
  blacklist: any;
}

interface PeriodosInterface {
  id: string;
  periodo: string;
}

interface OptionsData {
  value: string;
  label: string;
}

const ContratosCadastroEdita: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  // const { user, periodo } = useAuth();
  const { user, selectedClient } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [contratos, setContratos] = useState<ContratoFormData[]>([]);
  const [temas, setTemas] = useState<TemaFormData[]>([]);
  const [optionsTemas, setOptionsTemas] = useState<OptionsData[]>([]);
  const [selectedTema, setSelectedTema] = useState<OptionsData>({
    value: '',
    label: '',
  });
  const [especialista, setEspecialista] = useState<EspecialistaFormData>(
    {} as EspecialistaFormData,
  );
  const [especialistas, setEspecialistas] = useState<EspecialistaFormData[]>(
    [],
  );
  const [optionsEspecialistas, setOptionsEspecialistas] = useState<
    OptionsData[]
  >([]);
  const [selectedEspecialista, setSelectedEspecialista] = useState<OptionsData>(
    { value: '', label: '' },
  );
  const [profissionais, setProfissionais] = useState<ProfissionalFormData[]>(
    [],
  );
  const [optionsProfissionais, setOptionsProfissionais] = useState<
    OptionsData[]
  >([]);
  const [selectedProfissional, setSelectedProfissional] = useState<OptionsData>(
    { value: '', label: '' },
  );
  const [curadorias, setCuradorias] = useState<CuradoriaFormData[]>([]);
  const [optionsCuradorias, setOptionsCuradorias] = useState<OptionsData[]>([]);
  const [selectedCuradoria, setSelectedCuradoria] = useState<OptionsData>({
    value: '',
    label: '',
  });
  const [produtos, setProdutos] = useState<ProdutoFormData[]>([]);
  const [produtosPrecos, setProdutosPrecos] = useState<ProdutoPrecosFormData[]>(
    [],
  );
  const [valorTotal, setValorTotal] = useState('R$ 0.00');
  const [forceUpdate, setForceUpdate] = useState(false);
  const [hasNewPrices, setHasNewPrices] = useState(false);
  const [showResume, setShowResume] = useState(false);
  const [projeto, setProjeto] = useState<ProjetoFormData>(
    {} as ProjetoFormData,
  );
  const [projetos, setProjetos] = useState<ProjetoFormData[]>([]);
  const [optionsProjetos, setOptionsProjetos] = useState<OptionsData[]>([]);
  const [selectedProjeto, setSelectedProjeto] = useState<OptionsData>(
    {} as OptionsData,
  );
  const [tema, setTema] = useState<any>(null);
  const [disciplina, setDisciplina] = useState<any>(null);
  const [curso, setCurso] = useState<any>(null);
  const [feedbackContratos, setFeedbackContratos] = useState<any>(null);
  const [disciplinas, setDisciplinas] = useState<DisciplinaFormData[]>([]);
  const [optionsDisciplinas, setOptionsDisciplinas] = useState<OptionsData[]>(
    [],
  );
  const [selectedDisciplina, setSelectedDisciplina] = useState<OptionsData>({
    value: '',
    label: '',
  });
  const [cursos, setCursos] = useState<CursoFormData[]>([]);
  const [optionsCursos, setOptionsCursos] = useState<OptionsData[]>([]);
  const [selectedCurso, setSelectedCurso] = useState<OptionsData>({
    value: '',
    label: '',
  });
  const [optionsTiposContratos, setOptionsTiposContratos] = useState<
    OptionsData[]
  >([
    // { value: "producao_atual", label: "Produção" },
  ]);
  const [selectedTipoContrato, setSelectedTipoContrato] = useState<OptionsData>(
    // { value: "producao_atual", label: "Produção" }
    {} as OptionsData,
  );
  const [periodos, setPeriodos] = useState<PeriodosInterface[]>([]);
  const [optionsPeriodos, setOptionsPeriodos] = useState<OptionsData[]>([]);
  const [selectedPeriodo, setSelectedPeriodo] = useState<OptionsData>(
    {} as OptionsData,
  );
  const [areas, setAreas] = useState<any[]>([]);
  const [optionsAreas, setOptionsAreas] = useState<OptionsData[]>([]);
  const [selectedArea, setSelectedArea] = useState<OptionsData>(
    {} as OptionsData,
  );
  const [selectedProduto, setSelectedProduto] = useState<OptionsData>(
    {} as OptionsData,
  );
  const [optionsProdutos, setOptionsProdutos] = useState<OptionsData[]>([]);
  const [showAddProdButton, setShowAddProdButton] = useState<boolean>(false);
  const [dataPrestacaoServico, setDataPrestacaoServico] = useState<string>('');
  const [horaPrestacaoServico, setHoraPrestacaoServico] = useState<string>('');
  const [idServicoAvulso, setIdServicoAvulso] = useState<string>('');
  const [cursosProducao, setCursosProducao] = useState<CursoFormData[]>([]);
  const [optionsCursosProducao, setOptionsCursosProducao] = useState<
    OptionsData[]
  >([]);
  const [selectedCursosProducao, setSelectedCursosProducao] = useState<
    OptionsData
  >({ value: '', label: '' });
  const [verbaInternos, setVerbaInternos] = useState<any>(null);

  useEffect(() => {
    getProfissionais();
    //getEspecialistas();
    getProjetos();
    getPeriodos();
  }, []);

  useEffect(() => {
    if (
      selectedProfissional.label === 'Interno' &&
      selectedClient.nome === 'EnsineMe'
    ) {
      getVerbaInternos(valorTotal);
    }
  }, [valorTotal, selectedProfissional, selectedClient]);

  useEffect(() => {
    if (!(selectedClient && selectedClient.id)) return;

    if (selectedClient.tem_curadoria) {
      getCuradorias();
    }

    if (selectedClient.tem_area) {
      getAreas();
    }

    getCursos();
  }, [selectedClient]);

  useEffect(() => {
    var options: OptionsData[] = [];

    periodos.map(item => {
      options.push({ value: item.id, label: item.periodo });
    });

    setOptionsPeriodos(options);
  }, [periodos]);

  useEffect(() => {
    if (!periodos) return;
    if (!projetos) return;
    if (selectedClient.nome !== 'EnsineMe') return;

    var options: OptionsData[] = [];

    const temp: any = periodos.find(item => item.id === selectedPeriodo.value);

    if (!temp) {
      options = [{ value: 'producao_atual', label: 'Produção' }];

      setOptionsTiposContratos(options);
      setSelectedTipoContrato({ value: 'producao_atual', label: 'Produção' });

      return;
    }

    if (selectedProjeto.label === 'Roteiro de Prática') {
      options = [{ value: 'producao_atual', label: 'Produção' }];
    } else if (selectedProjeto.label === 'Learning Experience') {
      options = [{ value: 'producao_atual', label: 'Produção' }];
    } else {
      if (temp.corrente) {
        options = [{ value: 'producao_atual', label: 'Produção' }];
      } else if (temp.precedente) {
        options = [
          { value: 'producao_atual', label: 'Produção' },
          { value: 'manutencao_reativa', label: 'Manutenção Reativa' },
          {
            value: 'manutencao_proativa_direcionada',
            label: 'Manutenção Proativa Direcionada',
          },
          { value: 'terceirizado', label: 'Terceirizado' },
        ];
      } else {
        options = [
          { value: 'producao_atual', label: 'Produção' },
          { value: 'manutencao_reativa', label: 'Manutenção Reativa' },
          { value: 'manutencao_proativa', label: 'Manutenção Proativa' },
          {
            value: 'manutencao_proativa_direcionada',
            label: 'Manutenção Proativa Direcionada',
          },
          { value: 'terceirizado', label: 'Terceirizado' },
        ];
      }
    }

    const found = options.find(
      (item: any) => item.value === selectedTipoContrato.value,
    );

    if (!found) {
      setSelectedTipoContrato({ value: 'producao_atual', label: 'Produção' });
    }

    setOptionsTiposContratos(options);
  }, [selectedPeriodo, selectedProjeto, selectedClient]);

  useEffect(() => {
    var options: OptionsData[] = [];

    temas.map(item => {
      options.push({ value: item.id, label: item.tema });
    });

    setOptionsTemas(options);
  }, [temas]);

  useEffect(() => {
    if (!disciplinas) return;

    var options: OptionsData[] = [];

    disciplinas.map((item: any) => {
      options.push({
        value: item.id,
        label: item.disciplina?.titulo_disciplina,
      });
    });

    setOptionsDisciplinas(options);
  }, [disciplinas]);

  useEffect(() => {
    var options: OptionsData[] = [];

    cursos.map(item => {
      options.push({ value: item.id, label: item.titulo });
    });

    setOptionsCursos(options);
  }, [cursos]);

  useEffect(() => {
    if (!(cursosProducao && cursosProducao.length > 0)) return;

    var options: OptionsData[] = [];

    cursosProducao.map(item => {
      options.push({ value: item.id, label: item.titulo });
    });

    setOptionsCursosProducao(options);
  }, [cursosProducao]);

  useEffect(() => {
    if (!feedbackContratos) return;
    addToast({
      type: 'success',
      title: 'Contrato(s) cadastrado(s) com sucesso',
      description: '',
    });
    history.push('/contratos', {
      feedback_tema:
        tema && tema.tema
          ? tema.tema
          : disciplina?.disciplina?.titulo_disciplina
          ? disciplina.disciplina?.titulo_disciplina
          : curso?.curso?.titulo_curso,
      feedback_contratos: feedbackContratos.hashs_produtos_status,
      feedback_contratos_criados: feedbackContratos.hashs_processados.length,
    });
  }, [feedbackContratos]);

  useEffect(() => {
    if (profissionais.length === 0) return;

    var options: OptionsData[] = [];

    profissionais.map(item => {
      options.push({ value: item.id, label: item.titulo });
    });

    setOptionsProfissionais(options);
  }, [profissionais]);

  useEffect(() => {
    if (curadorias.length === 0) return;

    var options: OptionsData[] = [];

    curadorias.map(item => {
      options.push({ value: item.id, label: item.nome });
    });

    setOptionsCuradorias(options);

    if (curadorias.length === 1) {
      setSelectedCuradoria({
        value: curadorias[0].id,
        label: curadorias[0].nome,
      });
    }
  }, [curadorias]);

  useEffect(() => {
    if (areas.length === 0) return;

    var options: OptionsData[] = [];

    areas.map(item => {
      options.push({ value: item.id, label: item.nome });
    });

    setOptionsAreas(options);

    if (areas.length === 1) {
      setSelectedArea({
        value: areas[0].id,
        label: areas[0].nome,
      });
    }
  }, [areas]);

  useEffect(() => {
    if (projetos.length === 0) return;

    var options: OptionsData[] = [];

    projetos.map((item: ProjetoFormData) => {
      options.push({ value: item.id, label: item.titulo });
    });

    setOptionsProjetos(options);
  }, [projetos]);

  useEffect(() => {
    setSelectedTema({ value: '', label: '' });
    setSelectedDisciplina({ value: '', label: '' });
    setProdutos([]);
    setDisciplinas([]);
    setTemas([]);
    setDisciplinas([]);

    if (selectedProjeto.value && selectedProfissional.value) {
      if (
        projeto.tipo_modalidade === 'tema' ||
        projeto.tipo_modalidade === 'tema avulso'
      ) {
        getTemas();
      } else if (projeto.tipo_modalidade === 'disciplina') {
        getDisciplinas();
      } else if (projeto.tipo_modalidade === 'curso') {
        getCursosProducao();
      } else {
      }
    }
  }, [
    selectedProfissional,
    selectedCuradoria,
    selectedProjeto,
    selectedCurso,
    selectedPeriodo,
    selectedTipoContrato,
    projeto,
    selectedArea,
  ]);

  useEffect(() => {
    const temp = temas.find(item => item.id === selectedTema.value);

    if (temp && temp.id) {
      setTema(temp);
      setDisciplina(null);
      setCurso(null);
    } else {
      setTema(null);
      setDisciplina(null);
      setCurso(null);
    }

    if (temp && temp.produtos && temp.produtos.length > 0) {
      setOptionsProdutos(temp.produtos);
    } else {
      setOptionsProdutos([]);
      setProdutosPrecos([]);
    }
  }, [selectedTema]);

  useEffect(() => {
    const temp = disciplinas.find(item => item.id === selectedDisciplina.value);

    if (temp && temp.id) {
      setDisciplina(temp);
      setTema(null);
      setCurso(null);
    } else {
      setDisciplina(null);
      setTema(null);
      setCurso(null);
    }

    if (temp && temp.produtos && temp.produtos.length > 0) {
      setOptionsProdutos(temp.produtos);
    } else {
      setOptionsProdutos([]);
      setProdutosPrecos([]);
    }
  }, [selectedDisciplina]);

  useEffect(() => {
    const temp = cursosProducao.find(
      item => item.id === selectedCursosProducao.value,
    );

    if (temp && temp.id) {
      setCurso(temp);
      setDisciplina(null);
      setTema(null);
    } else {
      setCurso(null);
      setDisciplina(null);
      setTema(null);
    }

    if (temp && temp.produtos && temp.produtos.length > 0) {
      setOptionsProdutos(temp.produtos);
    } else {
      setOptionsProdutos([]);
      setProdutosPrecos([]);
    }
  }, [selectedCursosProducao]);

  useEffect(() => {
    produtos.forEach((item: any) => {
      const prod = produtosPrecos.find((it: any) => it.id === item.id);
      formRef.current?.setFieldValue(
        `produtos['${item.id}'].quantidade`,
        prod?.quantidade ?? 0,
      );
    });
  }, [produtos]);

  useEffect(() => {
    if (selectedEspecialista.label) {
      const tmp = especialistas.find(
        item => item.id === selectedEspecialista.value,
      );
      if (tmp) {
        setEspecialista(tmp);

        if (tmp.matricula && tmp.matricula !== '') {
        } else {
          if (tmp.conta_pessoa_fisica) {
          } else {
            if (tmp.conta_pessoa_fisica === 0) {
            } else {
              setSelectedProfissional({} as OptionsData);
            }
          }
        }
      } else {
        setEspecialista({} as EspecialistaFormData);
      }
    } else {
      setEspecialista({} as EspecialistaFormData);
    }
  }, [selectedEspecialista, profissionais]);

  useEffect(() => {
    if (selectedClient.nome === 'EnsineMe') return;
    if (!(selectedProjeto && selectedProjeto.value)) return;

    const prj = projetos.find(item => item.id === selectedProjeto.value);

    if (!prj) return;

    const tipo = prj.tipo_contrato;

    const tipo_split = tipo.split(',');

    const opt: OptionsData[] = [];

    tipo_split.forEach((item: string) => {
      const tmp = item.split(':');
      opt.push({
        value: tmp[0],
        label: tmp[1],
      });
    });

    setOptionsTiposContratos(opt);
    setSelectedTipoContrato({} as OptionsData);
  }, [selectedClient, selectedProjeto]);

  const dataContrato = useMemo(() => {
    const dt = convertUTCDateToLocalDate(new Date());
    return `${('0' + dt.getDate()).slice(-2)}/${(
      '0' +
      (dt.getMonth() + 1)
    ).slice(-2)}/${dt.getFullYear()}`;
  }, []);

  const getProjetos = useCallback(async () => {
    if (!(selectedClient && selectedClient.id)) return;

    const response = await api.get(`/projeto`, {
      params: { id_cliente: selectedClient.id },
    });

    setProjetos(response.data);
  }, [selectedClient]);

  const getProfissionais = useCallback(async () => {
    const response = await api.get(`/profissional`);

    setProfissionais(response.data);
  }, []);

  const getCuradorias = useCallback(async () => {
    const response = await api.get(`/curadoria`);

    setCuradorias(response.data);
  }, []);

  const getVerbaInternos = useCallback(
    async valor_total => {
      const params: any = {
        id_curadoria: selectedCuradoria.value,
        valor: parseFloat(valor_total.replace('R$ ', '')).toFixed(2),
      };

      const response = await api.get(
        `/verifica-verba/${selectedProjeto.value}`,
        { params },
      );

      setVerbaInternos(response.data);
    },
    [selectedCuradoria, selectedProjeto],
  );

  const getAreas = useCallback(async () => {
    const response = await api.get(`/area-atuacao`, {
      params: { id_cliente: selectedClient.id },
    });

    setAreas(response.data);
  }, [selectedClient]);

  const getEspecialistas = useCallback(async (inputValue: string) => {
    if (!inputValue) return [];
    if (inputValue.length < 3) return [];

    const response = await api.get(`/busca-usuario`, {
      params: { papel: ['especialista'], busca: inputValue },
    });
    setEspecialistas(response.data);

    return new Promise<any[]>(resolve => {
      resolve(
        response.data.map((item: any) => {
          return {
            value: item.id,
            label: item.nome ? `${item.nome} (${item.email})` : item.email,
          };
        }),
      );
    });
  }, []);

  const getPeriodos = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/periodo`);

      setPeriodos(response.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);

  const getTemas = useCallback(async () => {
    if (selectedClient.tem_curadoria) {
      if (
        selectedCuradoria.value &&
        selectedProfissional.value &&
        selectedProjeto.value &&
        selectedTipoContrato.value
      ) {
        const params: any = {
          id_curadoria: selectedCuradoria.value,
          id_profissional: selectedProfissional.value,
          id_projeto: selectedProjeto.value,
          tipo_contrato: selectedTipoContrato.value,
        };

        if (selectedClient.tem_periodo) {
          params.id_periodo = selectedPeriodo.value;
        }

        const response = await api.get(`/tema`, {
          params: params,
        });

        setTemas(response.data);
      } else {
      }
    }

    if (selectedClient.tem_area) {
      if (
        selectedArea.value &&
        selectedProfissional.value &&
        selectedProjeto.value &&
        selectedTipoContrato.value
      ) {
        const params: any = {
          id_area_atuacao: selectedArea.value,
          id_profissional: selectedProfissional.value,
          id_projeto: selectedProjeto.value,
          tipo_contrato: selectedTipoContrato.value,
        };

        if (selectedClient.tem_periodo) {
          params.id_periodo = selectedPeriodo.value;
        }

        const response = await api.get(`/tema`, {
          params: params,
        });

        setTemas(response.data);
      } else {
      }
    }
  }, [
    selectedProjeto,
    selectedCuradoria,
    selectedProfissional,
    selectedPeriodo,
    selectedTipoContrato,
    selectedClient,
    selectedArea,
  ]);

  const getDisciplinas = useCallback(async () => {
    if (selectedClient.tem_curadoria) {
      if (
        selectedCuradoria.value &&
        selectedProfissional.value &&
        selectedProjeto.value
      ) {
        const params: any = {
          id_curadoria: selectedCuradoria.value,
          id_profissional: selectedProfissional.value,
          id_projeto: selectedProjeto.value,
          id_curso: selectedCurso.value,
        };

        if (selectedClient.tem_periodo) {
          params.id_periodo = selectedPeriodo.value;
        }

        const response = await api.get(`/disciplina-producao`, {
          params: params,
        });

        setDisciplinas(response.data);
      }
    }

    if (selectedClient.tem_area) {
      if (
        selectedArea.value &&
        selectedProfissional.value &&
        selectedProjeto.value
      ) {
        const params: any = {
          id_area_atuacao: selectedArea.value,
          id_profissional: selectedProfissional.value,
          id_projeto: selectedProjeto.value,
          id_curso: selectedCurso.value,
        };

        if (selectedClient.tem_periodo) {
          params.id_periodo = selectedPeriodo.value;
        }

        const response = await api.get(`/disciplina-producao`, {
          params: params,
        });

        setDisciplinas(response.data);
      }
    }
  }, [
    selectedProjeto,
    selectedCuradoria,
    selectedProfissional,
    selectedCurso,
    selectedPeriodo,
    selectedClient,
    selectedArea,
  ]);

  const getCursos = useCallback(async () => {
    const response = await api.get(`/curso`, {
      params: { id_cliente: selectedClient.id },
    });

    setCursos(response.data);
  }, [selectedClient]);

  const getCursosProducao = useCallback(async () => {
    if (selectedClient.tem_curadoria) {
      if (
        selectedCuradoria.value &&
        selectedProfissional.value &&
        selectedProjeto.value
      ) {
        const params: any = {
          id_curadoria: selectedCuradoria.value,
          id_profissional: selectedProfissional.value,
          id_projeto: selectedProjeto.value,
        };

        if (selectedClient.tem_periodo) {
          params.id_periodo = selectedPeriodo.value;
        }

        const response = await api.get(`/curso-producao`, {
          params: params,
        });

        setCursosProducao(response.data);
      }
    }

    if (selectedClient.tem_area) {
      if (
        selectedArea.value &&
        selectedProfissional.value &&
        selectedProjeto.value
      ) {
        const params: any = {
          id_area_atuacao: selectedArea.value,
          id_profissional: selectedProfissional.value,
          id_projeto: selectedProjeto.value,
        };

        if (selectedClient.tem_periodo) {
          params.id_periodo = selectedPeriodo.value;
        }

        const response = await api.get(`/curso-producao`, {
          params: params,
        });

        setCursosProducao(response.data);
      }
    }
  }, [
    selectedProjeto,
    selectedCuradoria,
    selectedProfissional,
    selectedPeriodo,
    selectedClient,
    selectedArea,
  ]);

  const handleSubmit = useCallback(
    async (data: ContratoFormData) => {
      setShowResume(false);

      if (!selectedTipoContrato.value) {
        addToast({
          type: 'error',
          title: 'Selecione o tipo de contrato',
          description: '',
        });
        return;
      }
      console.log(selectedCuradoria);
      if (selectedClient.tem_curadoria) {
        if (!selectedCuradoria.value) {
          addToast({
            type: 'error',
            title: 'Selecione a curadoria',
            description: '',
          });
          return;
        }
      }
      console.log(selectedProfissional);
      if (!selectedProfissional.value) {
        addToast({
          type: 'error',
          title: 'Selecione um Tipo de Profissional',
          description: '',
        });
        return;
      }

      if (
        projeto.tipo_modalidade === 'tema' ||
        projeto.tipo_modalidade === 'tema avulso'
      ) {
        if (!selectedTema.value) {
          addToast({
            type: 'error',
            title: 'Selecione o tema',
            description: '',
          });
          return;
        }
      } else if (projeto.tipo_modalidade === 'disciplina') {
        if (!selectedDisciplina.value) {
          addToast({
            type: 'error',
            title: 'Selecione a disciplina',
            description: '',
          });
          return;
        }
      } else {
      }

      let tmp = false;

      for (let k in data.produtos) {
        if (parseInt(data.produtos[k].quantidade) > 0) {
          tmp = true;

          if (data.produtos[k].hasOwnProperty('valor_solicitado')) {
            if (!data.produtos[k].valor_solicitado) {
              addToast({
                type: 'error',
                title: 'Preencha o valor a ser solicitado do produto',
                description: '',
              });
              return;
            }
            if (!data.produtos[k].valor_solicitado_justificativa) {
              addToast({
                type: 'error',
                title:
                  'Preencha a justificativa para solicitação de novos valores de produto',
                description: '',
              });
              return;
            }
          } else {
          }
        }
      }

      if (!tmp) {
        addToast({
          type: 'error',
          title: 'Adicione o(s) produto(s) referente(s) ao contrato',
          description: '',
        });
        return;
      }

      if (projeto.tem_data_prestacao_servico) {
        if (!data.data_prestacao_servico) {
          addToast({
            type: 'error',
            title: 'Adicione o prazo de entrega',
            description: '',
          });
          return;
        }
      } else {
        if (!data.prazo_entrega) {
          addToast({
            type: 'error',
            title: 'Adicione o prazo de entrega',
            description: '',
          });
          return;
        }
      }

      try {
        setLoading(true);

        formRef.current?.setErrors({});

        let temp: any = [];

        for (let k in data.produtos) {
          if (parseFloat(data.produtos[k].quantidade) > 0) {
            const tmp: any = {
              id: parseInt(k.replace("'", '').replace("'", '')),
              quantidade: parseFloat(data.produtos[k].quantidade),
            };

            if (data.produtos[k].hasOwnProperty('valor_solicitado')) {
              tmp.valor = parseFloat(data.produtos[k].valor_solicitado).toFixed(
                2,
              );
              tmp.justificativa =
                data.produtos[k].valor_solicitado_justificativa;
            }

            temp.push(tmp);
          }
        }

        let dt_prestacao_servico = data.data_prestacao_servico
          ? data.data_prestacao_servico.split('/')
          : [];
        let dt_prazo_entrega = data.prazo_entrega
          ? data.prazo_entrega.split('/')
          : [];

        const today = new Date();
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        if (projeto.tem_data_prestacao_servico) {
          if (
            parseInt(dt_prestacao_servico[1]) > 12 ||
            parseInt(dt_prestacao_servico[0]) > 31
          ) {
            addToast({
              type: 'error',
              title: 'Data inválida',
              description: 'Corrigir o prazo de entrega',
            });
            return;
          }

          if (dt_prestacao_servico[2].replace('_', '').length !== 4) {
            addToast({
              type: 'error',
              title: 'Data inválida',
              description: 'Corrigir o prazo de entrega',
            });
            return;
          }

          if (parseInt(dt_prestacao_servico[2]) < today.getFullYear()) {
            addToast({
              type: 'error',
              title: 'Data inválida',
              description: 'Corrigir o prazo de entrega',
            });
            return;
          }

          if (selectedTipoContrato.value !== 'retroativo') {
            if (
              new Date(
                `${dt_prestacao_servico[2]}-${dt_prestacao_servico[1]}-${dt_prestacao_servico[0]}`,
              ) < today
            ) {
              addToast({
                type: 'error',
                title: 'Data inválida',
                description: 'Corrigir o prazo de entrega',
              });
              return;
            }
          }
        } else {
          if (
            parseInt(dt_prazo_entrega[1]) > 12 ||
            parseInt(dt_prazo_entrega[0]) > 31
          ) {
            addToast({
              type: 'error',
              title: 'Data inválida',
              description: 'Corrigir o prazo de entrega',
            });
            return;
          }

          if (dt_prazo_entrega[2].replace('_', '').length !== 4) {
            addToast({
              type: 'error',
              title: 'Data inválida',
              description: 'Corrigir o prazo de entrega',
            });
            return;
          }

          if (parseInt(dt_prazo_entrega[2]) < today.getFullYear()) {
            addToast({
              type: 'error',
              title: 'Data inválida',
              description: 'Corrigir o prazo de entrega',
            });
            return;
          }

          if (selectedTipoContrato.value !== 'retroativo') {
            if (
              new Date(
                `${dt_prazo_entrega[2]}-${dt_prazo_entrega[1]}-${dt_prazo_entrega[0]}`,
              ) < yesterday
            ) {
              addToast({
                type: 'error',
                title: 'Data inválida',
                description: 'Corrigir o prazo de entrega',
              });
              return;
            }
          }
        }

        let params: any = {
          // data_contrato: `${dt_data_contrato[2]}-${dt_data_contrato[1]}-${dt_data_contrato[0]}`,
          id_remetente: user.id,
          id_especialista: selectedEspecialista.value,
          observacoes: data.observacoes,
          // redirect_url: 'https://contrato.ensineme.com.br/convite-tema',
          // redirect_url: "http://localhost:3000/convite-tema",
          redirect_url: `https://${window.location.hostname}/convite-tema`,
          produtos: temp,
          tipo_contrato: selectedTipoContrato.value,
          id_cliente: projeto.id_cliente,
        };

        if (projeto.tem_data_prestacao_servico) {
          params.data_prestacao_servico = `${dt_prestacao_servico[2]}-${dt_prestacao_servico[1]}-${dt_prestacao_servico[0]} ${data.horario_prestacao_servico}`;
        } else {
          params.prazo_entrega = `${dt_prazo_entrega[2]}-${dt_prazo_entrega[1]}-${dt_prazo_entrega[0]}`;
        }

        if (
          projeto.tipo_modalidade === 'tema' ||
          projeto.tipo_modalidade === 'tema avulso'
        ) {
          params.id_tema = selectedTema.value;
        } else if (projeto.tipo_modalidade === 'disciplina') {
          params.id_disciplina_producao = selectedDisciplina.value;
        } else if (projeto.tipo_modalidade === 'curso') {
          params.id_curso_producao = selectedCursosProducao.value;
        }

        if (
          selectedProfissional.label === 'Interno' &&
          selectedClient.nome === 'EnsineMe'
        ) {
          params.solicitar_aprovacao_verba = verbaInternos?.aprovacao_verba;
          params.id_curadoria = selectedCuradoria.value;
        }

        const response = await api.post(`/contrato-edital`, params);

        setFeedbackContratos(response.data);

        goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }
      } finally {
        setLoading(false);
      }
    },
    [
      history,
      addToast,
      selectedClient,
      selectedTema,
      produtosPrecos,
      selectedEspecialista,
      selectedProfissional,
      selectedCuradoria,
      projeto,
      selectedDisciplina,
      selectedTipoContrato,
      selectedCurso,
      selectedCursosProducao,
      verbaInternos,
    ],
  );

  function goBack() {
    history.push('/contratos');
  }

  function handleChangeSelectedProduto(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedProduto({ value: '', label: '' });
      return;
    }

    if (newValue.length === 0) {
      setSelectedProduto({ value: '', label: '' });
      return;
    }

    setSelectedProduto(newValue);
  }

  function addNewProduct() {
    const prd: any = optionsProdutos.find(
      (item: any) => item.id === selectedProduto.value,
    );

    if (prd) {
      const check_exist: any = produtos.find(
        (item: any) => item.id === selectedProduto.value,
      );

      if (!check_exist) {
        setProdutos([...produtos, prd]);

        setProdutosPrecos([
          ...produtosPrecos,
          {
            id: prd.id,
            total: prd.valor,
            quantidade: 1,
            preco_diferenciado: false,
            valor: prd.valor,
            preco_diferenciado_valor: 0,
          },
        ]);
      } else {
        addToast({
          type: 'error',
          title: 'Produto já inserido!',
          description: '',
        });
      }
    }

    setShowAddProdButton(false);
    setSelectedProduto({} as OptionsData);
  }

  useEffect(() => {
    updateFinalPrice();
  }, [produtosPrecos]);

  function updateFinalPrice() {
    let total: any = 0;

    produtosPrecos.forEach((prod: any) => {
      if (prod.total) {
        total = total + prod.total;
      }
    });

    setValorTotal(`R$ ${parseFloat(total).toFixed(2)}`);
  }

  function handleChangeSelectedPeriodo(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedPeriodo({ value: '', label: '' });
      return;
    }

    if (newValue.length === 0) {
      setSelectedPeriodo({ value: '', label: '' });
      return;
    }

    setSelectedPeriodo(newValue);
  }

  function handleChangeSelectedTema(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedTema({ value: '', label: '' });
      return;
    }

    if (newValue.length === 0) {
      setSelectedTema({ value: '', label: '' });
      return;
    }
    setSelectedTema(newValue);
  }

  function handleChangeSelectedDisciplina(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedDisciplina({ value: '', label: '' });
      return;
    }

    if (newValue.length === 0) {
      setSelectedDisciplina({ value: '', label: '' });
      return;
    }

    setSelectedDisciplina(newValue);
  }

  function handleChangeSelectedCursoProducao(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedCursosProducao({ value: '', label: '' });
      return;
    }

    if (newValue.length === 0) {
      setSelectedCursosProducao({ value: '', label: '' });
      return;
    }

    setSelectedCursosProducao(newValue);
  }

  function handleChangeSelectedCurso(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedCurso({ value: '', label: '' });
      return;
    }

    if (newValue.length === 0) {
      setSelectedCurso({ value: '', label: '' });
      return;
    }

    setSelectedCurso(newValue);
  }

  function handleChangeSelectedProfissional(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedProfissional({ value: '', label: '' });
      return;
    }

    if (newValue.length === 0) {
      setSelectedProfissional({ value: '', label: '' });
      return;
    }

    setSelectedProfissional(newValue);
  }

  function handleChangeSelectedProjeto(newValue: any, actionMeta: any) {
    if (!newValue) {
      setProjeto({} as ProjetoFormData);
      setSelectedProjeto({} as OptionsData);
      return;
    }

    if (newValue.length === 0) {
      setProjeto({} as ProjetoFormData);
      setSelectedProjeto({} as OptionsData);
      return;
    }

    setSelectedProjeto(newValue);

    const item = projetos.find((projeto: any) => projeto.id === newValue.value);

    if (item && item.id) {
      setProjeto(item);
    } else {
      setProjeto({} as ProjetoFormData);
    }
  }

  function handleChangeSelectedTipoContrato(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedTipoContrato({ value: '', label: '' });
      return;
    }

    if (newValue.length === 0) {
      setSelectedTipoContrato({ value: '', label: '' });
      return;
    }

    setSelectedTipoContrato(newValue);
  }

  function handleChangeSelectedCuradoria(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedCuradoria({ value: '', label: '' });
      return;
    }

    if (newValue.length === 0) {
      setSelectedCuradoria({ value: '', label: '' });
      return;
    }

    setSelectedCuradoria(newValue);
  }

  function handleChangeSelectedArea(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedArea({ value: '', label: '' });
      return;
    }

    if (newValue.length === 0) {
      setSelectedArea({ value: '', label: '' });
      return;
    }

    setSelectedArea(newValue);
  }

  function handleChangeSelectedEspecialista(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedEspecialista({ value: '', label: '' });
      return;
    }

    if (newValue.length === 0) {
      setSelectedEspecialista({ value: '', label: '' });
      return;
    }

    setSelectedEspecialista(newValue);
  }

  const getPrice = (item: any) => {
    let preco = produtosPrecos.find(it => it.id === item.id);

    if (preco && preco.total) {
      return `R$ ${parseFloat(preco.total).toFixed(2)}`;
    } else {
      return `R$ 0.00`;
    }
  };

  const updatePrice = useCallback(
    (item, quantidade) => {
      let temp = produtosPrecos;
      let total: any = 0;

      temp.forEach(it => {
        if (it.id === item.id) {
          if (it.preco_diferenciado) {
            it.total = it.preco_diferenciado_valor * quantidade;
          } else {
            it.total = item.valor * quantidade;
          }
          it.quantidade = quantidade;
        }
        if (it.total) {
          total = total + it.total;
        }
      });

      setProdutosPrecos(temp);
      setValorTotal(`R$ ${parseFloat(total).toFixed(2)}`);

      return;
    },
    [produtosPrecos, produtos],
  );

  const updateNewPrice = useCallback(
    (item, new_price) => {
      let temp = produtosPrecos;
      let total: any = 0;
      let submeter_para_avaliacao = false;

      temp.forEach(it => {
        if (it.id === item.id) {
          it.preco_diferenciado_valor = parseFloat(new_price);
          if (it.preco_diferenciado) {
            it.total = it.preco_diferenciado_valor * it.quantidade;
          } else {
            it.total = item.valor * it.quantidade;
          }
        }
      });

      temp.forEach(it => {
        if (it.total) {
          total = total + it.total;
        }
      });

      setProdutosPrecos(temp);
      setValorTotal(`R$ ${parseFloat(total).toFixed(2)}`);

      return;
    },
    [produtosPrecos, produtos],
  );

  const checkIsNewPrice = useCallback(
    item => {
      let current = produtosPrecos.find(it => item.id == it.id);
      if (current) {
        return current.preco_diferenciado;
      } else {
        return false;
      }
    },
    [produtosPrecos],
  );

  const removeProdItem = useCallback(
    item => {
      const tmp: any = produtos.filter(i => i.id !== item.id);
      setProdutos(tmp);

      const temp: any = produtosPrecos.filter(i => i.id !== item.id);
      setProdutosPrecos(temp);
    },
    [produtos, produtosPrecos],
  );

  const checkShowWarning = useCallback(
    item => {
      let current = produtosPrecos.find(it => item.id == it.id);
      if (current) {
        if (current.preco_diferenciado_valor > 0) {
          return current.preco_diferenciado;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    [produtosPrecos],
  );

  const updateIsNewPrice = useCallback(
    item => {
      let temp = produtosPrecos;
      let checkHasNewPrices = false;
      let total: any = 0;

      temp.forEach(it => {
        if (it.id === item.id) {
          it.preco_diferenciado = !it.preco_diferenciado;

          if (it.preco_diferenciado) {
            checkHasNewPrices = true;
            it.preco_diferenciado_valor = 0;
            it.total = it.preco_diferenciado_valor * it.quantidade;
          } else {
            it.total = item.valor * it.quantidade;
          }
        }
      });

      temp.forEach(it => {
        if (it.total) {
          total = total + it.total;
        }
      });

      setProdutosPrecos(temp);
      setHasNewPrices(checkHasNewPrices);
      setValorTotal(`R$ ${parseFloat(total).toFixed(2)}`);
      setForceUpdate(!forceUpdate);

      return;
    },
    [produtosPrecos, forceUpdate],
  );

  const checkGoToValidation = useCallback(() => {
    let submeter_para_avaliacao = false;

    produtosPrecos.forEach(it => {
      if (it.preco_diferenciado && it.preco_diferenciado_valor > 0) {
        submeter_para_avaliacao = true;
      }
    });

    return submeter_para_avaliacao;
  }, [produtosPrecos]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Content>
        <Card>
          <div className="form">
            <div className="header">
              {showResume ? (
                <>
                  <h2>Resumo Contrato</h2>
                  <FiChevronLeft
                    onClick={() => setShowResume(false)}
                    className="icon"
                  />
                </>
              ) : (
                <>
                  <h2>Cadastrar Contrato</h2>
                  <FiX onClick={() => goBack()} className="icon" />
                </>
              )}
            </div>

            <Form ref={formRef} onSubmit={handleSubmit}>
              <div style={{ display: showResume ? 'none' : 'block' }}>
                <WrapRow>
                  <WrapRowItem>
                    <label>Projeto:</label>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedProjeto}
                      options={optionsProjetos}
                      value={selectedProjeto}
                    />
                  </WrapRowItem>
                </WrapRow>

                {selectedClient && selectedClient.tem_periodo ? (
                  <WrapRow>
                    <WrapRowItem style={{ marginTop: '30px' }}>
                      <label>Período:</label>
                      <Select
                        styles={{
                          // Fixes the overlapping problem of the component
                          menu: provided => ({ ...provided, zIndex: 9999 }),
                          // input: styles => ({ ...styles }),
                        }}
                        placeholder={''}
                        onChange={handleChangeSelectedPeriodo}
                        options={optionsPeriodos}
                        value={selectedPeriodo}
                      />
                    </WrapRowItem>
                  </WrapRow>
                ) : (
                  <></>
                )}

                <WrapRow>
                  <WrapRowItem style={{ marginTop: '30px' }}>
                    <label>Tipo de Contrato:</label>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedTipoContrato}
                      options={optionsTiposContratos}
                      value={selectedTipoContrato}
                    />
                  </WrapRowItem>
                </WrapRow>

                {selectedClient.tem_curadoria ? (
                  <WrapRow style={{ marginTop: '30px' }}>
                    <WrapRowItem>
                      <label>Curadoria:</label>
                      <Select
                        styles={{
                          // Fixes the overlapping problem of the component
                          menu: provided => ({ ...provided, zIndex: 9999 }),
                          // input: styles => ({ ...styles }),
                        }}
                        placeholder={''}
                        onChange={handleChangeSelectedCuradoria}
                        options={optionsCuradorias}
                        value={selectedCuradoria}
                      />
                    </WrapRowItem>
                  </WrapRow>
                ) : (
                  <></>
                )}

                {selectedClient.tem_area ? (
                  <WrapRow style={{ marginTop: '30px' }}>
                    <WrapRowItem>
                      <label>Área de Atuação:</label>
                      <Select
                        styles={{
                          // Fixes the overlapping problem of the component
                          menu: provided => ({ ...provided, zIndex: 9999 }),
                          // input: styles => ({ ...styles }),
                        }}
                        placeholder={''}
                        onChange={handleChangeSelectedArea}
                        options={optionsAreas}
                        value={selectedArea}
                      />
                    </WrapRowItem>
                  </WrapRow>
                ) : (
                  <></>
                )}

                {especialista &&
                  especialista.blacklist &&
                  especialista.blacklist.length > 0 && (
                    <div className="blacklist-contract-container">
                      <div className="blacklist-contract-title">
                        Atenção: Existem ocorrências na redlist para o
                        especialista selecionado.
                      </div>
                      {especialista.blacklist.map((esp: any) => {
                        if (!esp.data_exclusao) {
                          return (
                            <div
                              key={esp.id_contrato}
                              className="blacklist-contract"
                            >
                              <a
                                className="link"
                                href={`/contratos/visualizar-contrato?id_contrato=${esp.id_contrato}`}
                                target="_blank"
                              >
                                <span>{esp.hash}</span>
                                <span>Status: {esp.status}</span>
                              </a>
                            </div>
                          );
                        }
                      })}
                    </div>
                  )}

                <WrapRow>
                  <WrapRowItem style={{ marginTop: '30px' }}>
                    <label>Tipo de Profissional:</label>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedProfissional}
                      //={selectedProfissional}
                      options={optionsProfissionais}
                      //isDisabled={!!!selectedEspecialista.value}
                    />
                  </WrapRowItem>
                </WrapRow>

                {projeto.tipo_modalidade === 'tema' ||
                projeto.tipo_modalidade === 'tema avulso' ? (
                  <>
                    <WrapRow style={{ marginTop: '30px' }}>
                      <WrapRowItem>
                        <label>Tema:</label>
                        <Select
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({ ...provided, zIndex: 9999 }),
                            // input: styles => ({ ...styles }),
                          }}
                          placeholder={''}
                          onChange={handleChangeSelectedTema}
                          options={optionsTemas}
                          value={selectedTema}
                          isDisabled={
                            !!!(
                              selectedProfissional.value &&
                              (selectedCuradoria.value || selectedArea.value) &&
                              selectedProjeto.value
                            )
                          }
                        />
                      </WrapRowItem>
                    </WrapRow>

                    {tema && tema.id && (
                      <TemaData style={{ padding: '5px', marginTop: '20px' }}>
                        <div className="wrapper-tema">
                          <div className="wrapper-data-label">
                            Informações do Tema Selecionado:
                          </div>
                          <div className="wrapper-data-info">
                            <p>
                              <b>
                                {selectedClient.tem_curadoria
                                  ? 'Curador Responsável:'
                                  : 'Responsável:'}
                              </b>{' '}
                              {tema.curador_responsavel}
                            </p>
                            <p>
                              <b>Título:</b> {tema.tema}
                            </p>
                            {projeto.tipo_modalidade !== 'tema avulso' ? (
                              <p>
                                <b>Disciplina:</b>{' '}
                                {tema.disciplina?.codigo_disciplina} -{' '}
                                {tema.disciplina?.titulo_disciplina}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </TemaData>
                    )}
                  </>
                ) : (
                  <></>
                )}

                {projeto.tipo_modalidade === 'disciplina' ? (
                  <>
                    <WrapRow style={{ marginTop: '30px' }}>
                      <WrapRowItem>
                        <label>Curso:</label>
                        <Select
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({ ...provided, zIndex: 9999 }),
                            // input: styles => ({ ...styles }),
                          }}
                          placeholder={''}
                          onChange={handleChangeSelectedCurso}
                          options={optionsCursos}
                          value={selectedCurso}
                        />
                      </WrapRowItem>
                    </WrapRow>

                    <WrapRow style={{ marginTop: '30px' }}>
                      <WrapRowItem>
                        <label>Disciplina:</label>
                        <Select
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({ ...provided, zIndex: 9999 }),
                            // input: styles => ({ ...styles }),
                          }}
                          placeholder={''}
                          onChange={handleChangeSelectedDisciplina}
                          options={optionsDisciplinas}
                          value={selectedDisciplina}
                          isDisabled={
                            !!!(
                              selectedProfissional.value &&
                              (selectedCuradoria.value || selectedArea.value) &&
                              selectedProjeto.value &&
                              selectedCurso.value
                            )
                          }
                        />
                      </WrapRowItem>
                    </WrapRow>
                  </>
                ) : (
                  <></>
                )}

                {projeto.tipo_modalidade === 'curso' ? (
                  <>
                    <WrapRow style={{ marginTop: '30px' }}>
                      <WrapRowItem>
                        <label>Curso:</label>
                        <Select
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({ ...provided, zIndex: 9999 }),
                            // input: styles => ({ ...styles }),
                          }}
                          placeholder={''}
                          onChange={handleChangeSelectedCursoProducao}
                          options={optionsCursosProducao}
                          value={selectedCursosProducao}
                          isDisabled={
                            !!!(
                              selectedProfissional.value &&
                              (selectedCuradoria.value || selectedArea.value) &&
                              selectedProjeto.value
                            )
                          }
                        />
                      </WrapRowItem>
                    </WrapRow>
                  </>
                ) : (
                  <></>
                )}

                <WrapperProducts>
                  <div className="header">Produtos:</div>

                  {produtos.length === 0 ? (
                    <div className="none-products">
                      Nenhum produto adicionado
                    </div>
                  ) : (
                    <></>
                  )}

                  {produtos.map(item => (
                    <Scope
                      key={`${item.id}-${item.produto}`}
                      path={`produtos['${item.id}']`}
                    >
                      <WrapperProduct style={{ marginTop: '10px' }}>
                        <ProductItem>
                          <div className="header">
                            <TagProductPrice>
                              {item.produto}
                              {!checkIsNewPrice(item) && (
                                <div className="tag">{`R$ ${parseFloat(
                                  item.valor,
                                ).toFixed(2)}`}</div>
                              )}
                              <div
                                className="change_price"
                                onClick={() => {
                                  updateIsNewPrice(item);
                                }}
                              >
                                {checkIsNewPrice(item)
                                  ? `Voltar Preço Original de R$ ${parseFloat(
                                      item.valor,
                                    ).toFixed(2)}`
                                  : 'Solicitar Preço Diferenciado'}
                              </div>
                            </TagProductPrice>
                            <div className="price">{getPrice(item)}</div>
                          </div>

                          <WrapRow style={{ marginTop: '10px' }}>
                            <WrapRowItem>
                              <FormInput
                                name="quantidade"
                                type="number"
                                placeholder={
                                  item.unidade_descricao
                                    ? `Quantidade (${item.unidade_descricao})`
                                    : `Quantidade`
                                }
                                max={item.unidade_limite}
                                min={0}
                                step={item.decimal ? '0.5' : '1.0'}
                                onChange={input => {
                                  if (
                                    parseFloat(input.target.value) >
                                      item.unidade_limite &&
                                    item.unidade_aplicavel
                                  ) {
                                    formRef.current?.setFieldValue(
                                      `produtos['${item.id}'].quantidade`,
                                      item.unidade_limite,
                                    );
                                    updatePrice(item, item.unidade_limite);
                                  } else {
                                    if (
                                      parseFloat(input.target.value) > 1 &&
                                      !item.unidade_aplicavel
                                    ) {
                                      formRef.current?.setFieldValue(
                                        `produtos['${item.id}'].quantidade`,
                                        1,
                                      );
                                      updatePrice(item, 1);
                                    } else {
                                      updatePrice(
                                        item,
                                        parseFloat(input.target.value),
                                      );
                                    }
                                  }
                                }}
                              />
                            </WrapRowItem>

                            {checkIsNewPrice(item) && (
                              <WrapRowItem>
                                <FormInputMoneyMask
                                  name="valor_solicitado"
                                  // type="text"
                                  type="number"
                                  step=".01"
                                  min="0"
                                  placeholder="Valor"
                                  onChange={input => {
                                    let txt = input.target.value;

                                    if (
                                      txt.substring(0, 4) == '0.00' ||
                                      txt.substring(0, 4) == '0,00'
                                    ) {
                                      txt = txt.slice(4);
                                    }

                                    if (!txt || txt === '0') {
                                      txt = '0.00';
                                    }

                                    formRef.current?.setFieldValue(
                                      `produtos['${item.id}'].valor_solicitado`,
                                      txt,
                                    );
                                    updateNewPrice(item, parseFloat(txt));
                                  }}
                                />
                              </WrapRowItem>
                            )}
                          </WrapRow>

                          {checkIsNewPrice(item) && (
                            <WrapRow>
                              <WrapRowItem>
                                <FormInput
                                  name="valor_solicitado_justificativa"
                                  type="text"
                                  placeholder="Justificativa"
                                />
                              </WrapRowItem>
                            </WrapRow>
                          )}

                          {checkShowWarning(item) && (
                            <div className="obs-request-price">
                              Observação: Ao solicitar um preço diferenciado, o
                              contrato necessitará passar por um processo de
                              avaliação antes de sua criação.
                            </div>
                          )}

                          {item.contratos_existentes &&
                            item.contratos_existentes.length > 0 && (
                              <div className="obs-already-exist-contract-container">
                                <div className="obs-already-exist-contract-title">
                                  Atenção: Já existe contrato para este produto
                                  e tema selecionado.
                                </div>
                                {item.contratos_existentes.map(
                                  (contrato_existente: any) => (
                                    <div
                                      key={contrato_existente.hash}
                                      className="obs-already-exist-contract"
                                    >
                                      <a
                                        className="link"
                                        href={`/contratos/visualizar-contrato?id_contrato=${contrato_existente.id_contrato}`}
                                        target="_blank"
                                      >
                                        <span>{contrato_existente.hash}</span>
                                        <span>
                                          Especialista:{' '}
                                          {contrato_existente.especialista}
                                        </span>
                                        <span>
                                          Status: {contrato_existente.status}
                                        </span>
                                      </a>
                                    </div>
                                  ),
                                )}
                              </div>
                            )}

                          <WrapRow>
                            <WrapRowItem></WrapRowItem>
                            <button
                              type="button"
                              onClick={() => removeProdItem(item)}
                              className="remove-prod-button"
                            >
                              Excluir
                            </button>
                          </WrapRow>
                        </ProductItem>
                      </WrapperProduct>
                    </Scope>
                  ))}
                </WrapperProducts>

                <WrapperProdButton>
                  {produtos.length < 1 && !showAddProdButton && (
                    <button
                      type="button"
                      onClick={() => setShowAddProdButton(true)}
                      className="add-prod-button"
                    >
                      Adicionar novo produto
                    </button>
                  )}

                  {showAddProdButton && (
                    <WrapRow>
                      <WrapRowItem style={{ marginRight: '10px' }}>
                        <label>Selecione o produto:</label>
                        <Select
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({ ...provided, zIndex: 9999 }),
                            // input: styles => ({ ...styles }),
                          }}
                          placeholder={''}
                          onChange={handleChangeSelectedProduto}
                          // options={produtos.map((item:any) => { return {value: item.id, label: item.produto}})}
                          options={optionsProdutos.map((item: any) => {
                            return { value: item.id, label: item.produto };
                          })}
                          value={selectedProduto}
                        />
                      </WrapRowItem>

                      <button
                        type="button"
                        onClick={() => addNewProduct()}
                        className="add-prod-button"
                      >
                        Adicionar
                      </button>
                    </WrapRow>
                  )}
                </WrapperProdButton>

                <WrapRow style={{ marginTop: '30px' }}>
                  <WrapRowItem>
                    <FormInput
                      value={valorTotal}
                      name="total"
                      type="text"
                      placeholder="Valor Total"
                    />
                  </WrapRowItem>
                </WrapRow>

                {verbaInternos?.aprovacao_verba === true &&
                selectedProfissional.label === 'Interno' &&
                selectedClient.nome === 'EnsineMe' ? (
                  <div className="warning-verba">
                    Atenção: O valor do(s) contrato(s) ultrapassa(m) a verba
                    estipulada para a curadoria, e necessitará passar por um
                    processo de avaliação antes de sua criação.
                  </div>
                ) : (
                  <></>
                )}

                {projeto.tem_data_prestacao_servico ? (
                  <WrapRow style={{ marginTop: '0px' }}>
                    <WrapRowItem>
                      <FormInputMask
                        name="data_prestacao_servico"
                        type="text"
                        placeholder="Prazo de Entrega"
                        mask="99/99/9999"
                        onChange={(input: any) =>
                          setDataPrestacaoServico(input.target.value)
                        }
                      />
                    </WrapRowItem>

                    <WrapRowItem>
                      <FormInputMask
                        name="horario_prestacao_servico"
                        type="text"
                        placeholder="Horário da Prestação do Serviço (24h)"
                        mask="99:99"
                        onChange={(input: any) =>
                          setHoraPrestacaoServico(input.target.value)
                        }
                      />
                    </WrapRowItem>
                  </WrapRow>
                ) : (
                  <WrapRow style={{ marginTop: '0px' }}>
                    <WrapRowItem>
                      <FormInputMask
                        name="prazo_entrega"
                        type="text"
                        placeholder="Prazo de Entrega"
                        mask="99/99/9999"
                      />
                    </WrapRowItem>
                  </WrapRow>
                )}

                <WrapRow>
                  <WrapRowItem>
                    <FormTextarea
                      name="observacoes"
                      type="text"
                      placeholder={
                        selectedClient.nome === 'Ensino'
                          ? 'Informações importantes para o docente'
                          : 'Observações'
                      }
                    />
                  </WrapRowItem>
                </WrapRow>

                <Button
                  type="button"
                  onClick={() => {
                    setShowResume(true);
                    window.scrollTo(0, 0);
                  }}
                >
                  Prosseguir
                </Button>
              </div>

              {showResume && (
                <>
                  <ContratoData>
                    <div className="wrapper-data">
                      <div className="wrapper-data-label">
                        Tipo de Contrato:
                      </div>
                      <div className="wrapper-data-info">
                        {selectedTipoContrato.label}
                      </div>
                    </div>

                    {/* { (projeto.slug !== "roteiro_de_pratica" && projeto.slug !== "learning_experience") ? ( */}
                    {projeto.tipo_modalidade === 'tema' ||
                    projeto.tipo_modalidade === 'tema avulso' ? (
                      <div className="wrapper-data">
                        <div className="wrapper-data-label">Tema:</div>
                        <div className="wrapper-data-info">
                          {selectedTema.label}
                        </div>
                      </div>
                    ) : (
                      <div className="wrapper-data">
                        <div className="wrapper-data-label">
                          Disciplina Produção:
                        </div>
                        <div className="wrapper-data-info">
                          {selectedDisciplina.label}
                        </div>
                      </div>
                    )}

                    {selectedClient.tem_curadoria ? (
                      <div className="wrapper-data">
                        <div className="wrapper-data-label">Curadoria:</div>
                        <div className="wrapper-data-info">
                          {selectedCuradoria.label}
                        </div>
                      </div>
                    ) : (
                      <div className="wrapper-data">
                        <div className="wrapper-data-label">
                          Área de Atuação:
                        </div>
                        <div className="wrapper-data-info">
                          {selectedArea.label}
                        </div>
                      </div>
                    )}

                    <div className="wrapper-data">
                      <div className="wrapper-data-label">Especialista:</div>
                      <div className="wrapper-data-info">
                        Nome: {especialista ? especialista.nome : ''}
                      </div>
                      <div className="wrapper-data-info">
                        Email: {especialista ? especialista.email : ''}
                      </div>
                    </div>

                    <div className="wrapper-data">
                      <div className="wrapper-data-label">
                        Dados do Contrato:
                      </div>
                      <div className="wrapper-data-info">
                        Data Contrato: {dataContrato}
                      </div>
                      <div className="wrapper-data-info">
                        Tipo de Contratação: {selectedProfissional.label}
                      </div>
                      {projeto.tem_data_prestacao_servico ? (
                        <>
                          <div className="wrapper-data-info">
                            Prazo de Entrega: {dataPrestacaoServico}
                          </div>
                          <div className="wrapper-data-info">
                            Horário da Prestação do serviço:{' '}
                            {horaPrestacaoServico}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="wrapper-data-info">
                            Prazo de Entrega:{' '}
                            {formRef.current?.getFieldValue(`prazo_entrega`)}
                          </div>
                        </>
                      )}

                      <div className="wrapper-data-info">
                        {selectedClient.nome === 'Ensino'
                          ? 'Informações importantes para o docente:'
                          : 'Observações:'}

                        <div
                          style={{ marginTop: '10px', marginLeft: '10px' }}
                          dangerouslySetInnerHTML={{
                            __html: formRef.current
                              ?.getFieldValue(`observacoes`)
                              .replace(/\n/g, '<br>'),
                          }}
                        />
                      </div>
                    </div>

                    <div className="wrapper-data">
                      <div className="wrapper-data-label">Produtos:</div>

                      {produtos?.map((item: any) => {
                        if (
                          formRef.current?.getFieldValue(
                            `produtos['${item.id}'].quantidade`,
                          ) > 0
                        )
                          return (
                            <div key={item.id} className="wrapper-produto">
                              <div className="wrapper-data-label">
                                {item.produto}:
                              </div>
                              <div className="wrapper-data-info">
                                Quantidade:{' '}
                                {formRef.current?.getFieldValue(
                                  `produtos['${item.id}'].quantidade`,
                                )}
                              </div>
                              <div className="wrapper-data-info">
                                Valor Unitário: R${' '}
                                {parseFloat(item.valor).toFixed(2)}
                              </div>

                              {checkIsNewPrice(item) && (
                                <div className="wrapper-data-info">
                                  Valor Solicitado: R${' '}
                                  {parseFloat(
                                    formRef.current?.getFieldValue(
                                      `produtos['${item.id}'].valor_solicitado`,
                                    ) || 0,
                                  ).toFixed(2)}
                                </div>
                              )}

                              {checkIsNewPrice(item) && (
                                <div className="wrapper-data-info">
                                  Justificativa:{' '}
                                  {formRef.current?.getFieldValue(
                                    `produtos['${item.id}'].valor_solicitado_justificativa`,
                                  )}
                                </div>
                              )}

                              <div
                                className="wrapper-data-info"
                                style={{ display: 'flex' }}
                              >
                                <span style={{ flex: 1 }}></span>
                                <b>{getPrice(item)}</b>
                              </div>
                            </div>
                          );
                      })}
                      <div className="wrapper-money">
                        <div
                          className="wrapper-data-label"
                          style={{
                            paddingTop: '25px',
                            fontSize: '30px',
                            color: '#999',
                          }}
                        >
                          {valorTotal}
                        </div>
                      </div>

                      {verbaInternos?.aprovacao_verba === true &&
                      selectedProfissional.label === 'Interno' &&
                      selectedClient.nome === 'EnsineMe' ? (
                        <div
                          className="warning-verba"
                          style={{ marginTop: '30px' }}
                        >
                          Atenção: O valor do(s) contrato(s) ultrapassa(m) a
                          verba estipulada para a curadoria, e necessitará
                          passar por um processo de avaliação antes de sua
                          criação.
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </ContratoData>

                  <Button type="submit" loading={loading}>
                    {`${
                      hasNewPrices
                        ? checkGoToValidation()
                          ? 'Submeter para avaliação'
                          : 'Criar Contrato'
                        : 'Criar Contrato'
                    }`}
                  </Button>
                </>
              )}
            </Form>
          </div>
        </Card>
      </Content>
    </Container>
  );
};

export default ContratosCadastroEdita;
