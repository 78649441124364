import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiSave, FiTrash, FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import Switch from "react-switch";

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import FormInput from '../../components/FormInput';
// import FormInputMask from '../../components/FormInputMask';
import UploadInput from '../../components/UploadInput';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem,
  WrapFile,
  WrapFileDescription,
} from './styles';

interface ContratosUploadFormData {
  id: string;
  titulo: string;
  arquivos: any;
  tipo_contratacao: any;
  status: any;
}

interface TiposArquivoInterface {
  id: string;
  titulo: string;
  descricao: string;
  id_arquivo: string;
  url_arquivo: string;
  nome_original: string;
  preenchimento_obrigatorio: boolean;
  id_arquivo_original: string;
}

const ContratosUpload: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location: any = useLocation();

  const { user, token } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [tiposArquivo, setTiposArquivo] = useState<TiposArquivoInterface[]>([]);
  // const [arquivos, setArquivos] = useState<TiposArquivoInterface[]>([]);
  const [selectedItem, setSelectedItem] = useState<ContratosUploadFormData>(
    {} as ContratosUploadFormData,
  );
  const [confirmedInfo, setConfirmedInfo] = useState(false);
  const [someFileChanged, setSomeFileChanged] = useState(false);
  
  useEffect(() => {
    if (location && location.state) {
      // setSelectedItem(location.state.selectedItem);
      getContrato(location.state.selectedItem.id);
    }
  }, [location]);

  useEffect(() => {
    if (selectedItem && selectedItem.id) {
      //console.log('selectedItem', selectedItem);

      getTiposArquivo();
      

      if (location && location.state && location.state.showMessage) {
        showMessage();
      }
    }
  }, [selectedItem, location]);

  const getContrato = useCallback(async (id) => {
    setLoading(true);

    try {
      const response = await api.get(`/contrato/${id}`);
      //console.log("response", response.data);

      setSelectedItem(response.data);

    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const showMessage = useCallback(async () => {
    const user_response = await Swal.fire({
      title: '<strong>Atenção</strong>',
      icon: 'info',
      html:
        'Para agilizar o seu pagamento é preciso enviar os documentos solicitados o mais rápido possível!',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: true,
      confirmButtonText: 'Entendi',
      confirmButtonAriaLabel: 'Entendi',
      cancelButtonText: 'Voltar',
      cancelButtonAriaLabel: 'Voltar',
      confirmButtonColor: '#1CAEBD',
      cancelButtonColor: '#312e38',
      reverseButtons: true,
    });
  }, []);

  const getTiposArquivo = useCallback(async () => {
    setLoading(true);

    try {
      const tipo: any[] = [];

      if (selectedItem.tipo_contratacao === 'pessoa_juridica') {
        tipo.push('contrato_pj');
      }

      if (
        selectedItem.status?.slug !== 'aceite' &&
        selectedItem.status?.slug !== 'pagamento_comandado' &&
        selectedItem.status?.slug !== 'recusado' &&
        selectedItem.status?.slug !== 'cancelado'
      ) {
        tipo.push('documento_contrato');
      }

      //console.log('params tipo', tipo);

      const response = await api.get(`/tipo-arquivo`, {
        params: {
          tipo: tipo,
        },
      });

      //console.log('response tipo-arquivo', response.data);

      const temp: TiposArquivoInterface[] = [];

      const arquivos = selectedItem.arquivos;
      //console.log('arquivos', arquivos);

      response.data.forEach((item: TiposArquivoInterface) => {
        const params = {
          ...item,
          id_arquivo: '',
          url_arquivo: '',
          nome_original: '',
        };

        arquivos.forEach((arquivo: any) => {
          if (item.id === arquivo.id_tipo_arquivo) {
            params.id_arquivo_original = arquivo.id;
            params.id_arquivo = arquivo.id_arquivo;
            params.url_arquivo = arquivo.arquivo.url;
            params.nome_original = arquivo.arquivo.nome_original;
          }
        });

        temp.push(params);
      });

      setTiposArquivo(temp);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, [selectedItem]);

  const setItems = useCallback(
    (currentItem, upload) => {
      const temp: TiposArquivoInterface[] = [];

      //console.log("setItems", currentItem);

      if(currentItem.preenchimento_obrigatorio){
        setSomeFileChanged(true);
      }

      tiposArquivo.forEach((item: TiposArquivoInterface) => {
        if (item.id === currentItem.id) {
          temp.push({
            ...item,
            id_arquivo_original: '',
            id_arquivo: upload.id,
            url_arquivo: upload.url,
            nome_original: upload.nome_original,
          });
        } else {
          temp.push(item);
        }
      });

      setTiposArquivo(temp);

      //console.log('setItems', temp);
    },
    [tiposArquivo],
  );

  function goBack() {
    history.push('/contratos/meus-contratos');
  }

  const saveUploads = useCallback(async () => {
    //console.log('saveUploads');

    if(confirmedInfo === false){
      addToast({
        type: 'error',
        title: "É preciso confirmar que os documentos estão atualizados",
        description: '',
      });

      return;
    }

    let tmp = false;

    tiposArquivo.forEach((item: TiposArquivoInterface) => {
      if (item.preenchimento_obrigatorio) {
        if (!item.id_arquivo) {
          addToast({
            type: 'error',
            title: `Upload do ${item.titulo} é obrigatório`,
            description: '',
          });

          tmp = true;

          return;
        }
      }
    });

    if (tmp) return;

    try {
      setLoading(true);
      
      const temp: any = [];

      tiposArquivo.forEach(item => {
        if (item.id_arquivo) {
          const data: any = {
            id_tipo_arquivo: item.id,
            id_arquivo: item.id_arquivo,
          };

          if (item.id_arquivo_original) {
            data.id = item.id_arquivo_original;
          }

          temp.push(data);
        }
      });

      const params = {
        arquivos: temp,
        tipo_contratacao: selectedItem.tipo_contratacao
          ? selectedItem.tipo_contratacao
          : '',
        notificacao_financeiro: someFileChanged
      };

      //console.log('params', params);

      await api.put(`/contrato-arquivo/${selectedItem.id}`, params);

      addToast({
        type: 'success',
        title: 'Documentos cadastrados com sucesso',
        description: '',
      });

      setLoading(false);

      history.push('/contratos/meus-contratos');
    } catch (err) {
      setLoading(false);

      for (let error in err.response?.data) {
        addToast({
          type: 'error',
          title: 'Erro ao cadastrar documentos',
          description: err.response?.data[error]
            ? err.response.data[error]
            : 'Erro ao cadastrar documentos, tente novamente mais tarde.',
        });
      }
    }finally{
      setLoading(false);
    }
  }, [tiposArquivo, selectedItem, confirmedInfo, someFileChanged]);

  const removeUpload = useCallback(async id => {
    //console.log('removeUpload', id);

    try {
      await api.delete(`/contrato-arquivo/${id}`);

      addToast({
        type: 'success',
        title: 'Documento removido com sucesso',
        description: '',
      });
    } catch (err) {
      for (let error in err.response?.data) {
        addToast({
          type: 'error',
          title: 'Erro ao remover documento',
          description: err.response?.data[error]
            ? err.response.data[error]
            : 'Erro ao remover documento, tente novamente mais tarde.',
        });
      }
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Content>
        <Card>
          <div className="form">
            <div className="header">
              <h2>Envio de Arquivos do Contrato</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            {tiposArquivo.map(item => (
              <WrapFile key={item.id}>
                <WrapFileDescription>
                  <div className="titulo">
                    {item.titulo}
                    <span style={{ color: '#E94F51', fontSize: '12px' }}>
                      {item.preenchimento_obrigatorio && !item.id_arquivo
                        ? ' envio obrigatório'
                        : ''}
                    </span>
                  </div>
                  <p className="descricao">{item.descricao}</p>
                </WrapFileDescription>

                {!item.id_arquivo ? (
                  <UploadInput item={item} setItems={setItems} />
                ) : (
                  <div className="nome_arquivo">
                    <div style={{ flex: 1 }}>
                      <a
                        className="link_upload"
                        href={`${item.url_arquivo}?token=${token}`}
                        target="_blank"
                      >
                        {item.nome_original}
                      </a>
                    </div>
                    <FiTrash
                      style={{ fontSize: '18px', cursor: 'pointer' }}
                      onClick={() => {
                        if (item.id_arquivo_original) {
                          if(item.preenchimento_obrigatorio){
                            setSomeFileChanged(true);
                          }
                          removeUpload(item.id_arquivo_original);
                        }
                        setItems(item, {});
                      }}
                    />
                  </div>
                )}
              </WrapFile>
            ))}

            <WrapRow style={{ marginTop: "30px" }}>
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  offColor={'#e6e6e6'}
                  onColor={'#2FB4C2'}
                  onChange={() => { setConfirmedInfo(!confirmedInfo) }}
                  checked={confirmedInfo}
                />
                <span className="text-switch">Confirmo que o(s) documento(s) estão atualizados.</span>
            </WrapRow>

            <Button
              type="button"
              onClick={() => saveUploads()}
              loading={loading}
              style={{ marginTop: "30px" }}
            >
              Salvar
            </Button>
          </div>
        </Card>
      </Content>
    </Container>
  );
};

export default ContratosUpload;
