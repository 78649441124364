import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone'

import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';

import api from '../../../services/api';

import {
  DropzoneContainer
} from './styles';

interface UploadInputInterface {
  item: any;
  setItems: any;
  setLoading: any;
  startDate: any;
  endDate: any;
}

const UploadInput: React.FC<UploadInputInterface> = ({ item, setItems, setLoading, startDate, endDate }) => {
  const { addToast } = useToast();
  const { token, selectedClient } = useAuth();

  const onDrop = useCallback(async acceptedFiles => {
    try {
      //console.log("onDrop", acceptedFiles);

      setLoading(true);

      const data_inicio_permissao_corte = `${startDate.getFullYear()}-${("0" + (startDate.getMonth() + 1)).slice(-2)}-${("0" + startDate.getDate()).slice(-2)}`;
      const data_fim_permissao_corte = `${endDate.getFullYear()}-${("0" + (endDate.getMonth() + 1)).slice(-2)}-${("0" + endDate.getDate()).slice(-2)}`;

      const data = new FormData();
      data.append("upload", acceptedFiles[0]);
      data.append("data_inicio_permissao_corte", data_inicio_permissao_corte);
      data.append("data_fim_permissao_corte", data_fim_permissao_corte);
      data.append("id_cliente", selectedClient.id);
      
      const response = await api.post("/relatorio-rh-consolidado", data);
      //console.log("upload response", response.data);

      if(response.data){
        window.open(`${response.data}?token=${token}`, '_blank');
      }

      setItems(item, response.data);
      setLoading(false);

      addToast({
        type: 'success',
        title: 'Planilha importada com sucesso',
        description: '',
      });

    } catch (error) {
      //console.log("upload error", error);
      setLoading(false);

      addToast({
        type: 'error',
        title: 'Erro ao importar planilha',
        description: '',
      });
    }
  }, [item, setItems, startDate, endDate, selectedClient])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <DropzoneContainer {...getRootProps()}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Coloque seu arquivo aqui ...</p> :
          <p>Arraste o arquivo, ou clique para selecioná-lo.</p>
      }
    </DropzoneContainer>
  );
};

export default UploadInput;
