import styled from 'styled-components';
// import { shade } from 'polished';

export const Container = styled.div`
  .icon {
    font-size: 22px;
    color: #717171;
    cursor: pointer;
  }

  .title-sweetalert {
    font-size: 8px;
    color: red;
  }

  .button-top-datatable {
    padding: 8px 10px;
    border-radius: 4px;
  }

  .wrapper-table-actions {
    display: flex;
    flex-direction: center;
    text-align: right;
    /* flex: 1; */
  }

  .table-action {
    color: #666;
    font-size: 24px;
    padding: 0 5px;
    cursor: pointer;
  }

  .table-action-disabled {
    color: #e6e6e6;
    cursor: auto;
  }

  .table-action-button {
    font-size: 16px;
    color: #717171;
    cursor: pointer;
    border: 0.5px solid #e5e5e5;
    border-radius: 4px;
    padding: 5px 10px;
    margin-right: 7px;
  }

  .table-action-button svg {
    font-size: 16px;
  }

  .table-action-button span {
    /* margin-left: 5px; */
  }

  .table-action-button :hover {
    opacity: 0.8;
  }
`;

export const Content = styled.main`
  /* max-width: 1120px;
  margin: 10px auto; */
  display: flex;
  margin-bottom: 80px;
  flex-direction: column;

  form {
    width: 100%;

    h2 {
      margin-bottom: 30px;
      padding: 5px 0;
    }
  }
`;

export const WrapRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const WrapRowItem = styled.div`
  flex: 1;
  margin: 0 5px;
`;

export const Item = styled.div`
  flex: 1;
  margin: 20px 5px;
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .title {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .subtitle {
    font-weight: lighter;
  }

  .removeIcon {
    cursor: pointer;
  }
`;

export const TextField = styled.input`
  height: 40px;
  width: 250px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;

export const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  height: 40px;
  width: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  background-color: #f8f8f8;

  &:hover {
    background-color: #e6e6e6;
    color: #333;
  }
`;
