import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import FormInput from '../../components/FormInput';
// import FormInputMask from '../../components/FormInputMask';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem,
  WrapperModules,
  WrapperModule,
  ModuleItem
} from './styles';

interface TemaFormData {
  id: string;
  titulo: string;
  modelo: any;
  tags: any;
  modulos: any;
  ordem: number;
  id_curadoria: string;
  id_modelo: string;
  id_disciplina: string;
  curador_responsavel: string;
  codigo_tema: string;
  id_periodo: string;
  periodo: any;
  id_area_atuacao: string;
}

interface TagFormData {
  id: string;
  tag: string;
}

interface ModeloFormData {
  id: string;
  titulo: string;
}

interface ModuloFormData {
  id: any;
  modulo: string;
  topico: string;
}

interface CuradoriaFormData {
  id: string;
  nome: string;
}

interface DisciplinaFormData {
  id: string;
  titulo: string;
  codigo: string;
}

interface PeriodosInterface {
  id: string;
  periodo: string;
}

interface OptionsData {
  value: string;
  label: string;
}

const TemasEdicao: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location: any = useLocation();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [curadorias, setCuradorias] = useState<CuradoriaFormData[]>([]);
  const [optionsCuradorias, setOptionsCuradorias] = useState<OptionsData[]>([]);
  const [selectedCuradoria, setSelectedCuradoria] = useState<OptionsData>({ value: "", label: "" });
  const [areasAtuacao, setAreasAtuacao] = useState<CuradoriaFormData[]>([]);
  const [optionsAreasAtuacao, setOptionsAreasAtuacao] = useState<OptionsData[]>([]);
  const [selectedAreaAtuacao, setSelectedAreaAtuacao] = useState<OptionsData>({ value: "", label: "" });
  const [disciplinas, setDisciplinas] = useState<DisciplinaFormData[]>([]);
  const [optionsDisciplinas, setOptionsDisciplinas] = useState<OptionsData[]>([]);
  const [selectedDisciplina, setSelectedDisciplina] = useState<OptionsData>({ value: "", label: "" });
  const [selectedItem, setSelectedItem] = useState<TemaFormData>({} as TemaFormData);
  const [selectedTema, setSelectedTema] = useState<TemaFormData>({} as TemaFormData);
  const [periodos, setPeriodos] = useState<PeriodosInterface[]>([]);
  const [optionsPeriodos, setOptionsPeriodos] = useState<OptionsData[]>([]);
  const [selectedPeriodo, setSelectedPeriodo] = useState<OptionsData>({} as OptionsData);

  useEffect(() => {
    if (location && location.state) {
      setSelectedItem(location.state.selectedItem);
    }
  }, [location]);

  useEffect(() => {
    if (selectedItem) {
      getTema(selectedItem.id);
      getPeriodos();
    }
  }, [selectedItem]);

  useEffect(() => {
    var options: OptionsData[] = [];

    periodos.map(item => {
      options.push({ value: item.id, label: item.periodo });
    });

    setOptionsPeriodos(options);
    //console.log("options periodos", options);
  }, [periodos]);

  useEffect(() => {
    if (selectedTema) {
      // console.log("selectedTema", selectedTema)

      formRef.current?.setFieldValue("titulo", selectedTema.titulo);

      if (selectedTema.id_curadoria) {
        const current = curadorias.find((item) => item.id === selectedTema.id_curadoria)
        if (current) {
          setSelectedCuradoria({ value: current.id, label: current.nome });
        }
      }
      // }else if(selectedTema.id_area_atuacao){
      //   const current = areasAtuacao.find((item) => item.id === selectedTema.id_area_atuacao)
      //   if(current){
      //     setSelectedAreaAtuacao({ value: current.id, label: current.nome });
      //   }
      // }

      if (selectedTema.id_periodo) {
        setSelectedPeriodo({ value: selectedTema.periodo.id, label: selectedTema.periodo.periodo });
      }

      if (selectedTema.id_disciplina) {
        const current = disciplinas.find((item) => item.id === selectedTema.id_disciplina)
        if (current) {
          setSelectedDisciplina({ value: current.id, label: current.titulo });
        }
      }

      if (selectedTema.curador_responsavel) {
        formRef.current?.setFieldValue("curador_responsavel", selectedTema.curador_responsavel);
      }

      if (selectedTema.codigo_tema) {
        formRef.current?.setFieldValue("codigo_tema", selectedTema.codigo_tema);
      }
    }
  }, [selectedTema]);

  useEffect(() => {
    getCuradorias();
    getAreasAtuacao();
    // getDisciplinas();
  }, []);

  useEffect(() => {
    if (curadorias.length === 0) return;

    var options: OptionsData[] = [];

    curadorias.map(item => {
      options.push({ value: item.id, label: item.nome });
    });

    setOptionsCuradorias(options);
    //console.log("options curadorias", options);
  }, [curadorias]);

  useEffect(() => {
    if (areasAtuacao.length === 0) return;

    var options: OptionsData[] = [];

    areasAtuacao.map(item => {
      options.push({ value: item.id, label: item.nome });
    });

    setOptionsAreasAtuacao(options);
    //console.log("options areasAtuacao", options);
  }, [areasAtuacao]);

  useEffect(() => {
    if (disciplinas.length === 0) return;

    var options: OptionsData[] = [];

    disciplinas.map(item => {
      options.push({ value: item.id, label: item.titulo });
    });

    setOptionsDisciplinas(options);
    //console.log("options disciplinas", options);
  }, [disciplinas]);

  const getTema = useCallback(async (id) => {
    setLoading(true);

    try {
      const response = await api.get(`tema/${id}`);
      // console.log("response getTema:", response.data);

      setSelectedTema(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getCuradorias = useCallback(async () => {
    const response = await api.get(`/curadoria`);
    //console.log("curadoria", response.data);

    setCuradorias(response.data);
  }, []);

  const getAreasAtuacao = useCallback(async () => {
    const response = await api.get(`/area-atuacao`);
    //console.log("curadoria", response.data);

    setAreasAtuacao(response.data);
  }, []);

  const getDisciplinas = useCallback(async () => {
    const response = await api.get(`/disciplina`);
    //console.log("disciplina", response.data);

    setDisciplinas(response.data);
  }, []);

  const getPeriodos = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/periodo`);
      // console.log("getPeriodos", response.data);

      setPeriodos(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: TemaFormData) => {

      if (selectedTema.id_curadoria && !selectedCuradoria.value) {
        addToast({
          type: 'error',
          title: 'Selecione a curadoria',
          description: ''
        });
        return;
      }

      if (selectedTema.id_area_atuacao && !selectedAreaAtuacao.value) {
        addToast({
          type: 'error',
          title: 'Selecione a área de atuação',
          description: ''
        });
        return;
      }


      try {
        // console.log("handleSubmit", data);

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          titulo: Yup.string().required('Título obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let params: any = {
          titulo: data.titulo,
          // id_disciplina: selectedDisciplina.value,
          codigo_tema: data.codigo_tema,
          curador_responsavel: data.curador_responsavel,
          id_periodo: selectedPeriodo.value ? selectedPeriodo.value !== "" ? selectedPeriodo.value : null : null
        }

        if (selectedTema.id_curadoria) {
          params.id_curadoria = selectedCuradoria.value;
        } else {
          params.id_area_atuacao = selectedAreaAtuacao.value;
        }

        // console.log("params", params);

        await api.put(`/tema/${selectedTema.id}`, params);

        addToast({
          type: 'success',
          title: 'Tema atualizado com sucesso',
          description: '',
        });

        goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar tema',
            description: err.response?.data[error] ?
              err.response.data[error] :
              'Erro ao atualizar tema, tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, selectedTema, selectedCuradoria, selectedDisciplina, selectedAreaAtuacao, selectedPeriodo],
  );

  function handleChangeSelectedPeriodo(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedPeriodo({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedPeriodo({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue Periodo", newValue.value);

    setSelectedPeriodo(newValue);
  };

  function goBack() {
    history.push('/temas');
  }

  function handleChangeSelectedCuradoria(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedCuradoria({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedCuradoria({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue Curadoria", newValue.value);

    setSelectedCuradoria(newValue);
  };

  function handleChangeSelectedAreaAtuacao(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedAreaAtuacao({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedAreaAtuacao({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue Curadoria", newValue.value);

    setSelectedAreaAtuacao(newValue);
  };

  function handleChangeSelectedDisciplina(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedDisciplina({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedDisciplina({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue Disciplina", newValue.value);

    setSelectedDisciplina(newValue);
  };

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <Container>
      <Content>
        <Card>
          <div className="form">
            <div className="header">
              <h2>Editar Tema</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            <Form ref={formRef} onSubmit={handleSubmit}>
              <WrapRow>
                <WrapRowItem>
                  <FormInput
                    name="titulo"
                    type="text"
                    placeholder="Título"
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem>
                  <FormInput
                    name="codigo_tema"
                    type="text"
                    placeholder="Código do Tema"
                  />
                </WrapRowItem>
              </WrapRow>

              {selectedTema.id_curadoria ? (
                <WrapRow style={{ marginTop: "10px" }}>
                  <WrapRowItem>
                    <label>Curadoria:</label>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedCuradoria}
                      options={optionsCuradorias}
                      value={selectedCuradoria}
                    />
                  </WrapRowItem>
                </WrapRow>
              ) : (
                <WrapRow style={{ marginTop: "10px" }}>
                  <WrapRowItem>
                    <label>Área de Atuação:</label>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedAreaAtuacao}
                      options={optionsAreasAtuacao}
                      value={selectedAreaAtuacao}
                    />
                  </WrapRowItem>
                </WrapRow>
              )}

              <WrapRow>
                <WrapRowItem style={{ marginTop: "30px" }}>
                  <label>Período:</label>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedPeriodo}
                    options={optionsPeriodos}
                    value={selectedPeriodo}
                    isClearable
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow style={{ marginTop: "20px" }}>
                <WrapRowItem>
                  <FormInput
                    name="curador_responsavel"
                    type="text"
                    placeholder={`${selectedTema.id_curadoria ? 'Curador ' : ''}Responsável`}
                  />
                </WrapRowItem>
              </WrapRow>

              {/* <WrapRow style={{ marginTop: "20px" }}>
                  <WrapRowItem>
                    <label>Disciplina:</label>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedDisciplina}
                      options={optionsDisciplinas}
                      value={selectedDisciplina}
                    />
                  </WrapRowItem>
                </WrapRow> */}

              <Button type="submit" loading={loading} style={{ marginTop: "40px" }}>
                Salvar
              </Button>

            </Form>
          </div>
        </Card>
      </Content>
    </Container>
  );
};

export default TemasEdicao;
