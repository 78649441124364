import styled from 'styled-components';
// import { shade } from 'polished';

interface CheckBoxItemInterface {
  active: boolean;
}

export const Container = styled.div`
  .icon {
    font-size: 22px;
    color: #717171;
    cursor: pointer;
  }
`;

export const Content = styled.main`
  /* max-width: 1120px;
  margin: 10px auto; */
  display: flex;
  margin-bottom: 80px;
  flex-direction: column;

  form{
    width: 100%;

    h2{
      margin-bottom: 30px;
      padding: 5px 0;
    }
  }
`;

export const WrapRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row; 
  flex-wrap: wrap;
`;

export const WrapRowItem = styled.div`
  flex: 1;
  margin: 0 5px;
`;

export const Item = styled.div`
  flex: 1;
  margin: 20px 5px;
  background-color: #F1F1F1;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .title{
    font-size: 18px;
    margin-bottom: 10px;
  }

  .subtitle{
    font-weight: lighter;
  }

  .removeIcon{
    cursor: pointer;
  }
`;

export const ModalWrapper = styled.div`
  min-width: 560px;
  padding: 10px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .icon {
    font-size: 22px;
    color: #717171;
    cursor: pointer;
  }

  h2 {
    color: #717171;
    font-size: 22px;
  }

  p {
    color: #717171;
    margin-top: 30px;
    font-size: 16px;
  }
  
  .button-confirm {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
`;



export const CheckBoxWrapper = styled.div`

  flex-wrap:wrap;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */

`;

export const CheckBoxItem = styled.div<CheckBoxItemInterface>`

  display:flex;
  display:1;
 
  padding:10px;
  flex-direction: row;
  display: flex;
  flex-basis:25%;


  @media screen and (max-width: 1200px) {
    flex-basis:33.33%;
  }

  @media screen and (max-width: 768px) {
    flex-basis:50%;
  }

  span{
    color: ${(props: any) => props.active ? "#2FB4C2" : "#ccc"};;
    margin-left:10px;
    margin-top:4px;
    display:block;
  }

`;


