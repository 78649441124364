import React, { useRef, useCallback, useEffect, useState } from 'react';
import { FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';

import { Container, Content, Background, AnimationContainer } from './styles';

import logoImage from '../../../../assets/logo.png';

interface ConfirmEmailFormData {
  token: string;
}

const ConfirmEmail: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location = useLocation();

  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);

  const validateEmail = useCallback(
    async token => {
      try {
        if (!token) {
          throw new Error();
        }

        await api.put('/confirmar-email', {
          token,
        });

        addToast({
          type: 'success',
          title: 'Parabéns',
          description: 'E-mail confirmado com sucesso.',
        });

        history.push('/');
      } catch (err) {
        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao confirmar e-mail',
            description: err.response?.data[error]?.message
              ? err.response.data[error].message
              : 'Ocorreu um error ao confirmar e-mail, tente novamente.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, history],
  );

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');

    if (token) {
      validateEmail(token);
    }
  }, [location.search, validateEmail]);

  const handleSubmit = useCallback(
    async (data: ConfirmEmailFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          token: Yup.string().required('Token obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { token } = data;

        if (!token) {
          throw new Error();
        }

        validateEmail(token);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        //console.log(err);

        addToast({
          type: 'error',
          title: 'Erro ao confirmar e-mail',
          description: 'Ocorreu um error ao confirmar e-mail, tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, validateEmail],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImage} alt="EnsineMe – Flow" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Confirmar E-mail</h1>

            <Input name="token" type="text" icon={FiLock} placeholder="Token" />

            <Button type="submit" loading={loading}>
              Enviar Token
            </Button>
          </Form>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default ConfirmEmail;
