import styled from 'styled-components';


interface TimelineItemProps {
  done?: boolean;
  canceled?: boolean;

}

export const WrapperTimeline = styled.ul`
  list-style-type: none;
  flex:1;
  margin:0;
  padding: 0;
  display: flex;
  justify-content: center;
  color: #ccc; 
  font-weight: 400;
  font-size: 13px;
  opacity:0.8;
`;

export const TimelineItem = styled.li<TimelineItemProps>`
  flex:1;
  /* color: ${props => (props.done) ? "#000" : "#ccc"}; */

  .timestamp {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  height: 12px;
  color:#999;
  font-size:13px;
  }

  .status {
  display: flex;
  justify-content: center;
  position: relative;
  border-top: 2px solid ${props => (props.done) ? "#424566" : "#D6DCE0"}


  
}
.status div {
  margin-top:13px;
  font-weight: 500;
  font-size: 13px;
  color: ${props => (props.done) ? "#2FB4C2" : (props.canceled) ? "#a71a33" : "#ccc"};
}

.status:before {
  content: "";
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 25px;
  position: absolute;
  top: -12px;
  border: ${props => (props.done) ? "3px solid #424566" : (props.canceled) ? "3px solid #a71a33" : "1px solid #ddd"};
  cursor:pointer;
}



`;
