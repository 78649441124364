import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { FiTrash, FiPlus, FiEdit, FiX, FiUploadCloud } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { FormHandles } from '@unform/core';
import ReactTooltip from 'react-tooltip';
import { BsFileEarmarkCheck, BsFileEarmarkText, BsFileEarmarkArrowUp, BsFileRichtext } from "react-icons/bs";

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
import Loading from '../../components/Loading';
// import FormInput from '../../components/FormInput';
// import Button from '../../components/Button';

import api from '../../services/api';

import {
  Container,
  Content,
  TextField,
  ClearButton,
  CargaTemaData,
  WrapRow,
  TableButtonRefused,
  TableButtonAccepted
} from './styles';

interface CargaTemasVisualizarInterface {
  id: any;
  lote: string;
  finalizado: boolean;
  curadorias_criadas: number;
  disciplinas_criadas: number;
  temas_criados: number;
  areas_conhecimento_criadas: number;
  periodo: any;
}

// interface OptionsData {
//   value: boolean;
//   label: string;
// }

const CargaTemasVisualizar: React.FC = () => {
  // const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location: any = useLocation();

  const { token } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal)

  const [loading, setLoading] = useState(true);
  const [cargaTemas, setCargaTemas] = useState<CargaTemasVisualizarInterface[]>([]);
  const [selectedCargaTemas, setSelectedCargaTema] = useState<CargaTemasVisualizarInterface>({} as CargaTemasVisualizarInterface);
  const [selectedItem, setSelectedItem] = useState<CargaTemasVisualizarInterface>({} as CargaTemasVisualizarInterface);
  // const [selectedRows, setSelectedRows] = useState([]);
  // const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  useEffect(() => {
    if (location && location.state) {
      //console.log("location", location);
      setSelectedItem(location.state.selectedItem);
    }
  }, [location]);

  useEffect(() => {
    if (selectedItem && selectedItem.id) {
      //console.log("selectedItem", selectedItem);
      getCargaTema(selectedItem.id);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (selectedCargaTemas && selectedCargaTemas.id) {
      //console.log("selectedCargaTemas", selectedCargaTemas, selectedCargaTemas.id);
    }
  }, [selectedCargaTemas]);

  const filtered = useMemo(() => {
    if (!filterText)
      return cargaTemas

    let search = filterText.toLowerCase();

    search = search.split("-").join("");
    search = search.split(".").join("");

    search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

    return cargaTemas.filter((item:any) => {
      return item.tema.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        item.codigo_disciplina.toLowerCase().includes(search) ||
        item.nome_disciplina.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        item.curadoria.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(search) ||
        item.curador.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(search) ||
        item.tipo_conteudo.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(search) ||
        item.area_conhecimento.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(search) ||
        item.lote.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search)
    })
  }, [filterText, cargaTemas])


  const getCargaTema = useCallback(async (id) => {
    setLoading(true);

    try {
      const response = await api.get(`/carga-temas/${id}`);
      //console.log("response getCargaTema", response.data);

      setSelectedCargaTema(response.data);
      setCargaTemas(response.data.linhas_lote);

    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      //console.log("handleSubmit");

      setLoading(true);

      const params = {}
      //console.log("params", params, selectedCargaTemas);

      const response = await api.put(`/carga-temas/${selectedCargaTemas.id}`, params);
      //console.log("response handleSubmit", response);

      addToast({
        type: 'success',
        title: 'Lote importado com sucesso',
        description: '',
      });

      // getCargaTema(selectedCargaTemas.id);
      goBack();

    } catch (err) {
      for (let error in err.response?.data) {
        addToast({
          type: 'error',
          title: 'Erro ao importar dados',
          description: err.response?.data[error] ?
            err.response.data[error] :
            'Erro ao importar dados, tente novamente mais tarde.',
        });
      }
    } finally {
      setLoading(false);
    }
  }, [selectedCargaTemas, addToast]);

  
  const confirmImport = useCallback(() => {
    MySwal.fire({
      // title: 'Tem certeza que deseja remover?',
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      confirmButtonColor: '#1CAEBD',
      cancelButtonColor: '#312e38',
      cancelButtonText: 'Cancelar',
      // icon: 'warning',
      html: '<div style="font-size:20px">Tem certeza que deseja importar este lote?</div>',
      // position: 'top'
    }).then((result) => {
      if (result.isConfirmed) {
        //console.log("confirmed");
        handleSubmit();
      }
    })
  }, [handleSubmit]);

  // const removeSelectedRows = useCallback(() => {
  //   MySwal.fire({
  //     // title: 'Tem certeza que deseja remover?',
  //     showCancelButton: true,
  //     confirmButtonText: `Remover`,
  //     confirmButtonColor: '#1CAEBD',
  //     cancelButtonColor: '#312e38',
  //     cancelButtonText: 'Cancelar',
  //     // icon: 'warning',
  //     html: '<div style="font-size:20px">Tem certeza que deseja remover?</div>',
  //     // position: 'top'
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       selectedRows.map((row: any) => {
  //         removeRow(row.id);
  //       });
  //     }
  //   })
  // }, [selectedRows]);

  // const removeRow = useCallback(async (rowId) => {
  //   //console.log("remove", rowId);
  //   setLoading(true);

  //   try {
  //     await api.delete(`/contrato/${rowId}`);

  //     setClearSelectedRows(true);

  //     addToast({
  //       type: 'success',
  //       title: 'Contrato removido com sucesso',
  //       description: '',
  //     });

  //     getCargaTemas();
  //   } catch (err) {
  //     for (let error in err.response?.data) {
  //       addToast({
  //         type: 'error',
  //         title: 'Erro ao remover contrato',
  //         description: err.response?.data[error] ?
  //           err.response.data[error] :
  //           'Erro ao remover contrato, tente novamente mais tarde.',
  //       });
  //     }
  //   }
  // }, []);

  // function goStoreForm() {
  //   history.push("/cargaTemas/cadastro");
  // }

  // function goUpdateForm() {
  //   history.push("/cargaTemas/edicao", {
  //     selectedItem: selectedRows[0]
  //   });
  // }

  // const handleChange = (state: any) => {
  //   // You can use setState or dispatch with something like Redux so we can use the retrieved data
  //   //console.log('Selected Rows: ', state.selectedRows);

  //   setSelectedRows(state.selectedRows);
  // };

  // const actions = (
  //   <FiPlus className="icon" onClick={() => {
  //     goStoreForm();
  //   }} />
  // );

  // const contextActions = (
  //   <>
  //     {selectedRows.length === 1 &&
  //       <FiEdit className="icon" style={{ marginRight: "15px" }} onClick={() => {
  //         goUpdateForm();
  //       }} />
  //     }
  //     <FiTrash className="icon" style={{ marginRight: "10px" }} onClick={() => {
  //       removeSelectedRows();
  //     }} />
  //   </>
  // );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <>
        <TextField id="search" type="text" placeholder="Pesquisar" aria-label="Search Input" value={filterText} onChange={(e: any) => setFilterText(e.target.value)} />
        <ClearButton type="button" onClick={handleClear}>x</ClearButton>
      </>
    );
  }, [filterText, resetPaginationToggle]);

  function goBack() {
    history.goBack();
  }

  const columns = [
    {
      name: 'COD. Tema',
      selector: 'codigo_tema',
      sortable: false,
      // right: true,
      maxWidth: '150px',
    },
    {
      name: 'Tema',
      selector: 'tema',
      sortable: false,
      // right: true,
      maxWidth: '600px',
    },
    {
      name: 'Curadoria',
      selector: 'curadoria',
      sortable: false,
      // right: true,
      maxWidth: '180px',
      hide: 860
    },
    {
      name: 'Curador',
      selector: 'curador',
      sortable: false,
      // right: true,
      maxWidth: '180px',
      hide: 1375
    },
    {
      name: 'COD. Disciplina',
      selector: 'codigo_disciplina',
      sortable: false,
      // right: true,
      maxWidth: '150px',
      hide: 1240
    },
    {
      name: 'Disciplina',
      selector: 'nome_disciplina',
      sortable: false,
      // right: true,
      maxWidth: '180px',
      hide: 1685
    },
    {
      name: 'Área do Conhecimento',
      selector: 'area_conhecimento',
      sortable: false,
      // right: true,
      maxWidth: '180px',
      hide: 1535,
    },
    {
      name: 'Tipo',
      selector: 'tipo_conteudo',
      sortable: false,
      // right: true,
      maxWidth: '200px',
      hide: 1685
    },
  ];

  return (
    <Container>
      <Content>
        <Card>
          <div className="header" style={{ height: "0px", padding: "15px" }}>
            <h2>
              Carga de Temas - {selectedCargaTemas.lote}
            </h2>
            <FiX onClick={() => goBack()} className="icon" />
          </div>

          <CargaTemaData>
            <div className="wrapper-data">
              <div className="wrapper-data-info">
                Período: {selectedCargaTemas.periodo?.periodo}
              </div>
              <div className="wrapper-data-info">
                Lote: {selectedCargaTemas.lote}
              </div>
              <div className="wrapper-data-info">
                Status: {selectedCargaTemas.finalizado ? "Finalizado" : "Aguardando Confirmação"}
              </div>
              { (selectedCargaTemas && selectedCargaTemas.id && selectedCargaTemas.finalizado) ?
                  <>
                    <div className="wrapper-data-info">
                      Temas criados: {selectedCargaTemas.temas_criados}
                    </div>
                    <div className="wrapper-data-info">
                      Curadorias criadas: {selectedCargaTemas.curadorias_criadas}
                    </div>
                    <div className="wrapper-data-info">
                      Disciplinas criadas: {selectedCargaTemas.disciplinas_criadas}
                    </div>
                    <div className="wrapper-data-info">
                      Áreas do conhecimento criadas: {selectedCargaTemas.areas_conhecimento_criadas}
                    </div>
                  </>
                :
                  <></>
              }
            </div>
          </CargaTemaData>

          <DataTable
            title="Listagem de Temas"
            columns={columns}
            data={filtered}
            // selectableRows
            // highlightOnHover
            // onSelectedRowsChange={handleChange}
            defaultSortField="created_at"
            pagination
            // actions={actions}
            // contextActions={contextActions}
            noDataComponent="Nenhum registro encontrado."
            paginationComponentOptions={{
              rowsPerPageText: 'Itens por página',
              rangeSeparatorText: 'de'
            }}
            paginationPerPage={10}
            // clearSelectedRows={clearSelectedRows}
            contextMessage={{
              singular: 'item selecionado',
              plural: 'itens selecionados',
              message: ''
            }}
            progressComponent={<Loading />}
            progressPending={loading}
            subHeader
            subHeaderComponent={subHeaderComponent}
            paginationResetDefaultPage={resetPaginationToggle}
            responsive
          />

          { (selectedCargaTemas && selectedCargaTemas.id && !selectedCargaTemas.finalizado) &&
            <WrapRow style={{marginTop:"30px"}}>
              {/* <TableButtonRefused type="button" onClick={() => {}} disabled={loading} style={{ marginTop: "10px", marginRight: "5px" }} >
                Recusar
              </TableButtonRefused> */}

              <TableButtonAccepted type="button" onClick={() => confirmImport()} disabled={loading} style={{ marginTop: "10px" }} >
                Confirmar Importação
              </TableButtonAccepted>
            </WrapRow>
          }
        </Card>
      </Content>
    </Container>
  );
};

export default CargaTemasVisualizar;
