import React, { useCallback, useState, useEffect, useMemo, useRef, forwardRef } from 'react';
import { FiDollarSign, FiPlus, FiEdit } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import ReactTooltip from 'react-tooltip';
import { BsFileEarmarkText } from "react-icons/bs";
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
import Loading from '../../components/Loading';
import FormInputMaskSimple from '../../components/FormInputMaskSimple';
// import DateRangePicker from '../../components/DateRangePicker';

import api from '../../services/api';

import convertUTCDateToLocalDate from '../../utils/convertUTCDateToLocalDate';

import {
  Container,
  Content,
  TextField,
  ClearButton
} from './styles';
import { WrapRow, WrapRowItem } from '../_modules/Account/Roles/styles';

interface ContratosInterface {
  id: string;
  hash: string;
  data_contrato: string;
  prazo_entrega: string;
  tema: any;
  status: any;
  observacoes: string;
  produtos: any;
  especialista: any;
  tipo_contratacao: string;
  disciplina_producao: any;
  curso_producao: any;
}

const ContratosComandarPagamento: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const { selectedClient } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal);

  var date = new Date();

  const [loading, setLoading] = useState(true);
  const [contratos, setContratos] = useState<ContratosInterface[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [filterText_, setFilterText_] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filteredItems, setFilteredItems] = useState<ContratosInterface[]>([]);
  const [startDate, setStartDate] = useState<any>(new Date(date.getFullYear(), date.getMonth(), 1));
  const [endDate, setEndDate] = useState<any>(new Date(date.getFullYear(), date.getMonth() + 1, 0));
  const [dataInicioPermissaoCorte, setDataInicioPermissaoCorte] = useState("");
  const [dataFimPermissaoCorte, setDataFimPermissaoCorte] = useState("");

  useEffect(() => {
    if (clearSelectedRows) {
      setClearSelectedRows(false)
    }
  }, [clearSelectedRows]);

  useEffect(() => {
    if(!startDate) return;
    if(!endDate) return;
    if(!selectedClient) return;

    const data_inicio_permissao_corte = `${startDate.getFullYear()}-${("0" + (startDate.getMonth() + 1)).slice(-2)}-${("0" + startDate.getDate()).slice(-2)}`;
    const data_fim_permissao_corte = `${endDate.getFullYear()}-${("0" + (endDate.getMonth() + 1)).slice(-2)}-${("0" + endDate.getDate()).slice(-2)}`;

    setDataInicioPermissaoCorte(data_inicio_permissao_corte);
    setDataFimPermissaoCorte(data_fim_permissao_corte);

    getContratos(data_inicio_permissao_corte, data_fim_permissao_corte);
  }, [startDate, endDate, selectedClient])

  // useEffect(() => {
  //   if (!contratos) return;

  //   let search = filterText.toLowerCase();

  //   let result = contratos.filter(
  //     item => item.hash.toLowerCase() === search ||
  //       item.tema?.titulo.toLowerCase().includes(search) ||
  //       item.especialista?.email.toLowerCase().includes(search) ||
  //       item.especialista?.dados_usuario?.nome.toLowerCase().includes(search)
  //   );

  //   setFilterText_(filterText);
  //   //console.log('xxx', filterText, result)
  // }, [filterText, contratos])

  const filtered = useMemo(() => {
    if (!filterText)
      return contratos

    let search = filterText.toLowerCase();

    search = search.split("-").join("");
    search = search.split(".").join("");

    search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

    return contratos.filter(contrato => {
      return contrato.tema?.titulo.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
      contrato.disciplina_producao?.disciplina?.titulo
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(search) ||
        contrato.curso_producao?.curso?.titulo
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(search) ||
        contrato.hash.toLowerCase().includes(search) ||
        contrato.tipo_contratacao.toLowerCase().includes(search) ||
        contrato.status?.descricao?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        contrato.especialista?.dados_usuario?.nome.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        contrato.especialista?.dados_usuario?.cpf.toLowerCase().includes(search) ||
        contrato.produtos[0]?.servico?.titulo.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        ((contrato.tema && contrato.tema.id) ? "Tema" : contrato.disciplina_producao ? "Disciplina" : "Curso")
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(search)
    })
  }, [filterText, contratos])



  const getContratos = useCallback(async (data_inicio_permissao_corte, data_fim_permissao_corte) => {
    setLoading(true);

    try {
      const response = await api.get(`/contrato`, {
        params: {
          status: ["pagamento_permitido"],
          data_inicio_permissao_corte, 
          data_fim_permissao_corte,
          id_cliente: selectedClient.id
        }
      });
      //console.log("response", response.data);

      setContratos(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, [selectedClient]);

  const comandarPagamentoSelectedRows = useCallback(async (data: any) => {
    if (!(data.data_pagamento_previsao)) {
      addToast({
        type: 'error',
        title: 'Preencha a data do pagamento comandado',
        description: ''
      });
      return;
    }

    MySwal.fire({
      // title: 'Tem certeza que deseja remover?',
      showCancelButton: true,
      confirmButtonText: `Comandar`,
      confirmButtonColor: '#1CAEBD',
      cancelButtonColor: '#312e38',
      cancelButtonText: 'Cancelar',
      // icon: 'warning',
      html: '<div style="font-size:20px">Tem certeza que deseja comandar o pagamento de todos os contratos selecionados?</div>',
      // position: 'top'
    }).then((result) => {
      if (result.isConfirmed) {
        comandarPagamentos(selectedRows, data.data_pagamento_previsao);
      }
    })
  }, [selectedRows]);

  const comandarPagamentos = useCallback(async (rows, data_pagamento_previsao) => {
    //console.log("comandarPagamentos", rows);
    setLoading(true);

    try {

      let dt_data_pagamento_previsao = data_pagamento_previsao.split("/");

      const today = new Date();

      if(parseInt(dt_data_pagamento_previsao[1]) > 12 || parseInt(dt_data_pagamento_previsao[0]) > 31){
        addToast({
          type: 'error',
          title: 'Data de previsão de pagamento inválida',
          description: ''
        });
        setLoading(false);
        return;
      }

      if((dt_data_pagamento_previsao[2]).replace('_','').length !== 4){
        addToast({
          type: 'error',
          title: 'Data de previsão de pagamento inválida',
          description: ''
        });
        setLoading(false);
        return;
      }

      if(parseInt(dt_data_pagamento_previsao[2]) < today.getFullYear()){
        addToast({
          type: 'error',
          title: 'Data de previsão de pagamento inválida',
          description: ''
        });
        setLoading(false);
        return;
      }

      if(new Date(`${dt_data_pagamento_previsao[2]}-${dt_data_pagamento_previsao[1]}-${dt_data_pagamento_previsao[0]}`) < today){
        addToast({
          type: 'error',
          title: 'Data de previsão de pagamento inválida',
          description: ''
        });
        setLoading(false);
        return;
      }

      const params = {
        hashs: selectedRows.map((row: any) => row.hash),
        data_pagamento_previsao: `${dt_data_pagamento_previsao[2]}-${dt_data_pagamento_previsao[1]}-${dt_data_pagamento_previsao[0]}`
      }

      // console.log("params", params);

      const response = await api.post(`/contrato-mudanca-status`, params);

      // console.log("response", response);

      setClearSelectedRows(true);

      addToast({
        type: 'success',
        title: 'Pagamentos comandados com sucesso',
        description: '',
      });

      getContratos(dataInicioPermissaoCorte, dataFimPermissaoCorte);
    } catch (err) {
      for (let error in err.response?.data) {
        addToast({
          type: 'error',
          title: 'Erro ao comandar pagamentos',
          description: err.response?.data[error] ?
            err.response.data[error] :
            'Erro ao comandar pagamentos, tente novamente mais tarde.',
        });
      }
    }
  }, [selectedRows, selectedClient]);

  let parseText = function (text: string, limit: number) {
    if (text.length > limit)
      for (let i = limit; i > 0; i--) {
        if (text.charAt(i) === ' ' && (text.charAt(i - 1) != ',' || text.charAt(i - 1) != '.' || text.charAt(i - 1) != ';')) {
          return text.substring(0, i) + '...';
        }
      }
    else
      return text;
  };

  function goUpdateForm() {
    history.push("/contratos/edicao", {
      selectedItem: selectedRows[0]
    });
  }

  const handleChange = (state: any) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    //console.log('Selected Rows: ', state.selectedRows);

    setSelectedRows(state.selectedRows);
  };

  // const actions = (
  //   <FiPlus className="icon" onClick={() => {
  //     goStoreForm();
  //   }} />
  // );

  const contextActions = (
    <Form ref={formRef} onSubmit={comandarPagamentoSelectedRows}>
      <WrapRow>
        <WrapRowItem>
          <FormInputMaskSimple
            name="data_pagamento_previsao"
            type="text"
            placeholder="Previsão de Pagamento"
            mask="99/99/9999"
          />
        </WrapRowItem>
        <WrapRowItem>
          <button type="submit" className="button-top-datatable" >
            {/* <FiDollarSign className="icon" style={{ marginRight: "10px" }} /> */}
            Comandar Pagamentos
          </button>
        </WrapRowItem>
      </WrapRow>
    </Form>
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const CustomInputDatePicker = forwardRef(({ value, onClick }:any, ref:any) => (
      <button className="custom-input-datepicker" onClick={onClick} ref={ref}>
        {value}
      </button>
    ));

    return (
      <WrapRow>
        <span className="label-datapicker-input">Início:</span>
        <DatePicker
          closeOnScroll={true}
          selected={startDate}
          // onChange={(date) => setStartDate(date)}
          onChange={(date) => setStartDate(date)}
          dateFormat="dd/MM/yyyy"
          customInput={<CustomInputDatePicker />}
        />
        <span className="label-datapicker-input">Término:</span>
        <DatePicker
          closeOnScroll={true}
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          dateFormat="dd/MM/yyyy"
          customInput={<CustomInputDatePicker />}
        />
        <div style={{flex:1}}></div>
        <TextField id="search" type="text" placeholder="Pesquisar" aria-label="Search Input" value={filterText} onChange={(e: any) => setFilterText(e.target.value)} />
        <ClearButton type="button" onClick={handleClear}>x</ClearButton>
      </WrapRow>
    );
  }, [filterText, resetPaginationToggle, startDate, endDate]);

  const columns = [
    {
      name: 'ID',
      selector: 'hash',
      sortable: false,
      maxWidth: '140px',
      // right: true,
    },
    // {
    //   name: 'Tema',
    //   selector: 'tema.titulo',
    //   sortable: false,
    //   maxWidth: '400px',
    //   // right: true,
    //   cell: (row: any) => {

    //     let dots_string = parseText(row.tema.titulo, 50);
    //     let reduce = dots_string !== row.tema.titulo
    //     return (
    //       <>
    //         {reduce ?
    //           <>
    //             <div data-tip data-for={`${row.tema.id}`}>
    //               {dots_string}
    //             </div>
    //             <ReactTooltip id={`${row.tema.id}`} effect='solid'>
    //               <span>{row.tema.titulo}</span>
    //             </ReactTooltip>
    //           </>
    //           :
    //           <div>{dots_string}</div>
    //         }

    //       </>
    //     )
    //   }
    // },
    {
      name: 'Produção',
      selector: 'producao',
      sortable: false,
      maxWidth: '400px',
      // right: true,
      cell: (row: any) => {
        let producao = (row.tema && row.tema.titulo) ? row.tema.titulo : row.disciplina_producao ? row.disciplina_producao?.disciplina?.titulo : row.curso_producao?.curso?.titulo;
        let producao_id = (row.tema && row.tema.id) ? row.tema.id : row.disciplina_producao ? row.disciplina_producao?.disciplina?.id : row.curso_producao?.curso?.id;

        if(!producao) return "";

        let dots_string = String(producao).substring(0, 50);
        let reduce = dots_string !== producao;
        return (
          <>
            {reduce ? (
              <>
                <div data-tip data-for={`${producao_id}`}>
                  {dots_string}
                </div>
                <ReactTooltip id={`${producao_id}`} effect="solid">
                  <span>{producao}</span>
                </ReactTooltip>
              </>
            ) : (
              <div>{dots_string}</div>
            )}
          </>
        );
      },
    },
    {
      name: 'Tipo de Produção',
      selector: 'tipo',
      sortable: false,
      width: '120px',
      // right: true,
      hide: 1800,
      cell: (row: any) => {
        let tipo = (row.tema && row.tema.titulo) ? "Tema" : row.disciplina_producao ? "Disciplina" : "Curso";
        return <span>{tipo}</span>;
      },
    },
    {
      name: 'Tipo de Contrato',
      selector: 'tipo_contrato',
      sortable: false,
      width: '120px',
      // right: true,
      hide: 1620,
      cell: (row: any) => {
        let tipo = (row.tipo_contrato === "producao_atual") ? "Produção" : (row.tipo_contrato === "manutencao_reativa") ? "Manutenção Reativa" : (row.tipo_contrato === "manutencao_proativa") ? "Manutenção Proativa" : (row.tipo_contrato === "manutencao_proativa_direcionada") ? "Manutenção Proativa Direcionada" : (row.tipo_contrato === "terceirizado") ? "Terceirizado" : (row.tipo_contrato === "prestacao_servico") ? "Prestação de Serviço" : (row.tipo_contrato.replace("_", " ")[0].toUpperCase() + row.tipo_contrato.replace("_", " ").substring(1));
        return <span>{tipo}</span>;
      },
    },
    {
      name: 'Especialista',
      selector: 'especialista.dados_usuario.nome',
      sortable: false,
      maxWidth: '230px',
      // right: true,
      hide: 1210,
      cell: (row: any) => {

        let nome = row.especialista.dados_usuario ? row.especialista.dados_usuario.nome : ""
        return (<span>{nome}</span>)
      }
    },
    {
      name: 'CPF',
      selector: 'especialista.dados_usuario.cpf',
      sortable: false,
      width: '120px',
      // right: true,
      hide: 1430,
      cell: (row: any) => {

        let cpf = row.especialista.dados_usuario ? row.especialista.dados_usuario.cpf : ""
        return (<span>{cpf}</span>)
      }
    },
    {
      name: 'Contratação',
      selector: 'tipo_contratacao',
      sortable: false,
      width: '120px',
      // right: true,
      hide: 685,
      cell: (row: any) => (
        <p>{row.tipo_contratacao.replace('_', ' ')}</p>
      )
    },
    // {
    //   name: 'Data Contrato',
    //   selector: 'data_contrato',
    //   sortable: false,
    //   width: '130px',
    //   // right: true,
    //   hide: 1220,
    //   cell: (row: any) => {
    //     let dt_data_contrato = convertUTCDateToLocalDate(new Date(row.data_contrato));

    //     return (
    //       <span>
    //         {`${("0" + dt_data_contrato.getDate()).slice(-2)}/${("0" + (dt_data_contrato.getMonth() + 1)).slice(-2)}/${dt_data_contrato.getFullYear()}`}
    //       </span>
    //     )
    //   }
    // },
    // {
    //   name: 'Prazo Entrega',
    //   selector: 'prazo_entrega',
    //   sortable: false,
    //   // right: true,
    //   width: '130px',
    //   hide: 1470,
    //   cell: (row: any) => {
    //     let dt_prazo_entrega = convertUTCDateToLocalDate(new Date(row.prazo_entrega));
    //     let prazo_vencido = dt_prazo_entrega < new Date();

    //     return (
    //       <span style={{ color: prazo_vencido ? '#ff0000' : '' }}>
    //         {`${("0" + dt_prazo_entrega.getDate()).slice(-2)}/${("0" + (dt_prazo_entrega.getMonth() + 1)).slice(-2)}/${dt_prazo_entrega.getFullYear()}`}
    //       </span>
    //     )
    //   }
    // },
    {
      name: 'Produto',
      selector: 'produto',
      sortable: false,
      // right: true,
      hide: 570,
      cell: (row: any) => {
        return (
          row.produtos.map((item:any) => {
            return (<span key={`${item.servico?.titulo}`}>{item.servico?.titulo} </span>)
          })
        )
      }
    },
    {
      name: 'Valor Total',
      selector: 'valor_total',
      sortable: false,
      // right: true,
      hide: 1310,
      cell: (row: any) => {
        return (<span>R$ {parseFloat(row.valor_total).toFixed(2)}</span>)
      }
    },
    {
      name: 'Situação Atual',
      selector: 'status.descricao',
      sortable: false,
      // right: true,
      hide: 1490,
      cell: (row: any) => (
        <div style={{
          color: "#fff",
          backgroundColor:
            row.status.slug == 'criado' ? "#F09B0E" :
              row.status.slug == 'aceite' ? "#95B759" :
                row.status.slug == 'planejado' ? "#AFB7C2" :
                  row.status.slug == 'cancelado' ? "#E94F51" :
                    row.status.slug == 'recusado' ? "#333" :
                      row.status.slug == 'pagamento_permitido' ? "#800980" :
                        row.status.slug == 'pagamento_comandado' ? "#0783C2" :
                          row.status.slug == 'aguardando_aprovacao' ? "#F09B61" :
                            row.status.slug == 'erro_de_permissao' ? "#444" :
                              "#666",

          padding: "5px",
          borderRadius: "3px",
          width: '150px',
          textAlign: 'center'
        }}>
          {row.status.slug == 'criado' && <span>contrato criado</span>}
          {row.status.slug == 'aceite' && <span>contrato aceito</span>}
          {row.status.slug == 'planejado' && <span>contrato planejado</span>}
          {row.status.slug == 'cancelado' && <span>contrato cancelado</span>}
          {row.status.slug == 'recusado' && <span>contrato recusado</span>}
          {row.status.slug == 'pagamento_permitido' && <span>{row.status.descricao}</span>}
          {row.status.slug == 'pagamento_comandado' && <span>{row.status.descricao}</span>}
          {row.status.slug == 'aguardando_aprovacao' && <span>{row.status.descricao}</span>}
          {row.status.slug == 'erro_de_permissao' && <span>{row.status.descricao}</span>}

        </div>
      )
    },
    {
      name: 'Ações',
      button: true,
      cell: (row: any) => (
        <div className="wrapper-table-actions">
          {/* <div className={`table-action`}
            onClick={() => history.push('/contratos/visualizar-contrato', { selectedItem: row })}>
            <BsFileEarmarkText data-tip data-for='tipVisualizarContrato' />
          </div> */}

          <Link className={`table-action`} target={"_blank"} to={{
            pathname: "/contratos/visualizar-contrato",
            search: `?id_contrato=${row.id}`,
            // hash: "#the-hash",
            state: { id_contrato: row.id }
          }}>
            <BsFileEarmarkText data-tip data-for='tipVisualizarContrato' />
          </Link>

          <ReactTooltip id='tipVisualizarContrato' effect='solid'>
            <span>Visualizar Contrato</span>
          </ReactTooltip>
        </div>
      ),
    },
  ];

  return (
    <Container>
      <Content>
        <Card>
          <DataTable
            title="Contratos - Comandar Pagamentos"
            columns={columns}
            data={filtered}
            selectableRows
            highlightOnHover
            onSelectedRowsChange={handleChange}
            // defaultSortField="especialista.email"
            pagination
            // actions={actions}
            contextActions={contextActions}
            noDataComponent="Nenhum registro encontrado."
            paginationComponentOptions={{
              rowsPerPageText: 'Itens por página',
              rangeSeparatorText: 'de'
            }}
            clearSelectedRows={clearSelectedRows}
            contextMessage={{
              singular: 'item selecionado',
              plural: 'itens selecionados',
              message: ''
            }}
            progressComponent={<Loading />}
            progressPending={loading}
            subHeader
            subHeaderComponent={subHeaderComponent}
            paginationResetDefaultPage={resetPaginationToggle}
          />
        </Card>
      </Content>
    </Container>
  );
};

export default ContratosComandarPagamento;
