import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  useHistory,
  useLocation,
} from 'react-router-dom';

import Loading from '../components/Loading';
import AuthLayout from '../pages/_layouts/auth';
import DefaultLayout from '../pages/_layouts/default';

import { useAuth } from '../hooks/auth';
import { useToast } from '../hooks/toast';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
  isBlocked?: boolean;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  isBlocked = false,
  ...rest
}) => {
  const { user, userData } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();

  if (
    isBlocked === true &&
    location.pathname !== '/main' &&
    location.pathname !== '/main/' &&
    location.pathname !== '/account/profile' &&
    location.pathname !== '/account/profile/'
  ) {
    history.push('/blocked');
  }

  if (!(isPrivate === !!user)) {
    history.push(isPrivate ? '/signin' : '/main');
  }

  if (userData.loading !== true) {
    if (user && user.id && !(userData && userData.id)) {
      if (
        location.pathname !== '/account/profile' &&
        location.pathname !== '/account/profile/'
      ) {
        addToast({
          type: 'error',
          title: 'Cadastre suas informações',
          description:
            'Antes de começar a navegar na plataforma, é preciso cadastrar seus dados',
        });

        history.push('/account/profile');
      }
    }
  } else {
    if (user && user.id) {
      return <Loading />;
    }
  }

  const Layout = !!user ? DefaultLayout : AuthLayout;

  return (
    <ReactDOMRoute
      {...rest}
      render={props => {
        return (
          <Layout {...props}>
            <Component />
          </Layout>
        );
      }}
    />
  );
};

export default Route;
