import React, { useCallback, useState, useEffect } from 'react';
import { FiTrash, FiPlus, FiEdit, FiCheckCircle, FiCircle, FiLink, FiDownload, FiCheckSquare, FiSquare } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ReactPlayer from 'react-player';
import DOMPurify from 'dompurify';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem
} from './styles';

const TreinamentoCurador: React.FC = () => {
  
  return (
    <Container>
      <Content style={{padding:"10px"}}> 

      <Card>
                  <div style={{display:"flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap"}}>
                    <div>
                      <div className="item-titulo">
                        Treinamento Curador
                      </div>
                      
                      {/* <div className="item-subtitulo" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.subtitle)}}></div> */}

                      {/* <div className="item-descricao">
                        {item.descricao}
                      </div> */}
                    </div>
                    <div>
                    </div>
                  </div>
                  
                    <div className='player-wrapper'>
                      <iframe 
                        src="https://play.yduqs.videolib.live/index.html?token=de83e58006624bdf96b23bd64d0099c7&versao=26001"
                        title="Video" 
                        className='react-player'
                      >
                      </iframe>
                    </div>
                </Card>

      </Content>
    </Container>
  );
};

export default TreinamentoCurador;
