import React, { useCallback, useState } from 'react';
import { FiUser } from 'react-icons/fi';

import Header from '../../../components/Header';
import Sidebar from '../../../components/Sidebar';
import api from '../../../services/api';

import { Container, WrapperContent, Content, PageTitle, WarningDevelopementMode } from './styles';

const DefaultLayout: React.FC = ({ children }) => {
  const [showMenu, setShowMenu] = useState(true);
  const [showResponsiveMenu, setShowResponsiveMenu] = useState(false);
  const [currentPage, setCurrentPage] = useState("/dados-usuario");
  const [pageTitle, setPageTitle] = useState("Dados Cadastrais");
  const [pageIcon, setPageIcon] = useState(<FiUser />);

  return (
    <Container>
      <Header pageTitle={pageTitle}
        pageIcon={pageIcon}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        showResponsiveMenu={showResponsiveMenu}
        setShowResponsiveMenu={setShowResponsiveMenu}
      />
      <WrapperContent>
        <Sidebar currentPage={currentPage}
          setCurrentPage={(newPage: any) => setCurrentPage(newPage)}
          setPageTitle={(newTitle: any) => setPageTitle(newTitle)}
          setPageIcon={(newIcon: any) => setPageIcon(newIcon)}
          showMenu={showMenu}
          showResponsiveMenu={showResponsiveMenu}
          setShowResponsiveMenu={setShowResponsiveMenu}
        />
        <Content>
          <PageTitle>
            {pageIcon}
            <h1>{pageTitle.toUpperCase()}</h1>
          </PageTitle>

          {children}

          {(api.defaults.baseURL !== 'https://apicontrato.ensineme.com.br') ? (
            <WarningDevelopementMode>
              Ambiente de Teste
            </WarningDevelopementMode>
          ) : (
            <></>
          )}
        </Content>
      </WrapperContent>
    </Container>
  );
};

export default DefaultLayout;
