import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  .icon {
    font-size: 22px;
    color: #717171;
    cursor: pointer;
  }

  .blacklist-contract-container {
    margin: 10px 15px 10px 15px;
  }

  .blacklist-contract {
    padding: 10px 0;
  }

  .blacklist-contract a {
    color: #312e38;
    text-decoration: none;
    padding: 10px 0px 10px 25px;
    font-size: 12px;
    background-color: #f1f1f1;
    border-radius: 4px;
  }

  .blacklist-contract a:hover {
    color: blue;
  }

  .blacklist-contract span {
    padding: 10px 0;
    font-size: 12px;
    margin-right: 25px;
  }

  .blacklist-contract-title {
    color: ${({ theme }) => theme.warningColor};
    padding: 15px 0 10px;
  }

  .remove-prod-button {
    margin-right: 5px;
    padding: 7px 10px;
    cursor: pointer;
    background: ${({ theme }) => theme.inputBackgroundColor};
    color: ${({ theme }) => theme.warningColor};
    border: 0;
    font-size: 12px;
  }

  .remove-prod-button svg {
    margin-top: 2px;
  }

  .remove-prod-button:hover {
    background: ${({ theme }) => shade(0.2, theme.inputBackgroundColor)};
  }

  .warning-verba {
    color: ${({ theme }) => theme.warningColor};
    border: 1px solid ${({ theme }) => theme.warningColor};
    border-radius: 4px;
    background: #ffd6d6;
    margin: 10px 5px 20px 5px;
    padding: 8px;
  }
`;

export const Content = styled.main`
  /* max-width: 1120px;
  margin: 10px auto; */
  display: flex;
  margin-bottom: 80px;
  flex-direction: column;

  form {
    width: 100%;

    h2 {
      margin-bottom: 30px;
      padding: 5px 0;
    }
  }
`;

export const WrapRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const WrapRowItem = styled.div`
  flex: 1;
  margin: 0 5px;
`;

export const Item = styled.div`
  flex: 1;
  margin: 20px 5px;
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .title {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .subtitle {
    font-weight: lighter;
    margin-bottom: 10px;
  }

  .removeIcon {
    cursor: pointer;
  }
`;

export const WrapperProducts = styled.div`
  margin: 30px 5px 5px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 20px;
    color: #312e38;
    font-size: 16px;
  }

  .none-products {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 20px;
    color: gray;
    font-size: 14px;
  }

  /* background-color: #F2F2F2; */
`;

export const WrapperProdButton = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  border-color: #ccc;
  background-color: #f1f1f1;
  padding: 10px;

  .add-prod-button {
    /* margin-top: 10px; */
    /* flex: 1; */
    padding: 10px 30px;
    cursor: pointer;
    background: ${({ theme }) => theme.primaryColor};
    color: ${({ theme }) => theme.buttonTextColor};
    border: 0;
  }

  .add-prod-button svg {
    margin-top: 2px;
  }

  .add-prod-button:hover {
    background: ${({ theme }) => shade(0.2, theme.primaryColor)};
  }
`;

export const WrapperProduct = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const ProductItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px 20px 0;
  border: 0.5px solid #f2f2f2;

  .price {
    display: block;
    margin-left: 15px;
  }

  .obs-request-price {
    color: ${({ theme }) => theme.warningColor};
    padding: 15px 0 25px;
  }

  .obs-already-exist-contract-container {
    margin-bottom: 25px;
  }

  .obs-already-exist-contract {
    padding: 10px 0;
  }

  .obs-already-exist-contract a {
    color: #312e38;
    text-decoration: none;
    padding: 10px 0px 10px 25px;
    font-size: 12px;
    background-color: #f1f1f1;
    border-radius: 4px;
  }

  .obs-already-exist-contract a:hover {
    color: blue;
  }

  .obs-already-exist-contract span {
    padding: 10px 0;
    font-size: 12px;
    margin-right: 25px;
  }

  .obs-already-exist-contract-title {
    color: ${({ theme }) => theme.warningColor};
    padding: 15px 0 10px;
  }
`;

export const TagProductPrice = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .tag {
    color: #312e38;
    border-width: 1px 1px 1px 1px;
    border-color: #ccc;
    background-color: #e6e6e6;
    padding: 4px;
    border-radius: 3px;
    margin-left: 5px;
    font-size: 13px;
  }

  .change_price {
    color: #312e38;
    border-width: 1px 1px 1px 1px;
    border-color: #ccc;
    background-color: #e6e6e6;
    padding: 4px;
    border-radius: 3px;
    margin-left: 5px;
    font-size: 13px;
    cursor: pointer;

    &:hover {
      color: red;
    }
  }

  @media screen and (max-width: 430px) {
    .change_price {
      margin-top: 4px;
    }
  }
`;

export const ContratoData = styled.div`
  .wrapper-data {
    margin: 0;
    padding: 15px;
    border-bottom: 1px dashed #e6e6e6;
    color: #333;
  }

  .wrapper-data-label {
    margin: 7px 0 15px;
    font-size: 16px;
    font-weight: 600;
  }

  .wrapper-data-info {
    margin: 10px 0;
    font-size: 16px;
  }

  .wrapper-money {
    display: flex;
    flex-direction: row-reverse;
  }

  .wrapper-produto {
    padding: 10px 15px;
    border: 0.5px solid #e6e6e6;
    margin-bottom: 10px;
    background-color: #f9f9f9;
  }
`;

export const TemaData = styled.div`
  .wrapper-data {
    margin: 0;
    padding: 15px;
    border-bottom: 1px dashed #e6e6e6;
    color: #333;
  }

  .wrapper-data-label {
    margin: 7px 0 15px;
    font-size: 16px;
    font-weight: 600;
  }

  .wrapper-data-info {
    margin: 10px 0;
    font-size: 16px;
    line-height: 30px;
  }

  .wrapper-tema {
    padding: 10px 15px;
    border: 0.5px solid #e6e6e6;
    margin-bottom: 10px;
    background-color: #f9f9f9;
  }
`;
