import styled from 'styled-components';
// import { shade } from 'polished';

export const Container = styled.div``;

export const Content = styled.main`
  /* max-width: 1120px;
  margin: 10px auto; */
  display: flex;
  margin-bottom: 80px;

  form {
    width: 100%;

    h2 {
      margin-bottom: 30px;
      padding: 5px 0;
    }
  }
`;

export const WrapRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const WrapRowItem = styled.div`
  flex: 1;
  margin: 0 5px;
`;
