import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  .icon {
    font-size: 22px;
    color: #717171;
    cursor: pointer;
  }

  .title-sweetalert {
    font-size: 8px;
    color: red;
  }

  .wrapper-table-actions {
    display: flex;
    flex-direction: center;
    text-align: right;
    /* flex: 1; */
  }

  .table-action {
    color: #666;
    font-size: 24px;
    padding: 0 5px;
    cursor: pointer;
  }

  .table-action-disabled {
    color: #e6e6e6;
    cursor: auto;
  }

  .link-editar {
    color: #1a939e;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
    padding: 0 0px;

    &:hover {
      color: ${shade(0.2, '#3e3b47')};
    }
  }

  .blacklist-contract-container {
    margin: 15px 15px 10px 15px;
  }

  .blacklist-contract {
    padding: 10px 0px 10px 20px;
    margin-bottom: 10px;
    color: #312e38;
    text-decoration: none;
    font-size: 12px;
    background-color: #f1f1f1;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }

  .blacklist-contract a {
    padding: 5px 0;
    color: #312e38;
    text-decoration: none;
    font-size: 12px;
    background-color: #f1f1f1;
    border-radius: 4px;
  }

  .blacklist-contract a:hover {
    color: ${({ theme }) => theme.warningColor};
  }

  .blacklist-contract span {
    padding: 5px 0;
    font-size: 12px;
    margin-right: 15px;
  }

  .blacklist-contract-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .blacklist-contract-title a {
    cursor: pointer;
  }
`;

export const Content = styled.main`
  /* max-width: 1120px;
  margin: 10px auto; */
  display: flex;
  margin-bottom: 80px;
  flex-direction: column;

  form {
    width: 100%;

    h2 {
      margin-bottom: 30px;
      padding: 5px 0;
    }
  }
`;

export const WrapRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const WrapRowItem = styled.div`
  flex: 1;
  margin: 0 5px;
`;

export const Item = styled.div`
  flex: 1;
  margin: 20px 5px;
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .title {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .subtitle {
    font-weight: lighter;
  }

  .removeIcon {
    cursor: pointer;
  }
`;

export const TableButton = styled.button`
  background: ${({ theme }) => theme.buttonBackgroundColor};
  color: ${({ theme }) => theme.buttonTextColor};
  border-radius: 5px;
  border: 0;
  padding: 5px 15px;
  font-weight: 500;
  transition: background-color 0.2s;

  &:hover {
    background: ${({ theme }) => shade(0.2, theme.buttonBackgroundColor)};
  }
`;

export const TableButtonAccepted = styled.button`
  background: ${({ theme }) => theme.successColor};
  color: ${({ theme }) => theme.buttonTextColor};
  border-radius: 5px;
  border: 0;
  padding: 5px 15px;
  font-weight: 500;
  transition: background-color 0.2s;
  flex: 1;
  line-height: 35px;
  margin: 10px 0;

  &:hover {
    background: ${({ theme }) => shade(0.2, theme.successColor)};
  }
`;

export const TableButtonRefused = styled.button`
  background: ${({ theme }) => theme.warningColor};
  color: ${({ theme }) => theme.buttonTextColor};
  border-radius: 5px;
  border: 0;
  padding: 5px 15px;
  font-weight: 500;
  transition: background-color 0.2s;

  &:hover {
    background: ${({ theme }) => shade(0.2, theme.warningColor)};
  }
`;

export const ContratoData = styled.div`
  .wrapper-data {
    margin: 0;
    padding: 15px;
    border-bottom: 0.5px solid #b3b3b3;
  }

  .wrapper-data-label {
    margin: 7px 0 15px;
    font-size: 16px;
    font-weight: 600;
  }

  .wrapper-data-info {
    margin: 10px 0;
    font-size: 16px;
  }

  .wrapper-produto {
    padding: 10px 15px;
    border: 0.5px solid #b3b3b3;
    margin-bottom: 10px;
  }
`;

export const TextField = styled.input`
  height: 40px;
  width: 250px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;

export const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  height: 40px;
  width: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  background-color: #f8f8f8;

  &:hover {
    background-color: #e6e6e6;
    color: #333;
  }
`;

export const CargaTemaData = styled.div`
  .wrapper-data {
    margin: 0;
    padding: 15px;
    border-top: 1px dashed #e6e6e6;
    border-bottom: 1px dashed #e6e6e6;
    color: #333;
    margin-bottom: 25px;
  }

  .wrapper-data-label {
    margin: 7px 0 15px;
    font-size: 16px;
    font-weight: 600;
  }

  .wrapper-data-info {
    margin: 10px 0;
    font-size: 16px;
  }

  .wrapper-money {
    display: flex;
    flex-direction: row-reverse;
  }

  .wrapper-produto {
    padding: 10px 15px;
    border: 0.5px solid #e6e6e6;
    margin-bottom: 10px;
    background-color: #f9f9f9;
  }
`;
