import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import FormInput from '../../components/FormInput';
import FormInputMask from '../../components/FormInputMask';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem,
} from './styles';

interface ProducaoFormData {
  id: string;
  descricao: string;
  link: string;
  data_entrega: string;
  produto: any;
  tema: any;
  tags: any;
}

interface TemaFormData {
  id: string;
  titulo: string;
  modelo: any;
  tags: any;
}

interface ProdutoFormData {
  id: string;
  titulo: string;
}

interface TagFormData {
  id: string;
  tag: string;
}

interface OptionsData {
  value: string;
  label: string;
}

const ProducoesEdicao: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location:any = useLocation();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [temas, setTemas] = useState<TemaFormData[]>([]);
  const [produtos, setProdutos] = useState<ProdutoFormData[]>([]);
  const [tags, setTags] = useState<TagFormData[]>([]);
  const [optionsTemas, setOptionsTemas] = useState<OptionsData[]>([]);
  const [optionsProdutos, setOptionsProdutos] = useState<OptionsData[]>([]);
  const [optionsTags, setOptionsTags] = useState<OptionsData[]>([]);
  const [selectedTema, setSelectedTema] = useState<OptionsData>({ value: "", label: "" });
  const [selectedProduto, setSelectedProduto] = useState<OptionsData>({ value: "", label: "" });
  const [selectedTags, setSelectedTags] = useState<OptionsData[]>([]);
  const [selectedItem, setSelectedItem] = useState<ProducaoFormData>({} as ProducaoFormData);

  useEffect(() => {
    if(location && location.state){
      setSelectedItem(location.state.selectedItem);
    }
  }, [location]);

  useEffect(() => {
    if(selectedItem){
      //console.log("selectedItem", selectedItem);
      formRef.current?.setFieldValue("descricao", selectedItem.descricao);
      formRef.current?.setFieldValue("link", selectedItem.link);

      let dt_data_entrega = new Date(selectedItem.data_entrega);
      formRef.current?.setFieldValue("data_entrega", `${("0" + dt_data_entrega.getDate()).slice(-2)}/${("0" + (dt_data_entrega.getMonth() + 1)).slice(-2)}/${dt_data_entrega.getFullYear()}`);

      if(selectedItem.tema){
        const current = temas.find((item) => item.id === selectedItem.tema.id)
        if(current){
          setSelectedTema({ value: current.id, label: current.titulo });
        }
      }

      if(selectedItem.produto){
        const current = produtos.find((item) => item.id === selectedItem.produto.id)
        if(current){
          setSelectedProduto({ value: current.id, label: current.titulo });
        }
      }

      if(selectedItem.tags){
        let temp:OptionsData[] = [];
        tags.forEach((tag:TagFormData) => {
          selectedItem.tags.forEach((tag_:TagFormData) => {
            if(tag.id === tag_.id){
              temp.push({
                value: tag.id, 
                label: tag.tag
              })
            }
          });
        });

        if(temp){
          setSelectedTags(temp);
        }
      }
    }
  }, [selectedItem, temas, produtos, tags]);

  useEffect(() => {
    getTemas();
    getProdutos();
    getTags();
  }, []);

  useEffect(() => {
    if (temas.length === 0) return;

    var options: OptionsData[] = [];

    temas.map(item => {
      options.push({ value: item.id, label: item.titulo });
    });

    setOptionsTemas(options);
    //console.log("options temas", options);
  }, [temas]);

  useEffect(() => {
    if (produtos.length === 0) return;

    var options: OptionsData[] = [];

    produtos.map(item => {
      options.push({ value: item.id, label: item.titulo });
    });

    setOptionsProdutos(options);
    //console.log("options produtos", options);
  }, [produtos]);

  useEffect(() => {
    if (tags.length === 0) return;

    var options: OptionsData[] = [];

    tags.map(item => {
      options.push({ value: item.id, label: item.tag });
    });

    setOptionsTags(options);
    //console.log("options tag", options);
  }, [tags]);
  
  const getTemas = useCallback(async () => {
    const response = await api.get(`/tema`);
    //console.log("temas", response.data);

    setTemas(response.data);
  }, []);

  const getProdutos = useCallback(async () => {
    const response = await api.get(`/produto`);
    //console.log("produtos", response.data);

    setProdutos(response.data);
  }, []);

  const getTags = useCallback(async () => {
    const response = await api.get(`/tag`);
    //console.log("tags", response.data);

    setTags(response.data);
  }, []);

  const handleSubmit = useCallback(
    async (data: ProducaoFormData) => {
      try {
        //console.log("handleSubmit", data);

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descricao: Yup.string().required('Descrição obrigatório'),
          link: Yup.string().required('Link obrigatório'),
          data_entrega: Yup.string().required('Data de entrega obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let dt_entrega = data.data_entrega.split("/");

        let params = {
          descricao: data.descricao,
          link: data.link,
          data_entrega: `${dt_entrega[2]}-${dt_entrega[1]}-${dt_entrega[0]}`,
          id_tema: selectedTema.value,
          id_produto: selectedProduto.value,
          tags: selectedTags ? selectedTags.map((tag) => tag.value) : []
        }

        //console.log("params", params);

        await api.put(`/producao/${selectedItem.id}`, params);

        addToast({
          type: 'success',
          title: 'Produção atualizada com sucesso',
          description: '',
        });

        goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        //console.log(err.response?.data);
        
        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar produção',
            description: err.response?.data[error] ?
              err.response.data[error] :
              'Erro ao atualizar produção, tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, selectedTags, selectedTema, selectedProduto],
  );

  function goBack() {
    history.push('/producoes');
  }

  function handleChangeSelectedTema(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedTema({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedTema({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue tema", newValue.value);

    setSelectedTema(newValue);
  };

  function handleChangeSelectedProduto(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedProduto({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedProduto({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue Produto", newValue.value);

    setSelectedProduto(newValue);
  };

  function handleChangeSelectedTags(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedTags([]);
      return;
    };

    if (newValue.length === 0) {
      setSelectedTags([]);
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue tags", newValue.value);

    setSelectedTags(newValue);
  };

  if(loading){
    return (
      <Loading />
    )
  }

  return (
    <Container>
      <Content>
        <Card>
          <div className="form">
            <div className="header">
              <h2>Editar Produção</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            <Form ref={formRef} onSubmit={handleSubmit}>
                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="descricao"
                      type="text"
                      placeholder="Descrição"
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="link"
                      type="text"
                      placeholder="Link"
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem style={{ marginTop: "10px" }}>
                    <label>Tema:</label>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedTema}
                      options={optionsTemas}
                      value={selectedTema}
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem style={{ marginTop: "30px" }}>
                    <label>Produto:</label>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedProduto}
                      options={optionsProdutos}
                      value={selectedProduto}
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow style={{ marginTop: "30px" }}>
                  <WrapRowItem>
                    <label>Tags:</label>
                    <Select
                      isMulti
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedTags}
                      options={optionsTags}
                      value={selectedTags}
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow style={{ marginTop: "30px" }}>
                  <WrapRowItem>
                    <FormInputMask
                      name="data_entrega"
                      type="text"
                      placeholder="Data de Entrega"
                      mask="99/99/9999"
                    />
                  </WrapRowItem>
                </WrapRow>

                <Button type="submit" loading={loading}>
                  Salvar
            </Button>

              </Form>
          </div>
        </Card>
      </Content>
    </Container>
  );
};

export default ProducoesEdicao;
