import React, { useCallback, useState, useEffect, useMemo, useRef, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import ReactTooltip from 'react-tooltip';
import { FormHandles } from '@unform/core';
// import { Form } from '@unform/web';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
import Loading from '../../components/Loading';
// import FormInputMaskSimple from '../../components/FormInputMaskSimple';
// import DateRangePicker from '../../components/DateRangePicker';

import api from '../../services/api';

import convertUTCDateToLocalDate from '../../utils/convertUTCDateToLocalDate';

import {
  Container,
  Content,
  TextField,
  ClearButton
} from './styles';
import { WrapRow, WrapRowItem } from '../_modules/Account/Roles/styles';

interface DataInterface {
  id: string;
  hash: string;
  tema: any;
  especialista: any;
  tema_curador_responsavel: string;
  curadoria: string;
  tipo_contratacao: string;
}

interface PeriodosInterface {
  id: any;
  periodo: string;
}

interface OptionsData {
  value: any;
  label: string;
}

const RelatorioRedList: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const { token, selectedClient } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal);

  var date = new Date();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<DataInterface[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [periodos, setPeriodos] = useState<PeriodosInterface[]>([]);
  const [optionsPeriodos, setOptionsPeriodos] = useState<OptionsData[]>([]);
  const [selectedPeriodo, setSelectedPeriodo] = useState<OptionsData>({} as OptionsData);
  const [startDate, setStartDate] = useState<any>(new Date(date.getFullYear(), date.getMonth(), 1));
  const [endDate, setEndDate] = useState<any>(new Date(date.getFullYear(), date.getMonth() + 1, 0));
  const [dataInicioPermissaoCorte, setDataInicioPermissaoCorte] = useState("");
  const [dataFimPermissaoCorte, setDataFimPermissaoCorte] = useState("");


  useEffect(() => {
    getReport();
  }, [])

  useEffect(() => {
    if (clearSelectedRows) {
      setClearSelectedRows(false)
    }
  }, [clearSelectedRows]);

  const filtered = useMemo(() => {
    if (!filterText)
      return data

    let search = filterText.toLowerCase();

    search = search.split("-").join("");
    search = search.split(".").join("");

    search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

    return data.filter((item:any) => {
      return item.tema.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        // item.hash.toLowerCase().includes(search) ||
        item.especialista.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        item.curador_responsavel.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        item.projeto.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        item.curadoria.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search)
    })
  }, [filterText, data])

  const getReport = useCallback(async () => {
    try {
      setLoading(true);
      
      // console.log("getReport");

      const params:any = {
        id_cliente: selectedClient.id
      }

      const response = await api.get(`/relatorio-redlist/1`, { params });
      // console.log("response", response.data);

      setData(response.data ? response.data : []);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, [selectedClient]);

  const downloadReport = useCallback(async () => {
    try {
      setLoading(true);

      const params:any = {
        id_cliente: selectedClient.id
      }

      //console.log("params", params);

      const response = await api.post(`/relatorio-redlist`, params);
      //console.log("response", response.data);

      if(response.data){
        window.open(`${response.data}?token=${token}`, '_blank');
      }

    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, [selectedPeriodo, selectedClient]);
  
  const handleChange = (state: any) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    //console.log('Selected Rows: ', state.selectedRows);

    setSelectedRows(state.selectedRows);
  };

  const actions = (
    <div
      className="table-action-button"
      onClick={() => {
        downloadReport();
      }}
    >
      {/* <FiPlus /> */}
      <span>Download</span>
    </div>
  );

  function handleChangeSelectedPeriodo(newValue: any, actionMeta: any) {
    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue Periodo", newValue.value);

    setSelectedPeriodo(newValue);
  };

  
  const columns = [
    {
      name: 'Nome',
      selector: 'nome',
      sortable: false,
      maxWidth: '300px',
      // right: true,
    },
    {
      name: 'CPF',
      selector: 'cpf',
      sortable: false,
      maxWidth: '140px',
      // right: true,
    },
    {
      name: 'ID do Contrato',
      selector: 'hash',
      sortable: false,
      maxWidth: '140px',
      // right: true,
    },
    // {
    //   name: 'Motivo',
    //   selector: 'motivo',
    //   sortable: false,
    //   // maxWidth: '400px',
    //   // right: true,
    // },
    {
      name: 'Produção',
      selector: 'producao',
      sortable: false,
      // maxWidth: '300px',
      // right: true,
      cell: (row: any) => {
        return (
          <>
            {row.tema ?? row.disciplina ?? row.curso}
          </>
        )
      }
    },    
  ];

  return (
    <Container>
      <Content>
        <Card>
          <DataTable
            title={"Relatório - Redlist"}
            columns={columns}
            data={filtered}
            // selectableRows
            highlightOnHover
            onSelectedRowsChange={handleChange}
            // defaultSortField="especialista.email"
            pagination
            actions={actions}
            // contextActions={contextActions}
            noDataComponent="Nenhum registro encontrado."
            paginationComponentOptions={{
              rowsPerPageText: 'Itens por página',
              rangeSeparatorText: 'de'
            }}
            clearSelectedRows={clearSelectedRows}
            contextMessage={{
              singular: 'item selecionado',
              plural: 'itens selecionados',
              message: ''
            }}
            progressComponent={<Loading />}
            progressPending={loading}
            // subHeader
            // subHeaderComponent={subHeaderComponent}
            paginationResetDefaultPage={resetPaginationToggle}
          />
        </Card>
      </Content>
    </Container>
  );
};

export default RelatorioRedList;
