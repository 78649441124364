import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { FiTrash, FiPlus, FiEdit } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ReactTooltip from 'react-tooltip';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  TextField,
  ClearButton
} from './styles';

interface ArquivosTreinamentoInterface {
  id: string;
  descricao: string;
  projeto: any;
}

const ArquivosTreinamento: React.FC = () => {
  const history = useHistory();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal)

  const [loading, setLoading] = useState(true);
  const [ArquivosTreinamento, setArquivosTreinamento] = useState<ArquivosTreinamentoInterface[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filteredItems, setFilteredItems] = useState<ArquivosTreinamentoInterface[]>([]);

  useEffect(() => {
    getArquivosTreinamento();
  }, []);

  useEffect(() => {
    if (clearSelectedRows) {
      setClearSelectedRows(false)
    }
  }, [clearSelectedRows]);

  useEffect(() => {
    if (!ArquivosTreinamento) return;

    let search = filterText.toLowerCase();

    let result = ArquivosTreinamento.filter(
      item => (item.descricao.toLowerCase().includes(search) || item.projeto.titulo.toLowerCase().includes(search))
    );

    setFilteredItems(result);
  }, [filterText, ArquivosTreinamento])

  const getArquivosTreinamento = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/projeto-midia`);
      // console.log("response", response.data);

      setArquivosTreinamento(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  // const removeSelectedRows = useCallback(() => {
  //   MySwal.fire({
  //     // title: 'Tem certeza que deseja remover?',
  //     showCancelButton: true,
  //     confirmButtonText: `Remover`,
  //     confirmButtonColor: '#1CAEBD',
  //     cancelButtonColor: '#312e38',
  //     cancelButtonText: 'Cancelar',
  //     // icon: 'warning',
  //     html: '<div style="font-size:20px">Tem certeza que deseja remover?</div>',
  //     // position: 'top'
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       selectedRows.map((row: any) => {
  //         removeRow(row.id);
  //       });
  //     }
  //   })
  // }, [selectedRows]);

  // const removeRow = useCallback(async (rowId) => {
  //   //console.log("remove", rowId);
  //   setLoading(true);

  //   try {
  //     await api.delete(`/area-conhecimento/${rowId}`);

  //     setClearSelectedRows(true);

  //     addToast({
  //       type: 'success',
  //       title: 'Área do conhecimento removida com sucesso',
  //       description: '',
  //     });

  //     getArquivosTreinamento();
  //   } catch (err) {
  //     for (let error in err.response?.data) {
  //       addToast({
  //         type: 'error',
  //         title: 'Erro ao remover serviço',
  //         description: err.response?.data[error] ?
  //           err.response.data[error] :
  //           'Erro ao remover serviço, tente novamente mais tarde.',
  //       });
  //     }
  //   }
  // }, []);

  function goStoreForm() {
    history.push("/arquivos-treinamento/cadastro");
  }

  function goUpdateForm() {
    history.push("/arquivos-treinamento/edicao", {
      selectedItem: selectedRows[0]
    });
  }

  const handleChange = (state: any) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    //console.log('Selected Rows: ', state.selectedRows);

    setSelectedRows(state.selectedRows);
  };

  const actions = (
    <FiPlus className="icon" onClick={() => {
      goStoreForm();
    }} />
  );

  const contextActions = (
    <>
      {selectedRows.length === 1 &&
        <FiEdit className="icon" style={{ marginRight: "15px" }} onClick={() => {
          goUpdateForm();
        }} />
      }
      {/* <FiTrash className="icon" style={{ marginRight: "10px" }} onClick={() => {
        removeSelectedRows();
      }} /> */}
    </>
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <>
        <TextField id="search" type="text" placeholder="Pesquisar" aria-label="Search Input" value={filterText} onChange={(e: any) => setFilterText(e.target.value)} />
        <ClearButton type="button" onClick={handleClear}>x</ClearButton>
      </>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: 'Título',
      selector: 'titulo',
      sortable: false,
      // right: true,
    },
    // {
    //   name: 'Descrição',
    //   selector: 'descricao',
    //   sortable: false,
    //   // right: true,
    // },
    {
      name: 'Projeto',
      selector: 'projeto.titulo',
      sortable: false,
      maxWidth: "100px",
      // right: true,
      cell: (row: any) => {
        return (
          <div>
            {row.projeto?.titulo}
          </div>
        )
      }
    },
    {
      name: 'Modelo',
      selector: 'modelo.titulo',
      sortable: false,
      maxWidth: "100px",
      // right: true,
      cell: (row: any) => {
        return (
          <div>
            {row.modelo?.titulo}
          </div>
        )
      }
    },
    {
      name: 'Serviço',
      selector: 'servico.titulo',
      sortable: false,
      // right: true,
      hide: 1220,
      cell: (row: any) => {
        return (
          <div>
            {row.servico?.titulo}
          </div>
        )
      }
    },
    {
      name: 'Treinamento',
      selector: 'treinamento',
      sortable: false,
      maxWidth: "100px",
      // right: true,
      hide: 1520,
      cell: (row: any) => {
        return (
          <div>
            {row.treinamento ? <span>Sim</span> : <span>Não</span>}
          </div>
        )
      }
    },
    {
      name: 'Obrigatório',
      selector: 'obrigatorio',
      sortable: false,
      maxWidth: "100px",
      // right: true,
      hide: 1520,
      cell: (row: any) => {
        return (
          <div>
            {row.obrigatorio ? <span>Sim</span> : <span>Não</span>}
          </div>
        )
      }
    },
    {
      name: 'Prioridade',
      selector: 'prioridade',
      sortable: false,
      maxWidth: "40px",
      // right: true,
      hide: 1320,
    },
    {
      name: 'Ativo',
      selector: 'ativo',
      sortable: false,
      maxWidth: "100px",
      // right: true,
      hide: 1320,
      cell: (row: any) => {
        return (
          <div>
            {row.ativo ? <span>Ativo</span> : <span>Inativo</span>}
          </div>
        )
      }
    },
    {
      name: 'Ações',
      button: true,
      hide: 745,
      cell: (row: any) => (
        <div className="wrapper-table-actions">
          <div
            className={`table-action`}
            onClick={() =>
              history.push('/arquivos-treinamento/edicao', {
                selectedItem: row,
              })
            }
          >
            <FiEdit data-tip data-for="tipVisualizarContrato" />
          </div>

          <ReactTooltip id="tipVisualizarContrato" effect="solid">
            <span>Editar</span>
          </ReactTooltip>
        </div>
      ),
    },
  ];

  return (
    <Container>
      <Content>
        <Card>
          <DataTable
            title="Arquivos Treinamento Conteudista"
            columns={columns}
            data={filteredItems}
            // selectableRows
            highlightOnHover
            onSelectedRowsChange={handleChange}
            defaultSortField="descricao"
            pagination
            actions={actions}
            contextActions={contextActions}
            noDataComponent="Nenhum registro encontrado."
            paginationComponentOptions={{
              rowsPerPageText: 'Itens por página',
              rangeSeparatorText: 'de'
            }}
            clearSelectedRows={clearSelectedRows}
            contextMessage={{
              singular: 'item selecionado',
              plural: 'itens selecionados',
              message: ''
            }}
            progressComponent={<Loading />}
            progressPending={loading}
            subHeader
            subHeaderComponent={subHeaderComponent}
            paginationResetDefaultPage={resetPaginationToggle}
          />
        </Card>
      </Content>
    </Container>
  );
};

export default ArquivosTreinamento;
