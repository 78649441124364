import { FormHandles } from '@unform/core';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DataTable from 'react-data-table-component';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
import Loading from '../../components/Loading';
// import DateRangePicker from '../../components/DateRangePicker';

import api from '../../services/api';

import { WrapRow } from '../_modules/Account/Roles/styles';
import { ClearButton, Container, Content, TextField } from './styles';

interface DataInterface {
  created_at: string | number | Date;
  id: string;
  cpf: string;
  nome: string;
  email: string;
  roles: { slug: string; name: string }[];
  dados_usuario: {
    rg: any;
    cpf: string;
    nome: string;
  };
}

const RelatorioEspecialista: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const { token, selectedClient } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal);

  var date = new Date();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<DataInterface[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [startDate, setStartDate] = useState<any>(
    new Date(date.getFullYear(), date.getMonth(), 1),
  );
  const [endDate, setEndDate] = useState<any>(
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  );
  const [dataInicioPermissaoCorte, setDataInicioPermissaoCorte] = useState('');
  const [dataFimPermissaoCorte, setDataFimPermissaoCorte] = useState('');

  useEffect(() => {
    if (clearSelectedRows) {
      setClearSelectedRows(false);
    }
  }, [clearSelectedRows]);

  useEffect(() => {
    if (!startDate) return;
    if (!endDate) return;

    const data_inicio_permissao_corte = `${startDate.getFullYear()}-${(
      '0' +
      (startDate.getMonth() + 1)
    ).slice(-2)}-${('0' + startDate.getDate()).slice(-2)}`;
    const data_fim_permissao_corte = `${endDate.getFullYear()}-${(
      '0' +
      (endDate.getMonth() + 1)
    ).slice(-2)}-${('0' + endDate.getDate()).slice(-2)}`;

    setDataInicioPermissaoCorte(data_inicio_permissao_corte);
    setDataFimPermissaoCorte(data_fim_permissao_corte);

    getReport(data_inicio_permissao_corte, data_fim_permissao_corte);
  }, [startDate, endDate]);

  const filtered = useMemo(() => {
    if (!filterText) return data;

    let search = filterText.toLowerCase();

    search = search.split('-').join('');
    search = search.split('.').join('');

    search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    return data.filter((item: any) => {
      console.log('data: ', item);

      return (
        item.dados_usuario?.nome
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(search) ||
        item.dados_usuario?.cpf
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(search) ||
        item.email
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(search)
      );
    });
  }, [filterText, data]);

  const getReport = useCallback(
    async (data_inicio_permissao_corte, data_fim_permissao_corte) => {
      try {
        setLoading(true);

        const response = await api.get(`/usuario`);

        const tmp: DataInterface[] = [];

        response.data.forEach((item: any) => {
          // Verifica se a data de criação da conta está dentro do intervalo especificado
          if (
            item.created_at >= data_inicio_permissao_corte &&
            item.created_at <= data_fim_permissao_corte
          ) {
            tmp.push(item);
          }
        });

        setData(tmp);
      } catch (error) {
        // Trate o erro como preferir, talvez adicionar um toast de erro
        addToast({
          type: 'error',
          title: 'Erro ao carregar dados',
          description: 'Não foi possível carregar os dados do usuário.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const downloadReport = useCallback(async () => {
    try {
      setLoading(true);

      if (!startDate) return;
      if (!endDate) return;

      const data_inicio_permissao_corte = `${startDate.getFullYear()}-${(
        '0' +
        (startDate.getMonth() + 1)
      ).slice(-2)}-${('0' + startDate.getDate()).slice(-2)}`;
      const data_fim_permissao_corte = `${endDate.getFullYear()}-${(
        '0' +
        (endDate.getMonth() + 1)
      ).slice(-2)}-${('0' + endDate.getDate()).slice(-2)}`;

      setDataInicioPermissaoCorte(data_inicio_permissao_corte);
      setDataFimPermissaoCorte(data_fim_permissao_corte);

      const params = {
        data_inicio_permissao_corte,
        data_fim_permissao_corte,
        id_cliente: selectedClient.id,
      };

      const response = await api.post(`/relatorio-especialista`, params);

      if (response.data) {
        window.open(`${response.data}?token=${token}`, '_blank');
      }
    } catch (error) {
      // Trate o erro como preferir, talvez adicionar um toast de erro
      addToast({
        type: 'error',
        title: 'Erro ao baixar relatório',
        description: 'Não foi possível baixar o relatório do usuário.',
      });
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate, selectedClient, token, addToast]);

  const handleChange = (state: any) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    //console.log('Selected Rows: ', state.selectedRows);

    setSelectedRows(state.selectedRows);
  };

  const actions = (
    <div
      className="table-action-button"
      onClick={() => {
        downloadReport();
      }}
    >
      {/* <FiPlus /> */}
      <span>Download</span>
    </div>
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const CustomInputDatePicker = forwardRef(
      ({ value, onClick }: any, ref: any) => (
        <button className="custom-input-datepicker" onClick={onClick} ref={ref}>
          {value}
        </button>
      ),
    );

    return (
      <WrapRow>
        <span className="label-datapicker-input">Início:</span>
        <DatePicker
          closeOnScroll={true}
          selected={startDate}
          // onChange={(date) => setStartDate(date)}
          onChange={date => setStartDate(date)}
          dateFormat="dd/MM/yyyy"
          customInput={<CustomInputDatePicker />}
        />
        <span className="label-datapicker-input">Término:</span>
        <DatePicker
          closeOnScroll={true}
          selected={endDate}
          onChange={date => setEndDate(date)}
          dateFormat="dd/MM/yyyy"
          customInput={<CustomInputDatePicker />}
        />
        <div style={{ flex: 1 }}></div>
        <TextField
          id="search"
          type="text"
          placeholder="Pesquisar"
          aria-label="Search Input"
          value={filterText}
          onChange={(e: any) => setFilterText(e.target.value)}
        />
        <ClearButton type="button" onClick={handleClear}>
          x
        </ClearButton>
      </WrapRow>
    );
  }, [filterText, resetPaginationToggle, startDate, endDate]);

  let parseText = function (text: string, limit: number) {
    if (text.length > limit)
      for (let i = limit; i > 0; i--) {
        if (
          text.charAt(i) === ' ' &&
          (text.charAt(i - 1) != ',' ||
            text.charAt(i - 1) != '.' ||
            text.charAt(i - 1) != ';')
        ) {
          return text.substring(0, i) + '...';
        }
      }
    else return text;
  };

  const columns = [
    {
      name: 'Nome',
      selector: 'dados_usuario.nome',
      sortable: false,
      cell: (row: DataInterface) => {
        return <>{row.dados_usuario?.nome}</>;
      },
    },
    {
      name: 'E-mail',
      selector: 'email',
      sortable: false,
    },
    {
      name: 'CPF',
      selector: 'dados_usuario.cpf',
      sortable: false,
      cell: (row: DataInterface) => {
        return <>{row.dados_usuario?.cpf}</>;
      },
    },
    {
      name: 'Tipo de Usuário',
      selector: 'tipo',
      sortable: false,
      // right: true,
      hide: 800,
      cell: (row: any) => {
        return (
          <>
            {row.roles.map((item: any) => {
              return (
                <span key={`${item.name}`} style={{ marginRight: '10px' }}>
                  {item.name}
                </span>
              );
            })}
          </>
        );
      },
    },
    {
      name: 'RG',
      selector: 'dados_usuario.rg',
      sortable: false,
      cell: (row: DataInterface) => {
        return <>{row.dados_usuario?.rg}</>;
      },
    },
    {
      name: 'Data Criação da conta',
      selector: 'created_at',
      sortable: false,
      cell: (row: DataInterface) => {
        const formattedDate = new Date(row.created_at).toLocaleDateString(
          'pt-BR',
        );
        return <>{formattedDate}</>;
      },
    },
  ];

  return (
    <Container>
      <Content>
        <Card>
          <DataTable
            title="Relatório - Especialistas"
            columns={columns}
            data={filtered}
            // selectableRows
            highlightOnHover
            onSelectedRowsChange={handleChange}
            // defaultSortField="especialista.email"
            pagination
            actions={actions}
            // contextActions={contextActions}
            noDataComponent="Nenhum registro encontrado."
            paginationComponentOptions={{
              rowsPerPageText: 'Itens por página',
              rangeSeparatorText: 'de',
            }}
            clearSelectedRows={clearSelectedRows}
            contextMessage={{
              singular: 'item selecionado',
              plural: 'itens selecionados',
              message: '',
            }}
            progressComponent={<Loading />}
            progressPending={loading}
            subHeader
            subHeaderComponent={subHeaderComponent}
            paginationResetDefaultPage={resetPaginationToggle}
          />
        </Card>
      </Content>
    </Container>
  );
};

export default RelatorioEspecialista;
