import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import FormInput from '../../components/FormInput';
import FormInputMask from '../../components/FormInputMask';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem,
  WrapperProducts,
  WrapperProduct,
  ProductItem,
  TagProductPrice
} from './styles';
import { setTimeout } from 'timers';


interface ContratoFormData {
  id: string;
  data_contrato: string;
  prazo_entrega: string;
  tema: any;
  observacoes: string;
  produtos: any;
  id_especialista: string;
  valor_total: string;
}

interface TemaFormData {
  id: string;
  titulo: string;
  modelo: any;
  tags: any;
  tema: string;
  produtos: any;
}

interface ProfissionalFormData {
  id: string;
  titulo: string;
}

interface ProdutoFormData {
  id: string;
  produto: string;
  unidade_aplicavel: number;
  unidade_descricao: string;
  unidade_limite: number;
  valor: any;
  quantidade: number;
}

interface ProdutoPrecosFormData {
  id: string;
  total: any;
  valor: any;
  quantidade: number;
}

interface CuradoriaFormData {
  id: string;
  nome: string;
}

interface EspecialistaFormData {
  id: string;
  nome: string;
  email: string;
}

interface OptionsData {
  value: string;
  label: string;
}

const ContratosEdicao: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location:any = useLocation();

  const { user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [contratos, setContratos] = useState<ContratoFormData[]>([]);
  const [temas, setTemas] = useState<TemaFormData[]>([]);
  const [optionsTemas, setOptionsTemas] = useState<OptionsData[]>([]);
  const [selectedTema, setSelectedTema] = useState<OptionsData>({ value: "", label: "" });
  const [especialistas, setEspecialistas] = useState<EspecialistaFormData[]>([]);
  const [optionsEspecialistas, setOptionsEspecialistas] = useState<OptionsData[]>([]);
  const [selectedEspecialista, setSelectedEspecialista] = useState<OptionsData>({ value: "", label: "" });
  const [profissionais, setProfissionais] = useState<ProfissionalFormData[]>([]);
  const [optionsProfissionais, setOptionsProfissionais] = useState<OptionsData[]>([]);
  const [selectedProfissional, setSelectedProfissional] = useState<OptionsData>({ value: "", label: "" });
  const [curadorias, setCuradorias] = useState<CuradoriaFormData[]>([]);
  const [optionsCuradorias, setOptionsCuradorias] = useState<OptionsData[]>([]);
  const [selectedCuradoria, setSelectedCuradoria] = useState<OptionsData>({ value: "", label: "" });
  const [produtos, setProdutos] = useState<ProdutoFormData[]>([]);
  const [produtosPrecos, setProdutosPrecos] = useState<ProdutoPrecosFormData[]>([]);
  const [valorTotal, setValorTotal] = useState("R$ 0.00");
  const [selectedItem, setSelectedItem] = useState<ContratoFormData>({} as ContratoFormData);

  useEffect(() => {
    if(location && location.state){
      setSelectedItem(location.state.selectedItem);
    }
  }, [location]);

  useEffect(() => {
    if(selectedItem){
      //console.log("selectedItem", selectedItem);
      formRef.current?.setFieldValue("observacoes", selectedItem.observacoes);

      let dt_data_contrato = new Date(selectedItem.data_contrato);
      formRef.current?.setFieldValue("data_contrato", `${("0" + dt_data_contrato.getDate()).slice(-2)}/${("0" + (dt_data_contrato.getMonth() + 1)).slice(-2)}/${dt_data_contrato.getFullYear()}`);

      let dt_prazo_entrega = new Date(selectedItem.prazo_entrega);
      formRef.current?.setFieldValue("prazo_entrega", `${("0" + dt_prazo_entrega.getDate()).slice(-2)}/${("0" + (dt_prazo_entrega.getMonth() + 1)).slice(-2)}/${dt_prazo_entrega.getFullYear()}`);

      setValorTotal(`R$ ${parseFloat(selectedItem.valor_total).toFixed(2)}`)

      if(selectedItem.id_especialista){
        const current = especialistas.find((item) => item.id === selectedItem.id_especialista)
        if(current){
          setSelectedEspecialista({ value: current.id, label: current.email });
        }
      }

      if(selectedItem.tema){
        const current_curadoria = curadorias.find((item) => item.id === selectedItem.tema.id_curadoria)
        if(current_curadoria){
          setSelectedCuradoria({ value: current_curadoria.id, label: current_curadoria.nome });
        }
        setSelectedTema({ value: selectedItem.tema.id, label: selectedItem.tema.titulo });
      }

      if(selectedItem.produtos){
        let temp:ProdutoFormData[] = [];

        selectedItem.produtos.forEach((item:any) => {
          temp.push({
            id: item.id,
            produto: item.titulo,
            unidade_aplicavel: item.unidade_aplicavel,
            unidade_descricao: item.unidade_descricao,
            unidade_limite: item.unidade_limite,
            valor: item.valor,
            quantidade: item.pivot.quantidade
          })
        })
        setProdutos(temp);
      }
    }
  }, [selectedItem, especialistas, temas, curadorias]);

  useEffect(() => {
    getProfissionais();
    getCuradorias();
    getEspecialistas();
  }, []);

  useEffect(() => {
    if (temas.length === 0) return;

    var options: OptionsData[] = [];

    temas.map(item => {
      options.push({ value: item.id, label: item.tema });
    });

    setOptionsTemas(options);
    //console.log("options temas", options);
  }, [temas]);

  useEffect(() => {
    if (profissionais.length === 0) return;

    var options: OptionsData[] = [];

    profissionais.map(item => {
      options.push({ value: item.id, label: item.titulo });
    });

    setOptionsProfissionais(options);
    //console.log("options profissionais", options);
  }, [profissionais]);

  useEffect(() => {
    if (curadorias.length === 0) return;

    var options: OptionsData[] = [];

    curadorias.map(item => {
      options.push({ value: item.id, label: item.nome });
    });

    setOptionsCuradorias(options);
    //console.log("options curadorias", options);
  }, [curadorias]);

  useEffect(() => {
    if (especialistas.length === 0) return;

    var options: OptionsData[] = [];

    especialistas.map(item => {
      options.push({ value: item.id, label: item.nome ? `${item.nome} (${item.email})` : item.email });
    });

    setOptionsEspecialistas(options);
    //console.log("options especialistas", options);
  }, [especialistas]);

  useEffect(() => {
    // setSelectedTema({ value: "", label: "" });
    // setProdutos([]);
    getTemas();
  }, [selectedProfissional, selectedCuradoria]);

  // useEffect(() => {
  //   const temp = temas.find(item => item.id === selectedTema.value);

  //   //console.log("tema", temp);

  //   if (temp && temp.produtos && temp.produtos.length > 0) {
  //     setProdutos(temp.produtos);
  //   } else {
  //     setProdutos([]);
  //     setProdutosPrecos([]);
  //   }
  // }, [selectedTema]);

  useEffect(() => {
    let temp: any[] = [];
    produtos.forEach((item: any) => {
      formRef.current?.setFieldValue(`produtos['${item.id}'].quantidade`, item.quantidade ? item.quantidade : 0);
      temp.push({
        id: item.id,
        total: item.valor ? item.valor : 0,
        quantidade: item.quantidade ? item.quantidade : 0
      })
    });

    setProdutosPrecos(temp);
  }, [produtos]);
  
  const getProfissionais = useCallback(async () => {
    const response = await api.get(`/profissional`);
    //console.log("profissional", response.data);

    setProfissionais(response.data);
  }, []);

  const getCuradorias = useCallback(async () => {
    const response = await api.get(`/curadoria`);
    //console.log("curadoria", response.data);

    setCuradorias(response.data);
  }, []);

  const getEspecialistas = useCallback(async () => {
    const response = await api.get(`/busca-usuario`, { params: { papel: ["especialista"] } });
    //console.log("especialistas", response.data);

    setEspecialistas(response.data);
  }, []);

  const getTemas = useCallback(async () => {
    if (selectedCuradoria.value && selectedProfissional.value) {
      const response = await api.get(`/tema`, {
        params: {
          id_curadoria: selectedCuradoria.value,
          id_profissional: selectedProfissional.value
        }
      });
      //console.log("temas", response.data);

      setTemas(response.data);
    } else {
      //console.log("temas - sem params", selectedCuradoria, selectedProfissional);
    }
  }, [selectedCuradoria, selectedProfissional]);

  const handleSubmit = useCallback(
    async (data: ContratoFormData) => {
      try {
        //console.log("handleSubmit", data);

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          data_contrato: Yup.string().required('Data do contrato obrigatório'),
          prazo_entrega: Yup.string().required('Prazo de entrega obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let dt_data_contrato = data.data_contrato.split("/");
        let dt_prazo_entrega = data.prazo_entrega.split("/");

        let temp: any = [];

        for (let k in data.produtos) {
          //console.log("???", k, data.produtos[k]);
          if (parseInt(data.produtos[k].quantidade) > 0) {
            temp.push({ id: parseInt(k.replace("'", "").replace("'", "")), quantidade: parseInt(data.produtos[k].quantidade) });
          }
        }

        let params = {
          data_contrato: `${dt_data_contrato[2]}-${dt_data_contrato[1]}-${dt_data_contrato[0]}`,
          prazo_entrega: `${dt_prazo_entrega[2]}-${dt_prazo_entrega[1]}-${dt_prazo_entrega[0]}`,
          id_tema: selectedTema.value,
          id_remetente: user.id,
          id_especialista: selectedEspecialista.value,
          observacoes: data.observacoes,
          // redirect_url: 'https://contrato.ensineme.com.br/convite-tema',
          // redirect_url: "http://localhost:3000/convite-tema",
          redirect_url: `https://${window.location.hostname}/convite-tema`,
          produtos: temp
        }

        //console.log("params", params);

        await api.put(`/contrato/${selectedItem.id}`, params);

        addToast({
          type: 'success',
          title: 'Contrato atualizado com sucesso',
          description: '',
        });

        goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }
        
        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar contrato',
            description: err.response?.data[error] ?
              err.response.data[error] :
              'Erro ao atualizar contrato, tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, selectedTema, produtosPrecos, selectedEspecialista, selectedItem],
  );

  function goBack() {
    history.push('/contratos');
  }

  function handleChangeSelectedTema(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedTema({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedTema({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue tema", newValue.value);

    setSelectedTema(newValue);
  };

  function handleChangeSelectedProfissional(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedProfissional({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedProfissional({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue Profissional", newValue.value);

    setSelectedProfissional(newValue);
  };

  function handleChangeSelectedCuradoria(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedCuradoria({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedCuradoria({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue Curadoria", newValue.value);

    setSelectedCuradoria(newValue);
  };

  function handleChangeSelectedEspecialista(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedEspecialista({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedEspecialista({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue Especialista", newValue.value);

    setSelectedEspecialista(newValue);
  };

  
  const getPrice = (item: any) => {
    //console.log("getPrice", item);

    let preco = produtosPrecos.find((it => it.id === item.id));
    //console.log("preco", preco);

    if (preco && preco.total) {
      return `R$ ${parseFloat(preco.total).toFixed(2)}`;
    } else {
      return `R$ 0.00`;
    }
  };

  const updatePrice = useCallback((item, quantidade) => {
    let temp = produtosPrecos;
    let total: any = 0;

    temp.forEach((it => {
      if (it.id === item.id) {
        it.total = item.valor * quantidade;
      }
      if (it.total) {
        total = total + it.total;
        it.quantidade = quantidade;

        //console.log(item.valor, quantidade, total, it);
      }
    }));

    setProdutosPrecos(temp);
    setValorTotal(`R$ ${parseFloat(total).toFixed(2)}`)

    //console.log("updatePrice", produtosPrecos, total);

    return;
  }, [produtosPrecos]);

  if(loading){
    return (
      <Loading />
    )
  }

  return (
    <Container>
      <Content>
        <Card>
          <div className="form">
            <div className="header">
              <h2>Editar Contrato</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            <Form ref={formRef} onSubmit={handleSubmit}>
                <WrapRow>
                  <WrapRowItem>
                    <label>Especialista:</label>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedEspecialista}
                      options={optionsEspecialistas}
                      value={selectedEspecialista}
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem style={{ marginTop: "30px" }}>
                    <label>Tipo de Profissional:</label>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedProfissional}
                      options={optionsProfissionais}
                      value={selectedProfissional}
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow style={{ marginTop: "30px" }}>
                  <WrapRowItem>
                    <label>Curadoria:</label>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedCuradoria}
                      options={optionsCuradorias}
                      value={selectedCuradoria}
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow style={{ marginTop: "30px" }}>
                  <WrapRowItem>
                    <label>Tema:</label>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedTema}
                      options={optionsTemas}
                      value={selectedTema}
                      isDisabled={!(!!(selectedProfissional.value && selectedCuradoria.value))}
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapperProducts>
                  <div className="header">Produtos:</div>
                  {produtos.map(item => (
                    <Scope key={`produtos['${item.id}']`} path={`produtos['${item.id}']`}>
                      <WrapperProduct style={{ marginTop: "10px" }}>
                        <ProductItem>
                          <div className="header">
                            <TagProductPrice>

                              {item.produto}
                              <div className="tag">{`R$ ${parseFloat(item.valor).toFixed(2)}`}</div>
                            </TagProductPrice>
                            <div>{getPrice(item)}</div>
                          </div>



                          <FormInput
                            name="quantidade"
                            type="number"
                            placeholder={item.unidade_descricao ? `Quantidade (${item.unidade_descricao})` : `Quantidade`}
                            max={item.unidade_limite}
                            min={0}
                            onChange={(input) => {
                              if (parseInt(input.target.value) > item.unidade_limite && item.unidade_aplicavel) {
                                formRef.current?.setFieldValue(`produtos['${item.id}'].quantidade`, item.unidade_limite);
                                updatePrice(item, 100);
                              } else {
                                if (parseInt(input.target.value) > 1 && !item.unidade_aplicavel) {
                                  formRef.current?.setFieldValue(`produtos['${item.id}'].quantidade`, 1);
                                  updatePrice(item, 1);
                                } else {
                                  updatePrice(item, parseInt(input.target.value));
                                }
                              }
                            }}
                          />
                        </ProductItem>
                      </WrapperProduct>
                    </Scope>
                  ))}
                </WrapperProducts>

                <WrapRow style={{ marginTop: "30px" }}>
                  <WrapRowItem>
                    <FormInput
                      value={valorTotal}
                      name="total"
                      type="text"
                      placeholder="Valor total"
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow style={{ marginTop: "0px" }}>
                  <WrapRowItem>
                    <FormInputMask
                      name="data_contrato"
                      type="text"
                      placeholder="Data do Contrato"
                      mask="99/99/9999"
                    />
                  </WrapRowItem>

                  <WrapRowItem>
                    <FormInputMask
                      name="prazo_entrega"
                      type="text"
                      placeholder="Prazo de Entrega"
                      mask="99/99/9999"
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="observacoes"
                      type="text"
                      placeholder="Observações"
                    />
                  </WrapRowItem>
                </WrapRow>

                <Button type="submit" loading={loading}>
                  Salvar
            </Button>

              </Form>
          </div>
        </Card>
      </Content>
    </Container>
  );
};

export default ContratosEdicao;
