import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';

import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import { ClearButton, Container, Content, TextField } from './styles';

interface UsersInterface {
  id: string;
  name: string;
}

const EspecialistasEdita: React.FC = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<UsersInterface[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (clearSelectedRows) {
      setClearSelectedRows(false);
    }
  }, [clearSelectedRows]);

  const getUsers = useCallback(async () => {
    const params = new URLSearchParams(window.location.search);
    const id_contrato = params.get('id_contrato');
    setLoading(true);

    try {
      const response = await api.get(`/candidatos/${id_contrato}`);
      console.log('response', response.data);

      setUsers(response.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);

  function goUpdateForm() {
    history.push('/especialistas/visualizar', {
      selectedItem: selectedRows[0],
    });
  }

  const handleChange = (state: any) => {
    setSelectedRows(state.selectedRows);
  };

  const actions = <></>;

  /*const contextActions = (
    <>
      {selectedRows.length === 1 && (
        <button
          type="button"
          className="button-top-datatable"
          onClick={() => {
            goUpdateForm();
          }}
        >
          Visualizar
        </button>
      )}
    </>
  );*/

  const filtered = useMemo(() => {
    if (!filterText) return users;

    let search = filterText.toLowerCase();

    search = search.split('-').join('');
    search = search.split('.').join('');

    search = search.normalize('NFD');
    return users.filter((user: any) => {
      return (
        user?.nome
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(search) ||
        user?.cpf
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(search) ||
        user?.email.toLowerCase().normalize('NFD').includes(search)
      );
    });
  }, [filterText, users]);

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <>
        <TextField
          id="search"
          type="text"
          placeholder="Pesquisar"
          aria-label="Search Input"
          value={filterText}
          onChange={(e: any) => setFilterText(e.target.value)}
        />
        <ClearButton type="button" onClick={handleClear}>
          x
        </ClearButton>
      </>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: 'Nome',
      selector: 'nome',
      sortable: false,
      // right: true,
      hide: 600,
      cell: (row: any) => {
        return <>{row?.nome}</>;
      },
    },
    {
      name: 'E-mail',
      selector: 'email_candidatos',
      sortable: false,
      // right: true,
      hide: 600,
      cell: (row: any) => {
        return (
          <>{row.email_candidatos ? row.email_candidatos : 'não possui'}</>
        );
      },
    },
    {
      name: 'Telefone',
      selector: 'telefone',
      sortable: false,
      // right: true,
      hide: 600,
      cell: (row: any) => {
        return <>{row?.telefone}</>;
      },
    },
    {
      name: 'CPF',
      selector: 'cpf',
      sortable: false,
      // right: true,
      hide: 700,
      cell: (row: any) => {
        return <>{row?.cpf}</>;
      },
    },
    {
      name: 'Linkedin',
      selector: 'linkedin',
      sortable: false,
      // right: true,
      hide: 700,
      cell: (row: any) => {
        return <>{row?.linkedin}</>;
      },
    },
    {
      name: 'Lattes',
      selector: 'lattes',
      sortable: false,
      // right: true,
      hide: 700,
      cell: (row: any) => {
        return <>{row?.lattes}</>;
      },
    },
    {
      name: 'Área de Formação',
      selector: 'area_formacao',
      sortable: false,
      // right: true,
      hide: 700,
      cell: (row: any) => {
        return <>{row?.area_formacao}</>;
      },
    },
    {
      name: 'Formação Acadêmica',
      selector: 'formacao_academica',
      sortable: false,
      // right: true,
      hide: 700,
      cell: (row: any) => {
        return <>{row?.formacao_academica}</>;
      },
    },
    {
      name: 'Já elaborou material didático Yduqs',
      selector: 'material_didatico_yduqs',
      sortable: false,
      // right: true,
      hide: 700,
      cell: (row: any) => {
        return <>{row?.material_didatico_yduqs}</>;
      },
    },
    {
      name: 'Já elaborou material didático Digital',
      selector: 'material_didatico_digital',
      sortable: false,
      // right: true,
      hide: 700,
      cell: (row: any) => {
        return <>{row?.material_didatico_digital}</>;
      },
    },
    {
      name: 'Já elaborou Livros ou Artigos',
      selector: 'livros_artigos',
      sortable: false,
      // right: true,
      hide: 700,
      cell: (row: any) => {
        return <>{row?.livros_artigos}</>;
      },
    },
    {
      name: 'Pontuação',
      sortable: true,
      // right: true,
      hide: 700,
      selector: (row: any) => {
        let pontuacao = 0;
        if (row?.livros_artigos === 'Sim') {
          pontuacao += 10;
        }
        if (row?.material_didatico_digital === 'Sim') {
          pontuacao += 10;
        }
        if (row?.material_didatico_yduqs === 'Sim') {
          pontuacao += 10;
        }
        switch (row?.formacao_academica) {
          case 'Ensino Médio':
            pontuacao += 0;
            break;
          case 'Graduação em andamento':
            pontuacao += 0;
            break;
          case 'Graduação concluído':
            pontuacao += 0;
            break;
          case 'Especialização ou MBA':
            pontuacao += 2;
            break;
          case 'Mestrado em andamento':
            pontuacao += 3;
            break;
          case 'Mestrado concluído':
            pontuacao += 5;
            break;
          case 'Doutorado em andamento':
            pontuacao += 7;
            break;
          case 'Doutorado concluído':
            pontuacao += 8;
            break;
          case 'Pós-doutorado em andamento':
            pontuacao += 9;
            break;
          case 'Pós-doutorado concluído':
            pontuacao += 10;
            break;
        }
        return pontuacao;
      },
    },
  ];

  return (
    <Container>
      <Content>
        <Card>
          <DataTable
            title="Candidatos"
            columns={columns}
            data={filtered}
            selectableRows
            highlightOnHover
            onSelectedRowsChange={handleChange}
            defaultSortField="Pontuação"
            defaultSortAsc={true}
            pagination
            actions={actions}
            //contextActions={contextActions}
            subHeader
            subHeaderComponent={subHeaderComponent}
            noDataComponent="Nenhum registro encontrado."
            paginationComponentOptions={{
              rowsPerPageText: 'Itens por página',
              rangeSeparatorText: 'de',
            }}
            /*clearSelectedRows={clearSelectedRows}
            contextMessage={{
              singular: 'item selecionado',
              plural: 'itens selecionados',
              message: '',
            }}*/
            progressComponent={<Loading />}
            progressPending={loading}
          />
        </Card>
      </Content>
    </Container>
  );
};

export default EspecialistasEdita;
