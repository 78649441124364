import styled from 'styled-components';
// import { shade } from 'polished';

export const Container = styled.div`
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  padding: 20px;
  margin-bottom: 30px;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  @media screen and (max-width: 500px) {
    padding: 10px;
  }
`;

export const Content = styled.div` 
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .icon {
    font-size: 22px;
    color: #717171;
    cursor: pointer;
  }

  .form {
    padding: 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;
