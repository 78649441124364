import styled from 'styled-components';
// import { shade } from 'polished';

export const Container = styled.div`
  .icon {
    font-size: 22px;
    color: #717171;
    cursor: pointer;
  }

  .text-switch{
    font-size: 16px;
    line-height: 30px;
    margin-left: 10px;
    color: #333;
  }
`;

export const Content = styled.main`
  /* max-width: 1120px;
  margin: 10px auto; */
  display: flex;
  margin-bottom: 80px;
  flex-direction: column;

  form{
    width: 100%;

    h2{
      margin-bottom: 30px;
      padding: 5px 0;
    }
  }
`;

export const WrapRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row; 
  flex-wrap: wrap;
`;

export const WrapFile = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column; 
  flex-wrap: wrap;
  border-bottom:0.5px solid #eaeaea;
  margin-bottom:12px;
  min-height: 100px;

  .titulo{
    font-size:20px;
    font-weight:300;
  }

  .descricao{
    font-size:16px; 
    color:#ccc;
  
  }

  .nome_arquivo{
    font-size:18px;
    font-weight:300;
    color:#666 !important;
    display:flex;
    flex-direction:row;
    
  }

  .link_upload{
    text-decoration:none;
    color:#1FB0BE;
  }

  .link_upload:hover{
    color:#1a939e;
  }

`;

export const WrapFileDescription = styled.div`
  display: flex;

  flex-direction: column; 
  flex-wrap: wrap;
  margin-bottom:10px;

  `




export const WrapRowItem = styled.div`
  flex: 1;
  margin: 0 5px;
`;

export const Item = styled.div`
  flex: 1;
  margin: 20px 5px;
  background-color: #F1F1F1;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .title{
    font-size: 18px;
    margin-bottom: 10px;
  }

  .subtitle{
    font-weight: lighter;
  }

  .removeIcon{
    cursor: pointer;
  }
`;