export default function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();
    // console.log(offset)
    newDate.setHours(hours);
    //newDate.setHours(hours - offset);

    return newDate;
}
