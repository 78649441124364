import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';

import Card from '../../components/Card';

import { Container } from './styles';

const Main: React.FC = () => {
  const history = useHistory();
  const { userData, roles } = useAuth();

  const [loading, setLoading] = useState(true);
  const [meusContratos, setMeusContratos] = useState<any[]>([]);
  const [meusContratosCriados, setMeusContratosCriados] = useState<any[]>([]);
  const [isEspecialista, setIsEspecialista] = useState(false);
  const [treinamentosPendentes, setTreinamentosPendentes] = useState<any[]>([]);

  useEffect(() => {
    getMeusContratos();
    getTreinamentosEspecialista();
  }, []);

  const welcome = useMemo(() => {
    if (userData.nome) {
      //console.log("userData", userData);
      const nome = userData.nome.split(" ");

      let isEspecialista = false;
      let isCurador = false;

      if (roles.indexOf("especialista") > -1) {
        isEspecialista = true;
      }

      if (roles.indexOf("curador") > -1) {
        isCurador = true;
      }

      if (roles.indexOf("curador-adjunto") > -1) {
        isCurador = true;
      }

      setIsEspecialista(isEspecialista);

      if (isEspecialista || isCurador) {
        return `Olá Professor ${nome[0]}`;
      }

      return `Olá ${nome[0]}`;
    } else {
      return "";
    }
  }, [userData, roles]);

  const contratosPendentesAceite = useMemo(() => {
    const tmp: any[] = [];

    meusContratos.forEach((item: any) => {
      if (item.status.slug === "criado") {
        tmp.push(item);
      }
    })

    setMeusContratosCriados(tmp);

    return tmp.length;
  }, [meusContratos]);

  const treinamentosPendentesLista = useMemo(() => {
    return treinamentosPendentes.map((item: any) => {
      if (!item.finalizado) {
        return (
          <div key={item.projeto_nome} className="card-container-item" onClick={() => history.push('/treinamento-especialista')}>
            <p>Projeto: {item.projeto_nome}</p>
          </div>
        )
      }
    })
  }, [treinamentosPendentes]);

  const existemTreinamentosPendentes = useMemo(() => {
    let count = 0;

    treinamentosPendentes.forEach((item: any) => {
      if (!item.finalizado) {
        count++;
      }
    })

    return count > 0;
  }, [treinamentosPendentes]);

  const getMeusContratos = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/contrato-especialista`);
      //console.log("response", response.data);

      if (response.data) {
        setMeusContratos(response.data);
      }
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getTreinamentosEspecialista = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/projeto-especialista`);
      //console.log("treinamento-especialista", response.data);

      if (response.data) {
        setTreinamentosPendentes(response.data);
      }
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Container>
      {!isEspecialista &&
        <>
          <Card>
            <div className="card-container" style={{}}>
              <h2>{welcome}</h2>
              <p>O Flow centraliza todas as informações referentes aos seus contratos com o EnsineMe</p>
            </div>
          </Card>
        </>
      }

      {isEspecialista &&
        <>
          <Card>
            <div className="card-container" style={{}}>
              <h2>{welcome}</h2>
              <p>O Flow centraliza todas as informações referentes aos seus contratos com o EnsineMe</p>
              {/* <p>Dúvidas sobre os templates de produção e a estrutura dos nossos produtos? Entre em contato conosco através do e-mail <b>qualidade.ensineme@ensineme.com.br</b></p> */}
            </div>
          </Card>

          {(contratosPendentesAceite || existemTreinamentosPendentes) ?
            <Card>
              <div className="card-container" style={{}}>

                {existemTreinamentosPendentes ?
                  <div style={{ marginBottom: "40px" }}>
                    <h3>Treinamentos pendentes:</h3>
                    {treinamentosPendentesLista}
                  </div>
                  :
                  <></>
                }

                {contratosPendentesAceite ?
                  <>
                    <h3 style={{ marginBottom: "15px" }}>Contratos aguardando aceitação</h3>
                    {
                      meusContratosCriados.map((item: any) => (
                        <div key={item.id} className="card-container-item" onClick={() => { history.push('/contratos/aceitar-contrato', { selectedItem: item }) }}>
                          {item.tema ? (
                            <p>Tema: {item.tema.titulo}</p>
                          ) :
                            <></>
                          }

                          {item.disciplina_producao ? (
                            <p>Disciplina: {item.disciplina_producao.disciplina?.titulo}</p>
                          ) :
                            <></>
                          }

                          {item.curso_producao ? (
                            <p>Curso: {item.curso_producao.curso?.titulo}</p>
                          ) :
                            <></>
                          }

                          <p>Serviço: {item.produtos?.map((item: any) => (`${item.servico?.titulo} `))}</p>
                        </div>
                      ))
                    }
                  </>
                  :
                  <></>
                }

              </div>
            </Card>
            :
            <></>
          }
        </>
      }
    </Container>
  );
};

export default Main;
