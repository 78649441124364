import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { FiTrash, FiPlus, FiEdit } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  TextField,
  ClearButton
} from './styles';

interface ProdutosInterface {
  id: string;
  titulo: string;
  projeto: any;
  modelo: any;
  profissional: any;
  composicao: string;
  descricao: string;
  data_inicio_vigencia: string;
  data_final_vigencia: string;
  unidade_descricao: string;
  unidade_limite: string;
  valor: string;
}

const Produtos: React.FC = () => {
  const history = useHistory();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal)

  const [loading, setLoading] = useState(true);
  const [produtos, setProdutos] = useState<ProdutosInterface[]>([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filteredItems, setFilteredItems] = useState<ProdutosInterface[]>([]);

  useEffect(() => {
    getProdutos();
  }, []);

  useEffect(() => {
    if (clearSelectedRows) {
      setClearSelectedRows(false)
    }
  }, [clearSelectedRows]);

  // useEffect(() => {
  //   if (!produtos) return;

  //   let search = filterText.toLowerCase();

  //   let result = produtos.filter(
  //     item => item.titulo.toLowerCase().includes(search)
  //   );

  //   setFilteredItems(result);
  // }, [filterText, produtos])


  const filtered = useMemo(() => {
    if (!filterText)
      return produtos

    let search = filterText.toLowerCase();

    return produtos.filter(produto => {
      return produto.titulo.toLowerCase().includes(search) ||
        produto.projeto?.titulo.toLowerCase().includes(search) ||
        produto.modelo?.titulo.toLowerCase().includes(search) ||
        produto.profissional?.titulo.toLowerCase().includes(search)
    })
  }, [filterText, produtos])


  const getProdutos = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/produto`);
      //console.log("response", response.data);

      setProdutos(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const removeSelectedRows = useCallback(() => {
    MySwal.fire({
      // title: 'Tem certeza que deseja remover?',
      showCancelButton: true,
      confirmButtonText: `Remover`,
      confirmButtonColor: '#1CAEBD',
      cancelButtonColor: '#312e38',
      cancelButtonText: 'Cancelar',
      // icon: 'warning',
      html: '<div style="font-size:20px">Tem certeza que deseja remover?</div>',
      // position: 'top'
    }).then((result) => {
      if (result.isConfirmed) {
        selectedRows.map((row: any) => {
          removeRow(row.id);
        });
      }
    })
  }, [selectedRows]);

  const removeRow = useCallback(async (rowId) => {
    //console.log("remove", rowId);
    setLoading(true);

    try {
      await api.delete(`/produto/${rowId}`);

      setClearSelectedRows(true);

      addToast({
        type: 'success',
        title: 'Produto removido com sucesso',
        description: '',
      });

      getProdutos();
    } catch (err) {
      for (let error in err.response?.data) {
        addToast({
          type: 'error',
          title: 'Erro ao remover produto',
          description: err.response?.data[error] ?
            err.response.data[error] :
            'Erro ao remover produto, tente novamente mais tarde.',
        });
      }
    }
  }, []);

  function goStoreForm() {
    history.push("/produtos/cadastro");
  }

  function goUpdateForm() {
    history.push("/produtos/edicao", {
      selectedItem: selectedRows[0]
    });
  }

  const handleChange = (state: any) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    //console.log('Selected Rows: ', state.selectedRows);

    setSelectedRows(state.selectedRows);
  };

  const actions = (
    <FiPlus className="icon" onClick={() => {
      goStoreForm();
    }} />
  );

  const contextActions = (
    <>
      {(selectedRows.length > 0 && selectedRows[0].ativo) ?
        <>
          {selectedRows.length === 1 &&
            <FiEdit className="icon" style={{ marginRight: "15px" }} onClick={() => {
              goUpdateForm();
            }} />
          }
          <FiTrash className="icon" style={{ marginRight: "10px" }} onClick={() => {
            removeSelectedRows();
          }} />
        </>
        :
        <></>
      }
    </>
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <>
        <TextField id="search" type="text" placeholder="Pesquisar" aria-label="Search Input" value={filterText} onChange={(e: any) => setFilterText(e.target.value)} />
        <ClearButton type="button" onClick={handleClear}>x</ClearButton>
      </>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: 'Título',
      selector: 'titulo',
      sortable: false,
      // right: true,
    },
    {
      name: 'Modelo',
      selector: 'modelo.titulo',
      sortable: false,
      // right: true,
    },
    {
      name: 'Profissional',
      selector: 'profissional.titulo',
      sortable: false,
      // right: true,
    },
    {
      name: 'Valor',
      selector: 'valor',
      sortable: false,
      // right: true,
      hide: 1240,
      cell: (row: any) => (
        <p>R$ {parseFloat(row.valor).toFixed(2).replace(".", ",")}</p>
      )
    },
    {
      name: 'Projeto',
      selector: 'projeto.titulo',
      sortable: false,
      // right: true,
    },

    {
      name: 'Ativo',
      selector: 'ativo',
      sortable: false,
      // right: true,
      cell: (row: any) => {
        if (row.ativo) {
          return (
            <p>Sim</p>
          )
        } else {
          return (
            <p style={{ color: 'red' }}>Não</p>
          )
        }
      }
    },

  ];

  return (
    <Container>
      <Content>
        <Card>
          <DataTable
            title="Produtos"
            columns={columns}
            data={filtered}
            selectableRows
            highlightOnHover
            onSelectedRowsChange={handleChange}
            defaultSortField="titulo"
            pagination
            actions={actions}
            contextActions={contextActions}
            noDataComponent="Nenhum registro encontrado."
            paginationComponentOptions={{
              rowsPerPageText: 'Itens por página',
              rangeSeparatorText: 'de'
            }}
            clearSelectedRows={clearSelectedRows}
            contextMessage={{
              singular: 'item selecionado',
              plural: 'itens selecionados',
              message: ''
            }}
            progressComponent={<Loading />}
            progressPending={loading}
            subHeader
            subHeaderComponent={subHeaderComponent}
            paginationResetDefaultPage={resetPaginationToggle}
          />
        </Card>
      </Content>
    </Container>
  );
};

export default Produtos;
