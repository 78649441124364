import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiTrash, FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import Switch from "react-switch";
import Select from 'react-select';
import { useDropzone } from 'react-dropzone';
import slugify from 'slugify';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import FormInput from '../../components/FormInput';
// import FormInputMask from '../../components/FormInputMask';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem,
  DropzoneContainer
} from './styles';

interface ProjetosFormData {
  id: string;
  titulo: string;
}

interface ModelosFormData {
  id: string;
  titulo: string;
}

interface ServicosFormData {
  id: string;
  titulo: string;
}

interface OptionsData {
  value: any;
  label: string;
}

const ArquivosTreinamentoCadastro: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [ativo, setAtivo] = useState(false);
  const [treinamento, setTreinamento] = useState(false);
  const [obrigatorio, setObrigatorio] = useState(false);
  const [projetos, setProjetos] = useState<ProjetosFormData[]>([]);
  const [optionsProjetos, setOptionsProjetos] = useState<OptionsData[]>([]);
  const [selectedProjeto, setSelectedProjeto] = useState<OptionsData>({ value: "", label: "" });
  const [modelos, setModelos] = useState<ModelosFormData[]>([]);
  const [optionsModelos, setOptionsModelos] = useState<OptionsData[]>([]);
  const [selectedModelo, setSelectedModelo] = useState<OptionsData>({ value: "", label: "" });
  const [selectedTipo, setSelectedTipo] = useState<OptionsData>({ value: "", label: "" });
  const [servicos, setServicos] = useState<ServicosFormData[]>([]);
  const [optionsServicos, setOptionsServicos] = useState<OptionsData[]>([]);
  const [selectedServico, setSelectedServico] = useState<OptionsData>({ value: "", label: "" });
  const [uploadedFile, setUploadedFile] = useState<any>(null);

  useEffect(() => {
    getProjetos();
    getModelos();
    getServicos();
  }, []);

  useEffect(() => {
    if (projetos.length === 0) return;

    var options: OptionsData[] = [];

    projetos.forEach(item => {
      options.push({ value: item.id, label: item.titulo });
    });

    setOptionsProjetos(options);
    //console.log("options projetos", options);
  }, [projetos]);

  useEffect(() => {
    if (modelos.length === 0) return;

    var options: OptionsData[] = [];

    modelos.forEach(item => {
      options.push({ value: item.id, label: item.titulo });
    });

    setOptionsModelos(options);
    //console.log("options modelos", options);
  }, [modelos]);

  useEffect(() => {
    if (servicos.length === 0) return;

    var options: OptionsData[] = [];

    servicos.forEach(item => {
      options.push({ value: item.id, label: item.titulo });
    });

    setOptionsServicos(options);
    //console.log("options servicos", options);
  }, [servicos]);

  const getProjetos = useCallback(async () => {
    const response = await api.get(`/projeto`);
    //console.log("projetos", response.data);

    setProjetos(response.data);
  }, []);

  const getModelos = useCallback(async () => {
    const response = await api.get(`/modelo`);
    //console.log("modelos", response.data);

    setModelos(response.data);
  }, []);

  const getServicos = useCallback(async () => {
    const response = await api.get(`/servico`);
    //console.log("servicos", response.data);

    setServicos(response.data);
  }, []);

  const createSlug = useCallback((name) => {
    let slug = slugify(name);
    return slug.toLowerCase();
  }, []);

  const onDrop = useCallback(async acceptedFiles => {
    try {
      //console.log("onDrop", acceptedFiles);

      setUploadedFile(acceptedFiles[0]);

      let nome = acceptedFiles[0].name;
      nome = nome.split('.').shift();

      setTimeout(() => {
        formRef.current?.setFieldValue("arquivo_nome", createSlug(nome));
      }, 400);
    } catch (error) {
      //console.log("upload error", error);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSubmit = useCallback(
    async (data: any) => {
      if (!data.titulo) {
        addToast({
          type: 'error',
          title: 'Preencha o título',
          description: ''
        });
        return;
      }

      if (!data.descricao) {
        addToast({
          type: 'error',
          title: 'Preencha a descrição',
          description: ''
        });
        return;
      }

      // if (!data.prioridade) {
      //   addToast({
      //     type: 'error',
      //     title: 'Preencha a prioridade',
      //     description: ''
      //   });
      //   return;
      // }

      // if (!(selectedProjeto.value)) {
      //   addToast({
      //     type: 'error',
      //     title: 'Selecione o projeto',
      //     description: ''
      //   });
      //   return;
      // }

      // if (!(selectedModelo.value)) {
      //   addToast({
      //     type: 'error',
      //     title: 'Selecione o modelo',
      //     description: ''
      //   });
      //   return;
      // }

      if (!(selectedTipo.value)) {
        addToast({
          type: 'error',
          title: 'Selecione o tipo',
          description: ''
        });
        return;
      }

      if (selectedTipo.value === "arquivo" && !data.arquivo_nome) {
        addToast({
          type: 'error',
          title: 'Preencha o nome do arquivo',
          description: ''
        });
        return;
      }

      if (selectedTipo.value === "arquivo" && !uploadedFile) {
        addToast({
          type: 'error',
          title: 'Faça o upload do arquivo',
          description: ''
        });
        return;
      }

      if (selectedTipo.value === "link" && !data.link_url) {
        addToast({
          type: 'error',
          title: 'Preencha a url do link',
          description: ''
        });
        return;
      }

      if (selectedTipo.value === "video" && !data.video_url) {
        addToast({
          type: 'error',
          title: 'Preencha a url do vídeo',
          description: ''
        });
        return;
      }

      if (selectedTipo.value === "treinamento" && !data.video_url) {
        addToast({
          type: 'error',
          title: 'Preencha a url do vídeo',
          description: ''
        });
        return;
      }

      try {
        //console.log("handleSubmit", data);

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          titulo: Yup.string().required('Título obrigatório'),
          descricao: Yup.string().required('Descrição obrigatória'),
          // prioridade: Yup.string().required('Prioridade obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        //console.log(data);

        // const params:any = {
        //   titulo: data.titulo,
        //   descricao: data.descricao,
        //   id_projeto: (selectedProjeto && selectedProjeto.value) ? selectedProjeto.value : null,
        //   id_modelo: (selectedModelo && selectedModelo.value) ? selectedModelo.value : null,
        //   id_servico: (selectedServico && selectedServico.value) ? selectedServico.value : null,
        //   treinamento: selectedTipo.value === "treinamento" ? true : false,
        //   obrigatorio,
        //   ativo
        // }

        // if(selectedTipo.value === "arquivo"){
        //   params.arquivo_nome = data.arquivo_nome;
        // }

        // if(selectedTipo.value === "link"){
        //   params.link_url = data.link_url;
        // }

        // if(selectedTipo.value === "video"){
        //   params.video_url = data.video_url;
        // }

        // if(selectedTipo.value === "treinamento"){
        //   params.video_url = data.video_url;
        // }

        //console.log("params", params);

        const formData = new FormData();

        formData.append("titulo", data.titulo);
        formData.append("descricao", data.descricao);
        formData.append("prioridade", data.prioridade);
        formData.append("id_projeto", (selectedProjeto && selectedProjeto.value) ? selectedProjeto.value : null);
        formData.append("id_modelo", (selectedModelo && selectedModelo.value) ? selectedModelo.value : null);
        formData.append("id_servico", (selectedServico && selectedServico.value) ? selectedServico.value : null);
        formData.append("treinamento", selectedTipo.value === "treinamento" ? "1" : "0");
        formData.append("obrigatorio", obrigatorio ? "1" : "0");
        formData.append("ativo", ativo ? "1" : "0");

        if (selectedTipo.value === "arquivo") {
          formData.append("upload", uploadedFile);
          formData.append("arquivo_nome", data.arquivo_nome);
        }

        if (selectedTipo.value === "link") {
          formData.append("link_url", data.link_url);
        }

        if (selectedTipo.value === "video") {
          formData.append("video_url", data.video_url);
        }

        if (selectedTipo.value === "treinamento") {
          formData.append("video_url", data.video_url);
        }

        //console.log("formData", formData);

        await api.post(`/projeto-midia`, formData);

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
          description: '',
        });

        goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao cadastrar',
            description: err.response?.data[error] ?
              err.response.data[error] :
              'Erro ao cadastrar, tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, selectedProjeto, selectedModelo, selectedTipo, selectedServico, ativo, obrigatorio, uploadedFile],
  );

  function handleChangeSelectedTipo(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedTipo({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedTipo({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue", newValue.value);

    setSelectedTipo(newValue);
  };

  function handleChangeSelectedProjeto(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedProjeto({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedProjeto({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue", newValue.value);

    setSelectedProjeto(newValue);
  };

  function handleChangeSelectedModelo(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedModelo({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedModelo({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue", newValue.value);

    setSelectedModelo(newValue);
  };

  function handleChangeSelectedServico(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedServico({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedServico({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue", newValue.value);

    setSelectedServico(newValue);
  };

  function goBack() {
    history.push('/arquivos-treinamento');
  }

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <Container>
      <Content>
        <Card>
          <div className="form">
            <div className="header">
              <h2>Cadastrar Arquivo Treinamento</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            <Form ref={formRef} onSubmit={handleSubmit}>

              <WrapRow style={{ marginTop: "0px" }}>
                <WrapRowItem>
                  <FormInput
                    name="titulo"
                    type="text"
                    placeholder="Título"
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow style={{ marginTop: "10px" }}>
                <WrapRowItem>
                  <FormInput
                    name="descricao"
                    type="text"
                    placeholder="Descrição"
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow style={{ marginTop: "10px" }}>
                <WrapRowItem>
                  <FormInput
                    name="prioridade"
                    type="number"
                    placeholder="Prioridade"
                    min={1}
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem style={{ marginTop: "15px" }}>
                  <div style={{ color: '#666', marginBottom: '5px', }}>Projeto:</div>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedProjeto}
                    options={optionsProjetos}
                    value={selectedProjeto}
                    isClearable
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem style={{ marginTop: "30px" }}>
                  <div style={{ color: '#666', marginBottom: '5px', }}>Modelo:</div>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedModelo}
                    options={optionsModelos}
                    value={selectedModelo}
                    isClearable
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem style={{ marginTop: "30px" }}>
                  <div style={{ color: '#666', marginBottom: '5px', }}>Serviço relacionado:</div>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedServico}
                    options={optionsServicos}
                    value={selectedServico}
                    isClearable
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem style={{ marginTop: "30px" }}>
                  <div style={{ color: '#666', marginBottom: '5px', }}>Tipo:</div>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedTipo}
                    options={[
                      { value: "arquivo", label: "Arquivo" },
                      { value: "link", label: "Link" },
                      // {value: "video", label: "Vídeo"},
                      { value: "treinamento", label: "Treinamento" }
                    ]}
                    value={selectedTipo}
                  />
                </WrapRowItem>
              </WrapRow>

              {selectedTipo.value === "arquivo" &&
                <>
                  <WrapRow style={{ marginTop: "30px" }}>
                    <WrapRowItem>
                      {uploadedFile ?
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                            <FormInput
                              name="arquivo_upload"
                              type="text"
                              placeholder="Upload do arquivo"
                              value={uploadedFile.name}
                              disabled
                            />
                          </div>

                          <FiTrash className="icon" style={{ margin: "20px", marginTop: "48px" }} onClick={() => {
                            setUploadedFile(null);
                          }} />
                        </div>
                        :
                        <>
                          <div style={{ color: '#666', marginBottom: '5px', }}>Upload do arquivo:</div>
                          <DropzoneContainer {...getRootProps()}>
                            <input {...getInputProps()} />
                            {
                              isDragActive ?
                                <p>Coloque seu arquivo aqui ...</p> :
                                <p>Arraste o arquivo, ou clique para selecioná-lo.</p>
                            }
                          </DropzoneContainer>
                        </>
                      }
                    </WrapRowItem>
                  </WrapRow>

                  {uploadedFile &&
                    <WrapRow style={{ marginTop: "20px" }}>
                      <WrapRowItem>
                        <FormInput
                          name="arquivo_nome"
                          type="text"
                          placeholder="Nome do arquivo"
                          onChange={(evt) => {
                            formRef.current?.setFieldValue("arquivo_nome", createSlug(evt.target.value));
                          }}
                        />
                      </WrapRowItem>
                    </WrapRow>
                  }
                </>
              }

              {selectedTipo.value === "link" &&
                <>
                  <WrapRow style={{ marginTop: "30px" }}>
                    <WrapRowItem>
                      <FormInput
                        name="link_url"
                        type="text"
                        placeholder="Link URL"
                      />
                    </WrapRowItem>
                  </WrapRow>
                </>
              }

              {selectedTipo.value === "video" &&
                <>
                  <WrapRow style={{ marginTop: "30px" }}>
                    <WrapRowItem>
                      <FormInput
                        name="video_url"
                        type="text"
                        placeholder="Vídeo URL"
                      />
                    </WrapRowItem>
                  </WrapRow>
                </>
              }

              {selectedTipo.value === "treinamento" &&
                <>
                  <WrapRow style={{ marginTop: "30px" }}>
                    <WrapRowItem>
                      <FormInput
                        name="video_url"
                        type="text"
                        placeholder="Vídeo URL"
                      />
                    </WrapRowItem>
                  </WrapRow>
                </>
              }

              <WrapRow style={{ marginTop: "40px" }}>
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  offColor={'#e6e6e6'}
                  onColor={'#2FB4C2'}
                  onChange={() => { setObrigatorio(!obrigatorio) }}
                  checked={obrigatorio}
                />
                <span className="text-switch">Conteúdo obrigatório</span>
              </WrapRow>

              <WrapRow style={{ marginTop: "30px" }}>
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  offColor={'#e6e6e6'}
                  onColor={'#2FB4C2'}
                  onChange={() => { setAtivo(!ativo) }}
                  checked={ativo}
                />
                <span className="text-switch">Ativo</span>
              </WrapRow>

              <Button type="submit" loading={loading} style={{ marginTop: "40px" }}>
                Cadastrar
              </Button>

            </Form>
          </div>
        </Card>
      </Content>
    </Container>
  );
};

export default ArquivosTreinamentoCadastro;
