import React, { useRef, useCallback, useState, useEffect, useMemo } from 'react';
import { FiCheckSquare, FiCopy, FiPlus, FiSearch, FiSend, FiSquare, FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ReactTooltip from 'react-tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Select from 'react-select';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import FormInput from '../../components/FormInput';
// import FormInputMask from '../../components/FormInputMask';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Loading from '../../components/Loading';

import DataTable from 'react-data-table-component';


import api from '../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem,
  TextField,
  ClearButton
} from './styles';

interface ConvidarUsuarioFormData {
  nome: string;
  email: string;
  hash: string
}

interface ProjetosFormData {
  id: string;
  titulo: string;
}

interface OptionsData {
  value: any;
  label: string;
}

const ConvidarUsuario: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const MySwal = withReactContent(Swal)

  const { selectedClient, clients } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);

  const [convites, setConvites] = useState<ConvidarUsuarioFormData[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [projetos, setProjetos] = useState<ProjetosFormData[]>([]);
  const [optionsProjetos, setOptionsProjetos] = useState<OptionsData[]>([]);
  const [selectedProjeto, setSelectedProjeto] = useState<OptionsData>({ value: "", label: "" });
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [showAllConvites, setShowAllConvites] = useState(false);
  const [showFilter, setShowFilter] = useState(true);
  const [resendConvite, setResendConvite] = useState(false);
  const [optionsClients, setOptionsClients] = useState<OptionsData[]>([]);
  const [selectedOptClient, setSelectedOptClient] = useState<OptionsData>({} as OptionsData);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState<any>(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    getConvites();

    if(showAllConvites === true){
      setResendConvite(false);
    }else{
      setResendConvite(true);
    }
  }, [showAllConvites]);

  // useEffect(() => {
  //   getClientes();
  // }, []);

  useEffect(() => {
    if(!selectedClient) return;

    const optClients = clients.map((item:any) => {
      return {
        value: item.id,
        label: item.nome
      }
    })

    if(optClients){

      const clients_filtered = optClients.filter((v:any,i:any,a:any)=>a.findIndex((v2:any)=>(v.label === v2.label && v.value===v2.value))===i)

      setOptionsClients(clients_filtered);

      setSelectedOptClient({
        value: selectedClient.id,
        label: selectedClient.nome
      })
    }

    //console.log("optClients", optClients)
  }, [clients, selectedClient]);

  useEffect(() => {
    if(!selectedOptClient) return;

    getProjetos();
  }, [selectedOptClient]);

  useEffect(() => {
    if (clearSelectedRows) {
      setClearSelectedRows(false)
    }
  }, [clearSelectedRows]);

  useEffect(() => {
    // if (projetos.length === 0) return;

    var options: OptionsData[] = [];

    projetos.forEach(item => {
      options.push({ value: item.id, label: item.titulo });
    });

    setOptionsProjetos(options);
    //console.log("options projetos", options);
  }, [projetos]);

  useEffect(() => {
    getConvites(page);
  }, [rowsPerPage])

  const getConvites = useCallback(async (currentPage = 1) => {
    setLoading(true);

    // console.log("showAllConvites", showAllConvites);

    const params = { 
      especialista: 1,
      todos_convites: showAllConvites ? 1 : 0,
      page: currentPage,
      per_page: rowsPerPage,
      busca: filterText,
    }

    // console.log("params", params);

    try {
      const response = await api.get(`/convite`, { params });

      setConvites(response?.data?.data);
      setTotalRows(response?.data?.total)

      // console.log("convite", response.data);

      formRef.current?.setFieldValue("nome", "");
      formRef.current?.setFieldValue("email", "");
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, [showAllConvites, rowsPerPage, filterText]);

  // const getClientes = useCallback(async () => {
  //   setLoading(true);

  //   try {
  //     const response = await api.get(`/cliente`);
  //     //console.log("response", response.data);

  //     setOptionsClients(response.data.map((item:any) => {
  //       return {
  //         value: item.id,
  //         label: item.nome
  //       }
  //     }));
  //   } catch (error) {
  //     //console.log(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, []);

  const getProjetos = useCallback(async () => {
    if(!(selectedOptClient && selectedOptClient.value)) return;

    const response = await api.get(`/projeto`, { params: { id_cliente: selectedOptClient.value } });
    // console.log("projetos", response.data);

    setProjetos(response.data);
  }, [selectedOptClient]);

  const handleSubmit = useCallback(
    async (data: ConvidarUsuarioFormData) => {
      if (!(selectedProjeto.value)) {
        addToast({
          type: 'error',
          title: 'Selecione o projeto que o especialista será vinculado',
          description: ''
        });
        return;
      }

      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório').test('len', 'Digite um nome válido', val => val.length >= 3),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido')
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const params = { 
          ...data, 
          id_projeto: selectedProjeto.value,
          // redirect_url: 'https://contrato.ensineme.com.br/signup',
          // redirect_url: "http://localhost:3000/signup" 
          redirect_url: `https://${window.location.hostname}/signup`,
        }

        //console.log("params", params);

        await api.post(`convite`, params);

        getConvites();

        addToast({
          type: 'success',
          title: 'Convite criado com sucesso',
          description: '',
        });
        backToIndex();
        formRef.current?.setFieldValue("nome", "");
        formRef.current?.setFieldValue("email", "");
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        //console.log(err.response);

        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao criar convite',
            description: (err.response?.data[error] ?
            err.response?.data[error].message :
            'Erro ao criar convite, tente novamente mais tarde.'),
          });
        }

      } finally {
        setLoading(false);
      }
    },
    [history, addToast, selectedProjeto],
  );

  function handleChangeSelectedClient(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedOptClient({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedOptClient({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue", newValue.value);

    setSelectedOptClient(newValue);
  };

  const resendInvites = useCallback(
    async (hashs) => {
      try {
        setLoading(true);
        
        const params = { 
          hashs: hashs, 
          // redirect_url: 'https://contrato.ensineme.com.br/signup',
          // redirect_url: "http://localhost:3000/signup" 
          redirect_url: `https://${window.location.hostname}/signup`,
        }

        //console.log("params", params);

        await api.put(`convite/0`, params);

        getConvites();

        addToast({
          type: 'success',
          title: 'Convite(s) enviado(s) com sucesso',
          description: '',
        });
      } catch (err) {
        //console.log(err.response);

        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao enviar convite(s)',
            description: (err.response?.data[error] ?
            err.response?.data[error].message :
            'Erro ao enviar convite(s), tente novamente mais tarde.'),
          });
        }

      } finally {
        setLoading(false);
      }
    },
    [history, addToast],
  );

  const handleChange = (state: any) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    //console.log('Selected Rows: ', state.selectedRows);

    setSelectedRows(state.selectedRows);
  };

  function handleChangeSelectedProjeto(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedProjeto({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedProjeto({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue", newValue.value);

    setSelectedProjeto(newValue);
  };

  function goToAddForm() {
    setShowAddForm(true);
  }

  function backToIndex() {
    setShowAddForm(false);
  }

  const actions = (
    <FiPlus className="icon" onClick={() => {
      goToAddForm();
    }} />
  );

  const confirmResendInvites = useCallback(() => {
    MySwal.fire({
      // title: 'Tem certeza que deseja remover?',
      showCancelButton: true,
      confirmButtonText: `Enviar Convite(s)`,
      confirmButtonColor: '#1CAEBD',
      cancelButtonColor: '#312e38',
      cancelButtonText: 'Cancelar',
      // icon: 'warning',
      html: '<div style="font-size:20px">Tem certeza que deseja enviar os convites selecionados novamente?</div>',
      // position: 'top'
    }).then((result) => {
      if (result.isConfirmed) {
        //console.log("selectedRows", selectedRows.map((item:any) => item.hash));
        resendInvites(selectedRows.map((item:any) => item.hash));
      }
    })
  }, [selectedRows]);

  const contextActions = (
    <>
      { resendConvite &&
        <button type="button" style={{ marginRight: "10px" }} className="button-top-datatable" onClick={() => {
          confirmResendInvites();
        }}>
          Enviar Convite
        </button>
      }
    </>
  );

  const filtered = useMemo(() => {
    if (!filterText)
      return convites

    let search = filterText.toLowerCase();

    search = search.split("-").join("");
    search = search.split(".").join("");

    search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

    return convites.filter((item:any) => {
      return item.nome.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        item.email.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        item.hash.toLowerCase().normalize('NFD').includes(search)
    })
  }, [filterText, convites])

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const handleSearch = () => {
      setResetPaginationToggle(!resetPaginationToggle);
      setLoading(true);
      setConvites([]);
      getConvites(1);
    };

    return (
      <div style={{display:"flex", width:"100%"}}>
        { showFilter ?
          <>
            <div
              className="table-subheader-button"
              onClick={() => {
                setShowAllConvites(false);
              }}
            >
              { showAllConvites ?
                <FiSquare />
              :
                <FiCheckSquare />
              }
              <span>Criados por mim</span>
            </div>
            <div
              className="table-subheader-button"
              onClick={() => {
                setShowAllConvites(true);
              }}
            >
              { showAllConvites ?
                <FiCheckSquare />
              :
                <FiSquare />
              }
              <span>Todos</span>
            </div>
          </>
        :
          <div></div>
        }

        <div style={{flex:1}}></div>
          
        {/* <TextField id="search" type="text" placeholder="Pesquisar" aria-label="Search Input" value={filterText} onChange={(e: any) => setFilterText(e.target.value)} /> */}
        {/* <ClearButton type="button" onClick={handleClear}>x</ClearButton> */}

        <TextField
          id="search"
          type="text"
          placeholder="Pesquisar"
          aria-label="Search Input"
          value={filterText}
          onChange={(e: any) => setFilterText(e.target.value)}
          onKeyPress={(event: any) => {
            if(event.key === 'Enter'){
              handleSearch();
            }
          }}
        />

        <ClearButton type="button" onClick={handleSearch}>
          <FiSearch />
        </ClearButton>
      </div>
    );
  }, [filterText, resetPaginationToggle, showAllConvites, showFilter]);

  const columns = [
    {
      name: 'Hash',
      selector: 'hash',
      sortable: false,
      // right: true,
      hide: 920,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: false,
      // right: true,
    },
    {
      name: 'Nome',
      selector: 'nome',
      sortable: false,
      // right: true,
    },
    {
      name: 'Situação',
      selector: 'status',
      sortable: false,
      // right: true,
      hide: 570,
      cell: (row: any) => <div style={{
        color: "#fff",
        backgroundColor: (row.status == 'validação email' ? "#F09B0E"
          : (row.status == 'usuário ativo' ? "#95B759" : "#E94F51")),

        padding: "5px",
        borderRadius: "3px",
        width: '110px',
        textAlign: 'center'
      }}>{row.status}</div>,
    },
    {
      name: 'Ações',
      button: true,
      cell: (row: any) => (
        <div className="wrapper-table-actions">
          
          <CopyToClipboard text={`Você está sendo convidado a se cadastrar no sistema Flow para acompanhar os seus contratos. Para se cadastrar acesse https://${window.location.hostname}/signup?hash=${row.hash}, seu código de acesso é ${row.hash}.`}
            onCopy={() => {
              //console.log("copied"); 
              addToast({
                type: 'success',
                title: 'Copiado para sua Área de Transferência',
                description: '',
              });
            }}>
            <div className={`table-action`}>
              <FiCopy data-tip data-for='tipCopy' />
            </div>
          </CopyToClipboard>

          <ReactTooltip id='tipCopy' effect='solid'>
            <span>Copiar para Área de Transferência</span>
          </ReactTooltip>
        </div>
      ),
    },
  ];

  return (
    <Container>
      <Content>
        {!showAddForm ?
          <Card>
            <DataTable
              title="Convites Enviados"
              columns={columns}
              data={convites}
              highlightOnHover
              defaultSortField="name"
              pagination
              selectableRows
              onSelectedRowsChange={handleChange}
              selectableRowDisabled={(row:any) => row.utilizado}
              actions={actions}
              contextActions={contextActions}
              subHeader
              subHeaderComponent={subHeaderComponent}
              noDataComponent="Nenhum registro encontrado."
              paginationComponentOptions={{
                rowsPerPageText: 'Itens por página',
                rangeSeparatorText: 'de'
              }}
              clearSelectedRows={clearSelectedRows}
              contextMessage={{
                singular: 'item selecionado',
                plural: 'itens selecionados',
                message: ''
              }}
              progressComponent={<Loading />}
              progressPending={loading}

              paginationResetDefaultPage={resetPaginationToggle}
              responsive={true}
              
              onChangePage={(value:any) => {
                setLoading(true);
                //console.log("page:", value);
                setPage(value);
                getConvites(value);
              }}
              onSort={(column:any, sort_direction:any) => {
                setLoading(true);
                //console.log("sortBy:", column, sort_direction);
                setSortBy(column);
                setSortDirection(sort_direction);
              }}
              onChangeRowsPerPage={(value:any) => {
                setLoading(true);
                // console.log("rowsPerPage:", value);
                setRowsPerPage(value);
              }}
              paginationTotalRows={totalRows}
              paginationDefaultPage={1}
              paginationServer
            />
          </Card>
          :


          <Form ref={formRef} onSubmit={handleSubmit}>
            <Card>
              <div className="header">
                <h2>Convidar Especialista</h2>
                <FiX onClick={() => backToIndex()} className="icon" />
              </div>
              <WrapRow>
                <WrapRowItem>
                  <FormInput
                    name="nome"
                    type="text"
                    placeholder="Nome"
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem>
                  <FormInput
                    name="email"
                    type="text"
                    placeholder="E-mail"
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem style={{ marginTop: "20px", marginBottom: "30px" }}>
                  <div style={{ color: '#666', marginBottom: '5px', }}>Selecione o cliente:</div>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedClient}
                    options={optionsClients}
                    value={selectedOptClient}
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem style={{ marginTop: "10px", marginBottom: "30px" }}>
                  <div style={{ color: '#666', marginBottom: '5px', }}>Projeto:</div>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedProjeto}
                    options={optionsProjetos}
                    value={selectedProjeto}
                  />
                </WrapRowItem>
              </WrapRow>

            </Card>

            <Button type="submit" loading={loading}>
              Criar convite
          </Button>

          </Form>
        }
      </Content>
    </Container>
  );
};

export default ConvidarUsuario;
