import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import FormInput from '../../components/FormInput';
// import FormInputMask from '../../components/FormInputMask';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem,
} from './styles';

interface AreaDeAtuacaoFormData {
  id: string;
  nome: string;
  colaboradores: any;
}

interface ColaboradoresFormData {
  id: string;
  nome: string;
  email: string;
}

interface AreasConhecimentoFormData {
  id: string;
  descricao: string;
}

interface OptionsData {
  value: string;
  label: string;
}

const AreasAtuacaoCadastro: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [colaboradores, setColaboradores] = useState<ColaboradoresFormData[]>([]);
  const [optionsColaboradores, setOptionsColaboradores] = useState<OptionsData[]>([]);
  const [selectedColaborador, setSelectedColaborador] = useState<OptionsData[]>([]);
  const [optionsClientes, setOptionsClientes] = useState<OptionsData[]>([]);
  const [selectedCliente, setSelectedCliente] = useState<OptionsData>({} as OptionsData);

  useEffect(() => {
    getColaboradores();
    getClientes();
  }, []);

  useEffect(() => {
    if (colaboradores.length === 0) return;

    var options: OptionsData[] = [];

    colaboradores.map(item => {
      options.push({ value: item.id, label: item.nome ? `${item.nome} (${item.email})` : item.email });
    });

    setOptionsColaboradores(options);
    //console.log("options", options);
  }, [colaboradores]);

  
  const getColaboradores = useCallback(async () => {
    const response = await api.get(`/busca-usuario`, { params: { 
      papel: [
        "ensino-adm", 
        "ensino-gestor-da-area",
        "ensino-administrador", 
        "medicina-adm", 
        "medicina-gestor-da-area",
        "medicina-administrador",
        "pos-graduacao-adm", 
        "pos-graduacao-gestor-da-area",
        "pos-graduacao-administrador",
        "premium-adm", 
        "premium-gestor-da-area",
        "premium-administrador",
        "educare-adm", 
        "educare-gestor-da-area",
        "educare-administrador"
      ]
    }});
    
    //console.log("Colaboradores", response.data);

    setColaboradores(response.data);
  }, []);

  
  const getClientes = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/cliente`);
      //console.log("response", response.data);

      setOptionsClientes(response.data.map((item:any) => {
        return {
          value: item.id,
          label: item.nome
        }
      }));
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: AreaDeAtuacaoFormData) => {
      try {
        //console.log("handleSubmit", data);

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Título obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let params = {
          nome: data.nome,
          atuadores: selectedColaborador ? selectedColaborador.map((item) => item.value) : [],
          id_cliente: selectedCliente.value
        }

        //console.log("params", params);

        await api.post(`/area-atuacao`, params);

        addToast({
          type: 'success',
          title: 'Área de Atuação cadastrada com sucesso',
          description: '',
        });

        goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }
        
        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao cadastrar curadoria',
            description: err.response?.data[error] ?
              err.response.data[error] :
              'Erro ao cadastrar curadoria, tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, goBack, selectedCliente],
  );

  function handleChangeSelectedCliente(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedCliente({} as OptionsData);
      return;
    };

    if (newValue.length === 0) {
      setSelectedCliente({} as OptionsData);
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue tema", newValue.value);

    setSelectedCliente(newValue);
  };

  function goBack() {
    history.push('/area-atuacao');
  }

  function handleChangeSelectedColaborador(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedColaborador([]);
      return;
    };

    if (newValue.length === 0) {
      setSelectedColaborador([]);
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue tags", newValue.value);

    setSelectedColaborador(newValue);
  };

  if(loading){
    return (
      <Loading />
    )
  }

  return (
    <Container>
      <Content>
        <Card>
          <div className="form">
            <div className="header">
              <h2>Cadastrar Área de Atuação</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            <Form ref={formRef} onSubmit={handleSubmit}>
              
            

                <WrapRow style={{ marginBottom: "20px" }}>
                  <WrapRowItem>
                    <FormInput
                      name="nome"
                      type="text"
                      placeholder="Título"
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow style={{ marginTop: "10px", marginBottom: "30px" }}>
                  <WrapRowItem>
                    <label>Cliente:</label>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedCliente}
                    options={optionsClientes}
                    value={selectedCliente}
                  />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow style={{ marginTop: "10px", marginBottom: "30px" }}>
                  <WrapRowItem>
                    <label>Colaboradores:</label>
                    <Select
                      isMulti
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedColaborador}
                      options={optionsColaboradores}
                      value={selectedColaborador}
                    />
                  </WrapRowItem>
                </WrapRow>


                <Button type="submit" loading={loading}>
                  Cadastrar
            </Button>

              </Form>
          </div>
        </Card>
      </Content>
    </Container>
  );
};

export default AreasAtuacaoCadastro;
