import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import FormInput from '../../components/FormInput';
// import FormInputMask from '../../components/FormInputMask';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem,
} from './styles';

interface TiposArquivoFormData {
  id: string;
  titulo: string;
  descricao: string;
  tipo: string;
}

interface OptionsData {
  value: string;
  label: string;
}

const TiposArquivoEdicao: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location:any = useLocation();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [optionsTipos,] = useState<OptionsData[]>([
    { value: "contrato_pj", label: "Documentos Contrato PJ" },
    { value: "documento_contrato", label: "Documentos do Contrato" },
  ]);
  const [selectedTipo, setSelectedTipo] = useState<OptionsData>({ value: "", label: "" });
  const [selectedItem, setSelectedItem] = useState<TiposArquivoFormData>({} as TiposArquivoFormData);

  useEffect(() => {
    if(location && location.state){
      setSelectedItem(location.state.selectedItem);
    }
  }, [location]);

  useEffect(() => {
    if(selectedItem){
      //console.log("selectedItem", selectedItem);
      formRef.current?.setFieldValue("titulo", selectedItem.titulo);
      formRef.current?.setFieldValue("descricao", selectedItem.descricao);

      if(selectedItem.tipo){
        const current = optionsTipos.find((item) => item.value === selectedItem.tipo)
        if(current){
          setSelectedTipo(current);
        }
      }
    }
  }, [selectedItem, optionsTipos]);

  const handleSubmit = useCallback(
    async (data: TiposArquivoFormData) => {
      try {
        //console.log("handleSubmit", data);

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          titulo: Yup.string().required('Título obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let params = {
          ...data,
          tipo: selectedTipo.value
        }

        //console.log("params", params);

        await api.put(`/tipo-arquivo/${selectedItem.id}`, params);

        addToast({
          type: 'success',
          title: 'Tipo de arquivo atualizado com sucesso',
          description: '',
        });

        goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }
        
        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar tipo de arquivo',
            description: err.response?.data[error] ?
              err.response.data[error] :
              'Erro ao atualizar tipo de arquivo, tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, selectedTipo, selectedItem, goBack],
  );

  function goBack() {
    history.push('/tipos-arquivo');
  }

  function handleChangeSelectedTipo(newValue: any, actionMeta: any) {
    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue setSelectedTipo", newValue.value);

    setSelectedTipo(newValue);
  };

  if(loading){
    return (
      <Loading />
    )
  }

  return (
    <Container>
      <Content>
        <Card>
          <div className="form">
            <div className="header">
              <h2>Editar Tipo de Arquivo</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            <Form ref={formRef} onSubmit={handleSubmit}>
                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="titulo"
                      type="text"
                      placeholder="Título"
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="descricao"
                      type="text"
                      placeholder="Descrição"
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem style={{ marginTop: "10px", marginBottom: "30px" }}>
                    <label>Tipo:</label>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedTipo}
                      options={optionsTipos}
                      value={selectedTipo}
                    />
                  </WrapRowItem>
                </WrapRow>

                <Button type="submit" loading={loading}>
                  Salvar
            </Button>

              </Form>
          </div>
        </Card>
      </Content>
    </Container>
  );
};

export default TiposArquivoEdicao;
