import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import FormInput from '../../components/FormInput';
// import FormInputMask from '../../components/FormInputMask';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem,
  WrapperModules,
  WrapperModule,
  ModuleItem
} from './styles';

interface CursoFormData {
  id: string;
  titulo: string;
  modelo: any;
  tags: any;
  modulos: any;
  ordem: number;
  id_modelo: string;
  id_curso: string;
  curador_responsavel: string;
  codigo_producao: string;
  curso: any;
  id_curadoria: string;
  curadoria: any;
  id_area_atuacao: string;
  area_atuacao: any;
  id_periodo: string;
  periodo: any;
  disciplina: any;
}

interface TagFormData {
  id: string;
  tag: string;
}

interface ModeloFormData {
  id: string;
  titulo: string;
}

interface ModuloFormData {
  id: any;
  modulo: string;
  topico: string;
}

interface CuradoriaFormData {
  id: string;
  nome: string;
}

interface DisciplinaFormData {
  id: string;
  titulo: string;
  codigo: string;
}

interface PeriodosInterface {
  id: string;
  periodo: string;
}

interface OptionsData {
  value: string;
  label: string;
}

const CursosProducaoEdicao: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location: any = useLocation();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [curadorias, setCuradorias] = useState<CuradoriaFormData[]>([]);
  const [optionsCuradorias, setOptionsCuradorias] = useState<OptionsData[]>([]);
  const [selectedCuradoria, setSelectedCuradoria] = useState<OptionsData>({ value: "", label: "" });
  const [areasAtuacao, setAreasAtuacao] = useState<CuradoriaFormData[]>([]);
  const [optionsAreasAtuacao, setOptionsAreasAtuacao] = useState<OptionsData[]>([]);
  const [selectedAreaAtuacao, setSelectedAreaAtuacao] = useState<OptionsData>({ value: "", label: "" });
  const [cursos, setCursos] = useState<DisciplinaFormData[]>([]);
  const [optionsCursos, setOptionsCursos] = useState<OptionsData[]>([]);
  const [selectedCurso, setSelectedCurso] = useState<OptionsData>({ value: "", label: "" });
  const [selectedItem, setSelectedItem] = useState<CursoFormData>({} as CursoFormData);
  const [selectedCursoProducao, setSelectedCursoProducao] = useState<CursoFormData>({} as CursoFormData);
  const [periodos, setPeriodos] = useState<PeriodosInterface[]>([]);
  const [optionsPeriodos, setOptionsPeriodos] = useState<OptionsData[]>([]);
  const [selectedPeriodo, setSelectedPeriodo] = useState<OptionsData>({} as OptionsData);

  useEffect(() => {
    if (location && location.state) {
      setSelectedItem(location.state.selectedItem);
    }
  }, [location]);

  useEffect(() => {
    if (selectedItem) {
      // console.log("selectedItem", selectedItem)

      // getCursoProducao(selectedItem.id);
      setSelectedCursoProducao(selectedItem);
      getPeriodos();
    }
  }, [selectedItem]);

  useEffect(() => {
    var options: OptionsData[] = [];

    periodos.map(item => {
      options.push({ value: item.id, label: item.periodo });
    });

    setOptionsPeriodos(options);
    //console.log("options periodos", options);
  }, [periodos]);

  useEffect(() => {
    if (selectedCursoProducao && selectedCursoProducao.id) {
      // console.log("selectedCursoProducao", selectedCursoProducao)

      formRef.current?.setFieldValue("titulo", selectedCursoProducao.curso?.titulo);

      if (selectedCursoProducao.id_curadoria) {
        setSelectedCuradoria({ value: selectedCursoProducao.curadoria.id, label: selectedCursoProducao.curadoria.nome });
      } else if (selectedCursoProducao.id_area_atuacao) {
        setSelectedAreaAtuacao({ value: selectedCursoProducao.area_atuacao.id, label: selectedCursoProducao.area_atuacao.nome });
      }

      if (selectedCursoProducao.id_periodo) {
        setSelectedPeriodo({ value: selectedCursoProducao.periodo.id, label: selectedCursoProducao.periodo.periodo });
      }

      if (selectedCursoProducao.id_curso) {
        const current = cursos.find((item) => item.id === selectedCursoProducao.id_curso)
        if (current) {
          setSelectedCurso({ value: current.id, label: current.titulo });
        }
      }

      if (selectedCursoProducao.curador_responsavel) {
        formRef.current?.setFieldValue("curador_responsavel", selectedCursoProducao.curador_responsavel);
      }

      if (selectedCursoProducao.codigo_producao) {
        formRef.current?.setFieldValue("codigo_producao", selectedCursoProducao.codigo_producao);
      }
    }
  }, [selectedCursoProducao]);

  useEffect(() => {
    getCuradorias();
    getAreasAtuacao();
    // getCursos();
  }, []);

  useEffect(() => {
    if (curadorias.length === 0) return;

    var options: OptionsData[] = [];

    curadorias.map(item => {
      options.push({ value: item.id, label: item.nome });
    });

    setOptionsCuradorias(options);
    //console.log("options curadorias", options);
  }, [curadorias]);

  useEffect(() => {
    if (areasAtuacao.length === 0) return;

    var options: OptionsData[] = [];

    areasAtuacao.map(item => {
      options.push({ value: item.id, label: item.nome });
    });

    setOptionsAreasAtuacao(options);
    //console.log("options areasAtuacao", options);
  }, [areasAtuacao]);

  useEffect(() => {
    if (cursos.length === 0) return;

    var options: OptionsData[] = [];

    cursos.map(item => {
      options.push({ value: item.id, label: item.titulo });
    });

    setOptionsCursos(options);
    //console.log("options Cursos", options);
  }, [cursos]);

  const getCursoProducao = useCallback(async (id) => {
    setLoading(true);

    try {
      const response = await api.get(`curso-producao/${id}`);
      // console.log("response getCursoProducao:", response.data);

      setSelectedCursoProducao(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getCuradorias = useCallback(async () => {
    const response = await api.get(`/curadoria`);
    //console.log("curadoria", response.data);

    setCuradorias(response.data);
  }, []);

  const getPeriodos = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/periodo`);
      // console.log("getPeriodos", response.data);

      setPeriodos(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getAreasAtuacao = useCallback(async () => {
    const response = await api.get(`/area-atuacao`);
    //console.log("curadoria", response.data);

    setAreasAtuacao(response.data);
  }, []);

  const getCursos = useCallback(async () => {
    const response = await api.get(`/disciplina`);
    //console.log("disciplina", response.data);

    setCursos(response.data);
  }, []);

  const handleSubmit = useCallback(
    async (data: CursoFormData) => {
      try {
        //console.log("handleSubmit", data);

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          titulo: Yup.string().required('Título obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let params: any = {
          // titulo: data.titulo,
          // id_disciplina: selectedCurso.value,
          codigo_producao: data.codigo_producao,
          curador_responsavel: data.curador_responsavel,
          id_periodo: selectedPeriodo.value ? selectedPeriodo.value !== "" ? selectedPeriodo.value : null : null
        }

        if (selectedCursoProducao.id_curadoria) {
          params.id_curadoria = selectedCuradoria.value;
        } else {
          params.id_area_atuacao = selectedAreaAtuacao.value;
        }

        // console.log("params", params);

        await api.put(`/curso-producao/${selectedCursoProducao.id}`, params);

        addToast({
          type: 'success',
          title: 'Curso Produção atualizada com sucesso',
          description: '',
        });

        goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar curso produção',
            description: err.response?.data[error] ?
              err.response.data[error] :
              'Erro ao atualizar curso produção, tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, selectedCursoProducao, selectedCuradoria, selectedCurso, selectedAreaAtuacao, selectedPeriodo],
  );

  function goBack() {
    history.push('/cursos-producao');
  }

  function handleChangeSelectedCuradoria(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedCuradoria({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedCuradoria({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue Curadoria", newValue.value);

    setSelectedCuradoria(newValue);
  };


  function handleChangeSelectedPeriodo(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedPeriodo({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedPeriodo({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue Periodo", newValue.value);

    setSelectedPeriodo(newValue);
  };

  function handleChangeSelectedAreaAtuacao(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedAreaAtuacao({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedAreaAtuacao({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue Curadoria", newValue.value);

    setSelectedAreaAtuacao(newValue);
  };

  function handleChangeSelectedCurso(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedCurso({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedCurso({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue Disciplina", newValue.value);

    setSelectedCurso(newValue);
  };

  if (loading || !(selectedCursoProducao && selectedCursoProducao.id)) {
    return (
      <Loading />
    )
  }

  return (
    <Container>
      <Content>
        <Card>
          <div className="form">
            <div className="header">
              <h2>Editar Curso Produção</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            <Form ref={formRef} onSubmit={handleSubmit}>
              <WrapRow>
                <WrapRowItem>
                  <FormInput
                    name="titulo"
                    type="text"
                    placeholder="Título"
                    defaultValue={selectedCursoProducao.curso.titulo}
                    disabled
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem>
                  <FormInput
                    name="codigo_producao"
                    type="text"
                    placeholder="Código Produção"
                    defaultValue={selectedCursoProducao.codigo_producao}
                  />
                </WrapRowItem>
              </WrapRow>

              {selectedCursoProducao.id_curadoria ? (
                <WrapRow style={{ marginTop: "10px" }}>
                  <WrapRowItem>
                    <label>Curadoria:</label>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedCuradoria}
                      options={optionsCuradorias}
                      value={selectedCuradoria}
                    />
                  </WrapRowItem>
                </WrapRow>
              ) : (
                <WrapRow style={{ marginTop: "10px" }}>
                  <WrapRowItem>
                    <label>Área de Atuação:</label>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedAreaAtuacao}
                      options={optionsAreasAtuacao}
                      value={selectedAreaAtuacao}
                    />
                  </WrapRowItem>
                </WrapRow>
              )}

              <WrapRow>
                <WrapRowItem style={{ marginTop: "30px" }}>
                  <label>Período:</label>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedPeriodo}
                    options={optionsPeriodos}
                    value={selectedPeriodo}
                    isClearable
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow style={{ marginTop: "20px" }}>
                <WrapRowItem>
                  <FormInput
                    name="curador_responsavel"
                    type="text"
                    placeholder={`${selectedCursoProducao.id_curadoria ? 'Curador ' : ''}Responsável`}
                    defaultValue={selectedCursoProducao.curador_responsavel}
                  />
                </WrapRowItem>
              </WrapRow>

              {/* <WrapRow style={{ marginTop: "20px" }}>
                  <WrapRowItem>
                    <label>Disciplina:</label>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedCurso}
                      options={optionsCursos}
                      value={selectedCurso}
                    />
                  </WrapRowItem>
                </WrapRow> */}

              <Button type="submit" loading={loading} style={{ marginTop: "40px" }}>
                Salvar
              </Button>

            </Form>
          </div>
        </Card>
      </Content>
    </Container>
  );
};

export default CursosProducaoEdicao;
