import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import jwt_decode from "jwt-decode";
// import firebase from 'firebase/app';
// import 'firebase/auth';

import * as CryptoJS from "crypto-js";

import { useToast } from './toast';
import api from '../services/api';
import { isForInStatement } from 'typescript';

interface User {
  id: string;
  name: string;
  email: string;
  loading: boolean;
}

interface AuthState {
  user: User;
  token: string;
  roles: any;
  permissions: any;
  periodo: any;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  signIn: any;
  signOut: any;
  userData: any;
  updateUser: any;
  token: string;
  roles: any;
  permissions: any;
  routes: any;
  periodo: any;
  clients: any;
  selectedClient: any;
  setSelectedClient: any;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const { addToast } = useToast();

  const salt = '6d090716-ecdf-11ea-adc1-0242ac120017';
  const [roles, setRoles] = useState<any[]>([]);
  const [originalRoles, setOriginalRoles] = useState<any[]>([]);

  const [data, setData] = useState<AuthState>(() => {
    const user = localStorage.getItem('@Yduqs:user');
    const token = localStorage.getItem('@Yduqs:token');
    
    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      // api.defaults.params = {};
      // api.defaults.params['production'] = true;

      const decoded_jwt:any = jwt_decode(token);
      //console.log("decoded_jwt", decoded_jwt);
      //console.log("user", JSON.parse(user));

      const roles_jwt = decoded_jwt ? decoded_jwt.data?.roles : [];
      const permissions = decoded_jwt ? decoded_jwt.data?.permissions : [];      
      const periodo = decoded_jwt ? decoded_jwt.data?.periodo : {};

      // if(roles_jwt) {
      //   setRoles(roles_jwt)
      // }

      return { 
        user: JSON.parse(user), 
        token, 
        roles: roles_jwt, 
        permissions, 
        periodo 
      };
    }

    return {} as AuthState;
  });

  const [routes, setRoutes] = useState<any>(() => {
    const encrypted = localStorage.getItem('@Yduqs:routes');

    if(encrypted){
      const bytes = CryptoJS.AES.decrypt(encrypted, salt);
      try {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      catch(err){
        return [];
      }
    }else{
      return [];
    }
  })

  const [userData, setUserData] = useState<User>({loading: true} as User);
  const [clients, setClients] = useState<any[]>([]);

  const [selectedClient, setSelectedClient] = useState<any>(() => {
    const client = localStorage.getItem('@Yduqs:selectedClient');

    if(client){
      return JSON.parse(client);
    }

    return {}
  });

  useEffect(() => {
    if(data && data.token){
      getRoutes();
      getUserData();
    }
  }, [data]);

  useEffect(() => {
    if(routes){
      const encryptedData = encryptData(routes, salt);
      localStorage.setItem('@Yduqs:routes', encryptedData);
    }
  }, [routes]);

  // useEffect(() => {
  //   //console.log("roles", roles);
  // }, [roles])

  useEffect(() => {
    if(!(selectedClient && selectedClient.id && originalRoles)) return;

    //console.log("selectedClient", selectedClient);
    localStorage.setItem('@Yduqs:selectedClient', JSON.stringify(selectedClient));

    const roles_client = originalRoles.filter((input:any) => ((input.id_cliente === selectedClient.id) || (input.slug === "especialista")))

    setRoles(roles_client.map((item:any) => item.slug))
    //console.log("roles_client:", roles_client);
  }, [selectedClient, originalRoles])
      
  const encryptData = useCallback((data, salt) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();
  }, []);
  
  const decryptData = useCallback((ciphertext, salt) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, salt);
    try {
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    catch(err){
      return null;
    }
  }, []);

  // const onAuthStateChanged = useCallback(userState => {
  //   //console.log('Firebase user', userState);
  // }, []);

  // useEffect(() => {
  //   const subscriber = firebase.auth().onAuthStateChanged(onAuthStateChanged);
  //   return subscriber; // unsubscribe on unmount
  // }, [onAuthStateChanged]);

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('/sessao', { email, password });
    //console.log("signIn response", response);

    if(!response.data) {
      addToast({
        type: 'error',
        title: 'Erro na autenticação',
        description: 'Ocorreu um erro ao efetuar login, verifique suas credenciais.',
      });

      return false;
    }

    const { user, token, customToken } = response.data;

    const decoded_jwt:any = jwt_decode(token.token);
    //console.log("decoded_jwt", decoded_jwt);

    const roles_jwt = decoded_jwt ? decoded_jwt.data?.roles : [];
    const permissions = decoded_jwt ? decoded_jwt.data?.permissions : [];      
    const periodo = decoded_jwt ? decoded_jwt.data?.periodo : {};

    // if(roles_jwt) {
    //   setRoles(roles_jwt)
    // }

    localStorage.setItem('@Yduqs:user', JSON.stringify(user));
    localStorage.setItem('@Yduqs:token', token.token);

    api.defaults.headers.authorization = `Bearer ${token.token}`;
    
    // firebase
    //   .auth()
    //   .signInWithCustomToken(customToken)
    //   // .then(response => {
    //   //   //console.log('Firebase authentication response:', response);
    //   // })
    //   .catch(error => {
    //     //console.log('Firebase authentication error:', error);
    //   });

    setData({ 
      token: token.token, 
      user, 
      roles: roles_jwt, 
      permissions, 
      periodo 
    });

    return true;
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Yduqs:user');
    localStorage.removeItem('@Yduqs:token');
    localStorage.removeItem('@Yduqs:routes');
    localStorage.removeItem('@Yduqs:selectedClient');

    setData({} as AuthState);
    setSelectedClient({})

    setUserData({
      loading: true
    } as User);

    setRoutes([]);

    // firebase
    //   .auth()
    //   .signOut()
    //   // .then(() => {
    //   //   //console.log('Firebase signOut');
    //   // })
    //   .catch(error => {
    //     //console.log('Firebase signOut error', error);
    //   });
  }, []);

  const updateUser = useCallback(
    () => {
      getUserData();
    },
    [],
  );

  const getRoutes = useCallback(async () => {
    const response = await api.get('/rota-web-papel');

    setRoutes(response.data);
  }, []);

  const getUserData = useCallback(async () => {
    let user_id = null;

    if(data && data.user && data.user.id){
      user_id = data.user.id;
    }else{
      const user:any = localStorage.getItem('@Yduqs:user');
      const user_storage = JSON.parse(user);
      user_id = user_storage?.id;
    }

    try {
      const response = await api.get(`/usuario/${user_id}`);
      //console.log("response getUserData", response.data);

      if(response.data && response.data.id && response.data.force_logout){
        signOut();
      }

      if(response.data && response.data.id && response.data.dados_usuario && response.data.dados_usuario.id){
        setUserData({
          ...response.data.dados_usuario,
          loading: false
        });

        getClients(response.data.roles);
        setOriginalRoles(response.data.roles);
      }else{
        setUserData({
          loading: false
        } as User);
      }
    } catch (error) {
      //console.log("error getUserData", error);
      signOut();
    }
  }, [data]);

  const getClients = useCallback((data_roles) => {
    const clients_:any[] = [];


    data_roles.forEach((item:any) => {
      if(item.id_cliente && item.cliente){
          // clients_.find((item:any) => )

          clients_.push(item.cliente);
      }
    })

    setClients(clients_);

    //console.log("clients_", clients_);


    if(!(selectedClient && selectedClient.id)){
      setSelectedClient(clients_[0]);
    }

    // const roles_client = data_roles.filter((input:any) => input.id_cliente === selectedClient.id)

    // setRoles(roles_client.map((item:any) => item.slug))
    //console.log("roles_client:", roles_client);
  }, [selectedClient]);

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, token: data.token, userData, updateUser, roles: (roles.length > 0 ? roles : data.roles), permissions: data.permissions, routes, periodo: data.periodo, clients, selectedClient, setSelectedClient }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
