import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import FormInput from '../../components/FormInput';
import FormInputMask from '../../components/FormInputMask';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem,
} from './styles';
import { Item } from '../_modules/Account/RolesEdicao_old/styles';

interface ProdutoFormData {
  id: string;
  titulo: string;
  composicao: string;
  descricao: string;
  data_inicio_vigencia: string;
  data_final_vigencia: string;
  unidade_descricao: string;
  unidade_limite: string;
  valor: string;
}

interface ModeloFormData {
  id: string;
  titulo: string;
}

interface ServicoFormData {
  id: string;
  titulo: string;
}

interface TiposProfissionalFormData {
  id: string;
  titulo: string;
}

interface ProjetoFormData {
  id: string;
  titulo: string;
}

interface OptionsData {
  value: string;
  label: string;
}

interface OptionsData2 {
  value: boolean;
  label: string;
}

const ProdutosCadastro: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [modelos, setModelos] = useState<ModeloFormData[]>([]);
  const [servicos, setServicos] = useState<ServicoFormData[]>([]);
  const [tiposProfissional, setTiposProfissional] = useState<TiposProfissionalFormData[]>([]);
  const [projetos, setProjetos] = useState<ProjetoFormData[]>([]);
  const [optionsModelos, setOptionsModelos] = useState<OptionsData[]>([]);
  const [optionsServicos, setOptionsServicos] = useState<OptionsData[]>([]);
  const [optionsTiposProfissional, setOptionsTiposProfissional] = useState<OptionsData[]>([]);
  const [optionsProjetos, setOptionsProjetos] = useState<OptionsData[]>([]);
  const [selectedModelo, setSelectedModelo] = useState<OptionsData>({ value: "", label: "" });
  const [selectedServico, setSelectedServico] = useState<OptionsData>({ value: "", label: "" });
  const [selectedTipoProfissional, setSelectedTipoProfissional] = useState<OptionsData[]>([]);
  const [selectedProjeto, setSelectedProjeto] = useState<OptionsData>({ value: "", label: "" });
  const [unidadeAplicavel, setUnidadeAplicavel] = useState<OptionsData2>({} as OptionsData2);
  const [optionsClientes, setOptionsClientes] = useState<OptionsData[]>([]);
  const [selectedCliente, setSelectedCliente] = useState<OptionsData>({} as OptionsData);
  const [terceirizado, setTerceirizado] = useState<OptionsData2>({ value: false, label: "Não" });

  useEffect(() => {
    getClientes();
    getModelos();
    getTiposProfissional();
  }, []);

  useEffect(() => {
    if (selectedCliente && selectedCliente.value) {
      setOptionsServicos([]);
      setOptionsProjetos([]);

      getServicos();
      getProjetos();

      setSelectedProjeto({} as OptionsData);
      setSelectedServico({} as OptionsData);
    }
  }, [selectedCliente]);

  useEffect(() => {
    if (modelos.length === 0) return;

    var options: OptionsData[] = [];

    modelos.map(item => {
      options.push({ value: item.id, label: item.titulo });
    });

    setOptionsModelos(options);
    //console.log("options modelos", options);
  }, [modelos]);

  useEffect(() => {
    if (servicos.length === 0) return;

    var options: OptionsData[] = [];

    servicos.map(item => {
      options.push({ value: item.id, label: item.titulo });
    });

    setOptionsServicos(options);
    //console.log("options servicos", options);
  }, [servicos]);

  useEffect(() => {
    if (tiposProfissional.length === 0) return;

    var options: OptionsData[] = [];

    tiposProfissional.map(item => {
      options.push({ value: item.id, label: item.titulo });
    });

    setOptionsTiposProfissional(options);
    //console.log("options tiposProfissional", options);
  }, [tiposProfissional]);

  useEffect(() => {
    if (projetos.length === 0) return;

    var options: OptionsData[] = [];

    projetos.map(item => {
      options.push({ value: item.id, label: item.titulo });
    });

    setOptionsProjetos(options);
    //console.log("options projetos", options);
  }, [projetos]);

  const getModelos = useCallback(async () => {
    const response = await api.get(`/modelo`);
    //console.log("modelo", response.data);

    setModelos(response.data);
  }, []);

  const getServicos = useCallback(async () => {
    const response = await api.get(`/servico`, {
      params: {
        id_cliente: selectedCliente.value
      }
    });
    //console.log("servico", response.data);

    setServicos(response.data);
  }, [selectedCliente]);

  const getTiposProfissional = useCallback(async () => {
    const response = await api.get(`/profissional`);
    //console.log("profissional", response.data);

    setTiposProfissional(response.data);
  }, []);

  const getProjetos = useCallback(async () => {
    const response = await api.get(`/projeto`, {
      params: {
        id_cliente: selectedCliente.value
      }
    });
    //console.log("projeto", response.data);

    setProjetos(response.data);
  }, [selectedCliente]);

  const handleSubmit = useCallback(
    async (data: ProdutoFormData) => {
      try {
        //console.log("handleSubmit", data);

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          // composicao: Yup.string().required('Composição obrigatória'),
          // descricao: Yup.string().required('Descrição obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let dt_inicio = data.data_inicio_vigencia.split("/");
        let dt_final = data.data_final_vigencia.split("/");

        let params = {
          // titulo: data.titulo,
          // composicao: data.composicao,
          // descricao: data.descricao,
          id_modelo: selectedModelo.value,
          id_servico: selectedServico.value,
          ids_profissional: selectedTipoProfissional.map((item) => item.value),
          id_projeto: selectedProjeto.value,
          data_inicio_vigencia: `${dt_inicio[2]}-${dt_inicio[1]}-${dt_inicio[0]}`,
          data_final_vigencia: `${dt_final[2]}-${dt_final[1]}-${dt_final[0]}`,
          valor: parseFloat(data.valor),
          terceirizado: terceirizado.value,
          unidade_aplicavel: unidadeAplicavel.value,
          unidade_descricao: data.unidade_descricao,
          unidade_limite: data.unidade_limite,
          ativo: true
        }

        // console.log("params", params);

        const response = await api.post(`/produto`, params);

        // console.log("response", response.data);

        addToast({
          type: 'success',
          title: 'Produto cadastrado com sucesso',
          description: `Foram criado(s) ${response.data?.length} produto(s)`,
        });

        goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao cadastrar produto',
            description: err.response?.data[error] ?
              err.response.data[error] :
              'Erro ao cadastrar produto, tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, selectedModelo, selectedServico, selectedProjeto, selectedTipoProfissional, terceirizado, unidadeAplicavel],
  );

  function goBack() {
    history.push('/produtos');
  }

  function handleChangeSelectedProjeto(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedProjeto({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedProjeto({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue tema", newValue.value);

    setSelectedProjeto(newValue);
  };

  function handleChangeSelectedModelo(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedModelo({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedModelo({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue tema", newValue.value);

    setSelectedModelo(newValue);
  };

  function handleChangeSelectedServico(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedServico({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedServico({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue tema", newValue.value);

    setSelectedServico(newValue);
  };

  function handleChangeSelectedTipoProfissional(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedTipoProfissional([]);
      return;
    };

    if (newValue.length === 0) {
      setSelectedTipoProfissional([]);
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue tema", newValue.value);

    setSelectedTipoProfissional(newValue);
  };

  function handleChangeSelectedUnidadeAplicavel(newValue: any, actionMeta: any) {
    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue tema", newValue.value);

    setUnidadeAplicavel(newValue);

    if (newValue.value === false) {
      formRef.current?.setFieldValue("unidade_limite", 1);
    }
  };

  function handleChangeSelectedTerceirizado(newValue: any, actionMeta: any) {
    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue tema", newValue.value);

    setTerceirizado(newValue);
  };

  const getClientes = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/cliente`);
      //console.log("response", response.data);

      setOptionsClientes(response.data.map((item: any) => {
        return {
          value: item.id,
          label: item.nome
        }
      }));
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);


  function handleChangeSelectedCliente(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedCliente({} as OptionsData);
      return;
    };

    if (newValue.length === 0) {
      setSelectedCliente({} as OptionsData);
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue tema", newValue.value);

    setSelectedCliente(newValue);
  };

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <Container>
      <Content>
        <Card>
          <div className="form">
            <div className="header">
              <h2>Cadastrar Produto</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            <Form ref={formRef} onSubmit={handleSubmit}>
              <WrapRow>
                <WrapRowItem>
                  <div style={{ color: '#666', marginBottom: '5px', }}>Cliente:</div>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedCliente}
                    options={optionsClientes}
                    value={selectedCliente}
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem style={{ marginTop: "30px" }}>
                  <label>Projeto:</label>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedProjeto}
                    options={optionsProjetos}
                    value={selectedProjeto}
                  />
                </WrapRowItem>
              </WrapRow>

              {/* <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="titulo"
                      type="text"
                      placeholder="Título"
                    />
                  </WrapRowItem>
                </WrapRow> */}

              {/* <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="composicao"
                      type="text"
                      placeholder="Composição"
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="descricao"
                      type="text"
                      placeholder="Descrição"
                    />
                  </WrapRowItem>
                </WrapRow> */}

              <WrapRow>
                <WrapRowItem style={{ marginTop: "30px" }}>
                  <label>Serviço:</label>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedServico}
                    options={optionsServicos}
                    value={selectedServico}
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem style={{ marginTop: "30px" }}>
                  <label>Modelo:</label>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedModelo}
                    options={optionsModelos}
                    value={selectedModelo}
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem style={{ marginTop: "30px" }}>
                  <label>Tipo Profissional:</label>
                  <Select
                    isMulti
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedTipoProfissional}
                    options={optionsTiposProfissional}
                    value={selectedTipoProfissional}
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow style={{ marginTop: "30px" }}>
                <WrapRowItem>
                  <FormInputMask
                    name="data_inicio_vigencia"
                    type="text"
                    placeholder="Data Início Vigência"
                    mask="99/99/9999"
                  />
                </WrapRowItem>

                <WrapRowItem>
                  <FormInputMask
                    name="data_final_vigencia"
                    type="text"
                    placeholder="Data Término Vigência"
                    mask="99/99/9999"
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem>
                  <FormInput
                    name="valor"
                    type="text"
                    placeholder="Valor (R$)"
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem style={{ marginTop: "10px" }}>
                  <label>Terceirizado:</label>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedTerceirizado}
                    options={[
                      { value: true, label: "Sim" },
                      { value: false, label: "Não" }
                    ]}
                    value={terceirizado}
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem style={{ marginTop: "30px" }}>
                  <label>Unidade Aplicável:</label>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedUnidadeAplicavel}
                    options={[
                      { value: true, label: "Sim" },
                      { value: false, label: "Não" }
                    ]}
                    value={unidadeAplicavel}
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow style={{ marginTop: "30px" }}>
                <WrapRowItem>
                  <FormInput
                    name="unidade_descricao"
                    type="text"
                    placeholder="Unidade Descricao"
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem>
                  <FormInput
                    name="unidade_limite"
                    type="text"
                    placeholder="Limite de unidades"
                    disabled={unidadeAplicavel.value === false}
                  />
                </WrapRowItem>
              </WrapRow>

              <Button type="submit" loading={loading}>
                Cadastrar
              </Button>

            </Form>
          </div>
        </Card>
      </Content>
    </Container>
  );
};

export default ProdutosCadastro;
