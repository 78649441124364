import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';


import { useAuth } from '../../../../hooks/auth';
import { useToast } from '../../../../hooks/toast';

import Button from '../../../../components/Button';
import Card from '../../../../components/Card';

import Select from 'react-select';
import Switch from "react-switch";

import api from '../../../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem,
  Item,
  ModalWrapper,
  CheckBoxWrapper,
  CheckBoxItem
} from './styles';



interface RolesFormData {
  id: string;
  slug: string;
  name: string;
}

interface WebRoutesFormData {
  id: string;
  nome: string;
  path: string;
  tem_acesso: boolean;
}



interface OptionsData {
  value: string;
  label: string;
}

const AccessPermisions: React.FC = () => {

  const history = useHistory();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<RolesFormData[]>([]);


  const [optionsRoles, setOptionsRoles] = useState<OptionsData[]>([]);
  const [selectedRole, setSelectedRole] = useState<OptionsData>({ value: "", label: "" });
  const [webRoutes, setWebRoutes] = useState<WebRoutesFormData[]>([]);

  useEffect(() => {
    getWebRoutes();
    getRoles();
  }, []);

  useEffect(() => {
    if (roles.length === 0) return;

    var options: OptionsData[] = [];

    roles.map(item => {
      options.push({ value: item.id, label: item.name });
    });

    setOptionsRoles(options);
    //console.log("options modelos", options);
  }, [roles]);

  useEffect(() => {
    getRoutesRole();
  }, [selectedRole]);

  const getRoutesRole = useCallback(async () => {
    if (selectedRole.value) {
      const response = await api.get(`rota-web-papel/${selectedRole.value}`);
      //console.log("temas", response.data);

      const webRoutesPapel = webRoutes.map(route => {

        return {
          ...route,
          tem_acesso: route.path == '/main' ? true : response.data.includes(route.path)
        }

        // let current = route.find((item: any) => item.path === route.path);
        //console.log('current', current)
      })

      setWebRoutes(webRoutesPapel)



      // setTemas(response.data);
    } else {
      //console.log("temas - sem params", selectedRole);
    }
  }, [selectedRole]);


  const getRoles = useCallback(async () => {
    const response = await api.get(`/roles`);
    //console.log("roles", response.data);

    setRoles(response.data);
  }, []);

  const getWebRoutes = useCallback(async () => {
    const response = await api.get(`/rota-web`);
    //console.log("rota-web", response.data);

    if (response.data) {
      setWebRoutes(response.data.map((item: any) => {
        return { ...item, tem_acesso: false }
      }))
    }

    // setWebRoutes(response.data)
    // setRoles(response.data);

    // formRef.current?.setFieldValue("name", "");
    // formRef.current?.setFieldValue("description", "");
  }, []);







  const handleSubmit = useCallback(
    async () => {

      setLoading(true);
      //console.log("handleSubmit", webRoutes, selectedRole);

      let allowedRoutes: any = [];

      webRoutes.forEach(route => {
        if (route.tem_acesso) allowedRoutes.push(route.id)
      })







      try {
        let params = {
          id_papel: selectedRole.value,
          rotas: allowedRoutes
        }
        //console.log(params);
        await api.post(`/rota-web-papel`, params);
        addToast({
          type: 'success',
          title: 'Permissões de acesso cadastradas com sucesso',
          description: '',
        });

        setSelectedRole({ value: "", label: "" });

      } catch (err) {
        //console.log(err);
        addToast({
          type: 'error',
          title: 'Erro ao atualizar permissões',
          description: '',
        });
      } finally {
        setLoading(false);
      }












      // 

    },
    [addToast, webRoutes, selectedRole],
  );

  function handleChangeSwitch(route: any) {

    const temp = webRoutes.map(item => {
      if (item.id == route.id) {
        return {
          ...route,
          tem_acesso: route.path == '/main' ? true : !route.tem_acesso
        }
      } else {
        return item
      }
    })
    setWebRoutes(temp)
  }

  function handleChangeSelectedRole(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedRole({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedRole({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue tema", newValue.value);

    setSelectedRole(newValue);

  };


  return (
    <Container>

      <Content>
        <Card>
          <div className="form">
            <div className="header">
              <h2>Permissões de Acesso</h2>
            </div>

            <Form onSubmit={handleSubmit}>
              <WrapRow>
                <WrapRowItem style={{ marginTop: "10px" }}>
                  <div style={{ color: '#666', marginBottom: '5px', }}>Selecione a Role:</div>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedRole}
                    options={optionsRoles}
                    value={selectedRole}
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow style={{ marginTop: '20px' }}>
                <div style={{
                  marginBottom: '5px',
                  marginLeft: '12px',
                  color: '#666'
                }}>Rotas de Acesso:</div>
                <CheckBoxWrapper>
                  {
                    webRoutes.map(route =>

                      <CheckBoxItem key={`${route.nome}`} active={selectedRole.value == "" ? false : route.tem_acesso}>


                        <Switch
                          uncheckedIcon={false}
                          checkedIcon={false}
                          offColor={'#e6e6e6'}
                          onColor={'#2FB4C2'}
                          onChange={() => { handleChangeSwitch(route) }}
                          checked={selectedRole.value == "" ? false : route.tem_acesso}
                        />
                        <span>{route.nome}</span>

                      </CheckBoxItem>

                      // <div>{`${route.nome} ${route.path} ${route.tem_acesso}`}</div>
                    )
                  }
                </CheckBoxWrapper>
              </WrapRow>


              <Button disabled={selectedRole.value == ""} type="submit" loading={loading}>
                Atualizar
                  </Button>


            </Form>
          </div>
        </Card>
      </Content>

    </Container>
  );
};

export default AccessPermisions;
