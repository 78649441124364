import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import slugify from 'slugify';

import { useAuth } from '../../../../hooks/auth';
import { useToast } from '../../../../hooks/toast';

import getValidationErrors from '../../../../utils/getValidationErrors';

import FormInput from '../../../../components/FormInput';
// import FormInputMask from '../../../../components/FormInputMask';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import Loading from '../../../../components/Loading';

import api from '../../../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem,
} from './styles';

interface RolesFormData {
  id: string;
  name: string;
  slug: string;
  description: string;
}

const PermissionsEdicao: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location:any = useLocation();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<RolesFormData>({} as RolesFormData);

  useEffect(() => {
    if(location && location.state){
      setSelectedItem(location.state.selectedItem);
    }
  }, [location]);

  useEffect(() => {
    if(selectedItem){
      formRef.current?.setFieldValue("name", selectedItem.name);
      formRef.current?.setFieldValue("description", selectedItem.description);
    }
  }, [selectedItem]);

  const createSlug = useCallback((name) => {
    let slug = slugify(name);
    return slug.toLowerCase();
  }, []);

  const handleSubmit = useCallback(
    async (data: RolesFormData) => {
      try {
        //console.log("handleSubmit", data);

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          description: Yup.string().required('Descrição obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        data.slug = createSlug(data.name);
        
        //console.log("data", data);

        await api.put(`/permissions/${selectedItem.id}`, data);

        addToast({
          type: 'success',
          title: 'Permission atualizada com sucesso',
          description: '',
        });

        goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }
        
        //console.log(err.response?.data);

        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar permission',
            description: err.response?.data[error] ?
              err.response.data[error] :
              'Erro ao atualizar permission, tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, selectedItem, goBack],
  );

  function goBack() {
    history.push('/account/permissions');
  }

  if(loading){
    return (
      <Loading />
    )
  }

  return (
    <Container>
      <Content>
        <Card>
          <div className="form">
            <div className="header">
              <h2>Editar Permission</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            <Form ref={formRef} onSubmit={handleSubmit}>
                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="name"
                      type="text"
                      placeholder="Permission"
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="description"
                      type="text"
                      placeholder="Descrição"
                    />
                  </WrapRowItem>
                </WrapRow>

                <Button type="submit" loading={loading}>
                  Salvar
            </Button>

              </Form>
          </div>
        </Card>
      </Content>
    </Container>
  );
};

export default PermissionsEdicao;
