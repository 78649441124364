import styled, { css } from 'styled-components';
// import { shade } from 'polished';

const getColor = (props:any) => {
  if (props.isDragAccept) {
      return '#00e676';
  }
  if (props.isDragReject) {
      return '#ff1744';
  }
  if (props.isDragActive) {
      return '#2196f3';
  }
  return '#eeeeee';
}

export const DropzoneContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  /* transition: border .24s ease-in-out; */
  cursor: pointer;
  margin: 0px 0px 20px;

  &:hover{
    border-color: #2196f3;
    color: #2196f3;
  }
`;


export const Container = styled.div`
  .icon {
    font-size: 22px;
    color: #717171;
    cursor: pointer;
  }

  .text-switch{
    font-size: 16px;
    line-height: 30px;
    margin-left: 10px;
    color: #333;
  }
`;

export const Content = styled.main`
  /* max-width: 1120px;
  margin: 10px auto; */
  display: flex;
  margin-bottom: 80px;
  flex-direction: column;

  form{
    width: 100%;

    h2{
      margin-bottom: 30px;
      padding: 5px 0;
    }
  }
`;

export const WrapRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row; 
  flex-wrap: wrap;
`;

export const WrapRowItem = styled.div`
  flex: 1;
  margin: 0 5px;
`;

export const Item = styled.div`
  flex: 1;
  margin: 20px 5px;
  background-color: #F1F1F1;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .title{
    font-size: 18px;
    margin-bottom: 10px;
  }

  .subtitle{
    font-weight: lighter;
  }

  .removeIcon{
    cursor: pointer;
  }
`;
