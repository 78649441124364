import React, { useCallback, useState, useEffect, useRef } from 'react';
import { FiTrash, FiPlus, FiEdit, FiX } from 'react-icons/fi';
import { BsFileEarmarkCheck } from "react-icons/bs";
import { useHistory, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
import Loading from '../../components/Loading';
import FormInput from '../../components/FormInput';
import Button from '../../components/Button';

import api from '../../services/api';

import {
  Container,
  Content,
  TableButton,
  WrapRow,
  WrapRowItem,
  ContratoData
} from './styles';

interface AprovarContratosInterface {
  id: string;
  data_contrato: string;
  prazo_entrega: string;
  tema: any;
  disciplina_producao: any;
  curso_producao: any;
  observacoes: string;
  produtos: any;
  especialista: any;
  remetente: any;
  valor_total: any;
  tipo_contrato: string;
  contrato_aprovar: any;
}

interface OptionsData {
  value: boolean;
  label: string;
}

const AprovarContratosVerba: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location: any = useLocation();

  const { user, permissions, selectedClient } = useAuth();
  const { addToast } = useToast();

  // const MySwal = withReactContent(Swal)

  const [loading, setLoading] = useState(true);
  const [contrato, setContrato] = useState<AprovarContratosInterface>({} as AprovarContratosInterface);
  const [contratos, setContratos] = useState<AprovarContratosInterface[]>([]);
  const [selectedContrato, setSelectedContrato] = useState<AprovarContratosInterface>({} as AprovarContratosInterface);
  const [selectedParecer, setSelectedParecer] = useState<OptionsData>({} as OptionsData);
  // const [justificativaAprovador, setJustificativaAprovador] = useState<string>("");
  // const [selectedRows, setSelectedRows] = useState([]);
  // const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [idContratoParams, setIdContratoParams] = useState<any>(null);
  const [verbas, setVerbas] = useState<any[]>([]);

  useEffect(() => {
    if(!selectedClient) return;

    getContratos();
    getVerbas();
  }, [selectedClient]);

  useEffect(() => {
    if (selectedContrato && selectedContrato.id) {
      //console.log("selectedContrato changed", selectedContrato);
      getContrato(selectedContrato.id);
    }
  }, [selectedContrato]);

  useEffect(() => {
    if (contrato && contrato.id) {
      //console.log("contrato changed", contrato);
    }
  }, [contrato]);

  useEffect(() => {
    if (location && location.search) {
      //console.log("location", location.search);
      const query = new URLSearchParams(location.search);
      const id_contrato = query.get('id_contrato')
      //console.log("id", id_contrato);

      if(id_contrato){
        getContratos(id_contrato);
        setIdContratoParams(id_contrato);
      }
    }
  }, [location]);

  useEffect(() => {
    if (contratos.length > 0 && idContratoParams) {
      const temp:any = contratos.find((item:any) => item.id == idContratoParams);
      setSelectedContrato(temp);
    }
  }, [contratos, idContratoParams]);

  const getContratos = useCallback(async (id_contrato=null) => {
    setLoading(true);

    try {
      const response = await api.get(`/contrato-aprovacao-verba`, {params:{id_cliente: selectedClient.id}});
      // console.log("response", response.data);

      setContratos(response.data ? response.data : []);
      
      if(response.data){
        const temp = response.data.find((item:any) => item.id == id_contrato);
        setSelectedContrato(temp);
      }
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, [selectedClient]);

  const getContrato = useCallback(async (id) => {
    setLoading(true);

    try {
      const response = await api.get(`/contrato-aprovacao-verba/${id}`, {params:{id_cliente: selectedClient.id}});
      //console.log("response contrato-aprovacao:", response.data);

      setContrato(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  function handleChangeSelectedParecer(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedParecer({} as OptionsData);
      return;
    };

    if (newValue.length === 0) {
      setSelectedParecer({} as OptionsData);
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue tema", newValue.value);

    setSelectedParecer(newValue);
  };

  const getVerbas = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/verba`);
      // console.log("response", response.data);

      if(response.data){
        const atual = response.data.find((item:any) => item.corrente)

        if(atual){
          // console.log("atual", atual);

          updateCuradorias(atual);
        }
      }

    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const updateCuradorias = useCallback(async (verba_selecionada) => {
    const temp:any = [];

    verba_selecionada.curadorias.forEach((curadoria:any) => {
      temp.push({
        id: curadoria.id,
        nome: curadoria.nome,
        verba: curadoria.pivot.verba_total,
        verba_utilizada: curadoria.pivot.verba_utilizada,
      })
    });

    // console.log("temp", temp);

    setVerbas(temp);
  }, []);

  function goBack() {
    setContrato({} as AprovarContratosInterface);
    setSelectedContrato({} as AprovarContratosInterface);
  }

  const handleSubmit = useCallback(async (data: any) => {
    //console.log("handleSubmit", data);

    if (!(selectedParecer.value === true || selectedParecer.value === false)) {
      addToast({
        type: 'error',
        title: 'Selecione o parecer',
        description: ''
      });
      return;
    }

    setLoading(true);

    try {
      const params = {
        aprovacao_deferida: selectedParecer.value,
        justificativa_aprovador: data.justificativa_aprovador
      }

      //console.log("params", params);

      const response = await api.put(`/contrato-aprovacao-verba/${selectedContrato.id}`, params);
      //console.log("response", response.data);

      addToast({
        type: 'success',
        title: `${selectedParecer.value ? "Contrato aprovado com sucesso" : "Contrato recusado com sucesso"}`,
        description: '',
      });

      getContratos();
      goBack();
    } catch (err) {
      // if (err instanceof Yup.ValidationError) {
      //   const errors = getValidationErrors(err);
      //   formRef.current?.setErrors(errors);

      //   return;
      // }

      //console.log("error", err.response?.data);

      // for (let error in err.response?.data) {
      //   addToast({
      //     type: 'error',
      //     title: 'Erro ao cadastrar contrato',
      //     description: err.response?.data[error] ?
      //       err.response.data[error] :
      //       'Erro ao cadastrar contrato, tente novamente mais tarde.',
      //   });
      // }

    } finally {
      setLoading(false);
    }
  }, [selectedContrato, selectedParecer]);

  
  let parseText = function (text: string, limit: number) {
    if (text.length > limit)
      for (let i = limit; i > 0; i--) {
        if (
          text.charAt(i) === ' ' &&
          (text.charAt(i - 1) != ',' ||
            text.charAt(i - 1) != '.' ||
            text.charAt(i - 1) != ';')
        ) {
          return text.substring(0, i) + '...';
        }
      }
    else return text;
  };

  const columns = [
    {
      name: 'ID',
      selector: 'hash',
      sortable: false,
      // right: true,
      maxWidth: '140px',
      hide: 1250,
    },
    {
      name: 'Especialista',
      selector: 'especialista.email',
      sortable: false,
      // right: true,
    },
    {
      name: 'Remetente',
      selector: 'remetente.email',
      sortable: false,
      // right: true,
    },
    // {
    //   name: 'Tema',
    //   selector: 'tema.titulo',
    //   sortable: false,
    //   // right: true,
    //   hide: 1450,
    // },
    {
      name: 'Produção',
      selector: 'producao',
      sortable: false,
      maxWidth: '400px',
      hide: 1590,
      // right: true,
      cell: (row: any) => {
        let producao = (row.tema && row.tema.titulo) ? row.tema.titulo : row.disciplina_producao ? row.disciplina_producao?.disciplina?.titulo : row.curso_producao?.curso?.titulo;
        let producao_id = (row.tema && row.tema.id) ? row.tema.id : row.disciplina_producao ? row.disciplina_producao?.disciplina?.id : row.curso_producao?.curso?.id;

        if(!producao) return "";

        let dots_string = String(producao).substring(0, 50);
        let reduce = dots_string !== producao;
        return (
          <>
            {reduce ? (
              <>
                <div data-tip data-for={`${producao_id}`}>
                  {dots_string}
                </div>
                <ReactTooltip id={`${producao_id}`} effect="solid">
                  <span>{producao}</span>
                </ReactTooltip>
              </>
            ) : (
              <div>{dots_string}</div>
            )}
          </>
        );
      },
    },
    {
      name: 'Contratação',
      selector: 'tipo_contratacao',
      sortable: false,
      // right: true,
      maxWidth: '150px',
      hide: 1700,
    },
    {
      name: selectedClient.tem_curadoria ? 'Curadoria' : 'Área de Atuação',
      selector: selectedClient.tem_curadoria ? 'curadoria' : 'area_atuação',
      sortable: false,
      // right: true,
      // maxWidth: '140px',
      cell: (row: any) => {
        if(selectedClient.tem_curadoria){
          return <span>{row.tema ? row.tema?.curadoria?.nome : row.disciplina_producao ? row.disciplina_producao?.curadoria?.nome : row.curso_producao?.curadoria?.nome}</span>;
        }else{
          return <span>{row.tema ? row.tema?.area_atuacao?.nome : row.disciplina_producao ? row.disciplina_producao?.area_atuacao?.nome : row.curso_producao?.area_atuacao?.nome}</span>;
        }
      }
    },
    {
      name: 'Produto',
      selector: 'produto',
      sortable: false,
      // right: true,
      cell: (row: any) => {
        return (
          row.produtos.map((item:any) => {
            return (<span key={`${item.servico?.titulo}`}>{item.servico?.titulo} </span>)
          })
        )
      }
    },
    {
      name: 'Tipo de Produção',
      selector: 'tipo',
      sortable: false,
      width: '120px',
      // right: true,
      hide: 1370,
      cell: (row: any) => {
        let tipo = (row.tema && row.tema.titulo) ? "Tema" : row.disciplina_producao ? "Disciplina" : "Curso";
        return <span>{tipo}</span>;
      },
    },
    {
      name: 'Tipo de Contrato',
      selector: 'tipo_contrato',
      sortable: false,
      width: '120px',
      // right: true,
      hide: 1490,
      cell: (row: any) => {
        let tipo = (row.tipo_contrato === "producao_atual") ? "Produção" : (row.tipo_contrato === "manutencao_reativa") ? "Manutenção Reativa" : (row.tipo_contrato === "manutencao_proativa") ? "Manutenção Proativa" : (row.tipo_contrato === "manutencao_proativa_direcionada") ? "Manutenção Proativa Direcionada" : (row.tipo_contrato === "terceirizado") ? "Terceirizado" : (row.tipo_contrato === "prestacao_servico") ? "Prestação de Serviço" : (row.tipo_contrato.replace("_", " ")[0].toUpperCase() + row.tipo_contrato.replace("_", " ").substring(1));
        return <span>{tipo}</span>;
      },
    },
    {
      name: 'Valor Total',
      selector: 'valor_total',
      sortable: false,
      // right: true,
      maxWidth: '150px',
      hide: 1700,
      cell: (row: any) => {
        return (<span>R$ {parseFloat(row.valor_total).toFixed(2)}</span>)
      }
    },
    {
      name: 'Ações',
      button: true,
      maxWidth: '80px',
      cell: (row: any) =>
        (
          <div className="table-action" onClick={() => setSelectedContrato(row)}>
            <BsFileEarmarkCheck />
          </div>
        )

      ,
    },
  ];

  return (
    <Container>
      <Content>
        {!(selectedContrato && selectedContrato.id) ?
          <Card>
            <DataTable
              title="Contratos internos aguardando aprovação de verba"
              columns={columns}
              data={contratos}
              // selectableRows
              // highlightOnHover
              // onSelectedRowsChange={handleChange}
              defaultSortField="especialista.email"
              pagination
              // actions={actions}
              // contextActions={contextActions}
              noDataComponent="Nenhum registro encontrado."
              paginationComponentOptions={{
                rowsPerPageText: 'Itens por página',
                rangeSeparatorText: 'de'
              }}
              // clearSelectedRows={clearSelectedRows}
              contextMessage={{
                singular: 'item selecionado',
                plural: 'itens selecionados',
                message: ''
              }}
              progressComponent={<Loading />}
              progressPending={loading}
            />
          </Card>
          :
          <Card>
            <div className="form">
              <div className="header">
                <h2>Avaliação de Contrato</h2>
                <FiX onClick={() => goBack()} className="icon" />
              </div>
              <ContratoData>
                <div className="wrapper-data">
                  <div className="wrapper-data-label">
                    Tipo de Contrato:
                  </div>
                  <div className="wrapper-data-info">
                    {(selectedContrato.tipo_contrato === "producao_atual") ? "Produção" : (selectedContrato.tipo_contrato === "manutencao_reativa") ? "Manutenção Reativa" : (selectedContrato.tipo_contrato === "manutencao_proativa") ? "Manutenção Proativa" : (selectedContrato.tipo_contrato === "manutencao_proativa_direcionada") ? "Manutenção Proativa Direcionada" : (selectedContrato.tipo_contrato === "terceirizado") ? "Terceirizado" : (selectedContrato.tipo_contrato === "prestacao_servico") ? "Prestação de Serviço" : (selectedContrato.tipo_contrato.replace("_", " ")[0].toUpperCase() + selectedContrato.tipo_contrato.replace("_", " ").substring(1))}
                  </div>
                </div>

                { contrato.contrato_aprovar?.cliente.tem_curadoria ? (
                  <div className="wrapper-data">
                    <div className="wrapper-data-label">
                      Curadoria:
                    </div>
                    <div className="wrapper-data-info">
                      {selectedContrato.tema ? selectedContrato.tema?.curadoria?.nome : selectedContrato.disciplina_producao ? selectedContrato.disciplina_producao?.curadoria?.nome : selectedContrato.curso_producao?.curadoria?.nome}
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                { contrato.contrato_aprovar?.cliente.tem_area ? (
                  <div className="wrapper-data">
                    <div className="wrapper-data-label">
                      Área de Atuação:
                    </div>
                    <div className="wrapper-data-info">
                      {selectedContrato.tema ? selectedContrato.tema?.area_atuacao?.nome : selectedContrato.disciplina_producao ? selectedContrato.disciplina_producao?.area_atuacao?.nome : selectedContrato.curso_producao?.area_atuacao?.nome}
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                { selectedContrato.tema ? (
                  <div className="wrapper-data">
                    <div className="wrapper-data-label">
                      Tema:
                    </div>
                    <div className="wrapper-data-info">
                      {selectedContrato.tema?.titulo}
                    </div>
                  </div>
                ) : 
                  <></>
                }

                { selectedContrato.disciplina_producao ? (
                  <div className="wrapper-data">
                    <div className="wrapper-data-label">
                      Disciplina Produção:
                    </div>
                    <div className="wrapper-data-info">
                      {selectedContrato.disciplina_producao?.disciplina?.titulo}
                    </div>
                  </div>
                ) : 
                  <></>
                }

                { selectedContrato.curso_producao ? (
                  <div className="wrapper-data">
                    <div className="wrapper-data-label">
                      Curso Produção:
                    </div>
                    <div className="wrapper-data-info">
                      {selectedContrato.curso_producao?.curso?.titulo}
                    </div>
                  </div>
                ) : 
                  <></>
                }

                <div className="wrapper-data">
                  <div className="wrapper-data-label">
                    Serviço:
                  </div>
                  <div className="wrapper-data-info">
                    { selectedContrato.produtos.map((item:any) => {
                      return (<span key={`${item.servico?.titulo}`}>{item.servico?.titulo} </span>)
                    })}
                  </div>
                </div>

                <div className="wrapper-data">
                  <div className="wrapper-data-label">
                    Especialista:
                  </div>
                  <div className="wrapper-data-info">
                    Nome: {selectedContrato.especialista?.dados_usuario?.nome}
                  </div>
                  <div className="wrapper-data-info">
                    Email: {selectedContrato.especialista?.email}
                  </div>
                </div>

                <div className="wrapper-data">
                  <div className="wrapper-data-label">
                    Remetente:
                  </div>
                  <div className="wrapper-data-info">
                    Nome: {selectedContrato.remetente?.dados_usuario?.nome}
                  </div>
                  <div className="wrapper-data-info">
                    Email: {selectedContrato.remetente?.email}
                  </div>
                </div>

                <div className="wrapper-data">
                  <div className="wrapper-data-label">
                    Produtos:
                  </div>

                  {selectedContrato.produtos.map((item: any) => (
                    <div key={`${item.titulo}`} className="wrapper-produto">
                      <div className="wrapper-data-label">
                        {item.titulo}:
                      </div>
                      <div className="wrapper-data-info">
                        Quantidade: {item.pivot.quantidade}
                      </div>
                      {item.pivot.justificativa ?
                        <>
                          <div className="wrapper-data-info">
                            Valor: R$ {parseFloat(item.valor).toFixed(2)}
                          </div>
                          <div className="wrapper-data-info">
                            Valor Solicitado: R$ {parseFloat(item.pivot.valor).toFixed(2)}
                          </div>
                          <div className="wrapper-data-info">
                            Valor total: R$ {parseFloat(item.pivot.valor_total).toFixed(2)}
                          </div>
                          <div className="wrapper-data-info">
                            Justificativa: {item.pivot.justificativa}
                          </div>
                        </>
                        :
                        <>
                          <div className="wrapper-data-info">
                            Valor: R$ {parseFloat(item.valor).toFixed(2)}
                          </div>
                          <div className="wrapper-data-info">
                            Valor total: R$ {parseFloat(item.pivot.valor_total).toFixed(2)}
                          </div>
                        </>
                      }
                    </div>
                  ))}

                  {
                    selectedContrato.valor_total >= 0 &&
                    <div className="wrapper-money">
                      <div className="wrapper-data-label" style={{ paddingTop: "25px", fontSize: "30px", color: "#999" }}>
                        R$ {parseFloat(selectedContrato.valor_total).toFixed(2)}
                      </div>
                    </div>
                  }


                </div>

                { (permissions.indexOf("aprovar-verba") > -1) &&
                  <Form ref={formRef} onSubmit={handleSubmit}>
                    <WrapRow style={{ marginTop: "30px" }}>
                      <WrapRowItem>
                        <label>Parecer:</label>
                        <Select
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({ ...provided, zIndex: 9999 }),
                            // input: styles => ({ ...styles }),
                          }}
                          placeholder={''}
                          onChange={handleChangeSelectedParecer}
                          options={[
                            { value: false, label: "Recusar" },
                            { value: true, label: "Aprovar" }
                          ]}
                          value={selectedParecer}
                        />
                      </WrapRowItem>
                    </WrapRow>

                    <WrapRow style={{ marginTop: "30px" }}>
                      <WrapRowItem>
                        <FormInput
                          name="justificativa_aprovador"
                          type="text"
                          placeholder="Justificativa Avaliador"
                        />
                      </WrapRowItem>
                    </WrapRow>

                    <Button type="submit" loading={loading}>
                      Salvar
                    </Button>
                  </Form>
                }

              </ContratoData>
            </div>
          </Card>
        }
      </Content>
    </Container>
  );
};

export default AprovarContratosVerba;
