import styled, { keyframes } from 'styled-components';
// import { shade } from 'polished';

export const Container = styled.div`
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const Content = styled.div`
  padding: 0 30px;
  flex: 1;

  @media screen and (max-width: 500px) {
    padding: 0 10px;
  }
`;

export const PageTitle = styled.div`
  margin: 20px 0;

  > svg{
    font-size: 20px;
    margin-top: 0px;
    margin-right: 10px;
  }

  > h1{
    font-size: 18px;
    font-weight: 300;
  }

  display: none;

  @media screen and (max-width: 1080px) {
    display: flex;
    flex: 1;
    flex-direction: row;
  }
`;

export const WarningDevelopementMode = styled.div`
  background-color: ${({ theme }) => theme.warningColor};
  color: #fff;
  padding: 5px 15px;
  position: fixed;
  right: 0;
  bottom: 0;
`;
