import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { BsFileEarmarkText } from 'react-icons/bs';
import { FiCheckSquare, FiSearch, FiSquare } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import convertUTCDateToLocalDate from '../../utils/convertUTCDateToLocalDate';

import { ClearButton, Container, Content, TextField } from './styles';

interface ContratosInterface {
  id: string;
  hash: string;
  data_contrato: string;
  prazo_entrega: string;
  tema: any;
  status: any;
  observacoes: string;
  produtos: any;
  especialista: any;
  tipo_contratacao: string;
  disciplina_producao: any;
  curso_producao: any;
  tipo_contrato: string;
}

const Contratos: React.FC = () => {
  const history = useHistory();
  const location: any = useLocation();

  const { roles, selectedClient } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal);

  const [loading, setLoading] = useState(true);
  const [contratos, setContratos] = useState<ContratosInterface[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [filterText_, setFilterText_] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [showMeusContratos, setShowMeusContratos] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState<any>(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    if (location && location.state && location.state.feedback_contratos) {
      // if (location) {
      //console.log('location', location);

      const feedback_contratos = location.state.feedback_contratos;
      const feedback_tema = location.state.feedback_tema;
      const feedback_contratos_criados =
        location.state.feedback_contratos_criados;

      //console.log('feedback_tema', feedback_tema);
      MySwal.fire({
        title: `<div>Feedback dos ${
          feedback_contratos.length < 10
            ? '0' + feedback_contratos.length
            : feedback_contratos.length
        } contratos demandados.</div>`,
        // icon: 'info',
        width: '50%',
        html: `<div style="color:#5F5F5F"> 
        <div style="margin-top:30px;text-align:justify"><b>${feedback_tema}</b></div>
        <div style="margin-top:10px;text-align:justify">${feedback_contratos_criados} demandas de contrato criadas</div>
        
        ${feedback_contratos
          .map((item: any) => {
            if (item.processado) {
              return `<div key={${item.servico.titulo}} style="margin-top:30px;text-align:justify"><b>${item.servico.titulo}</b> Cód. do Contrato ${item.hash}</div>`;
            } else {
              return `<div key={${item.servico.titulo}} style="margin-top:30px;text-align:justify"><b style="color:#E94949">${item.servico.titulo}</b> Erro: ${item.messages[0]}</div>`;
            }
          })
          .join('')}</div>`,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: 'Ok',
        confirmButtonAriaLabel: 'Ok',
        cancelButtonText: 'Cancelar',
        cancelButtonAriaLabel: 'Cancelar',
      });
    }
  }, [location]);

  useEffect(() => {
    if (roles && roles.length > 0) {
      if (
        roles.indexOf('curador') > -1 ||
        roles.indexOf('curador-adjunto') > -1 ||
        roles.indexOf('ensino-adm') > -1 ||
        roles.indexOf('medicina-adm') > -1 ||
        roles.indexOf('pos-graduacao-adm') > -1 ||
        roles.indexOf('premium-adm') > -1 ||
        roles.indexOf('educare-adm') > -1 ||
        roles.indexOf('lp-pmo') > -1
      ) {
        setShowFilter(true);
      }
    }
  }, [roles]);

  useEffect(() => {
    getContratos();
    console.log(showMeusContratos);
  }, [showMeusContratos, rowsPerPage]);

  useEffect(() => {
    if (clearSelectedRows) {
      setClearSelectedRows(false);
    }
  }, [clearSelectedRows]);

  // useEffect(() => {
  //   if (!contratos) return;

  //   let search = filterText.toLowerCase();

  //   let result = contratos.filter(
  //     item => item.hash.toLowerCase() === search ||
  //       item.tema?.titulo.toLowerCase().includes(search) ||
  //       item.especialista?.email.toLowerCase().includes(search) ||
  //       item.especialista?.dados_usuario?.nome.toLowerCase().includes(search)
  //   );

  //   setFilterText_(filterText);
  //   //console.log('xxx', filterText, result)
  // }, [filterText, contratos])

  // const filtered = useMemo(() => {
  //   if (!filterText) return contratos;

  //   let search = filterText.toLowerCase();

  //   search = search.split('-').join('');
  //   search = search.split('.').join('');

  //   search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  //   return contratos.filter(contrato => {
  //     return (
  //       contrato.tema?.titulo
  //         .toLowerCase()
  //         .normalize('NFD')
  //         .replace(/[\u0300-\u036f]/g, '')
  //         .includes(search) ||
  //       contrato.disciplina_producao?.disciplina?.titulo
  //         .toLowerCase()
  //         .normalize('NFD')
  //         .replace(/[\u0300-\u036f]/g, '')
  //         .includes(search) ||
  //       contrato.hash.toLowerCase().includes(search) ||
  //       contrato.tipo_contratacao.toLowerCase().includes(search) ||
  //       contrato.status?.descricao
  //         ?.toLowerCase()
  //         .normalize('NFD')
  //         .replace(/[\u0300-\u036f]/g, '')
  //         .includes(search) ||
  //       contrato.especialista?.dados_usuario?.nome
  //         .toLowerCase()
  //         .normalize('NFD')
  //         .replace(/[\u0300-\u036f]/g, '')
  //         .includes(search) ||
  //       contrato.tipo_contrato
  //         .toLowerCase()
  //         .normalize('NFD')
  //         .replace(/[\u0300-\u036f]/g, '')
  //         .includes(search) ||
  //       contrato.especialista?.dados_usuario?.cpf
  //         .toLowerCase()
  //         .includes(search) ||
  //       contrato.produtos[0]?.servico?.titulo
  //         .toLowerCase()
  //         .normalize('NFD')
  //         .replace(/[\u0300-\u036f]/g, '')
  //         .includes(search) ||
  //       ((contrato.tema && contrato.tema.id) ? "Tema" : "Disciplina")
  //         .toLowerCase()
  //         .normalize('NFD')
  //         .replace(/[\u0300-\u036f]/g, '')
  //         .includes(search)
  //     );
  //   });
  // }, [filterText, contratos]);

  // const filtered = useMemo(() => {
  //   if (!filterText) return contratos;

  //     let search = filterText.toLowerCase();

  //     search = search.split('-').join('');
  //     search = search.split('.').join('');

  //     search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  //     return contratos.filter(contrato => {
  //       return (
  //         contrato.tema?.titulo
  //           .toLowerCase()
  //           .normalize('NFD')
  //           .replace(/[\u0300-\u036f]/g, '')
  //           .includes(search) ||
  //         contrato.disciplina_producao?.disciplina?.titulo
  //           .toLowerCase()
  //           .normalize('NFD')
  //           .replace(/[\u0300-\u036f]/g, '')
  //           .includes(search) ||
  //         contrato.hash.toLowerCase().includes(search) ||
  //         contrato.tipo_contratacao.toLowerCase().includes(search) ||
  //         contrato.status?.descricao
  //           ?.toLowerCase()
  //           .normalize('NFD')
  //           .replace(/[\u0300-\u036f]/g, '')
  //           .includes(search) ||
  //         contrato.especialista?.dados_usuario?.nome
  //           .toLowerCase()
  //           .normalize('NFD')
  //           .replace(/[\u0300-\u036f]/g, '')
  //           .includes(search) ||
  //         contrato.tipo_contrato
  //           .toLowerCase()
  //           .normalize('NFD')
  //           .replace(/[\u0300-\u036f]/g, '')
  //           .includes(search) ||
  //         contrato.especialista?.dados_usuario?.cpf
  //           .toLowerCase()
  //           .includes(search) ||
  //         contrato.produtos[0]?.servico?.titulo
  //           .toLowerCase()
  //           .normalize('NFD')
  //           .replace(/[\u0300-\u036f]/g, '')
  //           .includes(search) ||
  //         ((contrato.tema && contrato.tema.id) ? "Tema" : "Disciplina")
  //           .toLowerCase()
  //           .normalize('NFD')
  //           .replace(/[\u0300-\u036f]/g, '')
  //           .includes(search)
  //       );
  //     });
  //   }, [filterText, contratos]);

  const getContratos = useCallback(
    async (currentPage = 1) => {
      setLoading(true);

      try {
        if (showMeusContratos) {
          const response = await api.get(`/contrato`, {
            params: {
              todos_contratos: true,
              status: [
                'criado',
                'aceite',
                'planejado',
                'cancelado',
                'recusado',
                'pagamento_permitido',
                'pagamento_comandado',
                'aguardando_aprovacao',
                'erro_de_permissao',
              ],
              page: currentPage,
              per_page: rowsPerPage,
              busca: filterText,
              paginacao: true,
              id_cliente: selectedClient.id,
            },
          });
          setContratos(response.data);
          setContratos(response?.data?.data);
          setTotalRows(response?.data?.total);
        } else {
          const response = await api.get(`/contrato`, {
            params: {
              status: [
                'criado',
                'aceite',
                'planejado',
                'cancelado',
                'recusado',
                'pagamento_permitido',
                'pagamento_comandado',
                'aguardando_aprovacao',
                'erro_de_permissao',
              ],
              page: currentPage,
              per_page: rowsPerPage,
              busca: filterText,
              paginacao: true,
              id_cliente: selectedClient.id,
            },
          });
          setContratos(response.data);

          setContratos(response?.data?.data);
          setTotalRows(response?.data?.total);
        }
      } catch (error) {
        //console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [
      showMeusContratos,
      filterText,
      page,
      rowsPerPage,
      sortBy,
      sortDirection,
      selectedClient,
    ],
  );

  const removeSelectedRows = useCallback(() => {
    MySwal.fire({
      // title: 'Tem certeza que deseja remover?',
      showCancelButton: true,
      confirmButtonText: `Remover`,
      confirmButtonColor: '#1CAEBD',
      cancelButtonColor: '#312e38',
      cancelButtonText: 'Cancelar',
      // icon: 'warning',
      html: '<div style="font-size:20px">Tem certeza que deseja remover?</div>',
      // position: 'top'
    }).then(result => {
      if (result.isConfirmed) {
        selectedRows.map((row: any) => {
          removeRow(row.id);
        });
      }
    });
  }, [selectedRows]);

  const removeRow = useCallback(async rowId => {
    //console.log('remove', rowId);
    setLoading(true);

    try {
      await api.delete(`/contrato/${rowId}`);

      setClearSelectedRows(true);

      addToast({
        type: 'success',
        title: 'Contrato removido com sucesso',
        description: '',
      });

      getContratos();
    } catch (err) {
      for (let error in err.response?.data) {
        addToast({
          type: 'error',
          title: 'Erro ao remover contrato',
          description: err.response?.data[error]
            ? err.response.data[error]
            : 'Erro ao remover contrato, tente novamente mais tarde.',
        });
      }
    }
  }, []);

  let parseText = function (text: string, limit: number) {
    if (text.length > limit)
      for (let i = limit; i > 0; i--) {
        if (
          text.charAt(i) === ' ' &&
          (text.charAt(i - 1) != ',' ||
            text.charAt(i - 1) != '.' ||
            text.charAt(i - 1) != ';')
        ) {
          return text.substring(0, i) + '...';
        }
      }
    else return text;
  };

  function goStoreForm() {
    history.push('/contratos/cadastro');
  }

  function goUpdateForm() {
    history.push('/contratos/edicao', {
      selectedItem: selectedRows[0],
    });
  }

  const handleChange = (state: any) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    //console.log('Selected Rows: ', state.selectedRows);

    setSelectedRows(state.selectedRows);
  };

  const actions = (
    <div
      className="table-action-button"
      onClick={() => {
        goStoreForm();
      }}
    >
      {/* <FiPlus /> */}
      <span>Criar Contrato</span>
    </div>
  );

  const contextActions = (
    <>
      {/* {selectedRows.length === 1 &&
        <FiEdit className="icon" style={{ marginRight: "15px" }} onClick={() => {
          goUpdateForm();
        }} />
      } */}
      {/* <FiTrash className="icon" style={{ marginRight: "10px" }} onClick={() => {
        removeSelectedRows();
      }} /> */}
    </>
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const handleSearch = () => {
      setResetPaginationToggle(!resetPaginationToggle);
      setLoading(true);
      setContratos([]);
      getContratos(1);
    };

    return (
      <div style={{ display: 'flex', width: '100%' }}>
        {showFilter ? (
          <>
            <div
              className="table-subheader-button"
              onClick={() => {
                setShowMeusContratos(false);
              }}
            >
              {showMeusContratos ? <FiSquare /> : <FiCheckSquare />}
              <span>Criados por mim</span>
            </div>
            <div
              className="table-subheader-button"
              onClick={() => {
                setShowMeusContratos(true);
              }}
            >
              {showMeusContratos ? <FiCheckSquare /> : <FiSquare />}
              <span>Todos</span>
            </div>
          </>
        ) : (
          <div></div>
        )}

        <div style={{ flex: 1 }}></div>

        <TextField
          id="search"
          type="text"
          placeholder="Pesquisar"
          aria-label="Search Input"
          value={filterText}
          onChange={(e: any) => setFilterText(e.target.value)}
          onKeyPress={(event: any) => {
            if (event.key === 'Enter') {
              handleSearch();
            }
          }}
        />
        {/* <ClearButton type="button" onClick={handleClear}>
          x
        </ClearButton> */}

        <ClearButton type="button" onClick={handleSearch}>
          <FiSearch />
        </ClearButton>
      </div>
    );
  }, [filterText, resetPaginationToggle, showMeusContratos, showFilter]);

  const columns = [
    {
      name: 'ID',
      selector: 'hash',
      sortable: false,
      maxWidth: '140px',
      // right: true,
      hide: 820,
    },
    {
      name: 'Produção',
      selector: 'producao',
      sortable: false,
      maxWidth: '400px',
      // right: true,
      cell: (row: any) => {
        let producao =
          row.tema && row.tema.titulo
            ? row.tema.titulo
            : row.disciplina_producao?.disciplina?.titulo
            ? row.disciplina_producao?.disciplina?.titulo
            : row.curso_producao?.curso?.titulo;
        let producao_id =
          row.tema && row.tema.id
            ? row.tema.id
            : row.disciplina_producao?.disciplina?.id
            ? row.disciplina_producao?.disciplina?.id
            : row.curso_producao?.curso?.id;

        if (!producao) return '';

        let dots_string = String(producao).substring(0, 50);
        let reduce = dots_string !== producao;

        return (
          <>
            {reduce ? (
              <>
                <div data-tip data-for={`${producao_id}`}>
                  {dots_string}
                </div>
                <ReactTooltip id={`${producao_id}`} effect="solid">
                  <span>{producao}</span>
                </ReactTooltip>
              </>
            ) : (
              <div>{dots_string}</div>
            )}
          </>
        );
      },
    },
    {
      name: 'Tipo de Produção',
      selector: 'tipo',
      sortable: false,
      width: '120px',
      // right: true,
      hide: 1375,
      cell: (row: any) => {
        let tipo =
          row.tema && row.tema.titulo
            ? 'Tema'
            : row.disciplina_producao?.disciplina?.titulo
            ? 'Disciplina'
            : row.curso_producao?.curso?.titulo
            ? 'Curso'
            : '';
        return <span>{tipo}</span>;
      },
    },
    {
      name: 'Tipo de Contrato',
      selector: 'tipo_contrato',
      sortable: false,
      width: '120px',
      // right: true,
      hide: 1150,
      cell: (row: any) => {
        let tipo =
          row.tipo_contrato === 'producao_atual'
            ? 'Produção'
            : row.tipo_contrato === 'manutencao_reativa'
            ? 'Manutenção Reativa'
            : row.tipo_contrato === 'manutencao_proativa'
            ? 'Manutenção Proativa'
            : row.tipo_contrato === 'manutencao_proativa_direcionada'
            ? 'Manutenção Proativa Direcionada'
            : row.tipo_contrato === 'terceirizado'
            ? 'Terceirizado'
            : row.tipo_contrato === 'prestacao_servico'
            ? 'Prestação de Serviço'
            : row.tipo_contrato.replace('_', ' ')[0].toUpperCase() +
              row.tipo_contrato.replace('_', ' ').substring(1);
        return <span>{tipo}</span>;
      },
    },
    {
      name: 'Especialista',
      selector: 'especialista.dados_usuario.nome',
      sortable: false,
      maxWidth: '230px',
      // right: true,
      hide: 1260,
      cell: (row: any) => {
        let nome = row.especialista.dados_usuario
          ? row.especialista.dados_usuario.nome
          : '';
        return <span>{nome}</span>;
      },
    },
    {
      name: 'CPF',
      selector: 'especialista.dados_usuario.cpf',
      sortable: false,
      width: '120px',
      // right: true,
      hide: 1490,
      cell: (row: any) => {
        let cpf = row.especialista.dados_usuario
          ? row.especialista.dados_usuario.cpf
          : '';
        return <span>{cpf}</span>;
      },
    },
    {
      name: 'Contratação',
      selector: 'tipo_contratacao',
      sortable: false,
      width: '120px',
      // right: true,
      hide: 1490,
      cell: (row: any) => (
        <p>
          {row.tipo_contratacao ? row.tipo_contratacao.replace('_', ' ') : null}
        </p>
      ),
    },
    {
      name: 'Data Contrato',
      selector: 'data_contrato',
      sortable: false,
      width: '130px',
      // right: true,
      hide: 1800,
      cell: (row: any) => {
        let dt_data_contrato = convertUTCDateToLocalDate(
          new Date(row.data_contrato),
        );

        return (
          <span>
            {`${('0' + dt_data_contrato.getDate()).slice(-2)}/${(
              '0' +
              (dt_data_contrato.getMonth() + 1)
            ).slice(-2)}/${dt_data_contrato.getFullYear()}`}
          </span>
        );
      },
    },
    {
      name: 'Prazo Entrega',
      selector: 'prazo_entrega',
      sortable: false,
      // right: true,
      width: '130px',
      hide: 1875,
      cell: (row: any) => {
        let dt_prazo_entrega = convertUTCDateToLocalDate(
          new Date(row.prazo_entrega || row.data_prestacao_servico),
        );
        let prazo_vencido = dt_prazo_entrega < new Date();

        return (
          <span style={{ color: prazo_vencido ? '#ff0000' : '' }}>
            {`${('0' + dt_prazo_entrega.getDate()).slice(-2)}/${(
              '0' +
              (dt_prazo_entrega.getMonth() + 1)
            ).slice(-2)}/${dt_prazo_entrega.getFullYear()}`}
          </span>
        );
      },
    },
    {
      name: 'Produto',
      selector: 'produto',
      sortable: false,
      // right: true,
      cell: (row: any) => {
        return row.produtos.map((item: any) => {
          return (
            <span key={`${item.servico?.titulo}`}>{item.servico?.titulo} </span>
          );
        });
      },
    },
    {
      name: 'Situação Atual',
      selector: 'status.descricao',
      sortable: false,
      // right: true,
      hide: 600,
      cell: (row: any) => (
        <div
          style={{
            color: '#fff',
            backgroundColor:
              row.status.slug == 'criado'
                ? '#F09B0E'
                : row.status.slug == 'aceite'
                ? '#95B759'
                : row.status.slug == 'planejado'
                ? '#AFB7C2'
                : row.status.slug == 'cancelado'
                ? '#E94F51'
                : row.status.slug == 'recusado'
                ? '#333'
                : row.status.slug == 'pagamento_permitido'
                ? '#800980'
                : row.status.slug == 'pagamento_comandado'
                ? '#0783C2'
                : row.status.slug == 'aguardando_aprovacao'
                ? '#F09B61'
                : row.status.slug == 'edital_flow'
                ? '#ff06da'
                : row.status.slug == 'erro_de_permissao'
                ? '#444'
                : '#666',

            padding: '5px',
            borderRadius: '3px',
            width: '150px',
            textAlign: 'center',
          }}
        >
          {row.status.slug == 'criado' && <span>contrato criado</span>}
          {row.status.slug == 'aceite' && <span>contrato aceito</span>}
          {row.status.slug == 'planejado' && <span>contrato planejado</span>}
          {row.status.slug == 'cancelado' && <span>contrato cancelado</span>}
          {row.status.slug == 'recusado' && <span>contrato recusado</span>}
          {row.status.slug == 'pagamento_permitido' && (
            <span>{row.status.descricao}</span>
          )}
          {row.status.slug == 'pagamento_comandado' && (
            <span>{row.status.descricao}</span>
          )}
          {row.status.slug == 'aguardando_aprovacao' && (
            <span>{row.status.descricao}</span>
          )}
          {row.status.slug == 'edital_flow' && (
            <span>{row.status.descricao}</span>
          )}
          {row.status.slug == 'erro_de_permissao' && (
            <span>{row.status.descricao}</span>
          )}
        </div>
      ),
    },
    {
      name: 'Ações',
      button: true,
      cell: (row: any) => (
        <div className="wrapper-table-actions">
          {/* <div
            className={`table-action`}
            onClick={() =>
              history.push('/contratos/visualizar-contrato', {
                selectedItem: row,
              })
            }
          >
            <BsFileEarmarkText data-tip data-for="tipVisualizarContrato" />
          </div> */}

          <Link
            className={`table-action`}
            target={'_blank'}
            to={{
              pathname: '/contratos/visualizar-contrato',
              search: `?id_contrato=${row.id}`,
              // hash: "#the-hash",
              state: { id_contrato: row.id },
            }}
          >
            <BsFileEarmarkText data-tip data-for="tipVisualizarContrato" />
          </Link>

          <ReactTooltip id="tipVisualizarContrato" effect="solid">
            <span>Visualizar Contrato</span>
          </ReactTooltip>
        </div>
      ),
    },
  ];

  return (
    <Container>
      <Content>
        <Card>
          <DataTable
            title={<span style={{ marginLeft: '8px' }}>Contratos</span>}
            columns={columns}
            data={contratos}
            // selectableRows
            highlightOnHover
            onSelectedRowsChange={handleChange}
            // defaultSortField="especialista.email"
            pagination
            actions={actions}
            // contextActions={contextActions}
            noDataComponent="Nenhum registro encontrado."
            paginationComponentOptions={{
              rowsPerPageText: 'Itens por página',
              rangeSeparatorText: 'de',
            }}
            clearSelectedRows={clearSelectedRows}
            contextMessage={{
              singular: 'item selecionado',
              plural: 'itens selecionados',
              message: '',
            }}
            progressComponent={<Loading />}
            progressPending={loading}
            subHeader
            subHeaderComponent={subHeaderComponent}
            paginationResetDefaultPage={resetPaginationToggle}
            responsive={true}
            onChangePage={(value: any) => {
              setLoading(true);
              //console.log("page:", value);
              setPage(value);
              getContratos(value);
            }}
            onSort={(column: any, sort_direction: any) => {
              setLoading(true);
              //console.log("sortBy:", column, sort_direction);
              setSortBy(column);
              setSortDirection(sort_direction);
            }}
            onChangeRowsPerPage={(value: any) => {
              setLoading(true);
              //console.log("rowsPerPage:", value);
              setRowsPerPage(value);
            }}
            paginationTotalRows={totalRows}
            paginationDefaultPage={1}
            paginationServer
          />
        </Card>
      </Content>
    </Container>
  );
};

export default Contratos;
