import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import FormInput from '../../components/FormInput';
import FormInputMask from '../../components/FormInputMask';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem,
} from './styles';

interface FormacaoFormData {
  id: string;
  formacao: string;
  id_instituicao: string;
  ano_conclusao: string;
  situacao: string;
  curso: string;
}

interface InstituicaoFormData {
  id: string;
  nome: string;
}

interface OptionsData {
  value: string;
  label: string;
}

const FormacaoCadastro: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [instituicoes, setInstituicoes] = useState<InstituicaoFormData[]>([]);
  const [optionsInstituicoes, setOptionsInstituicoes] = useState<OptionsData[]>([]);
  const [selectedInstituicao, setSelectedInstituicao] = useState<OptionsData>({ value: "", label: "" });
  const [optionsFormacoes, setOptionsFormacoes] = useState<OptionsData[]>([
    { value: "graduação", label: "Graduação" },
    { value: "pós-graduação", label: "Pós-graduação" },
    { value: "especialização", label: "Especialização" },
    { value: "mestrado", label: "Mestrado" },
    { value: "doutorado", label: "Doutorado" },
    { value: "pós-doutorado", label: "Pós-doutorado" },
  ]);
  const [selectedFormacao, setSelectedFormacao] = useState<OptionsData>({ value: "", label: "" });
  const [optionsSituacoes, setOptionsSituacoes] = useState<OptionsData[]>([
    { value: "trancado", label: "Trancado" },
    { value: "em andamento", label: "Em andamento" },
    { value: "concluído", label: "Concluído" }
  ]);
  const [selectedSituacao, setSelectedSituacao] = useState<OptionsData>({ value: "", label: "" });
  
  useEffect(() => {
    getInstituicoes();
  }, []);

  useEffect(() => {
    if (instituicoes.length === 0) return;

    var options: OptionsData[] = [];

    instituicoes.map(item => {
      options.push({ value: item.id, label: item.nome });
    });

    setOptionsInstituicoes(options);
    //console.log("options instituicoes", options);
  }, [instituicoes]);
  
  const getInstituicoes = useCallback(async () => {
    const response = await api.get(`/instituicao`);
    //console.log("instituicao", response.data);

    setInstituicoes(response.data);
  }, []);

  const handleSubmit = useCallback(
    async (data: FormacaoFormData) => {
      try {
        //console.log("handleSubmit", data);

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          curso: Yup.string().required('Curso obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const params = { ...data };
        params.id_instituicao = selectedInstituicao.value;
        params.formacao = selectedFormacao.value;
        params.situacao = selectedSituacao.value;

        //console.log("params", params);

        await api.post(`/formacao`, params);

        addToast({
          type: 'success',
          title: 'Formação cadastrada com sucesso',
          description: '',
        });

        goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }
        
        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao cadastrar formação',
            description: err.response?.data[error] ?
              err.response.data[error] :
              'Erro ao cadastrar formação, tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, selectedInstituicao, selectedSituacao, selectedFormacao],
  );

  function goBack() {
    history.push('/formacao');
  }

  function handleChangeSelectedInstituicao(newValue: any, actionMeta: any) {
    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue setSelectedInstituicao", newValue.value);

    setSelectedInstituicao(newValue);
  };

  function handleChangeSelectedSituacao(newValue: any, actionMeta: any) {
    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue setSelectedSituacao", newValue.value);

    setSelectedSituacao(newValue);
  };

  function handleChangeSelectedFormacao(newValue: any, actionMeta: any) {
    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue setSelectedFormacao", newValue.value);

    setSelectedFormacao(newValue);
  };

  if(loading){
    return (
      <Loading />
    )
  }

  return (
    <Container>
      <Content>
        <Card>
          <div className="form">
            <div className="header">
              <h2>Cadastrar Formação</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            <Form ref={formRef} onSubmit={handleSubmit}>
                <WrapRow>
                  <WrapRowItem style={{ marginTop: "10px", marginBottom: "30px" }}>
                    <label>Formação:</label>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedFormacao}
                      options={optionsFormacoes}
                      value={selectedFormacao}
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="curso"
                      type="text"
                      placeholder="Curso"
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem>
                    <FormInputMask
                      name="ano_conclusao"
                      type="text"
                      placeholder="Ano Conclusão"
                      mask="9999"
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem style={{ marginTop: "10px", marginBottom: "30px" }}>
                    <label>Instituição:</label>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedInstituicao}
                      options={optionsInstituicoes}
                      value={selectedInstituicao}
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem style={{ marginTop: "10px", marginBottom: "30px" }}>
                    <label>Situação:</label>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedSituacao}
                      options={optionsSituacoes}
                      value={selectedSituacao}
                    />
                  </WrapRowItem>
                </WrapRow>

                <Button type="submit" loading={loading}>
                  Cadastrar
            </Button>

              </Form>
          </div>
        </Card>
      </Content>
    </Container>
  );
};

export default FormacaoCadastro;
