import styled from 'styled-components';
// import { shade } from 'polished';

export const Container = styled.div`
  .icon {
    font-size: 22px;
    color: #717171;
    cursor: pointer;
  }
`;

export const Content = styled.main`
  /* max-width: 1120px;
  margin: 10px auto; */
  display: flex;
  margin-bottom: 80px;
  flex-direction: column;

  form{
    width: 100%;

    h2{
      margin-bottom: 30px;
      padding: 5px 0;
    }
  }
`;

export const WrapRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row; 
  flex-wrap: wrap;
`;

export const WrapRowItem = styled.div`
  flex: 1;
  margin: 0 5px;
`;

export const Item = styled.div`
  flex: 1;
  margin: 20px 5px;
  background-color: #F1F1F1;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .title{
    font-size: 18px;
    margin-bottom: 10px;
  }

  .subtitle{
    font-weight: lighter;
    margin-bottom: 10px;
  }

  .removeIcon{
    cursor: pointer;
  }
`;

export const WrapperProducts = styled.div`
  margin: 30px 5px 5px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 20px;
    color: #312e38;
    font-size: 16px;
  }

  /* background-color: #F2F2F2; */
`;

export const WrapperProduct = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row; 
  flex-wrap: wrap;
`;

export const ProductItem = styled.div`
  flex: 1;
  padding: 10px 20px 0;
  border: 0.5px solid #F2F2F2;

  
`;

export const TagProductPrice = styled.div`
  display: flex;
  flex-direction: row; 

  .tag{
  color: #aaa;
  border-width:1px 1px 1px 1px;
  border-color:#ccc;
  background-color: #e6e6e6;
  padding: 4px;
  border-radius: 3px;
  margin-left:5px;
  font-size:11px;

  }
  


  
`;

