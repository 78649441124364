import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { FiTrash, FiPlus, FiEdit, FiX, FiUploadCloud } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { cpf, cnpj  } from 'cpf-cnpj-validator';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import convertUTCDateToLocalDate from '../../utils/convertUTCDateToLocalDate';
import getValidationErrors from '../../utils/getValidationErrors';

import Card from '../../components/Card';
import Loading from '../../components/Loading';
import FormInput from '../../components/FormInput';
import Button from '../../components/Button';

import api from '../../services/api';

import {
  Container,
  Content,
  TextField,
  ClearButton,
  CargaTemaData,
  WrapRow,
  WrapRowItem
} from './styles';

interface CuradoresVisualizarInterface {
  id: any;
  email: string;
  dados_usuario: any;
  dados_pagamento: any;
  contatos: any;
  endereco: any;
}

// interface OptionsData {
//   value: boolean;
//   label: string;
// }

const CuradoresVisualizar: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location: any = useLocation();

  const { token } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal)

  const [loading, setLoading] = useState(true);
  const [selectedEspecialista, setSelectedEspecialista] = useState<CuradoresVisualizarInterface>({} as CuradoresVisualizarInterface);
  const [selectedItem, setSelectedItem] = useState<CuradoresVisualizarInterface>({} as CuradoresVisualizarInterface);
  // const [selectedRows, setSelectedRows] = useState([]);
  // const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [showUpdateSAP, setShowUpdateSAP] = useState(false);

  useEffect(() => {
    if (location && location.state) {
      //console.log("location", location);
      setSelectedItem(location.state.selectedItem);
    }
  }, [location]);

  useEffect(() => {
    if (selectedItem && selectedItem.id) {
      //console.log("selectedItem", selectedItem);
      getEspecialista(selectedItem.id);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (selectedEspecialista && selectedEspecialista.id) {
      //console.log("selectedEspecialista", selectedEspecialista, selectedEspecialista.id);
    }
  }, [selectedEspecialista]);

  const getEspecialista = useCallback(async (id) => {
    setLoading(true);

    try {
      const response = await api.get(`/usuario/${id}`);
      //console.log("response getEspecialista", response.data);

      setSelectedEspecialista(response.data);

      // if(response.data?.dados_usuario?.pj_cnpj && !response.data?.dados_usuario?.pj_razao_social){
      //   searchCNPJ(response.data?.dados_usuario?.pj_cnpj);
      // }

    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  function goBack() {
    history.goBack();
  }

  const dataNascimento = useMemo(() => {
    if (selectedEspecialista.dados_usuario?.data_nascimento) {
      let dt = convertUTCDateToLocalDate(new Date(selectedEspecialista.dados_usuario?.data_nascimento));
      return `${("0" + dt.getDate()).slice(-2)}/${("0" + (dt.getMonth() + 1)).slice(-2)}/${dt.getFullYear()}`
    } else {
      return "";
    }
  }, [selectedEspecialista]);

  const dataExpediçãoRG = useMemo(() => {
    if (selectedEspecialista.dados_usuario?.rg_expedicao_data) {
      let dt = convertUTCDateToLocalDate(new Date(selectedEspecialista.dados_usuario?.rg_expedicao_data));
      return `${("0" + dt.getDate()).slice(-2)}/${("0" + (dt.getMonth() + 1)).slice(-2)}/${dt.getFullYear()}`
    } else {
      return "";
    }
  }, [selectedEspecialista]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        //console.log("handleSubmit", data);

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          codigo_sap: Yup.string().required('Código SAP obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const params = {
          dados_usuario: {
            codigo_sap: data.codigo_sap
          }
        }

        //console.log("params", params);

        await api.put(`/usuario/${selectedEspecialista.id}`, params);

        addToast({
          type: 'success',
          title: 'Código SAP atualizado com sucesso',
          description: '',
        });

        setShowUpdateSAP(false);
        getEspecialista(selectedEspecialista.id);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }
        
        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar código SAP',
            description: err.response?.data[error] ?
              err.response.data[error] :
              'Erro ao atualizar código SAP, tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, selectedEspecialista],
  );

  // const searchCNPJ = useCallback(async (input_cnpj) => {
  //   if (input_cnpj.length > 0) {
  //     const cnpj_final = input_cnpj.split("_").join("").replace("-", "").split(".").join("").replace("/", "");
  //     //console.log("cnpj_final:", cnpj_final);

  //     if (cnpj_final.length === 14 && cnpj.isValid(cnpj_final)) {
  //       try {
  //         const response = await api.get(`https://brasilapi.com.br/api/cnpj/v1/${cnpj_final}`);
  //         //console.log("cnpj (nao retornado da api):", response);
  
  //         if (response.data) {
  //           const tmp = selectedEspecialista;

  //           tmp.dados_usuario.pj_razao_social = response.data.razao_social;

  //           setSelectedEspecialista(tmp);
  //         }
          
  //       } catch (error) {

  //       }
  //     }
  //   }
  // }, []);

  return (
    <Container>
      <Content>
        <Card>
          <div className="header" style={{ height: "0px", padding: "15px" }}>
            <h2>
              { selectedEspecialista.dados_usuario?.nome ? selectedEspecialista.dados_usuario?.nome : selectedEspecialista.email }
            </h2>
            <FiX onClick={() => goBack()} className="icon" />
          </div>

          <CargaTemaData>
            <div className="wrapper-data">
              <div className="wrapper-data-label">
                Dados Cadastrais:
              </div>
              <div className="wrapper-data-info">
                Nome: {selectedEspecialista.dados_usuario?.nome}
              </div>
              <div className="wrapper-data-info">
                Email: {selectedEspecialista.email}
              </div>
              <div className="wrapper-data-info">
                CPF: {cpf.format(selectedEspecialista.dados_usuario?.cpf)}
              </div>
              <div className="wrapper-data-info">
                RG: {selectedEspecialista.dados_usuario?.rg}
              </div>
              <div className="wrapper-data-info">
                Emissão do RG: {dataExpediçãoRG}
              </div>
              <div className="wrapper-data-info">
                Órgão Emissor do RG: {selectedEspecialista.dados_usuario?.rg_expedicao_orgao}
              </div>
              <div className="wrapper-data-info">
                PIS: {selectedEspecialista.dados_usuario?.pis}
              </div>
              <div className="wrapper-data-info">
                Data de Nascimento: {dataNascimento}
              </div>
              <div className="wrapper-data-info">
                Estado Civil: {selectedEspecialista.dados_usuario?.estado_civil}
              </div>
              <div className="wrapper-data-info">
                Naturalidade: {selectedEspecialista.dados_usuario?.naturalidade}
              </div>
              <div className="wrapper-data-info">
                Nacionalidade: {selectedEspecialista.dados_usuario?.nacionalidade}
              </div>

              <div className="wrapper-data-label" style={{marginTop:"30px"}}>
                Dados Profissionais:
              </div>
              <div className="wrapper-data-info">
                Matricula: {selectedEspecialista.dados_usuario?.matricula}
              </div>
              
              <div className="wrapper-data-info">
                SAP: {selectedEspecialista.dados_usuario?.codigo_sap} 
                <span className="link-editar" onClick={() => {setShowUpdateSAP(!showUpdateSAP)}}>
                  { showUpdateSAP ? (selectedEspecialista.dados_usuario?.codigo_sap ? " - Voltar" : "Voltar") : (selectedEspecialista.dados_usuario?.codigo_sap ? " - Editar" : "Cadastrar") }
                </span>
              </div>

              { showUpdateSAP && 
                  <Form ref={formRef} onSubmit={handleSubmit}>
                    <WrapRow style={{ marginTop: "20px" }}>
                      <WrapRowItem>
                        <FormInput
                          name="codigo_sap"
                          type="text"
                          placeholder="Código SAP"
                        />
                      </WrapRowItem>
                    </WrapRow>

                    <Button type="submit" loading={loading} style={{ marginBottom: "15px" }}>
                      Salvar
                    </Button>
                  </Form>
                }

              <div className="wrapper-data-label" style={{marginTop:"30px"}}>
                Endereço:
              </div>
              <div className="wrapper-data-info">
                CEP: {selectedEspecialista.endereco?.cep}
              </div>
              <div className="wrapper-data-info">
                Rua: {selectedEspecialista.endereco?.rua}
              </div>
              <div className="wrapper-data-info">
                Número: {selectedEspecialista.endereco?.numero}
              </div>
              <div className="wrapper-data-info">
                Complemento: {selectedEspecialista.endereco?.complemento}
              </div>
              <div className="wrapper-data-info">
                Bairro: {selectedEspecialista.endereco?.bairro}
              </div>
              <div className="wrapper-data-info">
                Cidade: {selectedEspecialista.endereco?.cidade}
              </div>
              <div className="wrapper-data-info">
                Estado: {selectedEspecialista.endereco?.estado}
              </div>

              <div className="wrapper-data-label" style={{marginTop:"30px"}}>
                Contatos:
              </div>
              {selectedEspecialista.contatos?.map((item:any) => (
                <div key={item.conteudo}>
                  <div className="wrapper-data-info">
                    <span style={{textTransform:"capitalize"}}>{item.tipo}</span>: {item.conteudo}
                  </div>
                </div>
              ))}

              {selectedEspecialista.dados_usuario?.pj_cnpj &&
                <>
                  <div className="wrapper-data-label" style={{marginTop:"30px"}}>
                    Dados Pessoa Jurídica:
                  </div>
                  <div className="wrapper-data-info">
                    CNPJ: {cnpj.format(selectedEspecialista.dados_usuario?.pj_cnpj)}
                  </div>
                  <div className="wrapper-data-info">
                    Razão social: {selectedEspecialista.dados_usuario?.pj_razao_social}
                  </div>
                  <div className="wrapper-data-info">
                    CEP: {selectedEspecialista.dados_usuario?.pj_cep}
                  </div>
                  <div className="wrapper-data-info">
                    Rua: {selectedEspecialista.dados_usuario?.pj_rua}
                  </div>
                  <div className="wrapper-data-info">
                    Número: {selectedEspecialista.dados_usuario?.pj_numero}
                  </div>
                  <div className="wrapper-data-info">
                    Complemento: {selectedEspecialista.dados_usuario?.pj_complemento}
                  </div>
                  <div className="wrapper-data-info">
                    Bairro: {selectedEspecialista.dados_usuario?.pj_bairro}
                  </div>
                  <div className="wrapper-data-info">
                    Cidade: {selectedEspecialista.dados_usuario?.pj_cidade}
                  </div>
                  <div className="wrapper-data-info">
                    Estado: {selectedEspecialista.dados_usuario?.pj_uf}
                  </div>
                </>
              }
  
              { !(selectedEspecialista.dados_usuario?.matricula && selectedEspecialista.dados_usuario?.matricula.length >= 3) &&
                <>
                  <div className="wrapper-data-label" style={{marginTop:"30px"}}>
                    Dados para Pagamento:
                  </div>
                  <div className="wrapper-data-info">
                    Tipo de Conta Bancária: {selectedEspecialista.dados_pagamento?.conta_pessoa_fisica ? " Pessoa Física" : " Pessoa Jurídica"}
                  </div>
                  <div className="wrapper-data-info">
                    Banco: {selectedEspecialista.dados_pagamento?.banco}
                  </div>
                  <div className="wrapper-data-info">
                    Agência: {selectedEspecialista.dados_pagamento?.agencia}
                  </div>
                  <div className="wrapper-data-info">
                    Conta Corrente: {selectedEspecialista.dados_pagamento?.conta}
                  </div>
                  <div className="wrapper-data-info">
                    Digito Verificador: {selectedEspecialista.dados_pagamento?.digito_verificador}
                  </div>
                </>
              }

              <div className="wrapper-data-label" style={{marginTop:"30px"}}>
                Treinamentos:
              </div>
              <div className="wrapper-data-info">
                Treinamento Conteudista: {selectedEspecialista.dados_usuario?.treinamento_obrigatorio ? " Concluído" : " Pendente"}
              </div>
            </div>

          </CargaTemaData>
        </Card>
      </Content>
    </Container>
  );
};

export default CuradoresVisualizar;
