import styled from 'styled-components';
// import { shade } from 'polished';

export const Container = styled.div`
  iframe {
    width: 100%;
    height: 100%;
  }

  .icon {
    font-size: 22px;
    color: #717171;
    cursor: pointer;
  }

  .title-sweetalert{
    font-size: 8px;
    color: red;
  }

  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .obrigatorio{
    background: #e0e0e0;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    padding: 10px 15px;
    display: flex;
    border-radius: 8px;
    margin-bottom: 30px;
  }

  .obrigatorio svg{
    font-size: 20px;
    margin-right: 7px;
  }

  .obrigatorio span{
    line-height: 22px;
  }

  .completed-info {
    background: #1A8C98;
    color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    padding: 10px 15px;
    display: flex;
    border-radius: 8px;
    margin-bottom: 30px;
  }

  .completed-info svg{
    font-size: 20px;
    margin-right: 7px;
  }

  .completed-info span{
    line-height: 22px;
  }

  .not-completed-info {
    background: #ffcd3a;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    padding: 10px 15px;
    display: flex;
    border-radius: 8px;
    margin-bottom: 30px;
  }

  .not-completed-info svg{
    font-size: 20px;
    margin-right: 7px;
  }

  .not-completed-info span{
    line-height: 22px;
  }

  .label-links-top{
    margin-top: 10px;
    margin-bottom: 40px;
  }

  .label-links{
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .link-conteudo-treinamento{
    text-align: center;
    cursor: pointer;
    font-size: 18px;
    text-decoration: none;
    color: #717171;
    min-width: 350px;
    min-height: 40px;
  }

  .item-titulo{
    font-size: 26px;
    font-weight: 500;
    text-decoration: none;
    color: #717171;
    margin-right: 20px;
    line-height: 45px;
    margin-bottom: 15px;
  }

  .item-subtitulo{
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    color: #717171;
    margin-bottom: 40px;
    margin-right: 20px;
  }

  .item-descricao{
    font-size: 16px;
    text-decoration: none;
    color: #717171;
    margin-bottom: 40px;
    margin-right: 20px;
  }

  .link-url {
    display: flex;
    font-size: 20px;
    text-decoration: none;
    color: #1FB0BE;
    margin-bottom: 10px;
  }

  .link-url svg{
    font-size: 18px;
    margin-right: 7px;
  }

  .link-url span{
    line-height: 20px;
  }


  .table-subheader-button {
    font-size: 16px;
    color: #717171;
    cursor: pointer;
    border: 0.5px solid #e5e5e5;
    border-radius: 4px;
    padding: 5px 10px;
    line-height: 27px;
    margin-right: 10px;
    display: flex;
  }

  .table-subheader-button svg {
    margin-right: 7px;
    height: 100%;
  }

  .table-subheader-button span {
    /* margin-left: 5px; */
  }

  .table-subheader-button :hover {
    opacity: 0.8;
  }

  .table-subheader-button-disabled {
    font-size: 16px;
    color: #e5e5e5;
    cursor: pointer;
    border: 0.5px solid #717171;
    border-radius: 4px;
    padding: 5px 10px;
    line-height: 27px;
  }
`;

export const Content = styled.main`
  /* max-width: 1120px;
  margin: 10px auto; */
  display: flex;
  /* margin-bottom: 80px; */
  flex-direction: column;

  form{
    width: 100%;

    h2{
      margin-bottom: 30px;
      padding: 5px 0;
    }
  }
`;

export const WrapRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row; 
  flex-wrap: wrap;


`;

export const WrapRowItem = styled.div`
  flex: 1;
  margin: 0 10px;
`;

export const Item = styled.div`
  flex: 1;
  margin: 20px 5px;
  background-color: #F1F1F1;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .title{
    font-size: 18px;
    margin-bottom: 10px;
  }

  .subtitle{
    font-weight: lighter;
  }

  .removeIcon{
    cursor: pointer;
  }
`;
