import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import FormInput from '../../components/FormInput';
// import FormInputMask from '../../components/FormInputMask';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem,
} from './styles';

interface ServicosFormData {
  id: string;
  titulo: string;
}

interface OptionsData {
  value: any;
  label: string;
}


const ServicosCadastro: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [optionsClientes, setOptionsClientes] = useState<OptionsData[]>([]);
  const [selectedCliente, setSelectedCliente] = useState<OptionsData>({} as OptionsData);

  useEffect(() => {
    getClientes();
  }, []);

  const handleSubmit = useCallback(
    async (data: ServicosFormData) => {
      try {
        //console.log("handleSubmit", data);

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          titulo: Yup.string().required('Título obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const params: any = {
          titulo: data.titulo,
          id_cliente: selectedCliente.value
        }
        //console.log("params", params);

        await api.post(`/servico`, params);

        addToast({
          type: 'success',
          title: 'Serviço cadastrado com sucesso',
          description: '',
        });

        goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao cadastrar serviço',
            description: err.response?.data[error] ?
              err.response.data[error] :
              'Erro ao cadastrar serviço, tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, selectedCliente],
  );

  function goBack() {
    history.push('/servicos');
  }

  const getClientes = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/cliente`);
      //console.log("response", response.data);

      setOptionsClientes(response.data.map((item: any) => {
        return {
          value: item.id,
          label: item.nome
        }
      }));
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);


  function handleChangeSelectedCliente(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedCliente({} as OptionsData);
      return;
    };

    if (newValue.length === 0) {
      setSelectedCliente({} as OptionsData);
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue tema", newValue.value);

    setSelectedCliente(newValue);
  };

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <Container>
      <Content>
        <Card>
          <div className="form">
            <div className="header">
              <h2>Cadastrar Serviço</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            <Form ref={formRef} onSubmit={handleSubmit}>
              <WrapRow>
                <WrapRowItem>
                  <FormInput
                    name="titulo"
                    type="text"
                    placeholder="Título"
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem style={{ marginBottom: "30px" }}>
                  <div style={{ color: '#666', marginBottom: '5px', }}>Selecione o Cliente:</div>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedCliente}
                    options={optionsClientes}
                    value={selectedCliente}
                  />
                </WrapRowItem>
              </WrapRow>

              <Button type="submit" loading={loading} style={{ marginBottom: "20px" }}>
                Cadastrar
              </Button>

            </Form>
          </div>
        </Card>
      </Content>
    </Container>
  );
};

export default ServicosCadastro;
