import React, { useCallback, useState, useEffect } from 'react';
import { FiTrash, FiPlus, FiEdit } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content
} from './styles';

interface StatusCancelamentoInterface {
  id: string;
  descricao: string;
}

const StatusCancelamento: React.FC = () => {
  const history = useHistory();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal)

  const [loading, setLoading] = useState(true);
  const [statusCancelamento, setStatusCancelamento] = useState<StatusCancelamentoInterface[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);

  useEffect(() => {
    getStatusCancelamento();
  }, []);

  useEffect(() => {
    if (clearSelectedRows) {
      setClearSelectedRows(false)
    }
  }, [clearSelectedRows]);

  const getStatusCancelamento = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/status-cancelamento`);
      //console.log("response", response.data);

      setStatusCancelamento(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const removeSelectedRows = useCallback(() => {
    MySwal.fire({
      // title: 'Tem certeza que deseja remover?',
      showCancelButton: true,
      confirmButtonText: `Remover`,
      confirmButtonColor: '#1CAEBD',
      cancelButtonColor: '#312e38',
      cancelButtonText: 'Cancelar',
      // icon: 'warning',
      html: '<div style="font-size:20px">Tem certeza que deseja remover?</div>',
      // position: 'top'
    }).then((result) => {
      if (result.isConfirmed) {
        selectedRows.map((row: any) => {
          removeRow(row.id);
        });
      }
    })
  }, [selectedRows]);

  const removeRow = useCallback(async (rowId) => {
    //console.log("remove", rowId);
    setLoading(true);

    try {
      await api.delete(`/status-cancelamento/${rowId}`);

      setClearSelectedRows(true);

      addToast({
        type: 'success',
        title: 'Status de cancelamento removido com sucesso',
        description: '',
      });

      getStatusCancelamento();
    } catch (err) {
      for (let error in err.response?.data) {
        addToast({
          type: 'error',
          title: 'Erro ao remover status de cancelamento',
          description: err.response?.data[error] ?
            err.response.data[error] :
            'Erro ao remover status de cancelamento, tente novamente mais tarde.',
        });
      }
    }
  }, []);

  function goStoreForm() {
    history.push("/status-cancelamento/cadastro");
  }

  function goUpdateForm() {
    history.push("/status-cancelamento/edicao", {
      selectedItem: selectedRows[0]
    });
  }

  const handleChange = (state: any) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    //console.log('Selected Rows: ', state.selectedRows);

    setSelectedRows(state.selectedRows);
  };

  const actions = (
    <FiPlus className="icon" onClick={() => {
      goStoreForm();
    }} />
  );

  const contextActions = (
    <>
      {selectedRows.length === 1 &&
        <FiEdit className="icon" style={{ marginRight: "15px" }} onClick={() => {
          goUpdateForm();
        }} />
      }
      <FiTrash className="icon" style={{ marginRight: "10px" }} onClick={() => {
        removeSelectedRows();
      }} />
    </>
  );

  const columns = [
    {
      name: 'Título',
      selector: 'descricao',
      sortable: false,
      // right: true,
    },
  ];

  return (
    <Container>
      <Content>
        <Card>
          <DataTable
            title="Status de Cancelamento"
            columns={columns}
            data={statusCancelamento}
            selectableRows
            highlightOnHover
            onSelectedRowsChange={handleChange}
            defaultSortField="descricao"
            pagination
            actions={actions}
            contextActions={contextActions}
            noDataComponent="Nenhum registro encontrado."
            paginationComponentOptions={{
              rowsPerPageText: 'Itens por página',
              rangeSeparatorText: 'de'
            }}
            clearSelectedRows={clearSelectedRows}
            contextMessage={{
              singular: 'item selecionado',
              plural: 'itens selecionados',
              message: ''
            }}
            progressComponent={<Loading />}
            progressPending={loading}
          />
        </Card>
      </Content>
    </Container>
  );
};

export default StatusCancelamento;
