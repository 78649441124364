import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Switch from 'react-switch';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import FormInput from '../../components/FormInput';
// import FormInputMask from '../../components/FormInputMask';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Loading from '../../components/Loading';
import { useAuth } from '../../hooks/auth';

import api from '../../services/api';

import { Container, Content, WrapRow, WrapRowItem } from './styles';

interface ProjetosFormData {
  id: string;
  titulo: string;
}

interface OptionsData {
  value: any;
  label: string;
}

const ProjetosCadastro: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const { user } = useAuth();
  const { addToast } = useToast();
  console.log('user', user);
  const [loading, setLoading] = useState(false);
  const [modalidades, setModalidades] = useState<any[]>([]);
  const [optionsModalidades, _] = useState<OptionsData[]>([
    { value: 'tema', label: 'Tema' },
    { value: 'tema avulso', label: 'Tema Avulso' },
    { value: 'disciplina', label: 'Disciplina' },
    { value: 'curso', label: 'Curso' },
  ]);
  const [selectedModalidade, setSelectedModalidade] = useState<OptionsData>({
    value: '',
    label: '',
  });
  const [optionsClientes, setOptionsClientes] = useState<OptionsData[]>([]);
  const [selectedCliente, setSelectedCliente] = useState<OptionsData>(
    {} as OptionsData,
  );
  const [utilizaVerba, setUtilizaVerba] = useState<boolean>(false);
  const [
    utilizaDataPrestacaoServico,
    setUtilizaDataPrestacaoServico,
  ] = useState<boolean>(false);

  useEffect(() => {
    getClientes();
  }, []);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        //console.log("handleSubmit", data);

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          titulo: Yup.string().required('Título obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        //console.log("data", data);

        const params = {
          titulo: data.titulo,
          tipo_contrato: data.tipo_contrato === '' ? null : data.tipo_contrato,
          id_cliente: selectedCliente.value,
          tipo_modalidade: selectedModalidade.value,
          tem_data_prestacao_servico: utilizaDataPrestacaoServico,
          utiliza_verba: utilizaVerba,
          id_usuario: user.id,
        };

        // console.log(params);

        await api.post(`/projeto`, params);

        addToast({
          type: 'success',
          title: 'Projeto cadastrado com sucesso',
          description: '',
        });

        goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao cadastrar projeto',
            description: err.response?.data[error]
              ? err.response.data[error]
              : 'Erro ao cadastrar projeto, tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      history,
      addToast,
      selectedCliente,
      selectedModalidade,
      utilizaDataPrestacaoServico,
      utilizaVerba,
    ],
  );

  function goBack() {
    history.push('/projetos');
  }

  function handleChangeSelectedModalidade(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedModalidade({ value: '', label: '' });
      return;
    }

    if (newValue.length === 0) {
      setSelectedModalidade({ value: '', label: '' });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue tema", newValue.value);

    setSelectedModalidade(newValue);
  }

  function handleChangeSelectedCliente(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedCliente({} as OptionsData);
      return;
    }

    if (newValue.length === 0) {
      setSelectedCliente({} as OptionsData);
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue tema", newValue.value);

    setSelectedCliente(newValue);
  }

  const getClientes = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/cliente`);
      //console.log("response", response.data);

      setOptionsClientes(
        response.data.map((item: any) => {
          return {
            value: item.id,
            label: item.nome,
          };
        }),
      );
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Content>
        <Card>
          <div className="form">
            <div className="header">
              <h2>Cadastrar Projeto</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            <Form ref={formRef} onSubmit={handleSubmit}>
              <WrapRow>
                <WrapRowItem>
                  <FormInput name="titulo" type="text" placeholder="Título" />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem style={{ marginBottom: '20px' }}>
                  <div style={{ color: '#666', marginBottom: '5px' }}>
                    Selecione o Cliente:
                  </div>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedCliente}
                    options={optionsClientes}
                    value={selectedCliente}
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem>
                  <FormInput
                    name="tipo_contrato"
                    type="text"
                    placeholder="Tipos de Contrato"
                    disabled={
                      !(
                        selectedCliente &&
                        selectedCliente.value &&
                        selectedCliente.value !== 1
                      )
                    }
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem style={{ marginTop: '10px' }}>
                  <div style={{ color: '#666', marginBottom: '5px' }}>
                    Selecione a Modalidade:
                  </div>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedModalidade}
                    options={optionsModalidades}
                    value={selectedModalidade}
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow style={{ marginTop: '30px' }}>
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  offColor={'#e6e6e6'}
                  onColor={'#2FB4C2'}
                  onChange={() => {
                    setUtilizaVerba(!utilizaVerba);
                  }}
                  checked={utilizaVerba}
                />
                <span className="text-switch">Utiliza Verba</span>
              </WrapRow>

              <WrapRow style={{ marginTop: '30px' }}>
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  offColor={'#e6e6e6'}
                  onColor={'#2FB4C2'}
                  onChange={() => {
                    setUtilizaDataPrestacaoServico(
                      !utilizaDataPrestacaoServico,
                    );
                  }}
                  checked={utilizaDataPrestacaoServico}
                />
                <span className="text-switch">
                  Utiliza Data Prestação Serviço
                </span>
              </WrapRow>

              <Button
                type="submit"
                loading={loading}
                style={{ marginTop: '30px' }}
              >
                Cadastrar
              </Button>
            </Form>
          </div>
        </Card>
      </Content>
    </Container>
  );
};

export default ProjetosCadastro;
