import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { FiTrash, FiPlus, FiEdit, FiX, FiUploadCloud } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { FormHandles } from '@unform/core';
import ReactTooltip from 'react-tooltip';
import { BsFileEarmarkCheck, BsFileEarmarkText, BsFileEarmarkArrowUp, BsFileRichtext } from "react-icons/bs";

import UploadInput from './UploadInput';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
import Loading from '../../components/Loading';
// import FormInput from '../../components/FormInput';
// import Button from '../../components/Button';

import api from '../../services/api';

import {
  Container,
  Content,
  TextField,
  ClearButton
} from './styles';

interface CargaTemasInterface {
  id: string;
}

// interface OptionsData {
//   value: boolean;
//   label: string;
// }

const CargaTemas: React.FC = () => {
  // const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const { token } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal)

  const [loading, setLoading] = useState(true);
  const [cargaTemas, setCargaTemas] = useState<CargaTemasInterface[]>([]);
  // const [selectedContrato, setSelectedContrato] = useState<CargaTemasInterface>({} as CargaTemasInterface);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [showImportXLS, setShowImportXLS] = useState(false);
  

  useEffect(() => {
    getCargaTemas();
  }, []);

  const filtered = useMemo(() => {
    if (!filterText)
      return cargaTemas

    let search = filterText.toLowerCase();

    search = search.split("-").join("");
    search = search.split(".").join("");

    search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

    // return cargaTemas.filter(contrato => {
    //   return contrato.tema?.titulo.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
    //     contrato.hash.toLowerCase().includes(search) ||
    //     contrato.tipo_contratacao.toLowerCase().includes(search) ||
    //     contrato.status?.descricao?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
    //     contrato.especialista?.dados_usuario?.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(search) ||
    //     contrato.especialista?.dados_usuario?.cpf.toLowerCase().includes(search) ||
    //     contrato.produtos[0]?.servico?.titulo.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search)
    // })

    return cargaTemas.filter((item:any) => {
      return item.periodo?.periodo?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        item.lote?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search)
    })
  }, [filterText, cargaTemas])


  const getCargaTemas = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/carga-temas`);
      //console.log("response", response.data);

      setCargaTemas(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const removeSelectedRows = useCallback(() => {
    MySwal.fire({
      // title: 'Tem certeza que deseja remover?',
      showCancelButton: true,
      confirmButtonText: `Remover`,
      confirmButtonColor: '#1CAEBD',
      cancelButtonColor: '#312e38',
      cancelButtonText: 'Cancelar',
      // icon: 'warning',
      html: '<div style="font-size:20px">Tem certeza que deseja remover?</div>',
      // position: 'top'
    }).then((result) => {
      if (result.isConfirmed) {
        selectedRows.map((row: any) => {
          removeRow(row.id);
        });
      }
    })
  }, [selectedRows]);

  const removeRow = useCallback(async (rowId) => {
    //console.log("remove", rowId);
    setLoading(true);

    try {
      const response = await api.delete(`/carga-temas/${rowId}`);
      //console.log("remove response", response);

      setClearSelectedRows(true);

      addToast({
        type: 'success',
        title: 'Lote removido com sucesso',
        description: '',
      });

      getCargaTemas();
    } catch (err) {
      for (let error in err.response?.data) {
        addToast({
          type: 'error',
          title: 'Erro ao remover lote',
          description: err.response?.data[error] ?
            err.response.data[error] :
            'Erro ao remover lote, tente novamente mais tarde.',
        });
      }
    }
  }, []);

  // function goStoreForm() {
  //   history.push("/cargaTemas/cadastro");
  // }

  // function goUpdateForm() {
  //   history.push("/cargaTemas/edicao", {
  //     selectedItem: selectedRows[0]
  //   });
  // }

  const handleChange = (state: any) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    //console.log('Selected Rows: ', state.selectedRows);

    setSelectedRows(state.selectedRows);
  };

  const actions = (
    <>
      {!showImportXLS &&
        <div className="table-action-button" onClick={() => {
          setShowImportXLS(true);
        }}>
          {/* <FiPlus /> */}
          <span>Importar Planilha</span>
        </div>
      }
    </>
  );

  const contextActions = (
    <>
      {/* {selectedRows.length === 1 &&
        <FiEdit className="icon" style={{ marginRight: "15px" }} onClick={() => {
          goUpdateForm();
        }} />
      } */}
      <FiTrash className="icon" style={{ marginRight: "10px" }} onClick={() => {
        removeSelectedRows();
      }} />
    </>
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <>
        <TextField id="search" type="text" placeholder="Pesquisar" aria-label="Search Input" value={filterText} onChange={(e: any) => setFilterText(e.target.value)} />
        <ClearButton type="button" onClick={handleClear}>x</ClearButton>
      </>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: 'Período',
      selector: 'periodo',
      sortable: false,
      maxWidth: '100px',
      // right: true,
      cell: (row: any) => (
        <p>{row.periodo?.periodo}</p>
      )
    },
    {
      name: 'Lote',
      selector: 'lote',
      sortable: false,
      // right: true,
      cell: (row: any) => (
        <p>{row.lote}</p>
      )
    },
    {
      name: 'Curadorias',
      selector: 'curadorias_criadas',
      sortable: false,
      // right: true,
      hide: 1220
    },
    {
      name: 'Disciplinas',
      selector: 'disciplinas_criadas',
      sortable: false,
      // right: true,
      hide: 1220
    },
    {
      name: 'Áreas do Conhecimento',
      selector: 'areas_conhecimento_criadas',
      sortable: false,
      // right: true,
      hide: 1220
    },
    {
      name: 'Temas',
      selector: 'temas_criados',
      sortable: false,
      // right: true,
      hide: 1220
    },
    {
      name: 'Arquivo Base',
      selector: 'nome_arquivo',
      sortable: false,
      // right: true,
      width: '200px',
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: false,
      maxWidth: '200px',
      // right: true,
      cell: (row: any) => (
        <p>{row.finalizado ? "Finalizada" : "Aguardando confirmação"}</p>
      )
    },
    {
      name: 'Ações',
      button: true,
      cell: (row: any) => (
        <div className="wrapper-table-actions">
          <div className={`table-action criado`}
            onClick={() => history.push('/temas/carga/visualizar', { selectedItem: row })}>
            <BsFileEarmarkCheck data-tip data-for='tipVisualizar' />
          </div>

          <ReactTooltip id='tipVisualizar' effect='solid'>
            <span>Visualizar</span>
          </ReactTooltip>

        </div>
      ),
    },
  ];

  const setItems = useCallback(
    (currentItem, upload) => {
      //console.log('setItems', currentItem, upload);
      if(upload){
        setShowImportXLS(false);
        getCargaTemas();
      }
    },
    [],
  );

  return (
    <Container>
      <Content>
        { showImportXLS &&
          <Card>
            <div className="header" style={{ height: "0px", padding: "15px" }}>
              <h2>
                Importar Planilha
              </h2>
              <FiX onClick={() => setShowImportXLS(false)} className="icon" />
            </div>

            <div style={{ padding: "15px" }}>
              { loading ?
                <Loading />
              :
                <UploadInput item={{name: "xls"}} setItems={setItems} setLoading={setLoading} />
              }
            </div>
          </Card>
        }

        <Card>
          <DataTable
            title="Lotes"
            columns={columns}
            data={filtered}
            selectableRows
            highlightOnHover
            onSelectedRowsChange={handleChange}
            defaultSortField="created_at"
            pagination
            actions={actions}
            contextActions={contextActions}
            noDataComponent="Nenhum registro encontrado."
            paginationComponentOptions={{
              rowsPerPageText: 'Itens por página',
              rangeSeparatorText: 'de'
            }}
            // clearSelectedRows={clearSelectedRows}
            contextMessage={{
              singular: 'item selecionado',
              plural: 'itens selecionados',
              message: ''
            }}
            progressComponent={<Loading />}
            progressPending={loading}
            subHeader
            subHeaderComponent={subHeaderComponent}
            paginationResetDefaultPage={resetPaginationToggle}
            responsive
          />
        </Card>
      </Content>
    </Container>
  );
};

export default CargaTemas;
