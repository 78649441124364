import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { FiTrash, FiPlus, FiEdit } from 'react-icons/fi';
import { GoBook } from 'react-icons/go';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content
} from './styles';
import convertUTCDateToLocalDate from '../../utils/convertUTCDateToLocalDate';
import ReactTooltip from 'react-tooltip';
import { BsFillEyeFill } from 'react-icons/bs';

interface CuradoriasInterface {
  id: string;
  titulo: string;
}

const VerbaCuradorias: React.FC = () => {
  const history = useHistory();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal)

  const [loading, setLoading] = useState(true);
  const [verba, setVerba] = useState<any>([]);
  const [verbas, setVerbas] = useState<any[]>([]);
  const [curadorias, setCuradorias] = useState<CuradoriasInterface[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  useEffect(() => {
    // getCuradorias();
    getVerbas();
  }, []);

  useEffect(() => {
    if (clearSelectedRows) {
      setClearSelectedRows(false)
    }
  }, [clearSelectedRows]);

  const dataInicio = useMemo(() => {
    if(verba?.data_inicio){
      const dt = convertUTCDateToLocalDate(new Date(verba.data_inicio));
      return `${("0" + dt.getDate()).slice(-2)}/${("0" + (dt.getMonth() + 1)).slice(-2)}/${dt.getFullYear()}`
    }else{
      return "00/00/0000"
    }
  }, [verba]);

  const dataFinal = useMemo(() => {
    if(verba?.data_final){
      const dt = convertUTCDateToLocalDate(new Date(verba.data_final));
      return `${("0" + dt.getDate()).slice(-2)}/${("0" + (dt.getMonth() + 1)).slice(-2)}/${dt.getFullYear()}`
    }else{
      return "00/00/0000"
    }
  }, [verba]);

  // const getCuradorias = useCallback(async () => {
  //   setLoading(true);

  //   try {
  //     const response = await api.get(`/curadoria`);
  //     // console.log("response", response.data);

  //     const tmp:any = [];

  //     response.data.forEach((item:any) => {
  //       if(item.utiliza_verba){
  //         tmp.push(item)
  //       }
  //     });

  //     // console.log("curadorias", tmp);

  //     setCuradorias(tmp);
  //   } catch (error) {
  //     //console.log(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, []);

  const getVerbas = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/verba`);
      // console.log("response", response.data);

      setVerbas(response.data);

      if(response.data){
        const atual = response.data.find((item:any) => item.corrente)

        if(atual){
          // console.log("atual", atual);

          setVerba(atual);
          updateCuradorias(atual);
        }
      }

    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const updateCuradorias = useCallback(async (verba_selecionada) => {
    const temp:any = [];

    verba_selecionada.curadorias.forEach((curadoria:any) => {
      temp.push({
        id: curadoria.id,
        nome: curadoria.nome,
        verba: curadoria.pivot.verba_total,
        verba_utilizada: curadoria.pivot.verba_utilizada,
      })
    });

    // console.log("temp", temp);

    setCuradorias(temp)
  }, []);

  const removeSelectedRows = useCallback(() => {
    MySwal.fire({
      // title: 'Tem certeza que deseja remover?',
      showCancelButton: true,
      confirmButtonText: `Remover`,
      confirmButtonColor: '#1CAEBD',
      cancelButtonColor: '#312e38',
      cancelButtonText: 'Cancelar',
      // icon: 'warning',
      html: '<div style="font-size:20px">Tem certeza que deseja remover esta verba?</div>',
      // position: 'top'
    }).then((result) => {
      if (result.isConfirmed) {
        selectedRows.map((row: any) => {
          removeRow(row.id);
        });
      }
    })
  }, [selectedRows]);

  const removeRow = useCallback(async (rowId) => {
    //console.log("remove", rowId);
    setLoading(true);

    try {
      await api.delete(`/verba/${rowId}`);

      setClearSelectedRows(true);

      addToast({
        type: 'success',
        title: 'Verba removida com sucesso',
        description: '',
      });

      // getCuradorias();
      getVerbas();
    } catch (err) {
      for (let error in err.response?.data) {
        addToast({
          type: 'error',
          title: 'Erro ao remover verba',
          description: err.response?.data[error] ?
            err.response.data[error] :
            'Erro ao remover verba, tente novamente mais tarde.',
        });
      }
    }
  }, []);

  function goStoreForm() {
    history.push("/curadorias/verba/cadastro");
  }

  function goUpdateForm() {
    history.push("/curadorias/verba/edicao", {
      selectedItem: selectedRows[0]
    });
  }

  const handleChange = (state: any) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    //console.log('Selected Rows: ', state.selectedRows);

    setSelectedRows(state.selectedRows);
  };

  const actions = (
    <>     
      <GoBook className="icon" style={{marginRight:"10px"}} onClick={() => {
        setShowHistory(true);
      }} />

      <FiPlus className="icon" onClick={() => {
        goStoreForm();
      }} />
    </>
  );

  const contextActions = (
    <>
      {selectedRows.length === 1 &&
        <FiEdit className="icon" style={{ marginRight: "15px" }} onClick={() => {
          goUpdateForm();
        }} />
      }
      <FiTrash className="icon" style={{ marginRight: "10px" }} onClick={() => {
        removeSelectedRows();
      }} />
    </>
  );

  const columns = [
    {
      name: 'Nome',
      selector: 'nome',
      sortable: false,
      // right: true,
    },
    {
      name: 'Utilizado',
      selector: 'verba',
      sortable: false,
      // right: true,
      cell: (row: any) => {
        if(row.verba_utilizada > row.verba){
          return (
            <div style={{color:"#ff0000"}}>
              R$ {parseFloat(row.verba_utilizada).toFixed(2)}
            </div>
          )
        }else{
          return (
            <>
              R$ {parseFloat(row.verba_utilizada).toFixed(2)}
            </>
          )
        }
      }
    },
    {
      name: 'Verba',
      selector: 'verba',
      sortable: false,
      // right: true,
      cell: (row: any) => {
        return (
          <>
            R$ {parseFloat(row.verba).toFixed(2)}
          </>
        )
      }
    },
  ];

  const columns_verba = [
    {
      name: 'Vigência',
      selector: 'vigencia',
      sortable: false,
      // right: true,
      cell: (row: any) => {
        const verba_data_inicio_dt = convertUTCDateToLocalDate(new Date(row.data_inicio));
        const verba_data_inicio =  `${("0" + verba_data_inicio_dt.getDate()).slice(-2)}/${("0" + (verba_data_inicio_dt.getMonth() + 1)).slice(-2)}/${verba_data_inicio_dt.getFullYear()}`

        const verba_data_final_dt = convertUTCDateToLocalDate(new Date(row.data_final));
        const verba_data_final =  `${("0" + verba_data_final_dt.getDate()).slice(-2)}/${("0" + (verba_data_final_dt.getMonth() + 1)).slice(-2)}/${verba_data_final_dt.getFullYear()}`

        if(row.corrente === true){
          return (
            <div style={{color:"#057105"}}>
              {`${verba_data_inicio} até ${verba_data_final}`}
            </div>
          )
        }else{
          return (
            <>
              {`${verba_data_inicio} até ${verba_data_final}`}
            </>
          )
        }
      }
    },
    {
      name: 'Ações',
      button: true,
      cell: (row: any) => (
        <div className="wrapper-table-actions">
          <div
            className={`table-action`}
            onClick={() => {
              setVerba(row);
              updateCuradorias(row);
              setShowHistory(false);
            }}
          >
            <BsFillEyeFill data-tip data-for="tipVisualizarContrato" />
          </div>

          <ReactTooltip id="tipVisualizarContrato" effect="solid">
            <span>Visualizar Verba</span>
          </ReactTooltip>
        </div>
      ),
    },
  ];

  return (
    <Container>
      <Content>
        { !showHistory ? (
          <Card>
            <DataTable
              title={`Verba das Curadorias (${dataInicio} até ${dataFinal})`}
              columns={columns}
              data={curadorias}
              // selectableRows
              highlightOnHover
              onSelectedRowsChange={handleChange}
              defaultSortField="nome"
              pagination
              actions={actions}
              contextActions={contextActions}
              noDataComponent="Nenhum registro encontrado."
              paginationComponentOptions={{
                rowsPerPageText: 'Itens por página',
                rangeSeparatorText: 'de'
              }}
              clearSelectedRows={clearSelectedRows}
              contextMessage={{
                singular: 'item selecionado',
                plural: 'itens selecionados',
                message: ''
              }}
              progressComponent={<Loading />}
              progressPending={loading}
              // subHeader
              // subHeaderComponent={subHeaderComponent}
            />
          </Card>
        ) : (
          <Card>
            <DataTable
                title={`Histórico`}
                columns={columns_verba}
                data={verbas}
                selectableRows
                highlightOnHover
                onSelectedRowsChange={handleChange}
                defaultSortField="nome"
                pagination
                // actions={actions}
                contextActions={contextActions}
                noDataComponent="Nenhum registro encontrado."
                paginationComponentOptions={{
                  rowsPerPageText: 'Itens por página',
                  rangeSeparatorText: 'de'
                }}
                clearSelectedRows={clearSelectedRows}
                contextMessage={{
                  singular: 'item selecionado',
                  plural: 'itens selecionados',
                  message: ''
                }}
                progressComponent={<Loading />}
                progressPending={loading}
            />
          </Card>
        )}

        
      </Content>
    </Container>
  );
};

export default VerbaCuradorias;
