import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import FormInput from '../../components/FormInput';
// import FormInputMask from '../../components/FormInputMask';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem,
} from './styles';

interface CuradoriaFormData {
  id: string;
  nome: string;
  curadores: any;
  areas_conhecimento: any;
}

interface CuradoresFormData {
  id: string;
  nome: string;
  email: string;
}

interface AreasConhecimentoFormData {
  id: string;
  descricao: string;
}

interface OptionsData {
  value: string;
  label: string;
}

const CuradoriasEdicao: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location:any = useLocation();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [curadoria, setCuradoria] = useState<CuradoriaFormData>({} as CuradoriaFormData);
  const [curadores, setCuradores] = useState<CuradoresFormData[]>([]);
  const [optionsCuradores, setOptionsCuradores] = useState<OptionsData[]>([]);
  const [selectedCurador, setSelectedCurador] = useState<OptionsData[]>([]);
  const [areasConhecimento, setAreasConhecimento] = useState<AreasConhecimentoFormData[]>([]);
  const [optionsAreasConhecimento, setOptionsAreasConhecimento] = useState<OptionsData[]>([]);
  const [selectedAreaConhecimento, setSelectedAreaConhecimento] = useState<OptionsData[]>([]);
  const [selectedItem, setSelectedItem] = useState<CuradoriaFormData>({} as CuradoriaFormData);

  useEffect(() => {
    if(location && location.state){
      setSelectedItem(location.state.selectedItem);
    }
  }, [location]);

  useEffect(() => {
    if(selectedItem && selectedItem.id){
      //console.log("selectedItem", selectedItem);
      getCuradoria(selectedItem.id);

      // formRef.current?.setFieldValue("nome", selectedItem.nome);

      // formRef.current?.setFieldValue("codigo", selectedItem.codigo);

      // if(selectedItem.tags){
      //   let temp:OptionsData[] = [];
      //   tags.forEach((tag:TagFormData) => {
      //     selectedItem.tags.forEach((tag_:TagFormData) => {
      //       if(tag.id === tag_.id){
      //         temp.push({
      //           value: tag.id, 
      //           label: tag.tag
      //         })
      //       }
      //     });
      //   });

      //   if(temp){
      //     setSelectedTags(temp);
      //   }
      // }

      // if(selectedItem.id_area_conhecimento){
      //   const current = areasConhecimento.find((item) => item.id === selectedItem.id_area_conhecimento)
      //   if(current){
      //     setSelectedAreaConhecimento({ value: current.id, label: current.descricao });
      //   }
      // }
    }
  }, [selectedItem, areasConhecimento]);

  useEffect(() => {
    if(curadoria && curadoria.id){
      //console.log("curadoria", curadoria);

      formRef.current?.setFieldValue("nome", curadoria.nome);

      if(curadoria.curadores){
        let temp:OptionsData[] = [];
        curadores.forEach((curador:CuradoresFormData) => {
          curadoria.curadores.forEach((curador_:CuradoresFormData) => {
            if(curador.id === curador_.id){
              temp.push({
                value: curador_.id, 
                label: curador_.email
              })
            }
          });
        });

        if(temp){
          setSelectedCurador(temp);
        }
      }

      if(curadoria.areas_conhecimento){
        let temp:OptionsData[] = [];
        areasConhecimento.forEach((areaConhecimento:AreasConhecimentoFormData) => {
          curadoria.areas_conhecimento.forEach((areaConhecimento_:AreasConhecimentoFormData) => {
            if(areaConhecimento.id === areaConhecimento_.id){
              //console.log("???????", areaConhecimento.id);
              temp.push({
                value: areaConhecimento_.id, 
                label: areaConhecimento_.descricao
              })
            }
          });
        });

        if(temp){
          setSelectedAreaConhecimento(temp);
        }
      }
    }
  }, [curadoria, curadores, areasConhecimento]);

  useEffect(() => {
    getCuradores();
    getAreasConhecimento();
  }, []);

  useEffect(() => {
    if (curadores.length === 0) return;

    var options: OptionsData[] = [];

    curadores.map(item => {
      options.push({ value: item.id, label: item.nome ? `${item.nome} (${item.email})` : item.email });
    });

    setOptionsCuradores(options);
    //console.log("options", options);
  }, [curadores]);

  useEffect(() => {
    if (areasConhecimento.length === 0) return;

    var options: OptionsData[] = [];

    areasConhecimento.map(item => {
      options.push({ value: item.id, label: item.descricao });
    });

    setOptionsAreasConhecimento(options);
    //console.log("options area-conhecimento", options);
  }, [areasConhecimento]);

  const getCuradoria = useCallback(async (id) => {
    const response = await api.get(`/curadoria/${id}`);
    //console.log("getCuradoria", response.data);

    setCuradoria(response.data);
  }, []);
  
  const getCuradores = useCallback(async () => {
    const response = await api.get(`/busca-usuario`, { params: { papel: ["curador","curador-adjunto"] } });
    //console.log("getCuradores", response.data);

    setCuradores(response.data);
  }, []);

  const getAreasConhecimento = useCallback(async () => {
    const response = await api.get(`/area-conhecimento`);
    //console.log("area-conhecimento", response.data);

    setAreasConhecimento(response.data);
  }, []);

  const handleSubmit = useCallback(
    async (data: CuradoriaFormData) => {
      try {
        //console.log("handleSubmit", data);

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Título obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let params = {
          nome: data.nome,
          curadores: selectedCurador ? selectedCurador.map((item) => item.value) : [],
          areas_conhecimento: selectedAreaConhecimento ? selectedAreaConhecimento.map((item) => item.value) : []
        }

        //console.log("params", params);

        await api.put(`/curadoria/${selectedItem.id}`, params);

        addToast({
          type: 'success',
          title: 'Curadoria atualizada com sucesso',
          description: '',
        });

        goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }
        
        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar curadoria',
            description: err.response?.data[error] ?
              err.response.data[error] :
              'Erro ao atualizar curadoria, tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, selectedAreaConhecimento, selectedItem, selectedCurador],
  );

  function goBack() {
    history.push('/curadorias');
  }

  function handleChangeSelectedCurador(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedCurador([]);
      return;
    };

    if (newValue.length === 0) {
      setSelectedCurador([]);
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue tags", newValue.value);

    setSelectedCurador(newValue);
  };

  function handleChangeSelectedAreaConhecimento(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedAreaConhecimento([]);
      return;
    };

    if (newValue.length === 0) {
      setSelectedAreaConhecimento([]);
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue", newValue.value);

    setSelectedAreaConhecimento(newValue);
  };

  if(loading){
    return (
      <Loading />
    )
  }

  return (
    <Container>
      <Content>
        <Card>
          <div className="form">
            <div className="header">
              <h2>Editar Curadoria</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            <Form ref={formRef} onSubmit={handleSubmit}>
                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="nome"
                      type="text"
                      placeholder="Título"
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow style={{ marginTop: "10px", marginBottom: "30px" }}>
                  <WrapRowItem>
                    <label>Curadores e Curadores Adjuntos:</label>
                    <Select
                      isMulti
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedCurador}
                      options={optionsCuradores}
                      value={selectedCurador}
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow style={{ marginTop: "10px", marginBottom: "30px" }}>
                  <WrapRowItem>
                    <label>Áreas do conhecimento:</label>
                    <Select
                      isMulti
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedAreaConhecimento}
                      options={optionsAreasConhecimento}
                      value={selectedAreaConhecimento}
                    />
                  </WrapRowItem>
                </WrapRow>

                <Button type="submit" loading={loading}>
                  Salvar
            </Button>

              </Form>
          </div>
        </Card>
      </Content>
    </Container>
  );
};

export default CuradoriasEdicao;
