import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import convertUTCDateToLocalDate from '../../utils/convertUTCDateToLocalDate';

// import convertUTCDateToLocalDate from '../../utils/convertUTCDateToLocalDate';

import {
  WrapperTimeline,
  TimelineItem
} from './styles';


interface TimelineInterface {
  items: any;
}


const Timeline: React.FC<TimelineInterface> = ({ items }) => {

  const format_date = function (date: any, only_date: Boolean) {

   let dt_data = convertUTCDateToLocalDate(new Date(date));
    //let dt_data = new Date(date);

    let formated_date = `${("0" + dt_data.getDate()).slice(-2)}/${("0" + (dt_data.getMonth() + 1)).slice(-2)}/${dt_data.getFullYear()}`

    if (!only_date) {
      let hours = (dt_data.getHours().toString().length == 1 ? "0" + dt_data.getHours() : dt_data.getHours()) + ":"
        + (dt_data.getMinutes().toString().length == 1 ? "0" + dt_data.getMinutes() : dt_data.getMinutes()) + ":"
        + (dt_data.getSeconds().toString().length == 1 ? "0" + dt_data.getSeconds() : dt_data.getSeconds());

      formated_date = `${formated_date} às ${hours}`
    }

    return formated_date
  }


  return (
    <WrapperTimeline>
      {
        items.map((item: any, index: number) =>
          <TimelineItem key={`${item.status}-${index}`} done={item.done} canceled={item.canceled}>
            <div className="timestamp" >
              <span className="date">{item.date ? format_date(item.date, true) : ""}</span>
            </div>
            <div className="status" data-tip data-for={`${item.status}-${index}`}>
              <div> {item.status} </div>
            </div>

            {item.user_action &&
              <ReactTooltip id={`${item.status}-${index}`} effect='solid'>
                <div style={{ textAlign: 'center', lineHeight: '20px' }}>
                  <p style={{}}>{item.user_action}</p>
                  <p style={{ textAlign: 'center' }}>
                    {/* {item.date_log} */}
                    {format_date(item.date_log, false)}
                  </p>
                </div>

              </ReactTooltip>
            }




          </TimelineItem>
        )}
    </WrapperTimeline>



  );
}

export default Timeline;
