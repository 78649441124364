import React, {InputHTMLAttributes, useCallback, useEffect, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiMenu, FiPower, FiX } from 'react-icons/fi';
import Select from 'react-select';

import { useAuth } from '../../hooks/auth';
import logoImage from '../../assets/logo.png';

import { 
  Container, 
  Content, 
  WrapperMenuIcon, 
  WrapperLogo, 
  WrapperTitle, 
  WrapperActions,
  WrapperSelect,
  WrapperSelectItem
} from './styles';


interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  pageTitle: String;
  pageIcon: any;
  showMenu: boolean;
  setShowMenu: (status: boolean) => void;
  showResponsiveMenu: boolean;
  setShowResponsiveMenu: (status: boolean) => void;
}

interface OptionsData {
  value: any;
  label: string;
}


const Header: React.FC<InputProps> = ({pageTitle, pageIcon, showMenu, setShowMenu, showResponsiveMenu, setShowResponsiveMenu}) => {
  const history = useHistory();
  
  const { signOut, selectedClient, setSelectedClient, clients } = useAuth();
  const [optionsClients, setOptionsClients] = useState<OptionsData[]>([]);
  const [selectedOptClient, setSelectedOptClient] = useState<OptionsData>({} as OptionsData);

  useEffect(() => {
    if(!selectedClient) return;

    const optClients = clients.map((item:any) => {
      return {
        value: item.id,
        label: item.nome
      }
    })

    if(optClients){

      const clients_filtered = optClients.filter((v:any,i:any,a:any)=>a.findIndex((v2:any)=>(v.label === v2.label && v.value===v2.value))===i)

      setOptionsClients(clients_filtered);

      setSelectedOptClient({
        value: selectedClient.id,
        label: selectedClient.nome
      })

      // if(selectedClient){
      //   setSelectedOptClient({
      //     value: selectedClient.id,
      //     label: selectedClient.nome
      //   })
      // }else{
      //   setSelectedOptClient(optClients[0]);
      // }
    }

    //console.log("optClients", optClients)
  }, [clients, selectedClient]);

  useEffect(() => {
    if(selectedOptClient && selectedOptClient.value){
      //console.log("selectedOptClient", selectedOptClient);
      const cli = clients.find((c:any) => c.id === selectedOptClient.value)
      
      if(cli && cli.id){
        setSelectedClient(cli);
      }
  
      //console.log("cli", cli);
    }
  }, [selectedOptClient, clients])

  const logout = useCallback(
    async () => {
      try {
        signOut();
        history.push('/signin');
      } catch (err) {
        //console.log("signin error", err);
      } finally {
      }
    },
    [signOut, history],
  );

  
  function handleChangeSelectedClient(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedOptClient({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedOptClient({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue tema", newValue.value);

    setSelectedOptClient(newValue);
    
    history.push('/main');
  };


  return (
    <Container>
      <Content>
        <WrapperMenuIcon className="full-size">
          <FiMenu onClick={() => setShowMenu(!showMenu) } />
        </WrapperMenuIcon>

        <WrapperMenuIcon className="responsive">
          <FiMenu onClick={() => setShowResponsiveMenu(!showResponsiveMenu) } />
        </WrapperMenuIcon>

        <WrapperLogo>
          <Link to="/main">
            <img src={logoImage} alt="Flow" />
          </Link>
        </WrapperLogo>

        <WrapperTitle>
          {pageIcon}
          <h1>{pageTitle.toUpperCase()}</h1>
        </WrapperTitle>

        { (!showResponsiveMenu && optionsClients.length > 0) &&
          <WrapperSelect>
            <WrapperSelectItem>
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: provided => ({ ...provided, zIndex: 9999 }),
                  // input: styles => ({ ...styles }),
                }}
                placeholder={''}
                onChange={handleChangeSelectedClient}
                options={optionsClients}
                value={selectedOptClient}
              /> 
            </WrapperSelectItem>
          </WrapperSelect>
        }
        
        <WrapperActions className="full-size">
          <div>
            <button type="button" onClick={logout}>
              <FiPower />
            </button>
          </div>
        </WrapperActions>

        { showResponsiveMenu &&
          <WrapperActions className="responsive">
            <button type="button" onClick={() => setShowResponsiveMenu(false)}>
              <FiX />
            </button>
          </WrapperActions>
        }
      </Content>
    </Container>
  );
};

export default Header;
