import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`  
  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus{
    outline:0;
  }

  html, body, #root{
    height: 100%;
  }

  body{
    background: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.primaryColor};
    -webkit-font-smoothing: antialiased;
  }

  body, input, button{
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong{
    font-weight: 300;
  }

  button{
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 0;
  }

  a{
    text-decoration: none;
  }
  
  ul{
    list-style: none;
  }
`;
