import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import { useAuth } from '../hooks/auth';

import CadastroEspecialistaEdita from '../pages/_modules/Account/CadastroEspecialistaEdita';
import ConfirmEmail from '../pages/_modules/Authentication/ConfirmEmail';
import ForgotPassword from '../pages/_modules/Authentication/ForgotPassword';
import ResetPassword from '../pages/_modules/Authentication/ResetPassword';
import SignIn from '../pages/_modules/Authentication/SignIn';
import SignUp from '../pages/_modules/Authentication/SignUp';

import Main from '../pages/Main';

import AccessPermissions from '../pages/_modules/Account/AccessPermissions';
import ChangePassword from '../pages/_modules/Account/ChangePassword';
import Permissions from '../pages/_modules/Account/Permissions';
import PermissionsCadastro from '../pages/_modules/Account/PermissionsCadastro';
import PermissionsEdicao from '../pages/_modules/Account/PermissionsEdicao';
import Profile from '../pages/_modules/Account/Profile';
import Roles from '../pages/_modules/Account/Roles';
import RolesCadastro from '../pages/_modules/Account/RolesCadastro';
import RolesEdicao from '../pages/_modules/Account/RolesEdicao';
import Users from '../pages/_modules/Account/Users';
import UsersEdicao from '../pages/_modules/Account/UsersEdicao';

import Servicos from '../pages/Servicos';
import ServicosCadastro from '../pages/ServicosCadastro';
import ServicosEdicao from '../pages/ServicosEdicao';

import ConvidarColaborador from '../pages/ConvidarColaborador';
import ConvidarUsuario from '../pages/ConvidarUsuario';

import AceitarContrato from '../pages/AceitarContrato';
import AprovarContratos from '../pages/AprovarContratos';
import AprovarContratosVerba from '../pages/AprovarContratosVerba';
import AreasAtuacao from '../pages/AreasAtuacao';
import AreasAtuacaoCadastro from '../pages/AreasAtuacaoCadastro';
import AreasAtuacaoEdicao from '../pages/AreasAtuacaoEdicao';
import AreasConhecimento from '../pages/AreasConhecimento';
import AreasConhecimentoCadastro from '../pages/AreasConhecimentoCadastro';
import AreasConhecimentoEdicao from '../pages/AreasConhecimentoEdicao';
import ArquivosTreinamento from '../pages/ArquivosTreinamento';
import ArquivosTreinamentoCadastro from '../pages/ArquivosTreinamentoCadastro';
import ArquivosTreinamentoEdicao from '../pages/ArquivosTreinamentoEdicao';
import Carga from '../pages/Carga';
import CargaDisciplinasProducao from '../pages/CargaDisciplinasProducao';
import CargaDisciplinasProducaoVisualizar from '../pages/CargaDisciplinasProducaoVisualizar';
import CargaTemas from '../pages/CargaTemas';
import CargaTemasVisualizar from '../pages/CargaTemasVisualizar';
import CargaVisualizar from '../pages/CargaVisualizar';
import Cliente from '../pages/Cliente';
import ClienteCadastro from '../pages/ClienteCadastro';
import ClienteEdicao from '../pages/ClienteEdicao';
import Contratos from '../pages/Contratos';
import ContratosCadastro from '../pages/ContratosCadastro';
import ContratosCadastroEdita from '../pages/ContratosCadastroEdita';
import ContratosComandarPagamento from '../pages/ContratosComandarPagamento';
import ContratosEdicao from '../pages/ContratosEdicao';
import ContratosPermitirPagamento from '../pages/ContratosPermitirPagamento';
import ContratosResumoVisualizar from '../pages/ContratosResumoVisualizar';
import ContratosUpload from '../pages/ContratosUpload';
import ContratosVisualizar from '../pages/ContratosVisualizar';
import Curadores from '../pages/Curadores';
import CuradoresVisualizar from '../pages/CuradoresVisualizar';
import Curadorias from '../pages/Curadorias';
import CuradoriasCadastro from '../pages/CuradoriasCadastro';
import CuradoriasEdicao from '../pages/CuradoriasEdicao';
import CursosProducao from '../pages/CursoProducao';
import CursosProducaoEdicao from '../pages/CursoProducaoEdicao';
import Cursos from '../pages/Cursos';
import CursosCadastro from '../pages/CursosCadastro';
import CursosEdicao from '../pages/CursosEdicao';
import DisciplinasProducao from '../pages/DisciplinaProducao';
import DisciplinasProducaoEdicao from '../pages/DisciplinaProducaoEdicao';
import Disciplinas from '../pages/Disciplinas';
import DisciplinasEdicao from '../pages/DisciplinasEdicao';
import DocumentosUpload from '../pages/DocumentosUpload';
import EditaFlow from '../pages/EditaFlow';
import Especialistas from '../pages/Especialistas';
import EspecialistasEdita from '../pages/EspecialistasEdita';
import EspecialistasTreinamento from '../pages/EspecialistasTreinamento';
import EspecialistasVisualizar from '../pages/EspecialistasVisualizar';
import Formacao from '../pages/Formacao';
import FormacaoCadastro from '../pages/FormacaoCadastro';
import FormacaoEdicao from '../pages/FormacaoEdicao';
import Instituicoes from '../pages/Instituicoes';
import InstituicoesCadastro from '../pages/InstituicoesCadastro';
import InstituicoesEdicao from '../pages/InstituicoesEdicao';
import MeusContratos from '../pages/MeusContratos';
import Modelos from '../pages/Modelos';
import ModelosCadastro from '../pages/ModelosCadastro';
import ModelosEdicao from '../pages/ModelosEdicao';
import Periodos from '../pages/Periodos';
import PeriodosCadastro from '../pages/PeriodosCadastro';
import PeriodosEdicao from '../pages/PeriodosEdicao';
import Producoes from '../pages/Producoes';
import ProducoesCadastro from '../pages/ProducoesCadastro';
import ProducoesEdicao from '../pages/ProducoesEdicao';
import Produtos from '../pages/Produtos';
import ProdutosCadastro from '../pages/ProdutosCadastro';
import ProdutosEdicao from '../pages/ProdutosEdicao';
import Profissional from '../pages/Profissional';
import ProfissionalCadastro from '../pages/ProfissionalCadastro';
import ProfissionalEdicao from '../pages/ProfissionalEdicao';
import Projetos from '../pages/Projetos';
import ProjetosCadastro from '../pages/ProjetosCadastro';
import ProjetosEdicao from '../pages/ProjetosEdicao';
import RelatorioContratoGerais from '../pages/RelatorioContratoGerais';
import RelatorioContratoPeriodo from '../pages/RelatorioContratoPeriodo';
import RelatorioContratoTema from '../pages/RelatorioContratoTema';
import RelatorioContratosCancelados from '../pages/RelatorioContratosCancelados';
import RelatorioEspecialista from '../pages/RelatorioEspecialista';
import RelatorioPagamentoPermitido from '../pages/RelatorioPagamentoPermitido';
import RelatorioPessoaJuridicaRH from '../pages/RelatorioPessoaJuridicaRH';
import RelatorioRHAutCadastroIndividual from '../pages/RelatorioRHAutCadastroIndividual';
import RelatorioRHAutConsolidado from '../pages/RelatorioRHAutConsolidado';
import RelatorioRHFolha from '../pages/RelatorioRHFolha';
import RelatorioRedList from '../pages/RelatorioRedList';
import RelatorioTreinamento from '../pages/RelatorioTreinamento';
import StatusBlacklist from '../pages/StatusBlacklist';
import StatusBlacklistCadastro from '../pages/StatusBlacklistCadastro';
import StatusBlacklistEdicao from '../pages/StatusBlacklistEdicao';
import StatusCancelamento from '../pages/StatusCancelamento';
import StatusCancelamentoCadastro from '../pages/StatusCancelamentoCadastro';
import StatusCancelamentoEdicao from '../pages/StatusCancelamentoEdicao';
import Tags from '../pages/Tags';
import TagsCadastro from '../pages/TagsCadastro';
import TagsEdicao from '../pages/TagsEdicao';
import Temas from '../pages/Temas';
import TemasEdicao from '../pages/TemasEdicao';
import TiposArquivo from '../pages/TiposArquivo';
import TiposArquivoCadastro from '../pages/TiposArquivoCadastro';
import TiposArquivoEdicao from '../pages/TiposArquivoEdicao';
import TreinamentoCurador from '../pages/TreinamentoCurador';
import TreinamentoEspecialista from '../pages/TreinamentoEspecialista';
import VerbaCuradorias from '../pages/VerbaCuradorias';
import VerbaCuradoriasCadastro from '../pages/VerbaCuradoriasCadastro';
import VerbaCuradoriasEdicao from '../pages/VerbaCuradoriasEdicao';

interface RouteInterface {
  path: string;
  component: any;
  isPrivate: boolean;
  roles: any;
  permissions: any;
}

export const ROUTES: RouteInterface[] = [
  {
    path: '/main',
    component: Main,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/account/profile',
    component: Profile,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/account/change-password',
    component: ChangePassword,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/account/roles',
    component: Roles,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/account/roles/cadastro',
    component: RolesCadastro,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/account/roles/edicao',
    component: RolesEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/account/permissions',
    component: Permissions,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/account/permissions/cadastro',
    component: PermissionsCadastro,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/account/permissions/edicao',
    component: PermissionsEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/account/access-permissions',
    component: AccessPermissions,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/account/users',
    component: Users,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/account/users/edicao',
    component: UsersEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/convidar-colaborador',
    component: ConvidarColaborador,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/convidar-usuario',
    component: ConvidarUsuario,
    isPrivate: true,
    roles: [],
    permissions: ['teste'],
  },
  {
    path: '/servicos',
    component: Servicos,
    isPrivate: true,
    roles: [],
    permissions: ['servicos-index', 'servicos-destroy'],
  },
  {
    path: '/servicos/cadastro',
    component: ServicosCadastro,
    isPrivate: true,
    roles: [],
    permissions: ['servicos-store'],
  },
  {
    path: '/servicos/edicao',
    component: ServicosEdicao,
    isPrivate: true,
    roles: [],
    permissions: ['servicos-update'],
  },
  {
    path: '/tags',
    component: Tags,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/tags/cadastro',
    component: TagsCadastro,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/tags/edicao',
    component: TagsEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/modelos',
    component: Modelos,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/modelos/cadastro',
    component: ModelosCadastro,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/modelos/edicao',
    component: ModelosEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/instituicoes',
    component: Instituicoes,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/instituicoes/cadastro',
    component: InstituicoesCadastro,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/instituicoes/edicao',
    component: InstituicoesEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/temas',
    component: Temas,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  // { path: "/temas/cadastro", component: TemasCadastro, isPrivate: true, roles: [], permissions: [] },
  {
    path: '/temas/edicao',
    component: TemasEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/producoes',
    component: Producoes,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/producoes/cadastro',
    component: ProducoesCadastro,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/producoes/edicao',
    component: ProducoesEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/profissional',
    component: Profissional,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/profissional/cadastro',
    component: ProfissionalCadastro,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/profissional/edicao',
    component: ProfissionalEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/status-cancelamento',
    component: StatusCancelamento,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/status-cancelamento/cadastro',
    component: StatusCancelamentoCadastro,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/status-cancelamento/edicao',
    component: StatusCancelamentoEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/status-blacklist',
    component: StatusBlacklist,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/status-blacklist/cadastro',
    component: StatusBlacklistCadastro,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/status-blacklist/edicao',
    component: StatusBlacklistEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/tipos-arquivo',
    component: TiposArquivo,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/tipos-arquivo/cadastro',
    component: TiposArquivoCadastro,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/tipos-arquivo/edicao',
    component: TiposArquivoEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/projetos',
    component: Projetos,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/projetos/cadastro',
    component: ProjetosCadastro,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/projetos/edicao',
    component: ProjetosEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/produtos',
    component: Produtos,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/produtos/cadastro',
    component: ProdutosCadastro,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/produtos/edicao',
    component: ProdutosEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/formacao',
    component: Formacao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/formacao/cadastro',
    component: FormacaoCadastro,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/formacao/edicao',
    component: FormacaoEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/curadorias',
    component: Curadorias,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/curadorias/cadastro',
    component: CuradoriasCadastro,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/curadorias/edicao',
    component: CuradoriasEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/areas-conhecimento',
    component: AreasConhecimento,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/areas-conhecimento/cadastro',
    component: AreasConhecimentoCadastro,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/areas-conhecimento/edicao',
    component: AreasConhecimentoEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/disciplinas',
    component: Disciplinas,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  // { path: "/disciplinas/cadastro", component: DisciplinasCadastro, isPrivate: true, roles: [], permissions: [] },
  {
    path: '/disciplinas/edicao',
    component: DisciplinasEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/contratos',
    component: Contratos,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/contratos/cadastro',
    component: ContratosCadastro,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/contratos/edital-flow/cadastro-edital',
    component: ContratosCadastroEdita,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/contratos/edital-flow/especialista-candidatos',
    component: EspecialistasEdita,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/contratos/edicao',
    component: ContratosEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/contratos/aprovar-contrato',
    component: AprovarContratos,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/contratos/aprovar-contrato-verba',
    component: AprovarContratosVerba,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/contratos/edital-flow',
    component: EditaFlow,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/contratos/meus-contratos',
    component: MeusContratos,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/contratos/aceitar-contrato',
    component: AceitarContrato,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/contratos/upload',
    component: ContratosUpload,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/contratos/visualizar-contrato',
    component: ContratosVisualizar,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/contratos/visualizar-resumo-contrato',
    component: ContratosResumoVisualizar,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/periodos',
    component: Periodos,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/periodos/cadastro',
    component: PeriodosCadastro,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/periodos/edicao',
    component: PeriodosEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/contratos/documentos/upload',
    component: DocumentosUpload,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/contratos/permitir-pagamentos',
    component: ContratosPermitirPagamento,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/contratos/comandar-pagamentos',
    component: ContratosComandarPagamento,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/temas/carga',
    component: CargaTemas,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/temas/carga/visualizar',
    component: CargaTemasVisualizar,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/disciplinas/carga',
    component: CargaDisciplinasProducao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/disciplinas/carga/visualizar',
    component: CargaDisciplinasProducaoVisualizar,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/treinamento-especialista',
    component: TreinamentoEspecialista,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/treinamento-curador',
    component: TreinamentoCurador,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/especialistas',
    component: Especialistas,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/especialistas/visualizar',
    component: EspecialistasVisualizar,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/especialistas/treinamento',
    component: EspecialistasTreinamento,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/curadores',
    component: Curadores,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/curadores/visualizar',
    component: CuradoresVisualizar,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/arquivos-treinamento',
    component: ArquivosTreinamento,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/arquivos-treinamento/cadastro',
    component: ArquivosTreinamentoCadastro,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/arquivos-treinamento/edicao',
    component: ArquivosTreinamentoEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/cursos',
    component: Cursos,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/cursos/cadastro',
    component: CursosCadastro,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/cursos/edicao',
    component: CursosEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/relatorio/contrato-periodo',
    component: RelatorioContratoPeriodo,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/relatorio/contrato-pagamento-permitido',
    component: RelatorioPagamentoPermitido,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/relatorio/contrato-gerais',
    component: RelatorioContratoGerais,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/relatorio/rh-folha',
    component: RelatorioRHFolha,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/relatorio/rh-aut-cadastro-individual',
    component: RelatorioRHAutCadastroIndividual,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/relatorio/rh-aut-consolidado',
    component: RelatorioRHAutConsolidado,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/relatorio/pessoa-juridica-rh',
    component: RelatorioPessoaJuridicaRH,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/relatorio/especialista',
    component: RelatorioEspecialista,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/relatorio/contrato-tema',
    component: RelatorioContratoTema,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/relatorio/redlist',
    component: RelatorioRedList,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/relatorio/contratos-cancelados',
    component: RelatorioContratosCancelados,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/relatorio/treinamento',
    component: RelatorioTreinamento,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/clientes',
    component: Cliente,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/clientes/cadastro',
    component: ClienteCadastro,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/clientes/edicao',
    component: ClienteEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/area-atuacao',
    component: AreasAtuacao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/area-atuacao/cadastro',
    component: AreasAtuacaoCadastro,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/area-atuacao/edicao',
    component: AreasAtuacaoEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/carga',
    component: Carga,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/carga/visualizar',
    component: CargaVisualizar,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/curadorias/verba',
    component: VerbaCuradorias,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/curadorias/verba/cadastro',
    component: VerbaCuradoriasCadastro,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/curadorias/verba/edicao',
    component: VerbaCuradoriasEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/disciplinas-producao',
    component: DisciplinasProducao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/disciplinas-producao/edicao',
    component: DisciplinasProducaoEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/cursos-producao',
    component: CursosProducao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
  {
    path: '/cursos-producao/edicao',
    component: CursosProducaoEdicao,
    isPrivate: true,
    roles: [],
    permissions: [],
  },
];

const Routes: React.FC = () => {
  const { roles, permissions, routes } = useAuth();

  const [routesWithRoles, setRoutesWithRoles] = useState<RouteInterface[]>([]);

  useEffect(() => {
    if (!routes) return;

    const temp: any = [];

    ROUTES.forEach(route => {
      let current = routes.find((item: any) => item.path === route.path);

      if (current) {
        temp.push({
          ...route,
          roles: current.roles,
        });
      } else {
        temp.push(route);
        // temp.push({
        //   ...route,
        //   roles: ['administrador']
        // });
      }
    });

    setRoutesWithRoles(temp);
  }, [routes]);

  // const test: any = useCallback((route:any, roles:any) => {
  //   if(route.path === "/contratos/aceitar-contrato"){
  //     //console.log("isBlocked", route, roles)
  //   }
  //   return true
  // },[]);

  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/signin" exact component={SignIn} />
      <Route path="/signup" exact component={SignUp} />
      <Route path="/forgot-password" exact component={ForgotPassword} />
      <Route
        path="/cadastro-especialista-edital"
        exact
        component={CadastroEspecialistaEdita}
      />
      <Route path="/reset-password" exact component={ResetPassword} />
      <Route path="/confirm-email" exact component={ConfirmEmail} />
      {routesWithRoles.map(route => (
        <Route
          key="route"
          path={route.path}
          exact
          component={route.component}
          isPrivate={route.isPrivate}
          isBlocked={
            // (roles && permissions && test(route, roles)) ?
            roles && permissions
              ? !(
                  roles.filter((value: any) => route.roles.includes(value))
                    .length > 0
                ) &&
                !(
                  permissions.filter((value: any) =>
                    route.permissions.includes(value),
                  ).length > 0
                )
              : false
          }
        />
      ))}
      {/* <Route path="/account/roles" exact component={Roles} isPrivate />
      <Route path="/account/permissions" exact component={Permissions} isPrivate />
      <Route path="/account/access-permissions" exact component={AccessPermissions} isPrivate /> */}
      <Route
        path="/blocked"
        exact
        component={() => <>Sem permissão de acesso</>}
        isPrivate
      />
    </Switch>
  );
};

export default Routes;

// { path: "/", component: SignIn, isPrivate: false, roles:['administrador','curador'], permissions: [] },
// { path: "/signin", component: SignIn, isPrivate: false, roles:['administrador','curador'], permissions: [] },
// { path: "/signup", component: SignUp, isPrivate: false, roles:['administrador','curador'], permissions: [] },
// { path: "/forgot-password", component: ForgotPassword, isPrivate: false, roles:['administrador','curador'], permissions: [] },
// { path: "/reset-password", component: ResetPassword, isPrivate: false, roles:['administrador','curador'], permissions: [] },
// { path: "/confirm-email", component: ConfirmEmail, isPrivate: false, roles:['administrador','curador'], permissions: [] },
