import { DefaultTheme } from "styled-components";

declare module "styled-components" {
  export interface DefaultTheme {
    backgroundColor: string;
    primaryColor: string;
    secondaryColor: string;
    linkColor: string;
    textlinkColor: string;
    buttonTextColor: string;
    buttonBackgroundColor: string;
    inputTextColor: string;
    inputPlaceholderColor: string;
    inputBackgroundColor: string;
    formInputTextColor: string;
    formInputLabelColor: string;
    formInputPlaceholderColor: string;
    formInputBackgroundColor: string;
    focusColor: string;
    warningColor: string;
    successColor: string;
  }
}

export const lightTheme: DefaultTheme = {
  backgroundColor: "#f1f1f1",
  primaryColor: "#312e38",
  secondaryColor: "#666",
  linkColor: "#1FB0BE",
  textlinkColor: "#232129",
  buttonTextColor: "#fff",
  buttonBackgroundColor: "#1FB0BE",
  inputTextColor: "#232129",
  inputPlaceholderColor: "#666360",
  inputBackgroundColor: "#f1f1f1",
  formInputTextColor: "#232129",
  formInputLabelColor: "#232129",
  formInputPlaceholderColor: "#666360",
  formInputBackgroundColor: "#f1f1f1",
  focusColor: "#0093a0",
  warningColor: "#c53030",
  successColor: "#0093a0",
};

export const darkTheme: DefaultTheme = {
  backgroundColor: "#312e38",
  primaryColor: "#f4ede8",
  secondaryColor: "#ccc",
  linkColor: "#ff9000",
  textlinkColor: "#f4ede8",
  buttonTextColor: "#312e38",
  buttonBackgroundColor: "#ff9000",
  inputTextColor: "#f4ede8",
  inputPlaceholderColor: "#666360",
  inputBackgroundColor: "#232129",
  formInputTextColor: "#f4ede8",
  formInputLabelColor: "#666360",
  formInputPlaceholderColor: "#666360",
  formInputBackgroundColor: "#232129",
  focusColor: "#ff9000",
  warningColor: "#c53030",
  successColor: "#0093a0",
};