import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

interface ContainerProps {
  showMenu?: Boolean;
}

interface ItemMenuProps {
  currentPage?: Boolean;
  showItem?: Boolean;
}

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    width: 0px;
    transform: translateX(-300px);
  }
  to{
    opacity: 1;
    width: 300px;
    transform: translateX(0px);
  }
`;

const disappearToLeft = keyframes`
  from {
    opacity: 1;
    width: 300px;
    transform: translateX(0px);
  }
  to{
    opacity: 0;
    width: 0px;
    transform: translateX(-300px);
  }
`;

export const MenuSpace = styled.div<ContainerProps>`
  margin: 0;
  position: relative;
  width: 300px;
  /* height: 100vh; */
  animation: ${(props) => props.showMenu ? appearFromLeft : disappearToLeft} 0.5s;
  animation-fill-mode: forwards;

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

export const Container = styled.div<ContainerProps>`
  margin: 0;
  position: fixed;
  width: 300px;
  height: calc(100% - 45px);
  background-color: ${({ theme }) => theme.backgroundColor};
  /* display: ${(props) => props.showMenu ? "flex" : "none"}; */
  animation: ${(props) => props.showMenu ? appearFromLeft : disappearToLeft} 0.5s;
  animation-fill-mode: forwards;
  overflow-y: auto;
  z-index:3;
    
  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

export const Content = styled.div`
  position: fixed;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 50px;
`;

export const Menu = styled.div`
  margin-top: 5px;
`;

const appearFromUp = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to{
    opacity: 1;
    transform: translateY(100%);
  }
`;

const disappearToUp = keyframes`
  from {
    opacity: 1;
    transform: translateY(100%);
  }
  to{
    opacity: 1;
    transform: translateY(0);
  }
`;

export const ItemMenu = styled.div<ItemMenuProps>`
  padding: 0 30px;
  margin-bottom: 20px;
  width: 300px;
  display: ${(props) => props.showItem ? 'flex' : 'none'};
  animation: ${(props) => props.showItem ? appearFromUp : disappearToUp} 0.5s;
  animation-fill-mode: forwards;
  
  a{
    display: flex;
    flex-direction: row;
    color: ${(props) => props.currentPage ? props.theme.linkColor : props.theme.textlinkColor};
    font-size: 11px;
    cursor: pointer;
  }

  svg{
    font-size: 18px;
    margin-right: 10px;
    margin-top: 0px;
  }

  .no-show svg{
    visibility: hidden;
  }

  a:hover{
    color: ${props => props.currentPage ? shade(0.2, props.theme.linkColor) : shade(0.2, props.theme.linkColor)};
  }
`;


interface ResponsiveMenuProps {
  showMenu?: Boolean;
}

const appearFromLeftFull = keyframes`
  from {
    opacity: 0;
    width: 0px;
    transform: translateX(-300px);
  }
  to{
    opacity: 1;
    width: 100%;
    transform: translateX(0px);
  }
`;

const disappearToLeftFull = keyframes`
  from {
    opacity: 1;
    width: 100%;
    transform: translateX(0px);
  }
  to{
    opacity: 0;
    width: 0px;
    transform: translateX(-300px);
  }
`;

export const ResponsiveMenu = styled.div<ResponsiveMenuProps>`
  display: none;
  background-color: ${({ theme }) => theme.backgroundColor};
  position: fixed;
  width: 100%;
  height: calc(100% - 65px);
  overflow-y: auto;
  z-index: 3;

  @media screen and (max-width: 1080px) {
    /* display: ${(props) => props.showMenu ? "flex" : "none"}; */
    display: flex;
    animation: ${(props) => props.showMenu ? appearFromLeftFull : disappearToLeftFull} 0.5s;
    animation-fill-mode: forwards;
  }  
`;