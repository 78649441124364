import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { FiTrash, FiPlus, FiEdit, FiX, FiUploadCloud } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { FormHandles } from '@unform/core';
import ReactTooltip from 'react-tooltip';
import { BsFileEarmarkCheck, BsFileEarmarkText, BsFileEarmarkArrowUp, BsFileRichtext } from "react-icons/bs";
import {
  RiFile3Line
} from "react-icons/ri";

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
import Loading from '../../components/Loading';
import FormInput from '../../components/FormInput';
import Button from '../../components/Button';

import api from '../../services/api';

import {
  Container,
  Content,
  TextField,
  ClearButton
} from './styles';

interface ContratosInterface {
  id: string;
  hash: string;
  data_contrato: string;
  prazo_entrega: string;
  tema: any;
  status: any;
  observacoes: string;
  produtos: any;
  especialista: any;
  remetente: any;
  valor_total: any;
  tipo_contratacao: string;
  disciplina_producao: any;
  curso_producao: any;
  tipo_contrato: string;
}

interface OptionsData {
  value: boolean;
  label: string;
}

const MeusContratos: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const { user, token } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal)

  const [loading, setLoading] = useState(true);
  const [contrato, setContrato] = useState<ContratosInterface>({} as ContratosInterface);
  const [contratos, setContratos] = useState<ContratosInterface[]>([]);
  // const [selectedContrato, setSelectedContrato] = useState<ContratosInterface>({} as ContratosInterface);
  // const [selectedRows, setSelectedRows] = useState([]);
  // const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filteredItems, setFilteredItems] = useState<ContratosInterface[]>([]);

  useEffect(() => {
    getContratos();
  }, []);

  // useEffect(() => {
  //   if (clearSelectedRows) {
  //     setClearSelectedRows(false)
  //   }
  // }, [clearSelectedRows]);

  // useEffect(() => {
  //   if (!contratos) return;

  //   let search = filterText.toLowerCase();

  //   let result = contratos.filter(
  //     item => item.hash.toLowerCase() === search ||
  //       item.tema?.titulo.toLowerCase().includes(search)
  //   );

  //   setFilteredItems(result);
  // }, [filterText, contratos])


  const filtered = useMemo(() => {
    if (!filterText)
      return contratos

    let search = filterText.toLowerCase();

    search = search.split("-").join("");
    search = search.split(".").join("");

    search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

    return contratos.filter(contrato => {
      return contrato.tema?.titulo.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
      contrato.disciplina_producao?.disciplina?.titulo
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(search) ||
        contrato.curso_producao?.curso?.titulo
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(search) ||
        contrato.hash.toLowerCase().includes(search) ||
        contrato.tipo_contrato
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(search) ||
        contrato.tipo_contratacao.toLowerCase().includes(search) ||
        contrato.status?.descricao?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        contrato.especialista?.dados_usuario?.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(search) ||
        contrato.especialista?.dados_usuario?.cpf.toLowerCase().includes(search) ||
        contrato.produtos[0]?.servico?.titulo.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        ((contrato.tema && contrato.tema.id) ? "Tema" : contrato.disciplina_producao ? "Disciplina" : "Curso")
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(search)
    })
  }, [filterText, contratos])


  const getContratos = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/contrato-especialista`);
      //console.log("response", response.data);

      setContratos(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  // const removeSelectedRows = useCallback(() => {
  //   MySwal.fire({
  //     // title: 'Tem certeza que deseja remover?',
  //     showCancelButton: true,
  //     confirmButtonText: `Remover`,
  //     confirmButtonColor: '#1CAEBD',
  //     cancelButtonColor: '#312e38',
  //     cancelButtonText: 'Cancelar',
  //     // icon: 'warning',
  //     html: '<div style="font-size:20px">Tem certeza que deseja remover?</div>',
  //     // position: 'top'
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       selectedRows.map((row: any) => {
  //         removeRow(row.id);
  //       });
  //     }
  //   })
  // }, [selectedRows]);

  // const removeRow = useCallback(async (rowId) => {
  //   //console.log("remove", rowId);
  //   setLoading(true);

  //   try {
  //     await api.delete(`/contrato/${rowId}`);

  //     setClearSelectedRows(true);

  //     addToast({
  //       type: 'success',
  //       title: 'Contrato removido com sucesso',
  //       description: '',
  //     });

  //     getContratos();
  //   } catch (err) {
  //     for (let error in err.response?.data) {
  //       addToast({
  //         type: 'error',
  //         title: 'Erro ao remover contrato',
  //         description: err.response?.data[error] ?
  //           err.response.data[error] :
  //           'Erro ao remover contrato, tente novamente mais tarde.',
  //       });
  //     }
  //   }
  // }, []);

  // function goStoreForm() {
  //   history.push("/contratos/cadastro");
  // }

  // function goUpdateForm() {
  //   history.push("/contratos/edicao", {
  //     selectedItem: selectedRows[0]
  //   });
  // }

  // const handleChange = (state: any) => {
  //   // You can use setState or dispatch with something like Redux so we can use the retrieved data
  //   //console.log('Selected Rows: ', state.selectedRows);

  //   setSelectedRows(state.selectedRows);
  // };

  // const actions = (
  //   <FiPlus className="icon" onClick={() => {
  //     goStoreForm();
  //   }} />
  // );

  // const contextActions = (
  //   <>
  //     {selectedRows.length === 1 &&
  //       <FiEdit className="icon" style={{ marginRight: "15px" }} onClick={() => {
  //         goUpdateForm();
  //       }} />
  //     }
  //     <FiTrash className="icon" style={{ marginRight: "10px" }} onClick={() => {
  //       removeSelectedRows();
  //     }} />
  //   </>
  // );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <>
        <TextField id="search" type="text" placeholder="Pesquisar" aria-label="Search Input" value={filterText} onChange={(e: any) => setFilterText(e.target.value)} />
        <ClearButton type="button" onClick={handleClear}>x</ClearButton>
      </>
    );
  }, [filterText, resetPaginationToggle]);

  let parseText = function (text: string, limit: number) {
    if (text.length > limit)
      for (let i = limit; i > 0; i--) {
        if (
          text.charAt(i) === ' ' &&
          (text.charAt(i - 1) != ',' ||
            text.charAt(i - 1) != '.' ||
            text.charAt(i - 1) != ';')
        ) {
          return text.substring(0, i) + '...';
        }
      }
    else return text;
  };

  const columns = [
    {
      name: 'ID',
      selector: 'hash',
      sortable: false,
      maxWidth: '140px',
      // right: true,
      hide: 820,
    },
    // {
    //   name: 'Tema',
    //   selector: 'tema.titulo',
    //   sortable: false,
    //   // right: true,
    //   maxWidth: '500px',
    // },
    {
      name: 'Produção',
      selector: 'producao',
      sortable: false,
      maxWidth: '500px',
      // right: true,
      cell: (row: any) => {
        let producao = (row.tema && row.tema.titulo) ? row.tema.titulo : row.disciplina_producao ? row.disciplina_producao?.disciplina?.titulo : row.curso_producao?.curso?.titulo;
        let producao_id = (row.tema && row.tema.id) ? row.tema.id : row.disciplina_producao ? row.disciplina_producao?.disciplina?.id : row.curso_producao?.curso?.id;

        if(!producao) return "";

        let dots_string = String(producao).substring(0, 50);
        let reduce = dots_string !== producao;
        return (
          <>
            {reduce ? (
              <>
                <div data-tip data-for={`${producao_id}`}>
                  {dots_string}
                </div>
                <ReactTooltip id={`${producao_id}`} effect="solid">
                  <span>{producao}</span>
                </ReactTooltip>
              </>
            ) : (
              <div>{dots_string}</div>
            )}
          </>
        );
      },
    },
    {
      name: 'Tipo de Produção',
      selector: 'tipo',
      sortable: false,
      width: '120px',
      // right: true,
      cell: (row: any) => {
        let tipo = (row.tema && row.tema.titulo) ? "Tema" : row.disciplina_producao ? "Disciplina" : "Curso";
        return <span>{tipo}</span>;
      },
    },
    {
      name: 'Tipo de Contrato',
      selector: 'tipo_contrato',
      sortable: false,
      width: '120px',
      // right: true,
      cell: (row: any) => {
        let tipo = (row.tipo_contrato === "producao_atual") ? "Produção" : (row.tipo_contrato === "manutencao_reativa") ? "Manutenção Reativa" : (row.tipo_contrato === "manutencao_proativa") ? "Manutenção Proativa" : (row.tipo_contrato === "manutencao_proativa_direcionada") ? "Manutenção Proativa Direcionada" : (row.tipo_contrato === "terceirizado") ? "Terceirizado" : (row.tipo_contrato === "prestacao_servico") ? "Prestação de Serviço" : (row.tipo_contrato.replace("_", " ")[0].toUpperCase() + row.tipo_contrato.replace("_", " ").substring(1));        
        return <span>{tipo}</span>;
      },
    },
    {
      name: 'Remetente',
      selector: 'remetente.email',
      sortable: false,
      // right: true,
      hide: 1560,
    },
    {
      name: 'Contratação',
      selector: 'tipo_contratacao',
      sortable: false,
      // right: true,
      hide: 1640,
      cell: (row: any) => (
        <p>{row.tipo_contratacao.replace('_', ' ')}</p>
      )
    },
    {
      name: 'Produto',
      selector: 'produto',
      sortable: false,
      // right: true,
      maxWidth: '200px',
      hide: 1400,
      cell: (row: any) => {
        return (
          row.produtos.map((item:any) => {
            return (<span key={item.servico?.titulo}>{item.servico?.titulo} </span>)
          })
        )
      }
    },
    {
      name: 'Situação',
      selector: 'status.descricao',
      sortable: false,
      // right: true,
      hide: 1285,
      width: '150px',
      cell: (row: any) => (
        <div style={{
          color: "#fff",
          backgroundColor:
            row.status.slug == 'criado' ? "#F09B0E" :
              row.status.slug == 'aceite' ? "#95B759" :
                row.status.slug == 'planejado' ? "#AFB7C2" :
                  row.status.slug == 'cancelado' ? "#E94F51" :
                    row.status.slug == 'recusado' ? "#333" :
                      row.status.slug == 'pagamento_permitido' ? "#800980" :
                        row.status.slug == 'pagamento_comandado' ? "#0783C2" :
                          row.status.slug == 'aguardando_aprovacao' ? "#F09B61" :
                            row.status.slug == 'erro_de_permissao' ? "#444" :
                              "#666",

          padding: "5px",
          borderRadius: "3px",
          width: '150px',
          textAlign: 'center'
        }}>

          {row.status.slug == 'criado' && <span>contrato criado</span>}
          {row.status.slug == 'aceite' && <span>contrato aceito</span>}
          {row.status.slug == 'planejado' && <span>contrato planejado</span>}
          {row.status.slug == 'cancelado' && <span>contrato cancelado</span>}
          {row.status.slug == 'recusado' && <span>contrato recusado</span>}
          {row.status.slug == 'pagamento_permitido' && <span>{row.status.descricao}</span>}
          {row.status.slug == 'pagamento_comandado' && <span>{row.status.descricao}</span>}
          {row.status.slug == 'aguardando_aprovacao' && <span>{row.status.descricao}</span>}
          {row.status.slug == 'erro_de_permissao' && <span>{row.status.descricao}</span>}
        </div>
      )
    },
    {
      name: 'Contratos',
      button: true,
      cell: (row: any) => (
        <div className="wrapper-table-actions">


          {(row.arquivo_direito_imagem && row.arquivo_direito_imagem?.url && row.status?.slug !== "criado" && row.status?.slug !== "cancelado" && row.status?.slug !== "recusado") ?
            <a className={`table-action`} href={`${row.arquivo_direito_imagem.url}?token=${token}`} target="_blank"><BsFileRichtext data-tip data-for='tipDireitoImagem' /></a>
            :
            <div className={`table-action table-action-disabled`}><BsFileRichtext /></div>
          }

          {(row.arquivo_contrato && row.arquivo_contrato?.url && row.status?.slug !== "criado" && row.status?.slug !== "cancelado" && row.status?.slug !== "recusado") ?
            <a className={`table-action`} href={`${row.arquivo_contrato.url}?token=${token}`} target="_blank"><BsFileEarmarkText data-tip data-for='tipContrato' /></a>
            :
            <div className={`table-action table-action-disabled`}><BsFileEarmarkText /></div>
          }

          <ReactTooltip id='tipContrato' effect='solid'>
            <span>Contrato</span>
          </ReactTooltip>

          <ReactTooltip id='tipDireitoImagem' effect='solid'>
            <span>Direito de Imagem</span>
          </ReactTooltip>


        </div>
      ),
    },
    {
      name: 'Ações',
      button: true,
      cell: (row: any) => (
        <div className="wrapper-table-actions">
          {/* <div className={`table-action ${row.status?.slug === "criado" ? "" : "table-action-disabled"}`} */}
          <div className={`table-action`}
            onClick={() => row.status?.slug === "criado" ? history.push('/contratos/aceitar-contrato', { selectedItem: row }) : history.push('/contratos/visualizar-resumo-contrato', { selectedItem: row })}>
            {row.status?.slug === "criado" ?
              <BsFileEarmarkCheck data-tip data-for='tipAceitarContrato' />
              :
              // <BsFileEarmarkCheck />
              <RiFile3Line data-tip data-for='tipAceitarContrato' />
            }
          </div>

          <div className={`table-action ${(row.status?.slug !== "cancelado" && row.status?.slug !== "recusado" && row.status?.slug !== "pagamento_comandado") ? "" : "table-action-disabled"} ${(row.tipo_contratacao === "interno" || (row.tipo_contratacao === "pessoa_fisica" && row.status?.slug === "aceite")) ? "table-action-disabled" : ""}`}
            onClick={() => (row.status?.slug !== "cancelado" && row.status?.slug !== "recusado" && row.status?.slug !== "pagamento_comandado") ? history.push('/contratos/upload', { selectedItem: row }) : null}>
            {(row.status?.slug !== "cancelado" && row.status?.slug !== "recusado" && row.status?.slug !== "pagamento_comandado") ?
              <BsFileEarmarkArrowUp data-tip data-for='tipEnviarDocumentos' />
              :
              <BsFileEarmarkArrowUp />
            }
          </div>

          <ReactTooltip id='tipEnviarDocumentos' effect='solid'>
            <span>Enviar Documentos</span>
          </ReactTooltip>

          <ReactTooltip id='tipAceitarContrato' effect='solid'>
            <span>Visualizar Contrato</span>
          </ReactTooltip>

        </div>
      ),
    },
  ];

  return (
    <Container>
      <Content>
        <Card>
          <DataTable
            title="Meus Contratos"
            columns={columns}
            data={filtered}
            // selectableRows
            // highlightOnHover
            // onSelectedRowsChange={handleChange}
            // defaultSortField="tema.titulo"
            pagination
            // actions={actions}
            // contextActions={contextActions}
            noDataComponent="Nenhum registro encontrado."
            paginationComponentOptions={{
              rowsPerPageText: 'Itens por página',
              rangeSeparatorText: 'de'
            }}
            // clearSelectedRows={clearSelectedRows}
            contextMessage={{
              singular: 'item selecionado',
              plural: 'itens selecionados',
              message: ''
            }}
            progressComponent={<Loading />}
            progressPending={loading}
            subHeader
            subHeaderComponent={subHeaderComponent}
            paginationResetDefaultPage={resetPaginationToggle}
            responsive
          />
        </Card>
      </Content>
    </Container>
  );
};

export default MeusContratos;
