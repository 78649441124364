import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import FormInput from '../../components/FormInput';
// import FormInputMask from '../../components/FormInputMask';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem,
} from './styles';

interface DisciplinaFormData {
  id: string;
  titulo: string;
  codigo: string;
  id_area_conhecimento: any;
}

interface AreaConhecimentoFormData {
  id: string;
  descricao: string;
}

interface OptionsData {
  value: string;
  label: string;
}

const DisciplinasEdicao: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location:any = useLocation();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [areasConhecimento, setAreasConhecimento] = useState<AreaConhecimentoFormData[]>([]);
  const [optionsAreasConhecimento, setOptionsAreasConhecimento] = useState<OptionsData[]>([]);
  const [selectedAreaConhecimento, setSelectedAreaConhecimento] = useState<OptionsData>({ value: "", label: "" });
  const [selectedItem, setSelectedItem] = useState<DisciplinaFormData>({} as DisciplinaFormData);

  useEffect(() => {
    if(location && location.state){
      setSelectedItem(location.state.selectedItem);
    }
  }, [location]);

  useEffect(() => {
    if(selectedItem){
      //console.log("selectedItem", selectedItem);
      formRef.current?.setFieldValue("titulo", selectedItem.titulo);
      formRef.current?.setFieldValue("codigo", selectedItem.codigo);

      if(selectedItem.id_area_conhecimento){
        const current = areasConhecimento.find((item) => item.id === selectedItem.id_area_conhecimento)
        if(current){
          setSelectedAreaConhecimento({ value: current.id, label: current.descricao });
        }
      }
    }
  }, [selectedItem, areasConhecimento]);

  useEffect(() => {
    getAreasConhecimento();
  }, []);

  useEffect(() => {
    if (areasConhecimento.length === 0) return;

    var options: OptionsData[] = [];

    areasConhecimento.map(item => {
      options.push({ value: item.id, label: item.descricao });
    });

    setOptionsAreasConhecimento(options);
    //console.log("options", options);
  }, [areasConhecimento]);
  
  const getAreasConhecimento = useCallback(async () => {
    const response = await api.get(`/area-conhecimento`);
    //console.log("areasConhecimento", response.data);

    setAreasConhecimento(response.data);
  }, []);

  const handleSubmit = useCallback(
    async (data: DisciplinaFormData) => {
      try {
        //console.log("handleSubmit", data);

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          titulo: Yup.string().required('Título obrigatório'),
          codigo: Yup.string().required('Código obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let params = {
          titulo: data.titulo,
          codigo: data.codigo,
          // id_area_conhecimento: selectedAreaConhecimento.value,
        }

        //console.log(params);

        await api.put(`/disciplina/${selectedItem.id}`, params);

        addToast({
          type: 'success',
          title: 'Disciplina atualizada com sucesso',
          description: '',
        });

        goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }
        
        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar disciplina',
            description: err.response?.data[error] ?
              err.response.data[error] :
              'Erro ao atualizar disciplina, tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, goBack, selectedAreaConhecimento, selectedItem],
  );

  function goBack() {
    history.push('/disciplinas');
  }

  function handleChangeSelectedAreaConhecimento(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedAreaConhecimento({ value: "", label: "" });
      return;
    };

    if (newValue.length === 0) {
      setSelectedAreaConhecimento({ value: "", label: "" });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue AreaConhecimento", newValue.value);

    setSelectedAreaConhecimento(newValue);
  };


  if(loading){
    return (
      <Loading />
    )
  }

  return (
    <Container>
      <Content>
        <Card>
          <div className="form">
            <div className="header">
              <h2>Editar Disciplina</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            <Form ref={formRef} onSubmit={handleSubmit}>
                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="codigo"
                      type="text"
                      placeholder="Código da Disciplina"
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="titulo"
                      type="text"
                      placeholder="Título"
                    />
                  </WrapRowItem>
                </WrapRow>

                {/* <WrapRow>
                  <WrapRowItem style={{ marginTop: "10px", marginBottom: "30px" }}>
                    <label>Área do Conhecimento:</label>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedAreaConhecimento}
                      options={optionsAreasConhecimento}
                      value={selectedAreaConhecimento}
                    />
                  </WrapRowItem>
                </WrapRow> */}

              <Button type="submit" loading={loading}>
                Atualizar
              </Button>

            </Form>
          </div>
        </Card>
      </Content>
    </Container>
  );
};

export default DisciplinasEdicao;
