import React from 'react';
import api from '../../../services/api';
import { WarningDevelopementMode } from './styles';

// import { Container, Content } from './styles';

const AuthLayout: React.FC = ({ children }) => {
  return (
    <>
      {children}

      {(api.defaults.baseURL !== 'https://apicontrato.ensineme.com.br') ? (
        <WarningDevelopementMode>
          Ambiente de Teste
        </WarningDevelopementMode>
      ) : (
        <></>
      )}
    </>
  );
};

export default AuthLayout;
