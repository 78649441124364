import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

import { useAuth } from '../../../../hooks/auth';
import { useToast } from '../../../../hooks/toast';

import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import FormInput from '../../../../components/FormInput';
import FormInputMask from '../../../../components/FormInputMask';

import api from '../../../../services/api';

import { Container, Content, WrapRow, WrapRowItem } from './styles';

interface CadastroEspecialistaEditaFormData {
  id: any;
  nome: string;
  telefone: string;
  email_candidatos: string;
  area_formacao: string;
  cpf: string;
  data_nascimento: string;
  naturalidade: string;
  nacionalidade: string;
  linkedin: string;
  lattes: string;
  formacao_academica: string;
  material_didatico_ydquqs: string;
  material_didatico_digital: string;
  livros_artigos: string;
}

interface BanksInterface {
  id: any;
}

interface OptionsData {
  value: any;
  label: string;
}

const CadastroEspecialistaEdita: React.FC = () => {
  const params = new URLSearchParams(window.location.search);
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const { user, updateUser } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);

  const [selectedFormacaoAcademica, setSelectedFormacaoAcademica] = useState<
    OptionsData
  >({} as OptionsData);
  const [optionsFormacaoAcademica, setOptionsFormacaoAcademica] = useState<
    OptionsData[]
  >([
    { value: 'Pós-doutorado concluído', label: 'Pós-doutorado concluído' },
    {
      value: 'Pós-doutorado em andamento',
      label: 'Pós-doutorado em andamento',
    },
    { value: 'Doutorado concluído', label: 'Doutorado concluído' },
    { value: 'Doutorado em andamento', label: 'Doutorado em andamento' },
    {
      value: 'Mestrado concluído',
      label: 'Mestrado concluído',
    },
    { value: 'Mestrado em andamento', label: 'Mestrado em andamento' },
    { value: 'Especialização ou MBA', label: 'Especialização ou MBA' },
    { value: 'Graduação concluído', label: 'Graduação concluído' },
    { value: 'Graduação em andamento', label: 'Graduação em andamento' },
    { value: 'Ensino Médio', label: 'Ensino Médio' },
  ]);

  const [
    selectedMaterialDidaticoYduqs,
    setSelectedMaterialDidaticoYduqs,
  ] = useState<OptionsData>({} as OptionsData);
  const [
    optionsMaterialDidaticoYduqs,
    setOptionsMaterialDidaticoYduqs,
  ] = useState<OptionsData[]>([
    { value: 'Sim', label: 'Sim' },
    { value: 'Não', label: 'Não' },
  ]);

  const [
    selectedMaterialDidaticoDigital,
    setSelectedMaterialDidaticoDigital,
  ] = useState<OptionsData>({} as OptionsData);
  const [
    optionsMaterialDidaticoDigital,
    setOptionsMaterialDidaticoDigital,
  ] = useState<OptionsData[]>([
    { value: 'Sim', label: 'Sim' },
    { value: 'Não', label: 'Não' },
  ]);

  const [selectedLivroArtigo, setSelectedLivroArtigo] = useState<OptionsData>(
    {} as OptionsData,
  );
  const [optionsLivroArtigo, setOptionsLivroArtigo] = useState<OptionsData[]>([
    { value: 'Sim', label: 'Sim' },
    { value: 'Não', label: 'Não' },
  ]);

  const [isNewUser, setIsNewUser] = useState(true);
  const [especialista, setEspecialista] = useState<
    CadastroEspecialistaEditaFormData
  >({
    id: params.get('id'),
    nome: '',
    telefone: '',
    email_candidatos: '',
    area_formacao: '',
    cpf: '',
    data_nascimento: '',
    naturalidade: '',
    nacionalidade: '',
    linkedin: '',
    lattes: '',
    formacao_academica: '',
    material_didatico_ydquqs: '',
    material_didatico_digital: '',
    livros_artigos: '',
  });

  function handleChangeSelectedFormacaoAcademica(newValue: any) {
    setEspecialista({ ...especialista, formacao_academica: newValue.value });
    setSelectedFormacaoAcademica(newValue);
  }

  function handleChangeSelectedMaterialDidaticoYduqs(newValue: any) {
    setEspecialista({
      ...especialista,
      material_didatico_ydquqs: newValue.value,
    });
    setSelectedMaterialDidaticoYduqs(newValue);
  }

  function handleChangeSelectedMaterialDidaticoDigital(newValue: any) {
    setEspecialista({
      ...especialista,
      material_didatico_digital: newValue.value,
    });
    setSelectedMaterialDidaticoDigital(newValue);
  }

  function handleChangeSelectedLivroArtigo(newValue: any) {
    setEspecialista({ ...especialista, livros_artigos: newValue.value });
    setSelectedLivroArtigo(newValue);
  }

  const isValidCPF = useCallback(async cpf => {
    if (typeof cpf !== 'string') return false;
    cpf = cpf.replace(/[\s.-]*/gim, '');

    if (
      !cpf ||
      cpf.length != 11 ||
      cpf == '00000000000' ||
      cpf == '11111111111' ||
      cpf == '22222222222' ||
      cpf == '33333333333' ||
      cpf == '44444444444' ||
      cpf == '55555555555' ||
      cpf == '66666666666' ||
      cpf == '77777777777' ||
      cpf == '88888888888' ||
      cpf == '99999999999'
    ) {
      return false;
    }
    var soma = 0;
    var resto;
    for (var i = 1; i <= 9; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;
    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(cpf.substring(9, 10))) return false;
    soma = 0;
    for (var i = 1; i <= 10; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;
    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(cpf.substring(10, 11))) return false;
    return true;
  }, []);

  function hasNumber(myString: any) {
    return /\d/.test(myString);
  }

  function hasSpecialCharacters(myString: any) {
    return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(myString);
  }

  const validarNome = useCallback(input => {
    console.log('validarNome', input);

    let tmp = input.trim();

    if (tmp.split(' ').length <= 1) {
      return false;
    }

    if (hasNumber(tmp)) {
      return false;
    }

    if (hasSpecialCharacters(tmp)) {
      return false;
    }

    return true;
  }, []);

  function handleChangeNome(event: ChangeEvent<HTMLInputElement>) {
    setEspecialista({
      ...especialista,
      nome: event.target.value,
    });
  }

  function handleChangeTelefone(event: ChangeEvent<HTMLInputElement>) {
    setEspecialista({
      ...especialista,
      telefone: event.target.value,
    });
  }

  function handleChangeEmail(event: ChangeEvent<HTMLInputElement>) {
    setEspecialista({
      ...especialista,
      email_candidatos: event.target.value,
    });
  }
  function handleChangeAreaFormacao(event: ChangeEvent<HTMLInputElement>) {
    setEspecialista({
      ...especialista,
      area_formacao: event.target.value,
    });
  }

  function handleChangeCpf(event: ChangeEvent<HTMLInputElement>) {
    setEspecialista({
      ...especialista,
      cpf: event.target.value,
    });
  }

  function handleChangeDataNascimento(event: ChangeEvent<HTMLInputElement>) {
    setEspecialista({
      ...especialista,
      data_nascimento: event.target.value,
    });
  }

  function handleChangeNaturalidade(event: ChangeEvent<HTMLInputElement>) {
    setEspecialista({
      ...especialista,
      naturalidade: event.target.value,
    });
  }

  function handleChangeLinkedin(event: ChangeEvent<HTMLInputElement>) {
    setEspecialista({
      ...especialista,
      linkedin: event.target.value,
    });
  }

  function handleChangeLattes(event: ChangeEvent<HTMLInputElement>) {
    setEspecialista({
      ...especialista,
      lattes: event.target.value,
    });
  }

  function handleChangeNacionalidade(event: ChangeEvent<HTMLInputElement>) {
    setEspecialista({
      ...especialista,
      nacionalidade: event.target.value,
    });
  }

  async function handleSubmitForm(event: ChangeEvent<HTMLInputElement>) {
    try {
      const {
        id,
        nome,
        telefone,
        email_candidatos,
        area_formacao,
        cpf,
        data_nascimento,
        naturalidade,
        nacionalidade,
        linkedin,
        lattes,
        formacao_academica,
        material_didatico_ydquqs,
        material_didatico_digital,
        livros_artigos,
      } = especialista;
      if (!validarNome(nome)) {
        addToast({
          type: 'error',
          title: 'Nome inválido',
          description: '',
        });
        return;
      }
      if (!telefone) {
        addToast({
          type: 'error',
          title: 'É obrigatório o cadastro de ao menos um telefone',
          description: '',
        });
        return;
      }
      if (!email_candidatos) {
        addToast({
          type: 'error',
          title: 'Email inválido',
          description: '',
        });
        return;
      }
      if (!area_formacao) {
        addToast({
          type: 'error',
          title: 'Formação inválido',
          description: '',
        });
        return;
      }
      if (!cpf) {
        addToast({
          type: 'error',
          title: 'CPF inválido',
          description: '',
        });
        return;
      }
      if (!naturalidade) {
        addToast({
          type: 'error',
          title: 'Naturalidade inválida',
          description: '',
        });
        return;
      }
      if (!nacionalidade) {
        addToast({
          type: 'error',
          title: 'Nacionalidade inválida',
          description: '',
        });
        return;
      }
      if (!linkedin) {
        addToast({
          type: 'error',
          title: 'Linkedin inválido',
          description: '',
        });
        return;
      }
      /*if (!lattes) {
        addToast({
          type: 'error',
          title: 'Lattes inválido',
          description: '',
        });
        return;
      }*/
      if (!formacao_academica) {
        addToast({
          type: 'error',
          title: 'Formação Acadêmica',
          description: '',
        });
        return;
      }

      if (!material_didatico_ydquqs) {
        addToast({
          type: 'error',
          title: 'Material Didático YDUQS',
          description: '',
        });
        return;
      }

      if (!material_didatico_digital) {
        addToast({
          type: 'error',
          title: 'Material Didático Digital',
          description: '',
        });
        return;
      }

      if (!livros_artigos) {
        addToast({
          type: 'error',
          title: 'Elaborou Livro ou Artigo',
          description: '',
        });
        return;
      }

      let dt_data_nascimento = data_nascimento.split('/');

      const today = new Date();
      const dataFormatHoje = formatDatePtBr(today);
      console.log(today.getDay + 'today getday)');

      if (
        data_nascimento.trim() === '' ||
        parseInt(dt_data_nascimento[1]) > 12 ||
        parseInt(dt_data_nascimento[0]) > 31
      ) {
        addToast({
          type: 'error',
          title: 'Data de nascimento inválida',
          description: '',
        });
        return;
      }

      if (dt_data_nascimento[2].replace('_', '').length !== 4) {
        addToast({
          type: 'error',
          title: 'Data de nascimento inválida',
          description: '',
        });
        return;
      }

      if (
        parseInt(dt_data_nascimento[2]) > today.getFullYear() ||
        parseInt(formatDate(data_nascimento)) >
          parseInt(formatDate(dataFormatHoje))
      ) {
        addToast({
          type: 'error',
          title: 'Data de nascimento inválida',
          description: '',
        });
        return;
      }

      const data = especialista;
      const Data = { data };

      console.log(Data);
      const response = await api.post('/edital', data);
      const resposta = response.data.mensage;
      if ((resposta.status = 200)) {
        addToast({
          type: 'success',
          title: 'Convite(s) enviado(s) com sucesso',
          description: '',
        });
        console.log('Enviado com Sucesso');
        setEspecialista({
          id: '',
          nome: '',
          telefone: '',
          email_candidatos: '',
          area_formacao: '',
          cpf: '',
          data_nascimento: '',
          naturalidade: '',
          nacionalidade: '',
          linkedin: '',
          lattes: '',
          formacao_academica: '',
          material_didatico_ydquqs: '',
          material_didatico_digital: '',
          livros_artigos: '',
        });
      } else console.log('Ao realizar a operação');
    } catch (error) {
      console.log('Ao realizar a operação' + error);
    }
    addToast({
      type: 'success',
      title: 'Cadastro realizado com sucesso',
      description: '',
    });
  }
  function formatDate(date: string) {
    date = date.split('/').reverse().join('');

    return date;
  }

  function formatDatePtBr(date: Date) {
    const day = String(date.getDate()).padStart(2, '0'); // Obtém o dia e adiciona zero à esquerda se for menor que 10

    const month = String(date.getMonth() + 1).padStart(2, '0'); // Obtém o mês (lembrando que os meses são base 0) e adiciona zero à esquerda se for menor que 10

    const year = date.getFullYear(); // Obtém o ano

    return `${day}/${month}/${year}`;
  }
  return (
    <Container>
      <Content>
        <Form ref={formRef} onSubmit={handleSubmitForm}>
          <Card>
            <h2>Candidatura</h2>

            <WrapRow>
              <WrapRowItem>
                <FormInput
                  value={especialista.nome}
                  onChange={handleChangeNome}
                  name="nome"
                  type="text"
                  placeholder="Nome Completo"
                />
              </WrapRowItem>
            </WrapRow>

            <WrapRow>
              <WrapRowItem>
                <FormInputMask
                  value={especialista.telefone}
                  onChange={handleChangeTelefone}
                  name="telefone"
                  type="text"
                  placeholder="Telefone"
                  mask="(99) 99999-9999"
                />
              </WrapRowItem>
            </WrapRow>

            <WrapRow>
              <FormInput
                value={especialista.area_formacao}
                onChange={handleChangeAreaFormacao}
                name="Area de Formação"
                type="text"
                placeholder="Área de Formação"
                // disabled
              />
              <FormInput
                value={especialista.email_candidatos}
                onChange={handleChangeEmail}
                name="email"
                type="text"
                placeholder="E-mail"
                // disabled
              />
            </WrapRow>

            <WrapRow>
              <WrapRowItem>
                <FormInputMask
                  value={especialista.cpf}
                  onChange={handleChangeCpf}
                  name="cpf"
                  type="text"
                  placeholder="CPF"
                  mask="999.999.999-99"
                />
              </WrapRowItem>
              <WrapRowItem>
                <FormInputMask
                  value={especialista.data_nascimento}
                  onChange={handleChangeDataNascimento}
                  name="data_nascimento"
                  type="text"
                  placeholder="Data Nascimento"
                  mask="99/99/9999"
                />
              </WrapRowItem>
            </WrapRow>

            <WrapRow>
              <WrapRowItem>
                <FormInput
                  value={especialista.linkedin}
                  onChange={handleChangeLinkedin}
                  name="linkedin"
                  type="text"
                  placeholder="Linkedin"
                />
              </WrapRowItem>
              <WrapRowItem>
                <FormInput
                  value={especialista.lattes}
                  onChange={handleChangeLattes}
                  name="Lattes"
                  type="text"
                  placeholder="Lattes"
                />
              </WrapRowItem>
            </WrapRow>

            <WrapRow>
              <WrapRowItem>
                <FormInput
                  value={especialista.naturalidade}
                  onChange={handleChangeNaturalidade}
                  name="naturalidade"
                  type="text"
                  placeholder="Naturalidade"
                />
              </WrapRowItem>

              <WrapRowItem>
                <FormInput
                  value={especialista.nacionalidade}
                  onChange={handleChangeNacionalidade}
                  name="nacionalidade"
                  type="text"
                  placeholder="Nacionalidade"
                />
              </WrapRowItem>
            </WrapRow>

            <WrapRow style={{ marginTop: '15px', marginBottom: '15px' }}>
              <WrapRowItem style={{ minWidth: '215px', marginBottom: '30px' }}>
                <label>Formação Acadêmica:</label>
                <Select
                  styles={{
                    menu: provided => ({ ...provided, zIndex: 9999 }),
                  }}
                  placeholder={''}
                  onChange={handleChangeSelectedFormacaoAcademica}
                  options={optionsFormacaoAcademica}
                  value={selectedFormacaoAcademica}
                />
              </WrapRowItem>
              <WrapRowItem style={{ minWidth: '215px', marginBottom: '30px' }}>
                <label>Já elaborou material didático YDUQS:</label>
                <Select
                  styles={{
                    menu: provided => ({ ...provided, zIndex: 9999 }),
                  }}
                  placeholder={''}
                  onChange={handleChangeSelectedMaterialDidaticoYduqs}
                  options={optionsMaterialDidaticoYduqs}
                  value={selectedMaterialDidaticoYduqs}
                />
              </WrapRowItem>
            </WrapRow>
            <WrapRow style={{ marginTop: '15px', marginBottom: '15px' }}>
              <WrapRowItem style={{ minWidth: '215px', marginBottom: '30px' }}>
                <label>Já elaborou Livros ou Artigos:</label>
                <Select
                  styles={{
                    menu: provided => ({ ...provided, zIndex: 9999 }),
                  }}
                  placeholder={''}
                  onChange={handleChangeSelectedLivroArtigo}
                  options={optionsLivroArtigo}
                  value={selectedLivroArtigo}
                />
              </WrapRowItem>
              <WrapRowItem style={{ minWidth: '215px', marginBottom: '30px' }}>
                <label>Já elaborou material didático Digital:</label>
                <Select
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 }),
                    // input: styles => ({ ...styles }),
                  }}
                  placeholder={''}
                  onChange={handleChangeSelectedMaterialDidaticoDigital}
                  options={optionsMaterialDidaticoDigital}
                  value={selectedMaterialDidaticoDigital}
                />
              </WrapRowItem>
            </WrapRow>
          </Card>
          <Button type="submit" loading={loading}>
            {isNewUser ? 'Candidatar a Vaga' : 'Atualizar Dados'}
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default CadastroEspecialistaEdita;
