import React, { useRef, useCallback, useState, useEffect, forwardRef } from 'react';
import { FiX } from 'react-icons/fi';
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import FormInput from '../../components/FormInput';
// import FormInputMask from '../../components/FormInputMask';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem,
} from './styles';
import FormInputMoneyMask from '../../components/FormInputMoneyMask';
import convertUTCDateToLocalDate from '../../utils/convertUTCDateToLocalDate';

interface CuradoriaFormData {
  id: string;
  nome: string;
  curadores: any;
}


interface OptionsData {
  value: string;
  label: string;
}

const VerbaCuradoriasEdicao: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location:any = useLocation();

  // const { user } = useAuth();
  const { addToast } = useToast();

  var date = new Date();

  const [loading, setLoading] = useState(false);
  const [curadorias, setCuradorias] = useState<CuradoriaFormData[]>([]);
  const [startDate, setStartDate] = useState<any>(new Date(date.getFullYear(), date.getMonth(), 1));
  const [endDate, setEndDate] = useState<any>(new Date(date.getFullYear(), date.getMonth() + 3, 0));
  const [verbas, setVerbas] = useState<any[]>([]);
  const [initialData, setInitialData] = useState<any>(null);
  
  const [selectedItem, setSelectedItem] = useState<any>({} as any);

  useEffect(() => {
    if(location && location.state){
      setSelectedItem(location.state.selectedItem);
      getCuradorias(location.state.selectedItem);
    }
  }, [location]);

  useEffect(() => {
    if(selectedItem){
      // console.log("selectedItem", selectedItem);
      // formRef.current?.setFieldValue("titulo", selectedItem.titulo);
      // formRef.current?.setFieldValue("descricao", selectedItem.descricao);

      setStartDate(convertUTCDateToLocalDate(new Date(selectedItem.data_inicio)));
      setEndDate(convertUTCDateToLocalDate(new Date(selectedItem.data_final)));
    }
  }, [selectedItem]);

  // useEffect(() => {
  //   if(selectedItem && curadorias.length > 0){
  //     selectedItem.curadorias.forEach((curadoria:any) => {
  //       console.log("??", curadoria, `curadoria['${curadoria.id}'].verba_curadoria`, curadoria.pivot.verba_total);
  //       formRef.current?.setFieldValue(`curadoria['${curadoria.id}'].verba_curadoria`, curadoria.pivot.verba_total);
  //     });
  //   }
  // }, [selectedItem, curadorias]);

  
  const getCuradorias = useCallback(async (curadoria_selected) => {
    setLoading(true);

    try {
      const response = await api.get(`/curadoria`);
      // console.log("response", response.data);
      // console.log("curadoria_selected", curadoria_selected)

      const tmp:any = [];

      response.data.forEach((item:any) => {
        if(item.utiliza_verba){
          const atual = curadoria_selected.curadorias.find((item_:any) => item_.id === item.id);

          tmp.push({
            ...item,
            verba_total: atual?.pivot?.verba_total
          })
        }
      });

      // console.log("curadorias", tmp);

      setCuradorias(tmp);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);


  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        // console.log("handleSubmit", data);

        setLoading(true);
        formRef.current?.setErrors({});

        // const schema = Yup.object().shape({
        //   nome: Yup.string().required('Título obrigatório'),
        // });

        // await schema.validate(data, {
        //   abortEarly: false,
        // });

        const start_date = convertUTCDateToLocalDate(new Date(`${startDate.getFullYear()}-${("0" + (startDate.getMonth() + 1)).slice(-2)}-${("0" + startDate.getDate()).slice(-2)}`));
        const end_date = convertUTCDateToLocalDate(new Date(`${endDate.getFullYear()}-${("0" + (endDate.getMonth() + 1)).slice(-2)}-${("0" + endDate.getDate()).slice(-2)}`));

        const data_inicio = `${start_date.getFullYear()}-${("0" + (start_date.getMonth() + 1)).slice(-2)}-${("0" + start_date.getDate()).slice(-2)}`;
        const data_final = `${end_date.getFullYear()}-${("0" + (end_date.getMonth() + 1)).slice(-2)}-${("0" + end_date.getDate()).slice(-2)}`;

        const temp:any = [];

        for (let item in data.curadoria) {
          temp.push({
            id: parseInt(item.replace("'", "").replace("'", "")),
            verba_total: parseFloat(data.curadoria[item].verba_curadoria).toFixed(2)
          })
        }

        let params = {
          data_inicio,
          data_final,
          curadorias: temp
        }

        // console.log("params", params, `/verba/${selectedItem.id}`);

        await api.put(`/verba/${selectedItem.id}`, params);

        addToast({
          type: 'success',
          title: 'Verba atualizada com sucesso',
          description: '',
        });

        goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }
        
        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar verba',
            description: err.response?.data[error] ?
              err.response.data[error] :
              'Erro ao atualizar verba, tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, goBack, startDate, endDate, selectedItem],
  );

  function goBack() {
    history.push('/curadorias/verba');
  }

  const isValidInitialDate = (date:any) => {
    return date >= initialData
  };

  const isValidEndDate = (date:any) => {
    return date >= startDate
  };

  if(loading){
    return (
      <Loading />
    )
  }

  return (
    <Container>
      <Content>
        <Card>
          <div className="form">
            <div className="header">
              <h2>Editar Verba das Curadorias</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            <Form ref={formRef} onSubmit={handleSubmit}>
                <WrapRow>
                  <WrapRowItem>
                      <span className="label-datapicker-input">Início:</span>
                      <DatePicker
                        closeOnScroll={true}
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="input-datapicker-input"
                        filterDate={isValidInitialDate}
                        disabled
                      />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow style={{marginBottom:"20px"}}>
                  <WrapRowItem>
                      <span className="label-datapicker-input">Término:</span>
                      <DatePicker
                        closeOnScroll={true}
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="input-datapicker-input"
                        filterDate={isValidEndDate}
                        disabled
                      />
                  </WrapRowItem>
                </WrapRow>

                {curadorias.map((curadoria:any) => (
                  <Scope key={`${curadoria.nome}`} path={`curadoria['${curadoria.id}']`}>
                    <WrapRow>
                      <WrapRowItem>
                        <FormInputMoneyMask
                          name="verba_curadoria"
                          type="number"
                          placeholder={`${curadoria.nome}`}
                          defaultValue={parseFloat(curadoria.verba_total).toFixed(2)}
                          step=".01"
                          min="0"
                          onChange={(input) => {
                            let txt = input.target.value;

                          //   if(txt){
                          //     txt = txt.replace(/\D/g,'');
                          //   }

                            if(txt.substring(0,4) == "0.00" || txt.substring(0,4) == "0,00"){
                              txt = txt.slice(4);
                            }
                            
                            if(!txt){
                              txt = "0.00";
                            }
                            formRef.current?.setFieldValue(`curadoria['${curadoria.id}'].verba_curadoria`, txt);
                          }}
                        />
                      </WrapRowItem>
                    </WrapRow>
                  </Scope>
                ))}

                <Button type="submit" loading={loading}>
                  Atualizar
                </Button>

              </Form>
          </div>
        </Card>
      </Content>
    </Container>
  );
};

export default VerbaCuradoriasEdicao;
