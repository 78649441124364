import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import slugify from 'slugify';
import Select from 'react-select';

import { useAuth } from '../../../../hooks/auth';
import { useToast } from '../../../../hooks/toast';

import getValidationErrors from '../../../../utils/getValidationErrors';

import FormInput from '../../../../components/FormInput';
// import FormInputMask from '../../../../components/FormInputMask';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import Loading from '../../../../components/Loading';

import api from '../../../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem,
} from './styles';

interface RolesFormData {
  id: string;
  name: string;
  slug: string;
  description: string;
  permissions: any;
}

interface PermissionsFormData {
  id: string;
  name: string;
  slug: string;
}

interface OptionsData {
  value: any;
  label: string;
}

const RolesEdicao: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location:any = useLocation();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<RolesFormData>({} as RolesFormData);
  const [permissions, setPermissions] = useState<PermissionsFormData[]>([]);
  const [optionsPermissions, setOptionsPermissions] = useState<OptionsData[]>([]);
  const [selectedPermission, setSelectedPermission] = useState<OptionsData[]>([]);

  useEffect(() => {
    getPermissions();
  }, []);

  useEffect(() => {
    if(location && location.state){
      setSelectedItem(location.state.selectedItem);
    }
  }, [location]);

  useEffect(() => {
    if(selectedItem && selectedItem.name){
      formRef.current?.setFieldValue("name", selectedItem.name);
      formRef.current?.setFieldValue("description", selectedItem.description);
    }
  }, [selectedItem]);

  useEffect(() => {
    if(selectedItem.id && optionsPermissions.length > 0){
      //console.log("selectedItem", selectedItem);

      if(selectedItem.permissions){
        let temp:OptionsData[] = [];
        permissions.forEach((permission:PermissionsFormData) => {
          selectedItem.permissions.forEach((permission_:PermissionsFormData) => {
            if(permission.id === permission_.id){
              temp.push({
                value: permission_.id, 
                label: permission_.name
              })
            }
          });
        });

        if(temp){
          setSelectedPermission(temp);
        }
      }
    }
  }, [selectedItem, optionsPermissions]);

  useEffect(() => {
    if (permissions.length === 0) return;

    var options: OptionsData[] = [];

    permissions.map(item => {
      if(item.slug !== "aprovar-contrato-ensineme" && item.slug !== "aprovar-contrato-ensino" && item.slug !== "aprovar-contrato-medicina" && item.slug !== "aprovar-contrato-pos-graduacao" && item.slug !== "aprovar-contrato-premium" && item.slug !== "aprovar-contrato-educare"){
        options.push({ value: item.id, label: item.name });
      }
    });

    setOptionsPermissions(options);
    //console.log("options", options);
  }, [permissions]);

  const getPermissions = useCallback(async () => {
    try {
      const response = await api.get(`/permissions`);
      //console.log("response", response.data);

      setPermissions(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
    }
  }, []);

  const createSlug = useCallback((name) => {
    let slug = slugify(name);
    return slug.toLowerCase();
  }, []);

  const handleSubmit = useCallback(
    async (data: RolesFormData) => {
      try {
        //console.log("handleSubmit", data);

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          description: Yup.string().required('Descrição obrigatória')
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        data.slug = createSlug(data.name);
        data.permissions = selectedPermission.map((item:any) => item.value);

        
        //console.log("data", data);

        await api.put(`/roles/${selectedItem.id}`, data);

        addToast({
          type: 'success',
          title: 'Role atualizada com sucesso',
          description: '',
        });

        goBack();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }
        
        //console.log(err.response?.data);

        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar permission',
            description: err.response?.data[error] ?
              err.response.data[error] :
              'Erro ao atualizar role, tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, selectedItem, goBack, selectedPermission],
  );

  function handleChangeSelectedPermission(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedPermission([]);
      return;
    };

    if (newValue.length === 0) {
      setSelectedPermission([]);
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue", newValue.value);

    setSelectedPermission(newValue);
  };

  function goBack() {
    history.push('/account/roles');
  }

  if(loading){
    return (
      <Loading />
    )
  }

  return (
    <Container>
      <Content>
        <Card>
          <div className="form">
            <div className="header">
              <h2>Editar Role</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            <Form ref={formRef} onSubmit={handleSubmit}>
                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="name"
                      type="text"
                      placeholder="Role"
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="description"
                      type="text"
                      placeholder="Descrição"
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow style={{ marginTop: "10px", marginBottom: "30px" }}>
                  <WrapRowItem>
                    <label>Permissões:</label>
                    <Select
                      isMulti
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedPermission}
                      options={optionsPermissions}
                      value={selectedPermission}
                    />
                  </WrapRowItem>
                </WrapRow>

                <Button type="submit" loading={loading}>
                  Salvar
            </Button>

              </Form>
          </div>
        </Card>
      </Content>
    </Container>
  );
};

export default RolesEdicao;
