import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { BsFileEarmarkText } from 'react-icons/bs';
import { FiCheckSquare, FiCopy, FiSquare } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

// import convertUTCDateToLocalDate from '../../utils/convertUTCDateToLocalDate';

import { ClearButton, Container, Content, TextField } from './styles';

interface ContratosInterface {
  id: string;
  hash: string;
  data_contrato: string;
  prazo_entrega: string;
  tema: any;
  status: any;
  observacoes: string;
  produtos: any;
  especialista: any;
  tipo_contratacao: string;
  disciplina_producao: any;
  curso_producao: any;
}

const ContratosPermitirPagamento: React.FC = () => {
  const history = useHistory();

  const { selectedClient } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal);

  const [loading, setLoading] = useState(true);
  const [contratos, setContratos] = useState<ContratosInterface[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showAreaAtuacaoContratos, setShowAreaAtuacaoContratos] = useState(
    false,
  );

  useEffect(() => {
    if (!selectedClient) return;

    getContratos();
  }, [selectedClient, showAreaAtuacaoContratos]);

  useEffect(() => {
    if (clearSelectedRows) {
      setClearSelectedRows(false);
    }
  }, [clearSelectedRows]);

  useEffect(() => {
    if (selectedClient && selectedClient.id) {
      if (selectedClient.nome !== 'EnsineMe') {
        setShowFilter(true);
      }
    }
  }, [selectedClient]);

  const filtered = useMemo(() => {
    if (!filterText) return contratos;

    let search = filterText.toLowerCase();

    search = search.split('-').join('');
    search = search.split('.').join('');

    search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    return contratos.filter(contrato => {
      return (
        contrato.tema?.titulo
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(search) ||
        contrato.disciplina_producao?.disciplina?.titulo
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(search) ||
        contrato.curso_producao?.curso?.titulo
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(search) ||
        contrato.hash.toLowerCase().includes(search) ||
        contrato.tema?.curador_responsavel?.toLowerCase().includes(search) ||
        contrato.tema?.curadoria?.nome?.toLowerCase().includes(search) ||
        contrato.tipo_contratacao.toLowerCase().includes(search) ||
        contrato.status?.descricao
          ?.toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(search) ||
        contrato.especialista?.dados_usuario?.nome
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(search) ||
        contrato.especialista?.dados_usuario?.cpf
          .toLowerCase()
          .includes(search) ||
        contrato.produtos[0]?.servico?.titulo
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(search) ||
        (contrato.tema && contrato.tema.id
          ? 'Tema'
          : contrato.disciplina_producao
          ? 'Disciplina'
          : 'Curso'
        )
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(search)
      );
    });
  }, [filterText, contratos]);

  const getContratos = useCallback(async () => {
    setLoading(true);

    try {
      if (showAreaAtuacaoContratos) {
        const response = await api.get(`/contrato`, {
          params: {
            todos_contratos_atuacao: true,
            status: ['aceite', 'erro_de_permissao'],
            id_cliente: selectedClient.id,
            considerar_area_atuacao: selectedClient.nome === 'EnsineMe' ? 0 : 1,
          },
        });
        //console.log('response', response.data);

        setContratos(response.data);
      } else {
        const response = await api.get(`/contrato`, {
          params: {
            status: ['aceite', 'erro_de_permissao'],
            id_cliente: selectedClient.id,
            considerar_area_atuacao: selectedClient.nome === 'EnsineMe' ? 0 : 1,
          },
        });

        // console.log("params", {
        //   status: ['aceite', 'erro_de_permissao'],
        //   id_cliente: selectedClient.id,
        //   considerar_area_atuacao: (selectedClient.nome === "EnsineMe" ? 0 : 1)
        // });
        // console.log('response', response);

        setContratos(response.data);
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
  }, [selectedClient, showAreaAtuacaoContratos]);

  const permitirPagamentoSelectedRows = useCallback(() => {
    MySwal.fire({
      // title: 'Tem certeza que deseja remover?',
      showCancelButton: true,
      confirmButtonText: `Permitir`,
      confirmButtonColor: '#1CAEBD',
      cancelButtonColor: '#312e38',
      cancelButtonText: 'Cancelar',
      // icon: 'warning',
      html:
        '<div style="font-size:20px">Tem certeza que deseja permitir o pagamento de todos os contratos selecionados?</div>',
      // position: 'top'
    }).then(result => {
      if (result.isConfirmed) {
        permitirPagamentos(selectedRows);
      }
    });
  }, [selectedRows]);

  const permitirPagamentos = useCallback(
    async rows => {
      //console.log('permitirPagamentos', rows);
      setLoading(true);

      try {
        const params = {
          hashs: selectedRows.map((row: any) => row.hash),
        };

        //console.log('params', params);

        await api.post(`/contrato-mudanca-status`, params);

        setClearSelectedRows(true);

        addToast({
          type: 'success',
          title: 'Pagamentos permitidos com sucesso',
          description: '',
        });

        getContratos();
      } catch (err) {
        getContratos();

        if (err) {
          for (let error in err.response?.data) {
            addToast({
              type: 'error',
              title: 'Erro ao permitir pagamentos',
              description: err.response?.data[error]
                ? err.response.data[error]
                : 'Erro ao permitir pagamentos, tente novamente mais tarde.',
            });
          }
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao permitir pagamentos',
            description:
              'Erro ao permitir pagamentos, tente novamente mais tarde.',
          });
        }
      }
    },
    [selectedRows],
  );

  let parseText = function (text: string, limit: number) {
    if (text.length > limit)
      for (let i = limit; i > 0; i--) {
        if (
          text.charAt(i) === ' ' &&
          (text.charAt(i - 1) != ',' ||
            text.charAt(i - 1) != '.' ||
            text.charAt(i - 1) != ';')
        ) {
          return text.substring(0, i) + '...';
        }
      }
    else return text;
  };

  const handleChange = (state: any) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    //console.log('Selected Rows: ', state.selectedRows);

    setSelectedRows(state.selectedRows);
  };

  // const actions = (
  //   <FiPlus className="icon" onClick={() => {
  //     goStoreForm();
  //   }} />
  // );

  const contextActions = (
    <>
      {/* <FiDollarSign className="icon" style={{ marginRight: "10px" }} onClick={() => {
        permitirPagamentoSelectedRows();
      }} /> */}
      <button
        type="button"
        className="button-top-datatable"
        onClick={() => {
          permitirPagamentoSelectedRows();
        }}
      >
        Permitir Pagamentos
      </button>
    </>
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <div style={{ display: 'flex', width: '100%' }}>
        {showFilter ? (
          <>
            <div
              className="table-subheader-button"
              onClick={() => {
                setShowAreaAtuacaoContratos(false);
              }}
            >
              {showAreaAtuacaoContratos ? <FiSquare /> : <FiCheckSquare />}
              <span>Meus Contratos</span>
            </div>
            <div
              className="table-subheader-button"
              onClick={() => {
                setShowAreaAtuacaoContratos(true);
              }}
            >
              {showAreaAtuacaoContratos ? <FiCheckSquare /> : <FiSquare />}
              <span>Todos</span>
            </div>
          </>
        ) : (
          <div></div>
        )}

        <div style={{ flex: 1 }}></div>

        <TextField
          id="search"
          type="text"
          placeholder="Pesquisar"
          aria-label="Search Input"
          value={filterText}
          onChange={(e: any) => setFilterText(e.target.value)}
        />
        <ClearButton type="button" onClick={handleClear}>
          x
        </ClearButton>
      </div>
    );
  }, [filterText, resetPaginationToggle, showAreaAtuacaoContratos, showFilter]);

  const columns = [
    {
      name: 'ID',
      selector: 'hash',
      sortable: false,
      maxWidth: '140px',
      // right: true,
    },
    // {
    //   name: 'Tema',
    //   selector: 'tema.titulo',
    //   sortable: false,
    //   maxWidth: '400px',
    //   // right: true,
    //   cell: (row: any) => {
    //     let dots_string = parseText(row.tema.titulo, 50);
    //     let reduce = dots_string !== row.tema.titulo;
    //     return (
    //       <>
    //         {reduce ? (
    //           <>
    //             <div data-tip data-for={`${row.tema.id}`}>
    //               {dots_string}
    //             </div>
    //             <ReactTooltip id={`${row.tema.id}`} effect="solid">
    //               <span>{row.tema.titulo}</span>
    //             </ReactTooltip>
    //           </>
    //         ) : (
    //           <div>{dots_string}</div>
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      name: 'Produção',
      selector: 'producao',
      sortable: false,
      maxWidth: '400px',
      // right: true,
      cell: (row: any) => {
        let producao =
          row.tema && row.tema.titulo
            ? row.tema.titulo
            : row.disciplina_producao
            ? row.disciplina_producao?.disciplina?.titulo
            : row.curso_producao?.curso?.titulo;
        let producao_id =
          row.tema && row.tema.id
            ? row.tema.id
            : row.disciplina_producao
            ? row.disciplina_producao?.disciplina?.id
            : row.curso_producao?.curso?.id;

        if (!producao) return '';

        let dots_string = String(producao).substring(0, 50);
        let reduce = dots_string !== producao;
        return (
          <>
            {reduce ? (
              <>
                <div data-tip data-for={`${producao_id}`}>
                  {dots_string}
                </div>
                <ReactTooltip id={`${producao_id}`} effect="solid">
                  <span>{producao}</span>
                </ReactTooltip>
              </>
            ) : (
              <div>{dots_string}</div>
            )}
          </>
        );
      },
    },
    {
      name: 'Tipo de Produção',
      selector: 'tipo',
      sortable: false,
      width: '120px',
      // right: true,
      hide: 1850,
      cell: (row: any) => {
        let tipo =
          row.tema && row.tema.titulo
            ? 'Tema'
            : row.disciplina_producao
            ? 'Disciplina'
            : 'Curso';
        return <span>{tipo}</span>;
      },
    },
    {
      name: 'Tipo de Contrato',
      selector: 'tipo_contrato',
      sortable: false,
      width: '120px',
      // right: true,
      hide: 1580,
      cell: (row: any) => {
        let tipo =
          row.tipo_contrato === 'producao_atual'
            ? 'Produção'
            : row.tipo_contrato === 'manutencao_reativa'
            ? 'Manutenção Reativa'
            : row.tipo_contrato === 'manutencao_proativa'
            ? 'Manutenção Proativa'
            : row.tipo_contrato === 'manutencao_proativa_direcionada'
            ? 'Manutenção Proativa Direcionada'
            : row.tipo_contrato === 'terceirizado'
            ? 'Terceirizado'
            : row.tipo_contrato === 'terceirizado'
            ? 'Terceirizado'
            : row.tipo_contrato === 'prestacao_servico'
            ? 'Prestação de Serviço'
            : row.tipo_contrato.replace('_', ' ')[0].toUpperCase() +
              row.tipo_contrato.replace('_', ' ').substring(1);
        return <span>{tipo}</span>;
      },
    },
    {
      name: 'Especialista',
      selector: 'especialista.dados_usuario.nome',
      sortable: false,
      maxWidth: '240px',
      // right: true,
      hide: 1210,
      cell: (row: any) => {
        let nome = row.especialista.dados_usuario
          ? row.especialista.dados_usuario.nome
          : '';
        return <span>{nome}</span>;
      },
    },
    {
      name: 'Contratação',
      selector: 'tipo_contratacao',
      sortable: false,
      width: '120px',
      // right: true,
      hide: 1210,
      cell: (row: any) => <p>{row.tipo_contratacao.replace('_', ' ')}</p>,
    },
    // {
    //   name: 'Data Contrato',
    //   selector: 'data_contrato',
    //   sortable: false,
    //   width: '130px',
    //   // right: true,
    //   hide: 1590,
    //   cell: (row: any) => {
    //     let dt_data_contrato = convertUTCDateToLocalDate(new Date(row.data_contrato));

    //     return (
    //       <span>
    //         {`${("0" + dt_data_contrato.getDate()).slice(-2)}/${("0" + (dt_data_contrato.getMonth() + 1)).slice(-2)}/${dt_data_contrato.getFullYear()}`}
    //       </span>
    //     )
    //   }
    // },
    // {
    //   name: 'Prazo Entrega',
    //   selector: 'prazo_entrega',
    //   sortable: false,
    //   // right: true,
    //   width: '130px',
    //   hide: 1470,
    //   cell: (row: any) => {
    //     let dt_prazo_entrega = convertUTCDateToLocalDate(new Date(row.prazo_entrega));
    //     let prazo_vencido = dt_prazo_entrega < new Date();

    //     return (
    //       <span style={{ color: prazo_vencido ? '#ff0000' : '' }}>
    //         {`${("0" + dt_prazo_entrega.getDate()).slice(-2)}/${("0" + (dt_prazo_entrega.getMonth() + 1)).slice(-2)}/${dt_prazo_entrega.getFullYear()}`}
    //       </span>
    //     )
    //   }
    // },
    {
      name: selectedClient.tem_curadoria ? 'Curadoria' : 'Área de Atuação',
      selector: selectedClient.tem_curadoria ? 'curadoria' : 'area_atuação',
      sortable: false,
      // right: true,
      maxWidth: '150px',
      cell: (row: any) => {
        if (selectedClient.tem_curadoria) {
          return (
            <span>
              {row.tema
                ? row.tema?.curadoria?.nome
                : row.disciplina_producao
                ? row.disciplina_producao?.curadoria?.nome
                : row.curso_producao?.curadoria?.nome}
            </span>
          );
        } else {
          return (
            <span>
              {row.tema
                ? row.tema?.area_atuacao?.nome
                : row.disciplina_producao
                ? row.disciplina_producao?.area_atuacao?.nome
                : row.curso_producao?.area_atuacao?.nome}
            </span>
          );
        }
      },
    },
    {
      name: selectedClient.tem_curadoria ? 'Curador' : 'Responsável',
      selector: 'tema.curador_responsavel',
      sortable: false,
      // width: '120px',
      // right: true,
      cell: (row: any) => {
        if (row.tema) {
          let curador_responsavel = row.tema
            ? row.tema?.curador_responsavel
              ? row.tema?.curador_responsavel.replace('|', ', ')
              : ''
            : '';
          return <span>{curador_responsavel}</span>;
        } else if (row.disciplina_producao) {
          let curador_responsavel = row.disciplina_producao
            ? row.disciplina_producao?.curador_responsavel
              ? row.disciplina_producao?.curador_responsavel.replace('|', ', ')
              : ''
            : '';
          return <span>{curador_responsavel}</span>;
        } else if (row.curso_producao) {
          let curador_responsavel = row.curso_producao
            ? row.curso_producao?.curador_responsavel
              ? row.curso_producao?.curador_responsavel.replace('|', ', ')
              : ''
            : '';
          return <span>{curador_responsavel}</span>;
        }
      },
    },
    {
      name: 'Produto',
      selector: 'produto',
      sortable: false,
      // right: true,
      hide: 640,
      cell: (row: any) => {
        return row.produtos.map((item: any) => {
          return (
            <span key={`${item.servico?.titulo}`}>{item.servico?.titulo} </span>
          );
        });
      },
    },
    {
      name: 'Valor Total',
      selector: 'valor_total',
      sortable: false,
      // right: true,
      width: '110px',
      hide: 1310,
      cell: (row: any) => {
        return <span>R$ {parseFloat(row.valor_total).toFixed(2)}</span>;
      },
    },
    {
      name: 'Situação Atual',
      selector: 'status.descricao',
      sortable: false,
      // right: true,
      hide: 1450,
      cell: (row: any) => (
        <div
          style={{
            color: '#fff',
            backgroundColor:
              row.status.slug == 'criado'
                ? '#F09B0E'
                : row.status.slug == 'aceite'
                ? '#95B759'
                : row.status.slug == 'planejado'
                ? '#AFB7C2'
                : row.status.slug == 'cancelado'
                ? '#E94F51'
                : row.status.slug == 'recusado'
                ? '#333'
                : row.status.slug == 'pagamento_permitido'
                ? '#800980'
                : row.status.slug == 'pagamento_comandado'
                ? '#0783C2'
                : row.status.slug == 'aguardando_aprovacao'
                ? '#F09B61'
                : row.status.slug == 'erro_de_permissao'
                ? '#444'
                : '#666',

            padding: '5px',
            borderRadius: '3px',
            width: '150px',
            textAlign: 'center',
          }}
        >
          {row.status.slug == 'criado' && <span>contrato criado</span>}
          {row.status.slug == 'aceite' && <span>contrato aceito</span>}
          {row.status.slug == 'planejado' && <span>contrato planejado</span>}
          {row.status.slug == 'cancelado' && <span>contrato cancelado</span>}
          {row.status.slug == 'recusado' && <span>contrato recusado</span>}
          {row.status.slug == 'pagamento_permitido' && (
            <span>{row.status.descricao}</span>
          )}
          {row.status.slug == 'pagamento_comandado' && (
            <span>{row.status.descricao}</span>
          )}
          {row.status.slug == 'aguardando_aprovacao' && (
            <span>{row.status.descricao}</span>
          )}
          {row.status.slug == 'erro_de_permissao' && (
            <span>{row.status.descricao}</span>
          )}
        </div>
      ),
    },
    {
      name: 'Ações',
      button: true,
      cell: (row: any) => {
        console.log('selected', row);

        return (
          <div className="wrapper-table-actions">
            {
              <div
                className={`table-action`}
                onClick={() =>
                  history.push('/especialistas/visualizar', {
                    selectedItem: { id: row.id_especialista },
                  })
                }
              >
                <FiCopy data-tip data-for="tipVisualizarEspecialista" />
              </div>
            }
            <Link
              className={`table-action`}
              target={'_blank'}
              to={{
                pathname: '/contratos/visualizar-contrato',
                search: `?id_contrato=${row.id}`,
                // hash: "#the-hash",
                state: { id_contrato: row.id },
              }}
            >
              <BsFileEarmarkText data-tip data-for="tipVisualizarContrato" />
            </Link>

            <ReactTooltip id="tipVisualizarContrato" effect="solid">
              <span>Visualizar Contrato</span>
            </ReactTooltip>

            <ReactTooltip id="tipVisualizarEspecialista" effect="solid">
              <span>Visualizar Especialista</span>
            </ReactTooltip>
          </div>
        );
      },
    },
  ];

  return (
    <Container>
      <Content>
        <Card>
          <DataTable
            title="Contratos - Permitir Pagamentos"
            columns={columns}
            data={filtered}
            selectableRows
            highlightOnHover
            onSelectedRowsChange={handleChange}
            // defaultSortField="especialista.email"
            pagination
            // actions={actions}
            contextActions={contextActions}
            noDataComponent="Nenhum registro encontrado."
            paginationComponentOptions={{
              rowsPerPageText: 'Itens por página',
              rangeSeparatorText: 'de',
            }}
            clearSelectedRows={clearSelectedRows}
            contextMessage={{
              singular: 'item selecionado',
              plural: 'itens selecionados',
              message: '',
            }}
            progressComponent={<Loading />}
            progressPending={loading}
            subHeader
            subHeaderComponent={subHeaderComponent}
            paginationResetDefaultPage={resetPaginationToggle}
          />
        </Card>
      </Content>
    </Container>
  );
};

export default ContratosPermitirPagamento;
