import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiLogIn, FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../../../hooks/auth';
import { useToast } from '../../../../hooks/toast';
import getValidationErrors from '../../../../utils/getValidationErrors';

import FormInput from '../../../../components/FormInput';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';

import api from '../../../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem
} from './styles';

interface ResetPasswordFormData {
  password: string;
  password_confirmation: string;
  current_password: string;
}

const ChangePassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const { user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      formRef.current?.setFieldValue("email", user.email);
    }
  }, [user])

  const handleSubmit = useCallback(
    async (data: ResetPasswordFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          current_password: Yup.string().required('Senha atual obrigatória'),
          password: Yup.string().required('Senha obrigatória'),
          password_confirmation: Yup.string()
            .required('Confirmação de senha obrigatória')
            .oneOf([Yup.ref('password'), ''], 'Confirmação de senha incorreta'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { password, password_confirmation, current_password } = data;

        const params = {
          current_password,
          password,
          password_confirmation,
        };

        //console.log("params", params);

        await api.put('/atualizar-senha', params);

        addToast({
          type: 'success',
          title: 'Senha atualizada com sucesso',
          description: 'Realize o login com sua nova senha.',
        });

        history.push('/main');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar senha',
            description: err.response?.data[error]?.message ?
              err.response.data[error].message :
              'Ocorreu um error ao atualizar senha, tente novamente.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast],
  );

  return (
    <Container>
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Card>
            <h2>Alterar Senha</h2>

            <FormInput
              name="email"
              type="text"
              placeholder="E-mail"
              disabled
            />

            <FormInput
              name="current_password"
              type="password"
              placeholder="Senha atual"
              autoComplete="off"
            />

            <FormInput
              name="password"
              type="password"
              placeholder="Nova senha"
              autoComplete="off"
            />

            <FormInput
              name="password_confirmation"
              type="password"
              placeholder="Confirmação de senha"
              autoComplete="off"
            />

          </Card>

          <Button type="submit" loading={loading}>
            Atualizar
          </Button>

        </Form>
      </Content>
    </Container>
  );
};

export default ChangePassword;
