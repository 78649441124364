import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';


import api from '../../services/api';

import {
  DropzoneContainer
} from './styles';

interface UploadInputInterface {
  item: any;
  setItems: any;
}

const UploadInput: React.FC<UploadInputInterface> = ({ item, setItems }) => {
  const onDrop = useCallback(async acceptedFiles => {
    try {
      //console.log("onDrop", acceptedFiles);

      const data = new FormData();
      data.append("arquivo", acceptedFiles[0]);

      const response = await api.post("/arquivo", data);
      //console.log("upload response", response.data);

      setItems(item, response.data);

    } catch (error) {
      //console.log("upload error", error);
    }
  }, [item, setItems])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <DropzoneContainer {...getRootProps()}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Coloque seu arquivo aqui ...</p> :
          <p>Arraste o arquivo, ou clique para selecioná-lo.</p>
      }
    </DropzoneContainer>
  );
};

export default UploadInput;
