import React, { useCallback, useState, useEffect, useMemo, useRef, forwardRef } from 'react';
import { FiDollarSign, FiPlus, FiEdit } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import ReactTooltip from 'react-tooltip';
import { BsFileEarmarkText } from "react-icons/bs";
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
import Loading from '../../components/Loading';
import FormInputMaskSimple from '../../components/FormInputMaskSimple';
// import DateRangePicker from '../../components/DateRangePicker';

import api from '../../services/api';

import convertUTCDateToLocalDate from '../../utils/convertUTCDateToLocalDate';

import {
  Container,
  Content,
  TextField,
  ClearButton
} from './styles';
import { WrapRow, WrapRowItem } from '../_modules/Account/Roles/styles';

interface DataInterface {
  id: string;
  cpf: string;
  nome: string;
  codigo_sap: string;
  codigo_tema: string;
  cnpj: string;
  tema: string;
  curadoria: string;
  tema_curador_responsavel: string;
  disciplina: string;
  disciplina_codigo: string;
}

const RelatorioTreinamento: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const { token, selectedClient } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal);

  var date = new Date();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<DataInterface[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [startDate, setStartDate] = useState<any>(new Date(date.getFullYear(), date.getMonth(), 1));
  const [endDate, setEndDate] = useState<any>(new Date(date.getFullYear(), date.getMonth() + 1, 0));
  const [dataInicioPermissaoCorte, setDataInicioPermissaoCorte] = useState("");
  const [dataFimPermissaoCorte, setDataFimPermissaoCorte] = useState("");

  useEffect(() => {
    if (clearSelectedRows) {
      setClearSelectedRows(false)
    }
  }, [clearSelectedRows]);

  useEffect(() => {
    if (!startDate) return;
    if (!endDate) return;

    const data_inicio_permissao_corte = `${startDate.getFullYear()}-${("0" + (startDate.getMonth() + 1)).slice(-2)}-${("0" + startDate.getDate()).slice(-2)}`;
    const data_fim_permissao_corte = `${endDate.getFullYear()}-${("0" + (endDate.getMonth() + 1)).slice(-2)}-${("0" + endDate.getDate()).slice(-2)}`;

    setDataInicioPermissaoCorte(data_inicio_permissao_corte);
    setDataFimPermissaoCorte(data_fim_permissao_corte);

    getReport(data_inicio_permissao_corte, data_fim_permissao_corte);
  }, [startDate, endDate])

  const filtered = useMemo(() => {
    if (!filterText)
      return data

    let search = filterText.toLowerCase();

    search = search.split("-").join("");
    search = search.split(".").join("");

    search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

    return data.filter((item: any) => {
      return item.cpf?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        item.nome?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        item.email?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        item.projeto?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        item.titulo?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        (item.realizado_em ? "Realizado" : "").toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search)
    })
  }, [filterText, data])

  const getReport = useCallback(async (data_inicio_permissao_corte, data_fim_permissao_corte) => {
    try {
      setLoading(true);

      const params: any = {
        data_inicio_permissao_corte,
        data_fim_permissao_corte,
        id_cliente: selectedClient.id
      }

      // console.log("params", params);

      const response = await api.get(`/relatorio-treinamento/1`, { params });
      // console.log("response", response.data);

      setData(response.data ? response.data : []);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, [selectedClient]);

  const downloadReport = useCallback(async () => {
    try {
      setLoading(true);

      if (!startDate) return;
      if (!endDate) return;

      const data_inicio_permissao_corte = `${startDate.getFullYear()}-${("0" + (startDate.getMonth() + 1)).slice(-2)}-${("0" + startDate.getDate()).slice(-2)}`;
      const data_fim_permissao_corte = `${endDate.getFullYear()}-${("0" + (endDate.getMonth() + 1)).slice(-2)}-${("0" + endDate.getDate()).slice(-2)}`;

      setDataInicioPermissaoCorte(data_inicio_permissao_corte);
      setDataFimPermissaoCorte(data_fim_permissao_corte);

      const params = {
        data_inicio_permissao_corte,
        data_fim_permissao_corte,
        id_cliente: selectedClient.id
      };

      // console.log("params", params);

      const response = await api.post(`/relatorio-treinamento`, params);
      //console.log("response", response.data);

      if (response.data) {
        window.open(`${response.data}?token=${token}`, '_blank');
      }

    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate, selectedClient]);

  const handleChange = (state: any) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    //console.log('Selected Rows: ', state.selectedRows);

    setSelectedRows(state.selectedRows);
  };

  const actions = (
    <div
      className="table-action-button"
      onClick={() => {
        downloadReport();
      }}
    >
      {/* <FiPlus /> */}
      <span>Download</span>
    </div>
  );

  // const contextActions = (
  //   <Form ref={formRef} onSubmit={comandarPagamentoSelectedRows}>
  //     <WrapRow>
  //       <WrapRowItem>
  //         <FormInputMaskSimple
  //           name="data_pagamento_previsao"
  //           type="text"
  //           placeholder="Previsão de Pagamento"
  //           mask="99/99/9999"
  //         />
  //       </WrapRowItem>
  //       <WrapRowItem>
  //         <button type="submit" className="button-top-datatable" >
  //           {/* <FiDollarSign className="icon" style={{ marginRight: "10px" }} /> */}
  //           Comandar Pagamentos
  //         </button>
  //       </WrapRowItem>
  //     </WrapRow>
  //   </Form>
  // );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const CustomInputDatePicker = forwardRef(({ value, onClick }: any, ref: any) => (
      <button className="custom-input-datepicker" onClick={onClick} ref={ref}>
        {value}
      </button>
    ));

    return (
      <WrapRow>
        <span className="label-datapicker-input">Início:</span>
        <DatePicker
          closeOnScroll={true}
          selected={startDate}
          // onChange={(date) => setStartDate(date)}
          onChange={(date) => setStartDate(date)}
          dateFormat="dd/MM/yyyy"
          customInput={<CustomInputDatePicker />}
        />
        <span className="label-datapicker-input">Término:</span>
        <DatePicker
          closeOnScroll={true}
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          dateFormat="dd/MM/yyyy"
          customInput={<CustomInputDatePicker />}
        />
        <div style={{ flex: 1 }}></div>
        <TextField id="search" type="text" placeholder="Pesquisar" aria-label="Search Input" value={filterText} onChange={(e: any) => setFilterText(e.target.value)} />
        <ClearButton type="button" onClick={handleClear}>x</ClearButton>
      </WrapRow>
    );
  }, [filterText, resetPaginationToggle, startDate, endDate]);

  let parseText = function (text: string, limit: number) {
    if (text.length > limit)
      for (let i = limit; i > 0; i--) {
        if (
          text.charAt(i) === ' ' &&
          (text.charAt(i - 1) != ',' ||
            text.charAt(i - 1) != '.' ||
            text.charAt(i - 1) != ';')
        ) {
          return text.substring(0, i) + '...';
        }
      }
    else return text;
  };

  const columns = [
    {
      name: 'Nome',
      selector: 'nome',
      sortable: false,
      maxWidth: '300px',
      // right: true,
    },
    {
      name: 'CPF',
      selector: 'cpf',
      sortable: false,
      maxWidth: '140px',
      // right: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: false,
      maxWidth: '140px',
      // right: true,
    },
    {
      name: 'Titulo',
      selector: 'titulo',
      sortable: false,
      maxWidth: '300px',
      // right: true,
    },
    {
      name: 'Projeto',
      selector: 'projeto',
      sortable: false,
      // maxWidth: '400px',
      // right: true,
    },
    {
      name: 'Realizado',
      selector: 'realizado',
      sortable: false,
      // maxWidth: '300px',
      // right: true,
      cell: (row: any) => {
        return (
          <>
            {row.realizado_em ? "Sim" : "Não"}
          </>
        )
      }
    },
  ];

  return (
    <Container>
      <Content>
        <Card>
          <DataTable
            title="Relatório - Treinamento"
            columns={columns}
            data={filtered}
            // selectableRows
            highlightOnHover
            onSelectedRowsChange={handleChange}
            // defaultSortField="especialista.email"
            pagination
            actions={actions}
            // contextActions={contextActions}
            noDataComponent="Nenhum registro encontrado."
            paginationComponentOptions={{
              rowsPerPageText: 'Itens por página',
              rangeSeparatorText: 'de'
            }}
            clearSelectedRows={clearSelectedRows}
            contextMessage={{
              singular: 'item selecionado',
              plural: 'itens selecionados',
              message: ''
            }}
            progressComponent={<Loading />}
            progressPending={loading}
            subHeader
            subHeaderComponent={subHeaderComponent}
            paginationResetDefaultPage={resetPaginationToggle}
          />
        </Card>
      </Content>
    </Container>
  );
};

export default RelatorioTreinamento;
