import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from "styled-components";

import GlobalStyle from './styles/global';
import AppProvider from './hooks';
import Routes from './routes';

import { lightTheme, darkTheme } from './styles/theme';
import './services/firebase';

const App: React.FC = () => {
  const [selectedTheme, setSelectedTheme] = useState("light");

  useEffect(() => {
    const savedTheme = localStorage.getItem("@YDUQS:theme");

    if(savedTheme){
      if(savedTheme === "light" || savedTheme === "dark"){
        setSelectedTheme(savedTheme);
      }
    }
  },[])

  return (
    <Router>
      <ThemeProvider theme={selectedTheme === "dark" ? darkTheme : lightTheme}>
        <AppProvider>
          <Routes />
        </AppProvider>

        <GlobalStyle />
      </ThemeProvider>
    </Router>
  );
};

export default App;
