import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiX } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as Yup from 'yup';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import convertUTCDateToLocalDate from '../../utils/convertUTCDateToLocalDate';
import getValidationErrors from '../../utils/getValidationErrors';

import Button from '../../components/Button';
import Card from '../../components/Card';
import FormInput from '../../components/FormInput';

import api from '../../services/api';

import {
  CargaTemaData,
  Container,
  Content,
  WrapRow,
  WrapRowItem,
} from './styles';

interface EspecialistasVisualizarInterface {
  id: any;
  email: string;
  dados_usuario: any;
  dados_pagamento: any;
  contatos: any;
  endereco: any;
  blacklist: [];
}

// interface OptionsData {
//   value: boolean;
//   label: string;
// }

const EspecialistasVisualizar: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location: any = useLocation();

  const { token, permissions } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal);

  const [loading, setLoading] = useState(true);
  const [selectedEspecialista, setSelectedEspecialista] = useState<
    EspecialistasVisualizarInterface
  >({} as EspecialistasVisualizarInterface);
  const [selectedItem, setSelectedItem] = useState<
    EspecialistasVisualizarInterface
  >({} as EspecialistasVisualizarInterface);
  const [documents, setDocuments] = useState<any>([]);
  // const [selectedRows, setSelectedRows] = useState([]);
  // const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [showUpdateSAP, setShowUpdateSAP] = useState(false);

  useEffect(() => {
    if (location && location.state) {
      //console.log("location", location);
      setSelectedItem(location.state.selectedItem);
    }
  }, [location]);

  useEffect(() => {
    if (selectedItem && selectedItem.id) {
      //console.log("selectedItem", selectedItem);
      setDocuments([]);
      getEspecialista(selectedItem.id);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (selectedEspecialista && selectedEspecialista.id) {
      // console.log("selectedEspecialista", selectedEspecialista, selectedEspecialista.id);
      getDocuments();
    }
  }, [selectedEspecialista]);

  const getEspecialista = useCallback(async id => {
    setLoading(true);

    try {
      const response = await api.get(`/usuario/${id}`);
      // console.log("response getEspecialista", id, response.data);

      setSelectedEspecialista(response.data);

      // if(response.data?.dados_usuario?.pj_cnpj && !response.data?.dados_usuario?.pj_razao_social){
      //   searchCNPJ(response.data?.dados_usuario?.pj_cnpj);
      // }
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getDocuments = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(
        `/contrato-ultimo-documento/${selectedEspecialista.id}`,
      );
      setDocuments(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, [selectedEspecialista]);

  function goBack() {
    history.goBack();
  }

  const dataNascimento = useMemo(() => {
    if (selectedEspecialista.dados_usuario?.data_nascimento) {
      let dt = convertUTCDateToLocalDate(
        new Date(selectedEspecialista.dados_usuario?.data_nascimento),
      );
      return `${('0' + dt.getDate()).slice(-2)}/${(
        '0' +
        (dt.getMonth() + 1)
      ).slice(-2)}/${dt.getFullYear()}`;
    } else {
      return '';
    }
  }, [selectedEspecialista]);

  const dataExpediçãoRG = useMemo(() => {
    if (selectedEspecialista.dados_usuario?.rg_expedicao_data) {
      let dt = convertUTCDateToLocalDate(
        new Date(selectedEspecialista.dados_usuario?.rg_expedicao_data),
      );
      return `${('0' + dt.getDate()).slice(-2)}/${(
        '0' +
        (dt.getMonth() + 1)
      ).slice(-2)}/${dt.getFullYear()}`;
    } else {
      return '';
    }
  }, [selectedEspecialista]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        //console.log("handleSubmit", data);

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          codigo_sap: Yup.string().required('Código SAP obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const params = {
          dados_usuario: {
            codigo_sap: data.codigo_sap,
          },
        };

        //console.log("params", params);

        await api.put(`/usuario/${selectedEspecialista.id}`, params);

        addToast({
          type: 'success',
          title: 'Código SAP atualizado com sucesso',
          description: '',
        });

        setShowUpdateSAP(false);
        getEspecialista(selectedEspecialista.id);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar código SAP',
            description: err.response?.data[error]
              ? err.response.data[error]
              : 'Erro ao atualizar código SAP, tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, selectedEspecialista],
  );

  const requestRemoveRedlist = useCallback(
    blacklist_id => {
      MySwal.fire({
        title: 'Tem certeza que deseja remover o usuário da redlist?',
        showCancelButton: true,
        confirmButtonText: `Remover`,
        confirmButtonColor: '#1CAEBD',
        cancelButtonColor: '#312e38',
        cancelButtonText: 'Cancelar',
        input: 'text',
        // icon: 'warning',
        html:
          '<div style="font-size:20px">Preencha o campo abaixo com o motivo da exclusão</div>',
        // position: 'top'
      }).then(result => {
        if (result.isConfirmed) {
          removeRedlist(selectedItem.id, blacklist_id, result.value);
        }
      });
    },
    [selectedItem],
  );

  const removeRedlist = useCallback(
    async (id_usuario, blacklist_id, descricao) => {
      //console.log("remove", rowId);
      setLoading(true);

      try {
        await api.delete(`/blacklist/${blacklist_id}`, {
          params: { descricao_exclusao: descricao },
        });

        addToast({
          type: 'success',
          title: 'Usuário removido da redlist com sucesso',
          description: '',
        });

        getEspecialista(id_usuario);
      } catch (err) {
        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao remover usuário da redlist',
            description: err.response?.data[error]
              ? err.response.data[error]
              : 'Erro ao remover usuário da redlist, tente novamente mais tarde.',
          });
        }
      }
    },
    [],
  );

  return (
    <Container>
      <Content>
        <Card>
          <div className="header" style={{ height: '0px', padding: '15px' }}>
            <h2>
              {selectedEspecialista.dados_usuario?.nome
                ? selectedEspecialista.dados_usuario?.nome
                : selectedEspecialista.email}
            </h2>
            <FiX onClick={() => goBack()} className="icon" />
          </div>

          <CargaTemaData>
            <div className="wrapper-data">
              <div className="wrapper-data-label">Dados Cadastrais:</div>
              <div className="wrapper-data-info">
                Nome: {selectedEspecialista.dados_usuario?.nome}
              </div>
              <div className="wrapper-data-info">
                Email: {selectedEspecialista.email}
              </div>
              <div className="wrapper-data-info">
                CPF: {cpf.format(selectedEspecialista.dados_usuario?.cpf)}
              </div>
              <div className="wrapper-data-info">
                RG: {selectedEspecialista.dados_usuario?.rg}
              </div>
              <div className="wrapper-data-info">
                Emissão do RG: {dataExpediçãoRG}
              </div>
              <div className="wrapper-data-info">
                Órgão Emissor do RG:{' '}
                {selectedEspecialista.dados_usuario?.rg_expedicao_orgao}
              </div>
              <div className="wrapper-data-info">
                PIS: {selectedEspecialista.dados_usuario?.pis}
              </div>
              <div className="wrapper-data-info">
                Data de Nascimento: {dataNascimento}
              </div>
              <div className="wrapper-data-info">
                Estado Civil: {selectedEspecialista.dados_usuario?.estado_civil}
              </div>
              <div className="wrapper-data-info">
                Naturalidade: {selectedEspecialista.dados_usuario?.naturalidade}
              </div>
              <div className="wrapper-data-info">
                Nacionalidade:{' '}
                {selectedEspecialista.dados_usuario?.nacionalidade}
              </div>
              <div className="wrapper-data-info">
                Linkedin: {selectedEspecialista.dados_usuario?.linkedin}
              </div>
              <div className="wrapper-data-info">
                Lattes: {selectedEspecialista.dados_usuario?.lattes}
              </div>

              <div className="wrapper-data-label" style={{ marginTop: '30px' }}>
                Dados Profissionais:
              </div>
              <div className="wrapper-data-info">
                Matricula: {selectedEspecialista.dados_usuario?.matricula}
              </div>

              <div className="wrapper-data-info">
                SAP: {selectedEspecialista.dados_usuario?.codigo_sap}
                <span
                  className="link-editar"
                  onClick={() => {
                    setShowUpdateSAP(!showUpdateSAP);
                  }}
                >
                  {showUpdateSAP
                    ? selectedEspecialista.dados_usuario?.codigo_sap
                      ? ' - Voltar'
                      : 'Voltar'
                    : selectedEspecialista.dados_usuario?.codigo_sap
                    ? ' - Editar'
                    : 'Cadastrar'}
                </span>
              </div>
              <div className="wrapper-data-info">
                Formação Acadêmica:{' '}
                {selectedEspecialista.dados_usuario?.formacao_academica}
              </div>
              <div className="wrapper-data-info">
                Já elaborou material didático Yduqs:{' '}
                {selectedEspecialista.dados_usuario?.material_didatico_yduqs}
              </div>
              <div className="wrapper-data-info">
                Já elaborou material didático Digital:{' '}
                {selectedEspecialista.dados_usuario?.material_didatico_digital}
              </div>
              <div className="wrapper-data-info">
                Já elaborou Livros ou Artigos:{' '}
                {selectedEspecialista.dados_usuario?.livros_artigos}
              </div>

              {showUpdateSAP && (
                <>
                  <Form ref={formRef} onSubmit={handleSubmit}>
                    <WrapRow style={{ marginTop: '20px' }}>
                      <WrapRowItem>
                        <FormInput
                          name="codigo_sap"
                          type="text"
                          placeholder="Código SAP"
                        />
                      </WrapRowItem>
                    </WrapRow>

                    <Button
                      type="submit"
                      loading={loading}
                      style={{ marginBottom: '15px' }}
                    >
                      Salvar
                    </Button>
                  </Form>
                </>
              )}

              <div className="wrapper-data-label" style={{ marginTop: '30px' }}>
                Endereço:
              </div>
              <div className="wrapper-data-info">
                CEP: {selectedEspecialista.endereco?.cep}
              </div>
              <div className="wrapper-data-info">
                Rua: {selectedEspecialista.endereco?.rua}
              </div>
              <div className="wrapper-data-info">
                Número: {selectedEspecialista.endereco?.numero}
              </div>
              <div className="wrapper-data-info">
                Complemento: {selectedEspecialista.endereco?.complemento}
              </div>
              <div className="wrapper-data-info">
                Bairro: {selectedEspecialista.endereco?.bairro}
              </div>
              <div className="wrapper-data-info">
                Cidade: {selectedEspecialista.endereco?.cidade}
              </div>
              <div className="wrapper-data-info">
                Estado: {selectedEspecialista.endereco?.estado}
              </div>

              <div className="wrapper-data-label" style={{ marginTop: '30px' }}>
                Contatos:
              </div>
              {selectedEspecialista.contatos?.map((item: any) => (
                <div key={item.conteudo}>
                  <div className="wrapper-data-info">
                    <span style={{ textTransform: 'capitalize' }}>
                      {item.tipo}
                    </span>
                    : {item.conteudo}
                  </div>
                </div>
              ))}

              {selectedEspecialista.dados_usuario?.pj_cnpj && (
                <>
                  <div
                    className="wrapper-data-label"
                    style={{ marginTop: '30px' }}
                  >
                    Dados Pessoa Jurídica:
                  </div>
                  <div className="wrapper-data-info">
                    CNPJ:{' '}
                    {cnpj.format(selectedEspecialista.dados_usuario?.pj_cnpj)}
                  </div>
                  <div className="wrapper-data-info">
                    Razão social:{' '}
                    {selectedEspecialista.dados_usuario?.pj_razao_social}
                  </div>
                  <div className="wrapper-data-info">
                    CEP: {selectedEspecialista.dados_usuario?.pj_cep}
                  </div>
                  <div className="wrapper-data-info">
                    Rua: {selectedEspecialista.dados_usuario?.pj_rua}
                  </div>
                  <div className="wrapper-data-info">
                    Número: {selectedEspecialista.dados_usuario?.pj_numero}
                  </div>
                  <div className="wrapper-data-info">
                    Complemento:{' '}
                    {selectedEspecialista.dados_usuario?.pj_complemento}
                  </div>
                  <div className="wrapper-data-info">
                    Bairro: {selectedEspecialista.dados_usuario?.pj_bairro}
                  </div>
                  <div className="wrapper-data-info">
                    Cidade: {selectedEspecialista.dados_usuario?.pj_cidade}
                  </div>
                  <div className="wrapper-data-info">
                    Estado: {selectedEspecialista.dados_usuario?.pj_uf}
                  </div>
                </>
              )}

              {!(
                selectedEspecialista.dados_usuario?.matricula &&
                selectedEspecialista.dados_usuario?.matricula.length >= 3
              ) && (
                <>
                  <div
                    className="wrapper-data-label"
                    style={{ marginTop: '30px' }}
                  >
                    Dados para Pagamento:
                  </div>
                  <div className="wrapper-data-info">
                    Tipo de Conta Bancária:{' '}
                    {selectedEspecialista.dados_pagamento?.conta_pessoa_fisica
                      ? ' Pessoa Física'
                      : ' Pessoa Jurídica'}
                  </div>
                  <div className="wrapper-data-info">
                    Banco: {selectedEspecialista.dados_pagamento?.banco}
                  </div>
                  <div className="wrapper-data-info">
                    Agência: {selectedEspecialista.dados_pagamento?.agencia}
                  </div>
                  <div className="wrapper-data-info">
                    Conta Corrente:{' '}
                    {selectedEspecialista.dados_pagamento?.conta}
                  </div>
                  <div className="wrapper-data-info">
                    Digito Verificador:{' '}
                    {selectedEspecialista.dados_pagamento?.digito_verificador}
                  </div>
                </>
              )}

              <div className="wrapper-data-label" style={{ marginTop: '30px' }}>
                Documentos:
              </div>
              {documents.map((item: any) => (
                <div key={item.url} className="wrapper-data-info">
                  <a href={`${item.url}?token=${token}`} target="_blank">
                    <span className="link-editar">{item.tipo}</span>
                  </a>
                </div>
              ))}

              <div className="wrapper-data-label" style={{ marginTop: '30px' }}>
                Redlist:
              </div>
              <div className="blacklist-contract-container">
                {selectedEspecialista.blacklist?.map((item: any) => {
                  if (!item.data_exclusao) {
                    return (
                      <div
                        key={item.contrato.hash}
                        className="blacklist-contract"
                      >
                        <span className="blacklist-contract-title">
                          <a
                            className="link"
                            href={`/contratos/visualizar-contrato?id_contrato=${item.contrato.id}`}
                            target="_blank"
                          >
                            <span>{item.contrato.hash}</span>
                          </a>
                          {permissions.indexOf('deletar-redlist') > -1 && (
                            <a
                              className="link"
                              onClick={() => requestRemoveRedlist(item.id)}
                            >
                              <span>Excluir</span>
                            </a>
                          )}
                        </span>
                        <span>Motivo: {item.descricao}</span>
                        <span>
                          Descrição: {item.status_blacklist.descricao}
                        </span>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </CargaTemaData>
        </Card>
      </Content>
    </Container>
  );
};

export default EspecialistasVisualizar;
