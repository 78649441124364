import React, { useCallback, useState, useEffect, useMemo, useRef, forwardRef } from 'react';
import { FiDollarSign, FiPlus, FiEdit } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import ReactTooltip from 'react-tooltip';
import { BsFileEarmarkText } from "react-icons/bs";
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Select from 'react-select';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
import Loading from '../../components/Loading';
import FormInputMaskSimple from '../../components/FormInputMaskSimple';
// import DateRangePicker from '../../components/DateRangePicker';

import api from '../../services/api';

import convertUTCDateToLocalDate from '../../utils/convertUTCDateToLocalDate';

import {
  Container,
  Content,
  TextField,
  ClearButton
} from './styles';
import { WrapRow, WrapRowItem } from '../_modules/Account/Roles/styles';

interface DataInterface {
  id: string;
  hash: string;
  tema: any;
  especialista: any;
  tema_curador_responsavel: string;
  curadoria: string;
  tipo_contratacao: string;
}

interface PeriodosInterface {
  id: any;
  periodo: string;
}

interface ProjetosInterface {
  id: any;
  titulo: string;
}

interface OptionsData {
  value: any;
  label: string;
}

const RelatorioContratoTema: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const { token, selectedClient } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal);

  var date = new Date();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<DataInterface[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [periodos, setPeriodos] = useState<PeriodosInterface[]>([]);
  const [optionsPeriodos, setOptionsPeriodos] = useState<OptionsData[]>([]);
  const [selectedPeriodo, setSelectedPeriodo] = useState<OptionsData>({} as OptionsData);
  const [projetos, setProjetos] = useState<ProjetosInterface[]>([]);
  const [optionsProjetos, setOptionsProjetos] = useState<OptionsData[]>([]);
  const [selectedProjeto, setSelectedProjeto] = useState<OptionsData>({} as OptionsData);

  useEffect(() => {
    getPeriodos();
    getProjetos();
  }, []);

  useEffect(() => {
    if (clearSelectedRows) {
      setClearSelectedRows(false)
    }
  }, [clearSelectedRows]);

  useEffect(() => {
    var options: OptionsData[] = [];

    periodos.map(item => {
      options.push({ value: item.id, label: item.periodo });
    });

    setOptionsPeriodos(options);
    //console.log("options periodos", options);
  }, [periodos]);

  useEffect(() => {
    var options: OptionsData[] = [];

    projetos.map(item => {
      options.push({ value: item.id, label: item.titulo });
    });

    setOptionsProjetos(options);
    //console.log("options projetos", options);
  }, [projetos]);

  useEffect(() => {
    if(selectedPeriodo && selectedPeriodo.value && selectedProjeto && selectedProjeto.value){
      getReport();
    }
  }, [selectedPeriodo, selectedProjeto])

  const filtered = useMemo(() => {
    if (!filterText)
      return data

    let search = filterText.toLowerCase();

    search = search.split("-").join("");
    search = search.split(".").join("");

    search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

    return data.filter((item:any) => {
      return item.tema.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        // item.hash.toLowerCase().includes(search) ||
        // item.especialista.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        item.curador.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        item.curadoria.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search)
    })
  }, [filterText, data])

  const getPeriodos = useCallback(async () => {
    try {
      setLoading(true);
      
      const response = await api.get(`/periodo`);
      //console.log("response periodos", response.data);

      setPeriodos(response.data ? response.data : []);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getProjetos = useCallback(async () => {
    try {
      setLoading(true);
      
      const response = await api.get(`/projeto`);
      //console.log("response projeto", response.data);

      setProjetos(response.data ? response.data : []);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getReport = useCallback(async () => {
    try {
      setLoading(true);

      //console.log("id_periodo", selectedPeriodo.value);
      
      const response = await api.get(`/relatorio-status-contrato/1`, {
        params: {
          id_periodo: selectedPeriodo.value,
          id_projeto: selectedProjeto.value,
          id_cliente: selectedClient.id
        }
      });
      // console.log("response", response.data);

      setData(response.data ? response.data.temas : []);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, [selectedPeriodo, selectedProjeto, selectedClient]);

  const downloadReport = useCallback(async () => {
    try {
      setLoading(true);
      
      const params = {
        id_periodo: selectedPeriodo.value,
        id_projeto: selectedProjeto.value,
        id_cliente: selectedClient.id
      };

      //console.log("params", params);

      const response = await api.post(`/relatorio-status-contrato`, params);
      //console.log("response", response.data);

      if(response.data){
        window.open(`${response.data}?token=${token}`, '_blank');
      }

    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, [selectedPeriodo, selectedProjeto, selectedClient]);
  
  const handleChange = (state: any) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    //console.log('Selected Rows: ', state.selectedRows);

    setSelectedRows(state.selectedRows);
  };

  const actions = (
    <div
      className="table-action-button"
      onClick={() => {
        downloadReport();
      }}
    >
      {/* <FiPlus /> */}
      <span>Download</span>
    </div>
  );

  function handleChangeSelectedPeriodo(newValue: any, actionMeta: any) {
    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue Periodo", newValue.value);

    setSelectedPeriodo(newValue);
  };

  function handleChangeSelectedProjeto(newValue: any, actionMeta: any) {
    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue Projeto", newValue.value);

    setSelectedProjeto(newValue);
  };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <WrapRow>
        
        <div style={{display:'flex', flexDirection:'row'}}>
          <span className="label-datapicker-input">Período:</span>
          <div style={{width:'100px'}}>
            <Select
              styles={{
                // Fixes the overlapping problem of the component
                menu: provided => ({ ...provided, zIndex: 9999 }),
                // input: styles => ({ ...styles }),
              }}
              placeholder='Período'
              onChange={handleChangeSelectedPeriodo}
              options={optionsPeriodos}
              value={selectedPeriodo}
            />
          </div>

          <span className="label-datapicker-input" style={{marginLeft:'40px'}}>Projeto:</span>
          <div style={{width:'150px'}}>
            <Select
              styles={{
                // Fixes the overlapping problem of the component
                menu: provided => ({ ...provided, zIndex: 9999 }),
                // input: styles => ({ ...styles }),
              }}
              placeholder='Projeto'
              onChange={handleChangeSelectedProjeto}
              options={optionsProjetos}
              value={selectedProjeto}
            />
          </div>
        </div>

        
        <div style={{flex:1}}></div>
        <TextField id="search" type="text" placeholder="Pesquisar" aria-label="Search Input" value={filterText} onChange={(e: any) => setFilterText(e.target.value)} />
        <ClearButton type="button" onClick={handleClear}>x</ClearButton>
      </WrapRow>
    );
  }, [filterText, resetPaginationToggle, selectedPeriodo, handleChangeSelectedPeriodo]);

  
  let parseText = function (text: string, limit: number) {
    if (text.length > limit)
      for (let i = limit; i > 0; i--) {
        if (
          text.charAt(i) === ' ' &&
          (text.charAt(i - 1) != ',' ||
            text.charAt(i - 1) != '.' ||
            text.charAt(i - 1) != ';')
        ) {
          return text.substring(0, i) + '...';
        }
      }
    else return text;
  };

  const columns = [
    {
      name: 'Tema',
      selector: 'tema',
      sortable: false,
      maxWidth: '300px',
      // right: true,
    },
    {
      name: 'Conteúdo Acadêmico Teórico Demonstrativo',
      selector: 'servicos',
      sortable: false,
      width: '130px',
      // hide: 1350,
      // right: true,
      cell: (row: any) => {
        const servico = row.servicos.map((item:any) => {
          if(item.servico === "Conteúdo Acadêmico Teórico Demonstrativo"){
            return item
          }
        }).filter((item:any) => item)[0];
        return <span>R$ {servico?.valor_total}</span>;
      },
    },
    {
      name: 'Conteúdo Acadêmico Teórico Conceitual',
      selector: 'servicos',
      sortable: false,
      width: '130px',
      // hide: 1350,
      // right: true,
      cell: (row: any) => {
        const servico = row.servicos.map((item:any) => {
          if(item.servico === "Conteúdo Acadêmico Teórico Conceitual"){
            return item
          }
        }).filter((item:any) => item)[0];
        return <span>R$ {servico?.valor_total}</span>;
      },
    },
    {
      name: 'Conteúdo Acadêmico Híbrido',
      selector: 'servicos',
      sortable: false,
      width: '130px',
      // hide: 1350,
      // right: true,
      cell: (row: any) => {
        const servico = row.servicos.map((item:any) => {
          if(item.servico === "Conteúdo Acadêmico Híbrido"){
            return item
          }
        }).filter((item:any) => item)[0];
        return <span>R$ {servico?.valor_total}</span>;
      },
    },
    {
      name: 'Gravação Vídeo Estúdio EnsineMe',
      selector: 'servicos',
      sortable: false,
      width: '130px',
      hide: 1225,
      // right: true,
      cell: (row: any) => {
        const servico = row.servicos.map((item:any) => {
          if(item.servico === "Gravação Vídeo Estúdio EnsineMe"){
            return item
          }
        }).filter((item:any) => item)[0];
        return <span>R$ {servico?.valor_total}</span>;
      },
    },
    {
      name: 'Gravação Vídeo Remoto',
      selector: 'servicos',
      sortable: false,
      width: '130px',
      hide: 1350,
      // right: true,
      cell: (row: any) => {
        const servico = row.servicos.map((item:any) => {
          if(item.servico === "Gravação Vídeo Remoto"){
            return item
          }
        }).filter((item:any) => item)[0];
        return <span>R$ {servico?.valor_total}</span>;
      },
    },
    {
      name: 'Gravação Podcast Estúdio EnsineMe',
      selector: 'servicos',
      sortable: false,
      width: '130px',
      hide: 1500,
      // right: true,
      cell: (row: any) => {
        const servico = row.servicos.map((item:any) => {
          if(item.servico === "Gravação Podcast Estúdio EnsineMe"){
            return item
          }
        }).filter((item:any) => item)[0];
        return <span>R$ {servico?.valor_total}</span>;
      },
    },
    {
      name: 'Gravação Podcast Remoto',
      selector: 'servicos',
      sortable: false,
      width: '130px',
      hide: 1610,
      // right: true,
      cell: (row: any) => {
        const servico = row.servicos.map((item:any) => {
          if(item.servico === "Gravação Podcast Remoto"){
            return item
          }
        }).filter((item:any) => item)[0];
        return <span>R$ {servico?.valor_total}</span>;
      },
    },
  ];

  return (
    <Container>
      <Content>
        <Card>
          <DataTable
            title="Relatório - Contratos por Tema"
            columns={columns}
            data={filtered}
            // selectableRows
            highlightOnHover
            onSelectedRowsChange={handleChange}
            // defaultSortField="especialista.email"
            pagination
            actions={actions}
            // contextActions={contextActions}
            noDataComponent="Nenhum registro encontrado."
            paginationComponentOptions={{
              rowsPerPageText: 'Itens por página',
              rangeSeparatorText: 'de'
            }}
            clearSelectedRows={clearSelectedRows}
            contextMessage={{
              singular: 'item selecionado',
              plural: 'itens selecionados',
              message: ''
            }}
            progressComponent={<Loading />}
            progressPending={loading}
            subHeader
            subHeaderComponent={subHeaderComponent}
            paginationResetDefaultPage={resetPaginationToggle}
          />
        </Card>
      </Content>
    </Container>
  );
};

export default RelatorioContratoTema;
