import React, { useCallback, useState, useEffect } from 'react';
import { FiTrash, FiPlus, FiEdit } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content
} from './styles';

interface ProducaoInterface {
  id: string;
  descricao: string;
  link: string;
  data_entrega: string;
  produto: any;
  tema: any;
  tags: any;
}

const Producoes: React.FC = () => {
  const history = useHistory();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal)

  const [loading, setLoading] = useState(true);
  const [producoes, setProducoes] = useState<ProducaoInterface[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);

  useEffect(() => {
    getProducoes();
  }, []);

  useEffect(() => {
    if (clearSelectedRows) {
      setClearSelectedRows(false)
    }
  }, [clearSelectedRows]);

  const getProducoes = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/producao`);
      //console.log("response", response.data);

      setProducoes(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const removeSelectedRows = useCallback(() => {
    MySwal.fire({
      // title: 'Tem certeza que deseja remover?',
      showCancelButton: true,
      confirmButtonText: `Remover`,
      confirmButtonColor: '#1CAEBD',
      cancelButtonColor: '#312e38',
      cancelButtonText: 'Cancelar',
      // icon: 'warning',
      html: '<div style="font-size:20px">Tem certeza que deseja remover?</div>',
      // position: 'top'
    }).then((result) => {
      if (result.isConfirmed) {
        selectedRows.map((row: any) => {
          removeRow(row.id);
        });
      }
    })
  }, [selectedRows]);

  const removeRow = useCallback(async (rowId) => {
    //console.log("remove", rowId);
    setLoading(true);

    try {
      await api.delete(`/producao/${rowId}`);

      setClearSelectedRows(true);

      addToast({
        type: 'success',
        title: 'Produção removida com sucesso',
        description: '',
      });

      getProducoes();
    } catch (err) {
      for (let error in err.response?.data) {
        addToast({
          type: 'error',
          title: 'Erro ao remover produção',
          description: err.response?.data[error] ?
            err.response.data[error] :
            'Erro ao remover produção, tente novamente mais tarde.',
        });
      }
    }
  }, []);

  function goStoreForm() {
    history.push("/producoes/cadastro");
  }

  function goUpdateForm() {
    history.push("/producoes/edicao", {
      selectedItem: selectedRows[0]
    });
  }

  const handleChange = (state: any) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    //console.log('Selected Rows: ', state.selectedRows);

    setSelectedRows(state.selectedRows);
  };

  const actions = (
    <FiPlus className="icon" onClick={() => {
      goStoreForm();
    }} />
  );

  const contextActions = (
    <>
      {selectedRows.length === 1 &&
        <FiEdit className="icon" style={{ marginRight: "15px" }} onClick={() => {
          goUpdateForm();
        }} />
      }
      <FiTrash className="icon" style={{ marginRight: "10px" }} onClick={() => {
        removeSelectedRows();
      }} />
    </>
  );

  const columns = [
    {
      name: 'Descrição',
      selector: 'descricao',
      sortable: false,
      // right: true,
    },
    {
      name: 'Link',
      selector: 'link',
      sortable: false,
      // right: true,
    },
    {
      name: 'Produto',
      selector: 'produto.titulo',
      sortable: false,
      // right: true,
    },
    {
      name: 'Tema',
      selector: 'tema.titulo',
      sortable: false,
      // right: true,
    },
  ];


  return (
    <Container>
      <Content>
        <Card>
          <DataTable
            title="Produções"
            columns={columns}
            data={producoes}
            selectableRows
            highlightOnHover
            onSelectedRowsChange={handleChange}
            defaultSortField="titulo"
            pagination
            actions={actions}
            contextActions={contextActions}
            noDataComponent="Nenhum registro encontrado."
            paginationComponentOptions={{
              rowsPerPageText: 'Itens por página',
              rangeSeparatorText: 'de'
            }}
            clearSelectedRows={clearSelectedRows}
            contextMessage={{
              singular: 'item selecionado',
              plural: 'itens selecionados',
              message: ''
            }}
            progressComponent={<Loading />}
            progressPending={loading}
          />
        </Card>
      </Content>
    </Container>
  );
};

export default Producoes;
