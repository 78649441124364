import firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyC-RnmhD5724mLcDnm1B0CjNMkFmmGvFDw',
  authDomain: 'beezoo-a8ae9.firebaseapp.com',
  databaseURL: 'https://beezoo-a8ae9.firebaseio.com',
  projectId: 'beezoo-a8ae9',
  storageBucket: 'beezoo-a8ae9.appspot.com',
  messagingSenderId: '701499018731',
  appId: '1:701499018731:web:819e97ce5c9bab0c5d9dae',
  measurementId: 'G-VJGQ4XM3XC',
};

firebase.initializeApp(firebaseConfig);
