import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { FiTrash, FiPlus, FiEdit } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  TextField,
  ClearButton
} from './styles';

interface DisciplinasProducaoInterface {
  id: string;
  titulo: string;
  modelo: any;
  tags: any;
  modulos: any;
}

const DisciplinasProducao: React.FC = () => {
  const history = useHistory();

  // const { user } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal)

  const [loading, setLoading] = useState(true);
  const [disciplinasProducao, setDisciplinasProducao] = useState<DisciplinasProducaoInterface[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filteredItems, setFilteredItems] = useState<DisciplinasProducaoInterface[]>([]);

  useEffect(() => {
    getDisciplinasProducao();
  }, []);

  useEffect(() => {
    if (clearSelectedRows) {
      setClearSelectedRows(false)
    }
  }, [clearSelectedRows]);

  // useEffect(() => {
  //   if (!disciplinasProducao) return;

  //   let search = filterText.toLowerCase();

  //   let result = disciplinasProducao.filter(
  //     item => item.titulo.toLowerCase().includes(search)
  //   );

  //   setFilteredItems(result);
  // }, [filterText, disciplinasProducao])

  const filtered = useMemo(() => {
    if (!filterText)
      return disciplinasProducao




    let search = filterText.toLowerCase();

    search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

    search = search.split("-").join("");
    search = search.split(".").join("");

    return disciplinasProducao.filter(tema => {
      return tema.titulo.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search)
    })
  }, [filterText, disciplinasProducao])


  const getDisciplinasProducao = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/disciplina-producao`, { params: { edicao: 1 }});
      // console.log("response", response.data);

      setDisciplinasProducao(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const removeSelectedRows = useCallback(() => {
    MySwal.fire({
      // title: 'Tem certeza que deseja remover?',
      showCancelButton: true,
      confirmButtonText: `Remover`,
      confirmButtonColor: '#1CAEBD',
      cancelButtonColor: '#312e38',
      cancelButtonText: 'Cancelar',
      // icon: 'warning',
      html: '<div style="font-size:20px">Tem certeza que deseja remover?</div>',
      // position: 'top'
    }).then((result) => {
      if (result.isConfirmed) {
        selectedRows.map((row: any) => {
          removeRow(row.id);
        });
      }
    })
  }, [selectedRows]);

  const removeRow = useCallback(async (rowId) => {
    //console.log("remove", rowId);
    setLoading(true);

    try {
      await api.delete(`/disciplina-producao/${rowId}`);

      setClearSelectedRows(true);

      addToast({
        type: 'success',
        title: 'Disciplina Produção removido com sucesso',
        description: '',
      });

      getDisciplinasProducao();
    } catch (err) {
      for (let error in err.response?.data) {
        addToast({
          type: 'error',
          title: 'Erro ao remover disciplina produção',
          description: err.response?.data[error] ?
            err.response.data[error] :
            'Erro ao remover disciplina produção, tente novamente mais tarde.',
        });
      }
    }
  }, []);

  function goStoreForm() {
    history.push("/disciplinas-producao/cadastro");
  }

  function goUpdateForm() {
    history.push("/disciplinas-producao/edicao", {
      selectedItem: selectedRows[0]
    });
  }

  const handleChange = (state: any) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    //console.log('Selected Rows: ', state.selectedRows);

    setSelectedRows(state.selectedRows);
  };

  // const actions = (
  //   <FiPlus className="icon" onClick={() => {
  //     goStoreForm();
  //   }} />
  // );

  const contextActions = (
    <>
      {selectedRows.length === 1 &&
        <FiEdit className="icon" style={{ marginRight: "15px" }} onClick={() => {
          goUpdateForm();
        }} />
      }
      {/* <FiTrash className="icon" style={{ marginRight: "10px" }} onClick={() => {
        removeSelectedRows();
      }} /> */}
    </>
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <>
        <TextField id="search" type="text" placeholder="Pesquisar" aria-label="Search Input" value={filterText} onChange={(e: any) => setFilterText(e.target.value)} />
        <ClearButton type="button" onClick={handleClear}>x</ClearButton>
      </>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: 'Código da Produção',
      selector: 'codigo_producao',
      sortable: false,
      width: '100px',
      // right: true,
    },
    {
      name: 'Título',
      selector: 'titulo',
      sortable: false,
      // right: true,
      maxWidth: '400px',
      cell: (row: any) => (
        <p>{row.disciplina?.titulo}</p>
      )
    },
    // {
    //   name: 'Modelo',
    //   selector: 'modelo',
    //   sortable: false,
    //   width: '100px',
    //   // right: true,
    //   hide: 1310,
    //   cell: (row: any) => (
    //     <p>{row.modelo?.titulo}</p>
    //   )
    // },
    {
      name: 'Cliente',
      selector: 'cliente',
      sortable: false,
      width: '100px',
      // right: true,
      cell: (row: any) => (
        <p>{row.projeto?.cliente?.nome}</p>
      )
    },
    {
      name: 'Projeto',
      selector: 'projeto',
      sortable: false,
      width: '100px',
      // right: true,
      cell: (row: any) => (
        <p>{row.projeto?.titulo}</p>
      )
    },
    {
      name: 'Período',
      selector: 'periodo',
      sortable: false,
      width: '100px',
      // right: true,
      cell: (row: any) => (
        <p>{row.periodo?.periodo}</p>
      )
    },
    {
      name: 'Curadoria',
      selector: 'curadoria',
      sortable: false,
      width: '125px',
      // right: true,
      hide: 1210,
      cell: (row: any) => (
        <p>{row.curadoria?.nome}</p>
      )
    },
    {
      name: 'Área de Atuação',
      selector: 'area_atuacao',
      sortable: false,
      width: '125px',
      // right: true,
      hide: 1210,
      cell: (row: any) => (
        <p>{row.area_atuacao?.nome}</p>
      )
    },
  ];

  return (
    <Container>
      <Content>
        <Card>
          <DataTable
            title="Disciplinas Produção"
            columns={columns}
            data={filtered}
            selectableRows
            highlightOnHover
            onSelectedRowsChange={handleChange}
            defaultSortField="titulo"
            pagination
            // actions={actions}
            contextActions={contextActions}
            noDataComponent="Nenhum registro encontrado."
            paginationComponentOptions={{
              rowsPerPageText: 'Itens por página',
              rangeSeparatorText: 'de'
            }}
            clearSelectedRows={clearSelectedRows}
            contextMessage={{
              singular: 'item selecionado',
              plural: 'itens selecionados',
              message: ''
            }}
            progressComponent={<Loading />}
            progressPending={loading}
            subHeader
            subHeaderComponent={subHeaderComponent}
            paginationResetDefaultPage={resetPaginationToggle}
          />
        </Card>
      </Content>
    </Container>
  );
};

export default DisciplinasProducao;
