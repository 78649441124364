import styled, { keyframes } from 'styled-components';
// import { shade } from 'polished';

export const Container = styled.div`
  
`;

export const Content = styled.div`
  margin-top: 70px;
  padding: 0 30px;
`;

export const WarningDevelopementMode = styled.div`
  background-color: ${({ theme }) => theme.warningColor};
  color: #fff;
  padding: 5px 15px;
  position: fixed;
  right: 0;
  bottom: 0;
`;