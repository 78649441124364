import React from 'react';
import Lottie from 'react-lottie';
import animationData from './54548-loading.json';

import { Container } from './styles';

const Loading: React.FC = () => (
  <Container>
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      }}
      height={150}
      width={150}
    />
  </Container>
);

export default Loading;
