import React, { useCallback, useState, useEffect } from 'react';
import { FiTrash, FiPlus, FiEdit, FiCheckCircle, FiCircle, FiLink, FiDownload, FiCheckSquare, FiSquare, FiVideo } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ReactPlayer from 'react-player';
import DOMPurify from 'dompurify';
import { MdPlayCircleOutline } from "react-icons/md";

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  WrapRow,
  WrapRowItem
} from './styles';

import playImage from '../../assets/play.png';

const TreinamentoEspecialista: React.FC = () => {
  const history = useHistory();

  const { user, updateUser, token } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal)

  const aprovePercentual = 90;

  const [loading, setLoading] = useState(true);
  const [time, setTime] = useState(0);
  const [last, setLast] = useState(0);
  const [duration, setDuration] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [ended, setEnded] = useState(false);
  const [midiasTreinamento, setMidiasTreinamento] = useState<any>([]);
  const [arquivos, setArquivos] = useState<any>([]);
  const [projetos, setProjetos] = useState<any>([]);
  const [selectedMidia, setSelectedMidia] = useState<any>(null);
  const [outros, setOutros] = useState(true);
  const [finishedTreinamento, setFinishedTreinamento] = useState<any>([]);
  const [selectedTreinamento, setSelectedTreinamento] = useState<any>(null);

  useEffect(() => {
    getUserData();
    getArquivos();
  }, [])

  useEffect(() => {
    let tmp: any[] = midiasTreinamento.map((item: any) => {
      if (projetos.find((x: any) => ((x.id === item.projeto?.id && x.filtro === true)))) {
        return item
      } else {
        if (item.projeto === null && outros) {
          return item
        }
      }
    })

    tmp = tmp.filter(n => n)

    setArquivos(tmp);
  }, [midiasTreinamento, projetos, outros])

  const getUserData = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/usuario/${user.id}`);
      //console.log("response", response.data);

      // if(response.data?.dados_usuario?.treinamento_obrigatorio){
      //   setCompleted(response.data.dados_usuario.treinamento_obrigatorio);
      //   setEnded(response.data.dados_usuario.treinamento_obrigatorio);
      // }

    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getArquivos = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/projeto-especialista/1`);
      // console.log("projeto-especialista", response.data);

      // <b>Projeto:</b> {item.projeto?.titulo} | <b>Modelo:</b> {item.modelo?.titulo} | <b>Serviço:</b> {item.servico?.titulo}

      const temp = [
        ...response.data.itens,
        ...response.data.itens_sem_projeto
      ];

      const projetos: any[] = [];

      temp.map(item => {
        const tmp: any = [];

        if (item.projeto?.titulo) {
          tmp.push(`<b>Projeto:</b> ${item.projeto?.titulo}`);

          if (!projetos.find(x => x.id === item.projeto.id)) {
            projetos.push({
              ...item.projeto,
              filtro: true
            })
          }
        }

        if (item.treinamento && item.video_url) {
          item.id_video = (item.video_url.split("?token=")[1]).split("&")[0];
        }

        if (item.modelo?.titulo) {
          tmp.push(`<b>Modelo:</b> ${item.modelo?.titulo}`);
        }
        if (item.servico?.titulo) {
          tmp.push(`<b>Serviço:</b> ${item.servico?.titulo}`);
        }

        item.subtitle = tmp.join(' | ');

        return item;
      });

      setProjetos(projetos);
      setMidiasTreinamento(temp);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  // const updateTime = useCallback((evt, item) => {
  //   const duration = item.duration;

  //   // let played = evt.played;
  //   let playedSeconds = evt.playedSeconds;
  //   //console.log("playedSeconds", playedSeconds, played);

  //   if((playedSeconds - last) > 0 && (playedSeconds - last) < 3){
  //     setTime(time + (playedSeconds - last));
  //   }
  //   setLast(playedSeconds);

  //   if(time*100/duration >= aprovePercentual && completed === false){
  //     setCompleted(true);
  //     saveTreinamentoObrigatorio();
  //   }
  // }, [time, last, completed]);

  const saveTreinamentoObrigatorio = useCallback(async (id_midia = null) => {
    setLoading(true);

    if (!id_midia) {
      id_midia = selectedMidia;
    }

    // console.log("id_midia", id_midia);

    try {
      const params = {
        id_projeto_midia: id_midia
      }

      // console.log("params", params);

      const response = await api.post(`/treinamento-especialista`, params);
      // console.log("response", response.data);

      setCompleted(true);
      //console.log("Completed");

      setFinishedTreinamento([id_midia, ...finishedTreinamento])

    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, [selectedMidia]);

  const handleMessagesEventListener = useCallback((event: any) => {
    if (!arquivos) return;
    if (!event.data?.percentual) return;

    if (event.data?.percentual?.duracao) {
      setDuration(event.data?.percentual?.duracao);
    }

    // console.log("arquivos", arquivos);
    // console.log("--")

    const tmp = arquivos.find((x: any) => {
      return x.id === selectedTreinamento.id
    })

    // console.log("tmp:", tmp);

    if (tmp && tmp.id) {
      if (tmp.id !== selectedMidia) {
        setSelectedMidia(tmp.id);
        setLast(0);
      }

      // let played = evt.played;
      let playedSeconds = event.data?.percentual?.tempo;
      //console.log("playedSeconds", playedSeconds, played);

      //console.log("playedSeconds", playedSeconds, last, playedSeconds - last);
      //console.log("playedSeconds - last:", playedSeconds - last, (event.data?.percentual?.duracao/100 + 20))

      if ((playedSeconds - last) > 0 && (playedSeconds - last) < (event.data?.percentual?.duracao / 100 * 2 + 10)) {
        setTime(time + (playedSeconds - last));
      } else {
        if ((playedSeconds - last) < 0 && event.data?.percentual?.marco < 200) {
          setLast(0);
        }
      }

      // console.log("!!!", (time * 100) / (event.data?.percentual?.duracao),)

      // if((time*100)/(event.data?.percentual?.duracao) >= aprovePercentual && completed === false){
      // // // if(time/event.data?.percentual?.duracao >= aprovePercentual && completed === false){
      // //   setCompleted(true);
      //   //console.log("???? Completed");
      // //   // saveTreinamentoObrigatorio();
      // }

      //console.log("time:", time);
      //console.log("playedSeconds:", playedSeconds);
    } else {
      // setSelectedMidia(null);
      setTime(0);
      setLast(0);
    }
  }, [arquivos, time, last, aprovePercentual, completed, selectedMidia, selectedTreinamento]);

  useEffect(() => {
    if (!handleMessagesEventListener) return;

    if (arquivos.length > 0) {
      window.addEventListener('message', handleMessagesEventListener);
    }

    return () => {
      window.removeEventListener('message', handleMessagesEventListener);
    };
  }, [arquivos, handleMessagesEventListener])

  useEffect(() => {
    // console.log("Time:", time, " - ", time * 100 / duration, "%", ' - ', completed);
    // console.log("Time:", `${(time * 100 / duration).toFixed(2)}% de ${aprovePercentual}%`, time * 100 / duration >= aprovePercentual, completed)

    if (time * 100 / duration >= aprovePercentual && completed === false) {
      saveTreinamentoObrigatorio();
    }

    setLast(time);
  }, [time]);

  // useEffect(() => {
  //   //console.log("last", last);
  // }, [last]);

  useEffect(() => {
    if (!selectedMidia) return;

    // console.log("selectedMidia:", selectedMidia);
    // console.log("arquivos:", arquivos);

    const tmp = arquivos.find((x: any) => (x.id === selectedMidia));

    if (!tmp) return;

    if (tmp.realizado) {
      setCompleted(true);
    }

    //console.log("selectedMidia", selectedMidia)
    setLast(0);
  }, [selectedMidia])

  return (
    <Container>
      <Content style={{ padding: "10px" }}>
        {/* <div className="label-links-top">
          <h2>Conteúdo Obrigatório:</h2>  
        </div> */}

        {(midiasTreinamento.length > 0 && projetos.length > 0) &&
          <Card>
            <div style={{ display: "flex", width: "100%" }}>

              <div>
                <div className="item-titulo">
                  Filtrar por Projetos:
                </div>

                <div style={{ display: "flex", width: "100%" }}>
                  {projetos.map((proj: any) => (
                    <div key={`proj-${proj.id}`}
                      className="table-subheader-button"
                      onClick={() => {
                        const tmp = projetos.map((i: any) => {
                          if (i.id === proj.id) {
                            i.filtro = !i.filtro;
                          }
                          return i
                        });

                        setProjetos(tmp);
                      }}
                    >
                      {proj.filtro ?
                        <FiCheckSquare />
                        :
                        <FiSquare />
                      }
                      <span>{proj.titulo}</span>
                    </div>
                  ))}

                  <div
                    className="table-subheader-button"
                    onClick={() => {
                      setOutros(!outros);
                    }}
                  >
                    {outros ?
                      <FiCheckSquare />
                      :
                      <FiSquare />
                    }
                    <span>Outros</span>
                  </div>

                </div>
              </div>
            </div>
          </Card>
        }

        {
          arquivos.map((item: any) => {
            // if ((item.treinamento && item.obrigatorio) || false) {
            return (
              <Card key={`${item.id}`}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                  <div>
                    <div className="item-titulo">
                      {item.titulo}
                    </div>

                    <div className="item-subtitulo" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.subtitle) }}></div>

                    <div className="item-descricao">
                      {item.descricao}
                    </div>
                  </div>
                  <div>
                    {(item.realizado || (finishedTreinamento.includes(item.id))) ?
                      <>
                        {item.treinamento ?
                          <div className="completed-info">
                            <>
                              <FiCheckCircle /> <span>Treinamento realizado</span>
                            </>
                          </div>
                          :
                          <>
                          </>
                        }
                      </>
                      :
                      <>
                        {(item.treinamento && item.obrigatorio) ?
                          <div className="not-completed-info">
                            <span>Treinamento Obrigatório Pendente</span>
                          </div>
                          :
                          <></>
                        }

                        {(!item.treinamento && item.obrigatorio) ?
                          <div className="obrigatorio">
                            <span>Conteúdo Obrigatório</span>
                          </div>
                          :
                          <></>
                        }
                      </>



                    }
                  </div>
                </div>

                {item.treinamento ?
                  <>
                    {(selectedTreinamento && selectedTreinamento.id === item.id) ?
                      <div className='player-wrapper'>
                        <iframe
                          src={item.video_url}
                          title="Video"
                          className='react-player'
                        >
                        </iframe>
                      </div>
                      :
                      <a href={`#${item.id}`} onClick={() => {
                        setTime(0);
                        setLast(0);
                        setDuration(0);
                        setCompleted(item.realizado);
                        setEnded(item.realizado);
                        setSelectedTreinamento(item);
                      }}>
                        <div className="link-url">
                          <MdPlayCircleOutline style={{ fontSize: "50px", color: "#1FB7D5" }} /> <span style={{ marginTop: "15px" }}>Assistir treinamento</span>
                        </div>
                      </a>
                    }
                  </>
                  :
                  <>
                    {item.link_url ?
                      <a href={item.link_url} target="_blank" onClick={() => { saveTreinamentoObrigatorio(item.id) }}>
                        <div className="link-url">
                          <FiLink /> <span>Acessar conteúdo</span>
                        </div>
                      </a>
                      :
                      <>
                        {item.arquivo_nome ?
                          <a href={`https://apicontrato.ensineme.com.br/projeto-midia/${item.id}/${item.arquivo_nome}?token=${token}`} target="_blank" onClick={() => { saveTreinamentoObrigatorio(item.id) }}>
                            <div className="link-url">
                              <FiDownload /> <span>Baixar arquivo</span>
                            </div>
                          </a>
                          :
                          <></>
                        }
                      </>
                    }
                  </>
                }
              </Card>
            )
            // }
          })
        }
      </Content>
    </Container>
  );
};

export default TreinamentoEspecialista;
