import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isWrong: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: ${({ theme }) => theme.formInputBackgroundColor};
  color: ${({ theme }) => theme.formInputPlaceholderColor};
  border-radius: 5px;
  border: 2px solid ${({ theme }) => theme.formInputBackgroundColor};
  width: 100%;
  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.isWrong &&
    css`
      border-color: ${({ theme }) => theme.warningColor};
    `}

  ${props =>
    props.isFocused &&
    css`
      color: ${({ theme }) => theme.focusColor};
      border-color: ${({ theme }) => theme.focusColor};
    `}

  ${props =>
    props.isFilled &&
    css`
      color: ${({ theme }) => theme.focusColor};
    `}


  input {
    flex: 1;
    background: transparent;
    color: ${({ theme }) => theme.formInputTextColor};
    border: 0;
    padding: 16px 16px 16px 8px;

    &::placeholder {
      color: ${({ theme }) => theme.formInputPlaceholderColor};
    }
  }

  svg {
    margin-right: 16px;
  }

  .currency{
    padding-left: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  margin-right: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.formInputLabelColor};
  line-height: 35px;

  & + div {
    margin-bottom: 20px;
  }
`;
