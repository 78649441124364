import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone'

import { useToast } from '../../../hooks/toast';

import api from '../../../services/api';

import {
  DropzoneContainer
} from './styles';

interface UploadInputInterface {
  item: any;
  setItems: any;
  setLoading: any;
}

const UploadInput: React.FC<UploadInputInterface> = ({ item, setItems, setLoading }) => {
  const { addToast } = useToast();

  const onDrop = useCallback(async acceptedFiles => {
    try {
      //console.log("onDrop", acceptedFiles);

      setLoading(true);

      const data = new FormData();
      data.append("upload", acceptedFiles[0]);

      const response = await api.post("/carga-temas", data);
      //console.log("upload response", response.data);

      setItems(item, response.data);
      setLoading(false);

      addToast({
        type: 'success',
        title: 'Lotes importados com sucesso',
        description: '',
      });

    } catch (error) {
      //console.log("upload error", error);
      setLoading(false);

      addToast({
        type: 'error',
        title: 'Erro ao importar lotes',
        description: '',
      });
    }
  }, [item, setItems])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <DropzoneContainer {...getRootProps()}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Coloque seu arquivo aqui ...</p> :
          <p>Arraste o arquivo, ou clique para selecioná-lo.</p>
      }
    </DropzoneContainer>
  );
};

export default UploadInput;
