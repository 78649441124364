import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  .icon {
    font-size: 22px;
    color: #717171;
    cursor: pointer;
  }

  .title-sweetalert{
    font-size: 8px;
    color: red;
  }

  .table-action{
    color: #666;
    font-size: 24px;
    padding: 0 5px;
    cursor: pointer;
  }

  .warning-verba{
    color: ${({ theme }) => theme.warningColor};
    border: 1px solid ${({ theme }) => theme.warningColor};
    border-radius: 4px;
    background: #ffd6d6;
    margin: 40px 5px 40px 5px;
    padding: 8px;
  }
`;

export const Content = styled.main`
  /* max-width: 1120px;
  margin: 10px auto; */
  display: flex;
  margin-bottom: 80px;
  flex-direction: column;

  form{
    width: 100%;

    h2{
      margin-bottom: 30px;
      padding: 5px 0;
    }
  }
`;

export const WrapRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row; 
  flex-wrap: wrap;
`;

export const WrapRowItem = styled.div`
  flex: 1;
  margin: 0 5px;
`;

export const Item = styled.div`
  flex: 1;
  margin: 20px 5px;
  background-color: #F1F1F1;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .title{
    font-size: 18px;
    margin-bottom: 10px;
  }

  .subtitle{
    font-weight: lighter;
  }

  .removeIcon{
    cursor: pointer;
  }
`;

export const TableButton = styled.button`  
  background: ${({ theme }) => theme.buttonBackgroundColor};;
  color: ${({ theme }) => theme.buttonTextColor};;
  border-radius: 5px;
  border: 0;
  padding: 5px 15px;
  font-weight: 500;
  transition: background-color 0.2s;

  &:hover {
    background: ${({ theme }) => shade(0.2, theme.buttonBackgroundColor)};
  }
`;

export const TableButtonAccepted = styled.button`  
  background: ${({ theme }) => theme.successColor};;
  color: ${({ theme }) => theme.buttonTextColor};;
  border-radius: 5px;
  border: 0;
  padding: 5px 15px;
  font-weight: 500;
  transition: background-color 0.2s;

  &:hover {
    background: ${({ theme }) => shade(0.2, theme.successColor)};
  }
`;

export const TableButtonRefused = styled.button`  
  background: ${({ theme }) => theme.warningColor};;
  color: ${({ theme }) => theme.buttonTextColor};;
  border-radius: 5px;
  border: 0;
  padding: 5px 15px;
  font-weight: 500;
  transition: background-color 0.2s;

  &:hover {
    background: ${({ theme }) => shade(0.2, theme.warningColor)};
  }
`;


export const ContratoData = styled.div`
  .wrapper-data{
    margin: 0;
    padding: 15px;
    border-bottom: 1px dashed #e6e6e6;
  }

  .wrapper-data-label{
    margin: 7px 0 15px;
    font-size: 16px;
    font-weight: 600;
  }

  .wrapper-data-info{
    margin: 10px 0;
    font-size: 16px;
  }

  .wrapper-money{
    display: flex;
    flex-direction: row-reverse;
  }

  .wrapper-produto{
    padding: 10px 15px;
    border: 0.5px solid #e6e6e6;
    margin-bottom: 10px;
    background-color: #f9f9f9;
  }

  
`;
